class TemporaryData {
    constructor() {
        let tmp = {};

        this.get = (prop) => {
            return tmp[prop];
        };
        this.set = (prop, data) => {
            tmp[prop] = data;
            return tmp[prop];
        };
    }
}
commons.service('temporaryData', TemporaryData);