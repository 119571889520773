class documentViewer {
    constructor($mdDialog, $uibModal, $window) {
        this.$mdDialog = $mdDialog;
        this.$uibModal = $uibModal;
        this.$window = $window;
        this.restrict = 'A';
    }

    link(scope, elm, attrs) {
        if (scope.document) {
            elm.on('click', (e) => {
                e.preventDefault();
                if (attrs.documentViewer === 'meta') {
                    this.openMetaData(scope.document, scope.titlesDictionary, scope.getTitleByValue, scope.isBidDraft);
                } else {
                    ['edr_request.yaml', 'edr_identification.yaml'].includes(scope.document.title)
                        ? this.openEDR(scope.document)
                        : this.openDocument(scope.document, attrs.documentViewer);
                }
            });
        }
    }

    openMetaData(document, titlesDictionary, getTitleByValue, isBidDraft) {
        return this.$uibModal.open({
            template: `
                <div class="show-document-modal">
                    <div class="modal-body show-document-modal__body">
                        <span class="icon-white close-cross show-document-closer" ng-click="vm.close()"></span>
                        <div class="meta-data">
                            <h3>{{ 'Загальний опис документа' | translate }}</h3>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Статус завантаження' | translate }}</div>
                                <div class="col-sm-8">
                                    <span ng-if="!vm.isBidDraft" style="color: #008c78">{{ 'Завантажено в Прозорро' | translate }}</span>
                                    <span ng-if="vm.isBidDraft">{{ 'Не завантажено в Прозорро' | translate }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Назва файла' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.title }}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Дата публікації' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.datePublished | date : 'dd.MM.yyyy HH:mm' }}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Дата модифікації' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.dateModified | date : 'dd.MM.yyyy HH:mm' }}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Приналежність файла' | translate }}</div>
                                <div class="col-sm-8">
                                    {{ vm.getTitleByValue(vm.document.documentOf) }}
                                    <div ng-if="vm.document.relatedItem">{{ vm.titlesDictionary[vm.document.relatedItem] }}</div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Тип документу' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.documentType | documentTypeCaption }}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'Формат документу' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.format }}</div>
                            </div>
                            <div class="row">
                                <div class="col-sm-4">{{ 'ID документу' | translate }}</div>
                                <div class="col-sm-8">{{ vm.document.id }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            `,
            controller: function($uibModalInstance) {
                this.document = document;
                this.titlesDictionary = titlesDictionary;
                this.getTitleByValue = getTitleByValue;
                this.isBidDraft = isBidDraft;
                this.close = function () {
                    $uibModalInstance.dismiss();
                };
            },
            controllerAs: 'vm',
            windowClass: 'center-modal'
        });
    }

    openEDR(document) {
        const data = () => document;
        this.$mdDialog.show({
            templateUrl: '/static/scripts/modules/Commons/views/modals/overview-edr.html',
            controller: 'overviewEDRController',
            controllerAs: 'vm',
            fullscreen: true,
            resolve: { data }
        });
    }

    openDocument(document, documentViewer) {
        if (!document.url) return;

        let originURL = documentViewer && documentViewer === 'bid'
                ? document.url + '?'
                : '/opc/proxy_doc_download/?doc_url=' + encodeURIComponent(document.url) + '&';

        const modalFormats = [
            'image/bmp',
            'image/jpeg',
            'image/gif',
            'image/png',
            'text/plain',
            'application/pdf'
        ];
        const googleFormats = [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ];
        if (modalFormats.includes(document.format)) {
            return this.$uibModal.open({
                template: `
                    <div class="show-document-modal">
                        <div class="modal-body show-document-modal__body">
                            <span class="icon-white close-cross show-document-closer" ng-click="vm.close()"></span>
                            <iframe ng-src="{{ vm.url }}" height="100%" width="100%" frameborder="0">
                            </iframe>
                        </div>
                    </div>
                `,
                controller: function($uibModalInstance) {
                    this.url = originURL + 'view=proxy';
                    this.close = function () {
                        $uibModalInstance.dismiss();
                    };
                },
                controllerAs: 'vm',
                windowClass: 'center-modal',
                size: 'lg'
            });
        } else if (googleFormats.includes(document.format)) {
            this.$window.open(originURL + 'view=google');
        } else {
            this.$window.open(document.url);
        }
    }
}

commons.directive('documentViewer', ($mdDialog, $uibModal, $window) => new documentViewer($mdDialog, $uibModal, $window));