(function() {
    class VerifySign {
        $onChanges() {
            if (!this.entity) {
                this.message = null;
                return;
            }

            let datePublished = this.entity.datePublished ? Date.parse(this.entity.datePublished) : null;
            let datePublishedSign = null,
                docPublished = null;

            (this.entity.documents || []).forEach((doc) => {
                docPublished = Date.parse(doc.datePublished);
                if (doc.title === 'sign.p7s' && (!datePublishedSign || datePublishedSign < docPublished)) {
                    datePublishedSign = docPublished;
                } else if (!datePublished || datePublished < docPublished) {
                    datePublished = docPublished;
                }
            });

            if (datePublished) {
                if (!datePublishedSign) {
                    this.message = gettext('Підпис не накладено'); // entity don't sign
                    this.style = 'color: #E91E63;';
                } else if (datePublishedSign > datePublished) {
                    this.message = gettext('Підпис накладено');  // entity sign valid
                    this.style = 'color: green;';
                } else {
                    this.message = gettext('Необхідно повторно накласти підпис');  // entity sign expired
                    this.style = 'color: #3a7d2b;';
                }
            }
        }

    }

    angular.module('monitoring').component('verifySign', {
        template: `
            <div class="row"  ng-show="vm.message">
                <div class="col-xs-12">
                    <div style="{{vm.style}}">{{ vm.message }}</div>
                </div>
            </div>
        `,
        controller: VerifySign,
        controllerAs: 'vm',
        bindings: {
            entity: '<',
        }
    });
})();