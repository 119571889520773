class UserChangePassword {
    constructor(
        profileService,
        ntFlash,
        loaderService,
        $timeout,
        $state
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.ntFlash = ntFlash;
        this.$timeout = $timeout;
        this.$state = $state;

        this.user = {
            password: '',
            new_password: '',
            new_password_confirm: ''
        };
        this.errorMessages = null;
    }

    savePassword() {
        this.errorMessages = null;
        this.loader.startLoading();
        this.profileService
            .saveUserPassword(this.user)
            .then(() => {
                this.ntFlash.success(gettext('Пароль успешно обновлен'));
                this.$timeout(() => this.$state.reload(), 1000);
            })
            .catch((error) => {
                this.errorMessages = error.data.errors
            })
            .finally(() => {
                this.loader.stopLoading();
            });
    }
}


profileApp.controller('userChangePassword', UserChangePassword);