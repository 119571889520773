class CompanyCodeForm {
    constructor(
        googleMapsPlaces
    ) {
        this.googleMapsPlaces = googleMapsPlaces;
        this.company = {
            registration_number: '',
            iati_ora_code: null,
            legal_address: {
                country_name: 'Украина',
                street_address: '',
                locality: '',
                region: '',
                postal_code: ''
            },
            is_government: '',
            procuring_kind: null,
            scale: null,
            name: ''
        };

        this.countryShortCode = 'UA';
        this.country = {
            place_id: 'ChIJjw5wVMHZ0UAREED2iIQGAQA'
        };
        
        this.typeForm = this.typeForm || 'full';
    }

    submit() {
        this.onSubmit({company: this.company});
    }

    countryChanged(place) {
        this.country = place;
        const category = place && this.googleMapsPlaces.parseCountryShortName(place.address_components);
        if(category) {
            this.countryShortCode = category;
        }
    }

    clearCompany() {
        this.company = {
            registration_number: '',
            iati_ora_code: null,
            legal_address: {
                country_name: '',
                street_address: '',
                locality: '',
                region: '',
                postal_code: ''
            },
            is_government: '',
            procuring_kind: null,
            scale: null,
            name: ''
        };
        this.country = null;
    }
}

profileApp.component('companyCodeForm', {
    templateUrl: '/static/scripts/modules/Profile/views/components/company-code-form.html',
    controller: CompanyCodeForm,
    controllerAs: 'vm',
    bindings: {
        submitLabel: '@',
        typeForm: '@',
        onSubmit: '&',
        isCreateForm: '@'
    }
});