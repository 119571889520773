class CompanyService {
    constructor(http, API_BASE_URL) {
       this.http = http;

       this.current = null;

       this.URL_COMPANIES = `${API_BASE_URL}/companies`;
       this.URL_COMPANY = `${this.URL_COMPANIES}/:id/`;
       this.URL_COMPANY_VERIFY = `${this.URL_COMPANIES}/edr/verify/`;

       this.URL_PUBLIC_COMPANIES = `${API_BASE_URL}/public-companies/?:pageNum:nameOrId:query:type:region:cpv:createReport:pageSize`;
       this.URL_PUBLIC_COMPANY = `${API_BASE_URL}/public-companies/:type/:edrpou/`;
    }

    setCurrent(company) {
        this.current = company;
    }

    setCurrentById(companyId) {
        this.getCompanyById(companyId)
            .then(result => this.setCurrent(result.data));
    }

    getCompanyById(companyId) {
        const url = this.URL_COMPANY.replace(':id', companyId);
        return this.http.get(url, true);
    }

    verifyCompany(company) {
        return this.http.post(this.URL_COMPANY_VERIFY, company, true);
    }

    getPublicCompanies(data) {
        const url = this.URL_PUBLIC_COMPANIES
            .replace(/:type/g, (data.type ? '&type=' + data.type : '&type=buyer'))
            .replace(/:pageNum/g, (data.pageNum ? 'page=' + data.pageNum : 'page=1'))
            .replace(/:nameOrId/g, (data.nameOrId ? '&name_or_id=' + encodeURIComponent(data.nameOrId) : ''))
            .replace(/:query/g, (data.query ? '&q=' + encodeURIComponent(data.query) : ''))
            .replace(/:region/g, (data.region ? '&region=' + data.region : ''))
            .replace(/:cpv/g, (data.cpv ? '&classifications__code=' + data.cpv : ''))
            .replace(/:createReport/g, (data.createReport ? '&createReport=' + data.createReport : ''))
            .replace(/:pageSize/g, (data.pageSize ? '&page_size=' + data.pageSize : ''));
        return new Promise((resolve, reject) => {
            this.http.get(url, true)
                .then(data => {
                    // console.log('load PublicCompanies data', data);
                    resolve(data.data);
                })
                .catch(error => reject(error));
        });
    }

    getPublicCompany(edrpou, type) {
        return new Promise((resolve, reject) => {
            if (edrpou) {
                const url = this.URL_PUBLIC_COMPANY.replace(':type', type).replace(':edrpou', edrpou);
                this.http.get(url, true)
                    .then(data => {
                        // console.log('load PublicCompany data', data);
                        resolve(data.data);
                    })
                    .catch(error => reject(error));
            } else {
                reject({'error': 'edrpou does not provided'});
            }
        });
    }
}

profileApp.service('companyService', CompanyService);