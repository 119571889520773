providerCabinetApp.controller('sidebarProfileCtrl', function (
    $scope,
    $uibModal,
    $rootScope,
    $timeout,
    $state,
    ntFlash,
    billingService,
    profileService,
    $location
) {
    this.user = profileService;
    $scope.state = $state;
    $scope.role = null;

    ntFlash.clean();

    if (!$rootScope.isAnonymous) {
        this.user.reloadCurrentUser()
            .then(()=>{
                $scope.role = this.user.current.companies[0].role
                $scope.allow_admin_notifications = this.user.allow_admin_notifications

            })
        billingService.getWallets()
            .then(function (result) {
                $scope.wallets = result.data.response;
            });
    }


    $scope.type = $state.params.type;
    $scope.edrpou = $state.params.edrpou;

    $scope.createInvoice = function () {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Profile/views/modals/profile.invoice.modal.html",
            controller: "profileInvoiceModalCtrl",
            backdrop: 'static'
        });
    };

    $scope.isAnonymous = $rootScope.isAnonymous;

});
