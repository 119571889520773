class TransferService {
    constructor(
        http
    ) {
        // endpoints follows
        // 'tender/:tenderId/ownership';
        // 'plans/:plansId/ownership';
        // 'contracts/:contractsId/ownership';
        // 'agreements/:agreementsId/ownership';

        const simpleErrors = [
            {
                eng: 'Broker Accreditation level does not permit ownership change',
                uk: 'Рівень акредитації поточного майданчика не дозволяє перенести об\'єкт.'
            },
            {
                eng: 'Owner Accreditation level does not permit ownership change',
                uk: 'Рівень акредитації майданчика-власника об\'єкта не дозволяє перенести об\'єкт.'
            },
            {
                eng: 'Invalid transfer',
                uk: 'Некоректний токен переходу об\'єкта.'
            },
            {
                eng: 'Transfer already used',
                uk: 'Токен переходу вже використаний.'
            }
        ];

        const regErrors = [
            {
                eng: /Can(.*)t update credentials in current \((.*)\) tender status/,
                uk: 'Заборонено переносити закупівлю в статусі - '
            },
            {
                eng: /Can(.*)t update credentials in current \((.*)\) contract status/,
                uk: 'Заборонено переносити договір в статусі - '
            },
            {
                eng: /Can(.*)t update credentials in current \((.*)\) agreement status/,
                uk: 'Заборонено переносити угоду в статусі - '
            }
        ];

        const statuses = new Map([
            ['pending', 'Очікується підписання'],
            ['terminated', 'Завершився'],
            ['complete', 'Завершено'],
            ['cancelled', 'Відмінено'],
            ['unsuccessful', 'Не відбулась'],
            ['active.stage2.waiting', 'В очикуванні'],
            ['draft.pending', 'Очікується підписання'],
            ['draft.unsuccessful', 'Не відбулась']
        ]);

        this.send = (type, transferToken, id) => {
            const sendUrl = type + '/' + id + '/ownership';
            const obj = {
                "transfer": transferToken
            }

            let errorMessages = '';

            return new Promise((resolve, reject) => {
                http.post(sendUrl, obj)
                    .then(data => resolve(data))
                    .catch(error => {
                        error.data.errors.forEach(el => {
                            for (const item of simpleErrors) {
                                if (item.eng === el.message) {
                                    errorMessages += item.uk + '<br />';
                                }
                            }
                            for (const item of regErrors) {
                                const status = el.message.match(item.eng);
                                if (status !== null) {
                                    const translatedStatus = statuses.get(status[2]) || status[2];
                                    errorMessages += item.uk + translatedStatus + '<br />';
                                }
                            }
                        });
                        if (errorMessages === '') {
                            error.data.errors.forEach(el => {
                                errorMessages += el.message + '<br />';
                            });
                        }
                        reject({error: error, errorMessages: errorMessages});
                    });
            });
            // return http.post(sendUrl, obj);
        }
    }
}
commons.service('transferService', TransferService);