class AgreementItem {
    constructor(

    ) {
    }
}

agreementModule.component('agreementItem', {
    templateUrl: '/static/scripts/modules/Agreements/components/AgreeItem/AgreeItem.html',
    controller: AgreementItem,
    controllerAs: 'vm',
    bindings: {
        items: '<'
    }
});