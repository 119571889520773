class CompanyCtrl {
    constructor(
        profileService,
        ntFlash,
        loaderService,
        userCompany,
        $state,
        $timeout,
        companyService,
        classifierService
    ) {
        this.profileService = profileService;
        this.ntFlash = ntFlash;
        this.loader = loaderService;
        this.$state = $state;
        this.$timeout = $timeout;
        this.companyService = companyService;
        this.classifierService = classifierService;

        userCompany.is_government = userCompany.is_government.toString();
        this.company = userCompany;
        this.disablePublishBtn = null;
        this.czoList = [];
        this.countriesList = [];
        this.regionsList = [];

        this.classifierService.loadCzo()
            .then((data) =>  {
                console.log('loadCzo data', data);
                this.czoList = data;
            })
            .catch((error) => {
                console.log('loadCzo error', error);
                this.ntFlash.error(gettext('При завантаженні списку ЦЗО виникла помилка'));
            });

        this.classifierService.loadCountriesRegions()
            .then((data) =>  {
                console.log('loadCountriesRegions data', data);
                this.countriesList = data.countries;
                this.regionsList = data.ua_regions;
            })
            .catch((error) => {
                console.log('loadCountriesRegions error', error);
                this.ntFlash.error(gettext('При завантаженні списку держав і регіонів виникла помилка'));
            });
    }

    checkCompanyAsCZO() {
        if (this.company.procuring_kind !== 'central') return true;
        for (let czo of this.czoList) {
            if (this.company.registration_number == czo.identifier.id && czo.active) return true;
        }
        return false;
    }

    checkCountryIsInList() {
        if (this.countriesList.length === 0) return true;
        if (this.countriesList.includes(this.company.legal_address.country_name)) return true;
        return false;
    }

    checkRegionIsInList() {
        if (this.regionsList.length === 0) return true;
        if (this.regionsList.includes(this.company.legal_address.region)) return true;
        return false;
    }

    saveCompany() {
        this.disablePublishBtn = true;
        this.loader.startLoading();

        if (!this.checkCompanyAsCZO()) {
            this.ntFlash.error(gettext('Введений ідентифікаційний код компанії не належить зареєстрованим ЦЗО'));
            this.disablePublishBtn = false;
            this.loader.stopLoading();
            return;
        }

        if (!this.checkCountryIsInList()) {
            this.ntFlash.error(gettext('Введена некоректна назва країни або ви використовуєте не українську мову'));
            this.disablePublishBtn = false;
            this.loader.stopLoading();
            return;
        }

        if (this.company.legal_address.country_name === 'Україна') {
            if (!this.checkRegionIsInList()) {
                this.ntFlash.error(gettext('Введена некоректна назва області'));
                this.disablePublishBtn = false;
                this.loader.stopLoading();
                return;
            }
        }

        this.profileService.saveCompany(this.company)
            .then((result) => {
                this.ntFlash.success(gettext('Компания успешно обновлена'));
                this.companyService.setCurrent(result.data);
                this.$timeout(() => window.location.reload(), 500);
            })
            .finally(() => {
                this.disablePublishBtn = false;
                this.loader.stopLoading();
            });
    }
}


profileApp.controller('companyCtrl', CompanyCtrl);