tenderView.controller('tenderViewGuaranteeModalCtrl', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    modalData,
    guaranteeService,
    dateListService,
    uibDatepickerConfig,
    ntFlash
) {
    $scope.bankGuarantee = {
        bank: null,
        tender_id: modalData.tenderId,
        lot_id: modalData.lotId,
        date_from: {},
        date_to: {},
        date_got: {},
        amount: modalData.amount,
        currency: modalData.currency,
        customer: null,
        covered: null
    };

    guaranteeService.getBanksList().then((response) => {
        $scope.banksList = response.data.results;
    });

    $scope.request = function () {
        guaranteeService.request($scope.bankGuarantee)
            .then(function (data) {
                ntFlash.success('Заявка отправлена. С Вами свяжется представитель банка');
                $uibModalInstance.close();
            });
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };

    $scope.open = function ($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();
        obj[string + 'IsOpen'] = true;
    };
});