class TenderItems {
    constructor(uuid4, md5) {
        this.uuid4 = uuid4;
        this.md5 = md5;
    }

    $onInit() {
        this.items = this.items || [];
        this.lot = this.lot || null;
        if (this.items.length === 0) {
            this.addItem();
        }
    }

    getItems() {


        let result = [];
        if(this.procurementMethodType === 'closeFrameworkAgreementSelectionUA'){
            for (let i=0, size=this.items.length; i < size; i++) {
                if ((!this.lot && !this.items[i].relatedLot) ||
                    (this.lot && this.lot.id === this.items[i].relatedLot)) {
                    result.push(this.items[i]);
                }
            }
        } else {
            for (let i=0, size=this.items.length; i < size; i++) {
                if ((!this.lot && !this.items[i].relatedLot) ||
                    (this.lot && this.lot.id === this.items[i].relatedLot)) {
                    result.push(this.items[i]);
                }
            }
        }

        return result;
    }

    addItem() {
        let item = {
            id: this.md5.createHash(this.uuid4.generate()),
            additionalClassifications: [],
            classification: {
                scheme: "",
                description: "",
                id: "",
            },
            deliveryAddress: {
                countryName: "",
                postalCode: "",
                region: "",
                locality: "",
                streetAddress: "",
            },
            deliveryDate: {
                endDate: null,
                startDate: null
            },
            description: "",
            description_en: "",
            quantity: 1,
            relatedLot: this.lot ? this.lot.id : undefined,
            unit: {
                code: "",
                name: gettext('единицы измерения')
            }
        };

        this.items.push(item);
    }

    delItem(item) {
        console.log('Delete: ', item);
        let index = this.items.map((i) => { return i.id; }).indexOf(item.id);
        if (index > -1) {
            this.items.splice(index, 1);
        }
    }

}

tenderEdit.component('tenderItems', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/tender-items.html',
    controller: TenderItems,
    controllerAs: 'vm',
    bindings: {
        items: '<',
        lot: '<',
        procurementMethodType: '<',
        ngDisabled: '<',
    }
});
