class UnitsService {
    constructor(
        http,
        API_BASE_URL
    ) {
        const getUnitsResource = `${API_BASE_URL}/catalog/units/`;

        this.get = () => {
            return http.get(getUnitsResource, true)
                .then((result) => {
                    // console.log('UnitsService.get', result)


                    let units = result.data;
                    if (Array.isArray(units)) {
                        units.forEach(item => item.name = item.name.toLowerCase());
                        units.sort((a, b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0);
                    }
                    return units || [];
                });
        }
    }
}
commons.service('unitsService', UnitsService);