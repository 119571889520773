tenderView.controller('tenderViewBidsRequirementModalCtrl', function(
    $scope,
    $state,
    $timeout,
    $uibModalInstance,
    data,
    tenderBidService,
    ntFlash
) {
    var tenderId = data.tenderId,
        awardId = data.awardId;

    $scope.disablePublishBtn = false;
    $scope.formData = {
        code: '24h',
        description: ''
    };

    $scope.publicRequirement = function() {
        $scope.disablePublishBtn = true;
        tenderBidService.addMilestones(tenderId, awardId, $scope.formData)
            .then(data => {
                ntFlash.success(gettext('Вимогу про усунення невідповідностей створено'));
                console.log('tenderBidService.addMilestones data', data);
                $uibModalInstance.close();
                $timeout(function() {
                    $state.reload();
                }, 1000);
            })
            .catch(error => {
                ntFlash.error(gettext('Сталася помилка', error));
                console.log('tenderBidService.addMilestones error', error);
            })
            .finally(() => {
                $scope.disablePublishBtn = false;
            });
        $uibModalInstance.close(data);
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
});