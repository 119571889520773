class EdsSignBlock {
    constructor(
        $rootScope,
        $state,
        $timeout,
        documentsService,
        monitoringService,
        agreementService,
        agreementInstanceService,
        ntFlash,
        profileService,
        $scope
    ) {
        this.$rootScope = $rootScope;
        this.profileService = profileService;
        this.$scope = $scope;
        this.$state = $state;
        this.$timeout = $timeout;
        this.documentsService = documentsService;
        this.monitoringService = monitoringService;
        this.agreementService = agreementService;
        this.agreementInstanceService = agreementInstanceService;
        this.ntFlash = ntFlash;
        this.baseUrl = OPC_CONFIG.OPC_API_PUBLIC_BASE_URL;
        this.monitUrl = OPC_CONFIG.OPC_API_MONITORING_PUBLIC_BASE_URL;
        this.ErrorDrfo = false
    }

    $onInit() {
        if (window.autotest_mode) {
            this.SignBtn = true;
            this.imitateSigning();
        } else {
            this.initSign()
        }

    }

    autoTest(){
        return  window.autotest_mode
    }

    initSign(){
        this.SignBtn = false;
        const _this = this

        const options = {
            callbackAfterAuth: (certificates) => {
                _this.certificate = certificates

                // console.log(certificates);
                // console.log(certificates[0].infoEx.subjDRFOCode)
                // console.log(certificates[0].infoEx.subjEDRPOUCode)

                _this.checkCompany(certificates[0].infoEx)

                _this.$scope.$apply(()=>{
                    _this.SignBtn = true
                })
            },
        };
        window.eds.init(options)
            .then(()=>{
                return window.eds.loadWidget()
            })
            .then((data)=>{
                this.widgetService = data
                // angular.element('#signPlaceholder').addClass('hide')
            })
            .catch((error)=>{
                this.ntFlash.error(gettext('Ошибка загрузки цифровой подписи'));
                console.log('Sign Error', error)
            })



    }


    sign(){
        const links = this.initOptions()

        if (window.autotest_mode) {

            links.forEach((el)=>{
                this.postSign(this.imitateSigning())
            })

        } else {
            window.eds.signObjects(links)
                .then((data)=>{
                    data.forEach((el)=>{
                        this.postSign(el.sign)
                    })
                })
                .catch((error)=>{
                    this.ntFlash.error(gettext('Ошибка загрузки цифровой подписи'));
                    console.log(error)
                });
        }
    }

    getSign(){
        function readImageFile(el) {
            return new Promise((resolve, reject) => {
                var fr = new FileReader();
                fr.onload = (e) => {
                    var arrayBuffer = e.target.result;
                    var array = new Uint8Array(arrayBuffer);
                    let result = window.eds.sign(array)
                        .then((data)=>{
                            let signBlob = new Blob([data]);
                            return new File(
                                [signBlob],
                                `${el.name}.p7s`,
                            );
                        })
                    resolve(result )
                };
                fr.onerror = reject;
                fr.readAsArrayBuffer(el);
            });
        }
        let promise = []
        this.documents.map((el) => {
            promise.push(readImageFile(el))
        })
        return Promise.all(promise)
            .then((response)=>{
                this.close(response);
            })
            .catch(error => console.log('EDS.sign error', error));

    }

    checkPersonSign(){
        if (window.autotest_mode) {
            this.profileService.setUo('test')
            if(typeof this.checkPersonCallback == 'function'){
                this.checkPersonCallback('test')
                this.close({ap: true})
            } else {
                this.AP = true
                this.sign()
            }
        } else {
            let drfo = this.certificate[0].infoEx.subjDRFOCode
            console.log('AuthorityPerson.certificate', this.certificate)
            // console.log('AuthorityPerson.DRFO', drfo)

            this.profileService.setUo('temp sign')
            const now2 = new Date()
            if(typeof this.checkPersonCallback == 'function'){
                this.checkPersonCallback(now2.getTime())
                this.close({ap: true})
            } else {
                this.AP = true
                this.sign()
            }


            // return this.profileService.UoAuthorityRequest(drfo)
            //     .then((data)=>{
            //         // if( data.data.certified_at && data.data.certified_at !== null){
            //         //     this.profileService.setUo(data)
            //         //     if(typeof this.checkPersonCallback == 'function'){
            //         //         this.checkPersonCallback(data.data.certified_at)
            //         //         this.close({ap: true})
            //         //     } else {
            //         //         this.AP = true
            //         //         this.sign()
            //         //     }
            //         // } else {
            //         //     this.$rootScope.edsSignSetup.reload = false
            //         //     this.ntFlash.error(gettext('Проверка на Уповноважену Особу отклонена'));
            //         //     this.$timeout(() => {
            //         //         this.close({ap: false})
            //         //     }, 1000)
            //         // }
            //
            //
            //         const now2 = new Date()
            //         if(typeof this.checkPersonCallback == 'function'){
            //             this.checkPersonCallback(now2.getTime())
            //             this.close({ap: true})
            //         } else {
            //             this.AP = true
            //             this.sign()
            //         }
            //
            //     })
            //     .catch((error)=>{
            //         this.ntFlash.error(gettext('Проверка на Уповноважену Особу отклонена'));
            //         this.close({ap: false})
            //     })

        }
    }


    checkCompany(certificate){
        // const company =
        const _this = this
        // console.log()
        if(this.checkCompany$) {
            this.profileService.getCompany()
                .then((res) => {
                    const companyId = res.data.registration_number
                    const userDrfo = certificate.subjDRFOCode
                    const userEDRPOU = certificate.subjEDRPOUCode

                    if (companyId != userDrfo && companyId != userEDRPOU) {
                        _this.ErrorDrfo = true
                    }
                });
        }
    }



    $onChanges(changes) {

        if (changes.edsSignIsOpened.currentValue) {
            if(this.$rootScope.edsSignSetup.setSign){
                this.setSign = this.$rootScope.edsSignSetup.setSign
            }
            if(this.$rootScope.edsSignSetup.checkPerson){
                this.checkPersonCallback  = this.$rootScope.edsSignSetup.checkPerson
                this.checkPerson = true
                this.AP = false
            }
            this.checkCompany$ = false

            if(this.$rootScope.edsSignSetup.checkCompany){
                this.checkCompany$ = this.$rootScope.edsSignSetup.checkCompany
            }

            this.open();
        }

    }

    open() {
        console.log('open edsSignData', this.edsSignData);
        if(!this.setSign && this.edsSignData != null){
            this.documents = this.edsSignData.documents || [];
            this.financialDocuments = this.edsSignData.financialDocuments;
            this.tenderId = this.edsSignData.tenderId;
            this.complaintURL = this.edsSignData.complaintURL;
            this.planId = this.edsSignData.planId;
            this.milestoneId = this.edsSignData.milestoneId;
            this.awardId = this.edsSignData.awardId;
            this.agreementId = this.edsSignData.agreementId;
            this.agreementInstanceId = this.edsSignData.agreementInstanceId;
            this.contractId = this.edsSignData.contractId;
            this.qualificationId = this.edsSignData.qualificationId;
            this.complaintId = this.edsSignData.complaintId;
            this.bidId = this.edsSignData.bid && this.edsSignData.bid.id;
            this.documentsType = this.edsSignData.documentsType;
            this.monitoringId = this.edsSignData.monitoringId;
            this.cancellationId = this.edsSignData.cancellationId;
            this.postId = this.edsSignData.postId;
            this.entity = this.edsSignData.entity;
            this.skipPublic = this.edsSignData.skipPublic;
            this.callback = this.edsSignData.callback;
            this.complaintDOCURL = this.edsSignData.complaintDOCURL;
        } else {
            this.documents = this.edsSignData || [];
        }


        angular.element('body').addClass('modal-open').css('padding-right', '17px');
    }

    refresh() {
       this.widgetService.resetKey()
    }

    close(response) {
        if (!window.autotest_mode) {
            this.refresh()
        }
      this.$timeout(() => {
        this.SignBtn = false
        this.$rootScope.edsSignIsOpened = false;
          this.setSign = false;
          this.checkPerson = false;
        if (this.onResolved) this.onResolved({response});
        //TODO check works
        if(this.$rootScope.edsSignSetup.reload){
            if (response) this.$state.reload();
        }

          if(this.$rootScope.edsSignSetup.mustReload){
              this.$state.reload();
          }

        //
      }, 500);
      angular.element('body').removeClass('modal-open').css('padding-right', '0');
    }

    imitateSigning() {
        // let signature = 'ImitateSigning'
        console.log('ImitateSigning');
        // this.postSign(signature);
        return  '!!! Test happens !!! A.P.A.B. !!!';
    }
  
    initOptions() {
        const options = {
            placeholderId: "#signPlaceholder",
            callbackPostSign: "postSign",
            ...window.options,
            apiResourceUrl:[]
        };

        if (this.monitoringId && this.entity === 'post') {
            options.apiResourceUrl.push(`${this.monitUrl}/monitorings/${this.monitoringId}/posts/${this.postId}`);
        } else if (this.monitoringId && this.entity === 'eliminationReport') {
            options.apiResourceUrl.push(`${this.monitUrl}/monitorings/${this.monitoringId}/eliminationReport`);
        } else if (this.monitoringId && this.entity === 'appeal') {
            options.apiResourceUrl.push(`${this.monitUrl}/monitorings/${this.monitoringId}/appeal`);
        } else if (this.qualificationId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}/qualifications/${this.qualificationId}`);
        } else if (this.contractId && this.awardId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}/contracts/${this.contractId}`);
        } else if (this.complaintURL) {
            options.apiResourceUrl.push(`${this.baseUrl}/${this.complaintURL}`);
        } else if (this.contractId) {
            options.apiResourceUrl.push(`${this.baseUrl}/contracts/${this.contractId}`);
        } else if (this.awardId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}/awards/${this.awardId}`);
        } else if (this.agreementId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}/agreements/${this.agreementId}`);
        } else if (this.agreementInstanceId) {
            options.apiResourceUrl.push(`${this.baseUrl}/agreements/${this.agreementInstanceId}`);
        } else if (this.bidId) {
            if (this.financialDocuments instanceof Array) {
                options.apiResourceUrl.push(`/opc/tender/${this.tenderId}/bid/${this.bidId}/?private_mode=onlyPublic`);
                options.apiResourceUrl.push(`/opc/tender/${this.tenderId}/bid/${this.bidId}/?private_mode=onlyPrivate`)

            } else {
                options.apiResourceUrl.push(`/opc/tender/${this.tenderId}/bid/${this.bidId}/?private_mode=all`);
            }
        } else if (this.tenderId && this.cancellationId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}/cancellations/${this.cancellationId}`);
        } else if (this.tenderId) {
            options.apiResourceUrl.push(`${this.baseUrl}/tenders/${this.tenderId}`);
            // http://api-url.com/tenders/;sldkf;ldfghd
        } else if (this.planId && this.milestoneId) {
            options.apiResourceUrl.push(`${this.baseUrl}/plans/${this.planId}/milestones/${this.milestoneId}`);
        } else if (this.planId) {
            options.apiResourceUrl.push(`${this.baseUrl}/plans/${this.planId}`);
        }
        console.log(options.apiResourceUrl)
        return options.apiResourceUrl;
    }

    postSign(signature) {

        const getSignFileId = function(docs) {
          let s = null;
          (docs || []).forEach((document) => {
              if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                  s = document.id;
              }
          });
          return s;
        }

        let isMultiSign = signature instanceof Array;

        let request = null,
            signFile, signBlob;

        if (!isMultiSign) {
            signBlob = new Blob([signature]);
            signFile = new File([signBlob], `sign.p7s`, {
                type: 'application/pkcs7-signature'
            });
        } else {
            signFile = [];
            for (const key in signature) {
                if (signature.hasOwnProperty(key)) {
                    signBlob = new Blob([signature[key]]);
                    signFile.push(new File([signBlob], 'sign.p7s', {
                        type: 'application/pkcs7-signature'
                    }));
                }
            }
        }

        if (this.monitoringId) {
            if (this.entity === 'post') {
                request = this.documentsService.uploadToDocumentService([signFile])
                    .then((res) => {
                        return this.monitoringService.addPostDocument(this.tenderId, this.monitoringId, this.postId, res[0]);
                    });
            } else if (this.entity === 'eliminationReport') {
                request = this.documentsService.uploadToDocumentService([signFile])
                    .then((res) => {
                        return this.monitoringService.addViolationEliminationDocument(this.tenderId, this.monitoringId, res[0]);
                    });
            } else if (this.entity === 'appeal') {
                request = this.documentsService.uploadToDocumentService([signFile])
                    .then((res) => {
                        return this.monitoringService.addDocumentToAppeal(this.tenderId, this.monitoringId, res[0]);
                    });
            }
            request.then((response) => {
                console.log('response: ', response);
                if (response) {
                    this.documents.push(response);
                    this.ntFlash.success(gettext('Моніторинг підписано'));
                    this.close(response);
                } else {
                    this.ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                }
            });
        } else if (this.agreementId) {
            request = this.documentsService.uploadToDocumentService([signFile])
                .then((res) => {
                    return this.agreementService.uploadDocument({
                        tenderId: this.tenderId,
                        agreementId: this.agreementId
                    }, res[0]);
                });
            request.then((response) => {
                console.log('response: ', response);
                if (response) {
                    this.documents.push(response);
                    this.ntFlash.success(gettext('Угода підписана'));
                    this.close(response);
                } else {
                    this.ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                }
            });
        }
        // todo remake #1
        else if (this.agreementInstanceId) {
            request = this.documentsService.uploadToDocumentService([signFile])
                .then((res) => {
                    res[0].documentOf = 'agreement'
                    console.log(res);
                    return this.agreementInstanceService.documentUpload(this.agreementInstanceId, res[0]);
                });
            request.then((response) => {
                console.log('response: ', response);
                if (response) {
                    this.documents.push(response);
                    this.ntFlash.success(gettext('Угода підписана'));
                    this.close(response);
                } else {
                    this.ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                }
            });

        } else {
            const signId = getSignFileId(this.documents);
            if (this.qualificationId) {
                request = signId ?
                    this.documentsService.replace(signFile, this.tenderId, signId, {
                        qualificationId: this.qualificationId
                    }) :
                    this.documentsService.save(signFile, this.tenderId, {
                        qualificationId: this.qualificationId
                    });
            } else if (this.contractId && this.awardId) {
                request = signId ?
                    this.documentsService.replace(signFile, this.tenderId, signId, {
                        awardId: this.awardId,
                        contractId: this.contractId
                    }) :
                    this.documentsService.save(signFile, this.tenderId, {
                        awardId: this.awardId,
                        contractId: this.contractId
                    });
            } else if (this.contractId) {
                request = signId ?
                    this.documentsService.replace(signFile, null, signId, {
                        contractId: this.contractId
                    }) :
                    this.documentsService.save(signFile, null, {
                        contractId: this.contractId
                    });
            } else if (this.awardId) {
                request = signId ?
                    this.documentsService.replace(signFile, this.tenderId, signId, {
                        awardId: this.awardId
                    }) :
                    this.documentsService.save(signFile, this.tenderId, {
                        awardId: this.awardId,
                        docData: {
                            documentType: this.$rootScope.edsSignSetup.documentType ? this.$rootScope.edsSignSetup.documentType : 'notice'

                        }
                    });


                // request = signId ?
                //     this.documentsService.replace(signFile, this.tenderId, signId) :
                //     this.documentsService.save(signFile, this.tenderId, {docData: {
                //             documentType: this.$rootScope.edsSignSetup.documentType ? this.$rootScope.edsSignSetup.documentType : 'notice'
                //
                //         }
                //     });

            } else if (this.complaintURL) {
                request =
                    this.documentsService.save(signFile, this.tenderId, {
                        complaintId: this.complaintId,
                        complaintDOCURL: this.complaintDOCURL,
                    });
            }
            else if (this.bidId) {

                const fileData = {
                    documentOf: "tender",
                }
                if(this.$rootScope.edsSignSetup.documentType){
                    fileData.documentType = this.$rootScope.edsSignSetup.documentType
                }

                if (!isMultiSign) {

                    if(this.documentsType === 'financial_documents'){
                        const signFinDocId = getSignFileId(this.financialDocuments);
                        fileData.type = 'financialDocuments'
                        request = signFinDocId ?
                            this.documentsService.replace(signFile, this.tenderId, signFinDocId, {
                                bidId: this.bidId,
                                docData: fileData,
                                documentsType: 'financialDocuments'
                            }) :
                            this.documentsService.saveBid(signFile, this.tenderId, {
                                bidId: this.bidId,
                                docData: fileData
                            });
                    } else {
                        request = signId ?
                            this.documentsService.replace(signFile, this.tenderId, signId, {
                                bidId: this.bidId,
                                docData: fileData
                            }) :
                            this.documentsService.saveBid(signFile, this.tenderId, {
                                bidId: this.bidId,
                                docData: fileData
                            });
                    }


                } else {
                    let uploadPromises = [];

                    // Sign without value for pre-qualification procedures and full data for other procedures
                    if (!this.skipPublic) {
                        uploadPromises.push(signId ?
                            this.documentsService.replace(signFile[0], this.tenderId, signId, {
                                bidId: this.bidId,
                                docData: fileData
                            }) :
                            this.documentsService.saveBid(signFile[0], this.tenderId, {
                                bidId: this.bidId,
                                docData: fileData
                                // docData:
                            }));
                    }

                    // Sign with value for pre-qualification procedures
                    if (signFile.length > 1) {
                        const signFinId = getSignFileId(this.financialDocuments);
                        fileData.documentType = 'financialDocuments'

                        uploadPromises.push(signFinId ?
                            this.documentsService.replace(signFile[1], this.tenderId, signFinId, {
                                bidId: this.bidId,
                                docData: fileData
                            }) :
                            this.documentsService.saveBid(signFile[1], this.tenderId, {
                                bidId: this.bidId,
                                docData: fileData
                            }))
                    }
                    request = Promise.all(uploadPromises);
                }
            } else if (this.tenderId && this.cancellationId) {
                request = this.documentsService.save(signFile, this.tenderId, {
                        cancellationId: this.cancellationId
                    });
            } else if (this.tenderId) {



                 const data = {
                     docData: {
                        documentType: this.$rootScope.edsSignSetup.documentType ? this.$rootScope.edsSignSetup.documentType : 'notice'

                    }
                }

                if(this.$rootScope.edsSignSetup.confidentialityRationale){
                    data.docData.confidentialityRationale = this.$rootScope.edsSignSetup.confidentialityRationale
                }

                if(this.$rootScope.edsSignSetup.confidentiality){
                    data.docData.confidentiality = this.$rootScope.edsSignSetup.confidentiality
                }


                request = signId ?
                    this.documentsService.replace(signFile, this.tenderId, signId) :
                    this.documentsService.save(signFile, this.tenderId, data);
            } else if (this.planId && this.milestoneId) {
                request = signId ?
                    this.documentsService.replace(signFile, this.planId, signId, {
                        milestoneId: this.milestoneId
                    }) :
                    this.documentsService.save(signFile, this.planId, {
                        milestoneId: this.milestoneId
                    });
            } else if (this.planId) {
                request = signId ?
                    this.documentsService.replace(signFile, null, signId, {
                        planId: this.planId
                    }) :
                    this.documentsService.save(signFile, null, {
                        planId: this.planId
                    });
            }

            if (request) {
                request.then((response) => {
                    console.log('Success: ', response);
                    if (this.planId) this.ntFlash.success(gettext('План підписаний'));
                    else this.ntFlash.success(gettext('Тендер подписан'));
                    // this.edsSignData.awardSign ? this.edsSignData.awardSign.signed = true : '';
                    if (this.callback) this.callback();
                    if (this.checkPerson){
                        // console.log(this.AP)
                        response.ap = this.AP;
                    }
                    this.close(response);
                }).catch((error => {
                    console.log('Errors: ', error);
                    this.ntFlash.error(gettext('Ошибка загрузки цифровой подписи'));
                }));
            } else {
                console.error('Object request is empty!');
            }
        }
    }
}

providerCabinetApp.component('edsSignBlock', {
    templateUrl: "/static/scripts/modules/Provider/views/components/eds-sign-block.html",
    controller: EdsSignBlock,
    controllerAs: 'vm',
    bindings: {
      edsSignIsOpened: '<',
      edsSignData: '<',
      onResolved: '&'
    }
});
