class TenderViewAwardInfo {
    constructor(
        $state,
        $timeout,
        $uibModal,
        awardsService,
        ntFlash
    ) {
        this.$state = $state;
        this.$uibModal = $uibModal;
        this.awardsService = awardsService;
        this.ntFlash = ntFlash;
        this.$timeout = $timeout;
    }

    decide(id, status, procurementMethodType) {
        if (procurementMethodType === 'reporting') {
            if (this.tender.procurementMethodRationale === 'COVID-19' &&
                !(this.award.documents || []).some(doc => ['registerExtract', 'awardNotice'].includes(doc.documentType))
               ) {
                this.ntFlash.error(gettext('Повідомлення про бенефіціарів відсутнє'));
                return;
            }
            this.awardsService.save({
                tenderId: this.tender.id,
                awardId: id
            }, {
                status: 'active',
                qualified: true
            })
            .then(() => {
                this.ntFlash.success(gettext('Предложение принято.'));
                this.$timeout(() => this.$state.reload(), 1000);
            });
        } else {
            var isAccepted = status === 'active';
            return this.$uibModal.open({
                templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.bids.modal.html",
                controller: "tenderViewBidsModalCtrl",
                resolve: {
                    modalData: () => {
                        return {
                            procurementMethodType: procurementMethodType,
                            procurementMethodRationale: this.tender.procurementMethodRationale,
                            isAccepted: isAccepted,
                            awardId: id,
                            status: status
                        };
                    }
                },
                backdrop: 'static'
            });
        }
    }

    makeComplaint(awardId) {
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: () => {
                    return {
                        tenderId: this.tender.id,
                        awardId: awardId,
                        isQuestion: true,
                        isMultilotTender: false
                    };
                }
            },
            backdrop: 'static'
        });
    }
}

tenderView.component('tenderViewAwardInfo', {
    templateUrl: '/static/scripts/modules/TenderView/views/components/tender-view.award.info.html',
    controller: TenderViewAwardInfo,
    controllerAs: 'vm',
    bindings: {
        award: '=',
        actions: '=',
        tender: '='
    }
});
