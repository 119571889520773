commons.filter('monitoringPartiesUnique', () => {
    "use strict";

    return (values) => {
        let parties = values || [];

        // prepare data
        let partiesName = [];
        let partyDict = {};
        for (const p of parties) {
            partiesName.push(p.name);
            partyDict[p.name] = p;
        }

        // make unique name
        partiesName = partiesName.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });

        // make response array
        let results = [];
        for (const name of partiesName) {
            results.push(partyDict[name]);
        }

        return results;
    };
});