class ProfileUserNotificationsController {
    constructor(
        // $q,
        $scope,
        $state,
        // $filter,
        // $uibModal,
        // $timeout,
        // ntFlash,
        profileService
    ) {
        // this.$q = $q;
        this.ps = profileService
        // this.ntFlash = ntFlash;
        // this.$state = $state;
        // this.$timeout = $timeout;
        // this.$q = $q;
        let self = this;
        this.notificationKinds = [];
        $scope.getNotifications = () => {
            profileService.getNotificationsSettings()
                .then((result) => {
                        let settings = angular.copy(result.data.settings);
                        let notifications = {};
                        settings.forEach(
                            s => {
                                if (!notifications[s.kind.key]) {
                                    notifications[s.kind.key] = {};
                                    notifications[s.kind.key].key = s.kind.key;
                                    notifications[s.kind.key].title = s.kind.title;
                                    notifications[s.kind.key].email = true;
                                    notifications[s.kind.key].bell = true;
                                    notifications[s.kind.key].viber = true;
                                }
                                if (s.type.value === 'bell')
                                    notifications[s.kind.key].bell = s.is_enabled;

                                if (s.type.value === 'email')
                                    notifications[s.kind.key].email = s.is_enabled;

                                if (s.type.value === 'viber')
                                    notifications[s.kind.key].viber = s.is_enabled;
                            }
                        )
                        self.notificationKinds = Object.values(notifications);
                    }
                );
        }

        this.$onInit = () => {
            $scope.getNotifications()
            // this.$q.resolve(null)
            //     .then(getNotifications);
        };
        $scope.handleCheckboxChange = (notificationRow, changedType) => {
            let setting = {
                "user": null,
                "settings": [
                    {
                        "is_enabled": notificationRow[changedType],
                        "type": {"value": changedType},
                        "kind": {"key": notificationRow.key}
                    }
                ]
            }
            profileService.setNotificationsSetting(setting)
        };

        $scope.changeAll = (notificationTypeLabel) => {
            const value = !self.notificationKinds[0][notificationTypeLabel];
            let settings = {
                "user": null,
                "settings": []
            }

            for (let el of self.notificationKinds) {
                el[notificationTypeLabel] = value;
                settings.settings.push({
                        "is_enabled": value,
                        "type": {"value": notificationTypeLabel},
                        "kind": {"key": el.key}
                    }
                )
            }
            profileService.setNotificationsSetting(settings)
        };

        $scope.someChecked = (type) => {
            let len = self.notificationKinds.filter(t => t[type]).length;
            return len > 0 && len < self.notificationKinds.length;
        }
        $scope.allChecked = (type) => {
            let len = self.notificationKinds.filter(t => t[type]).length;
            return len === self.notificationKinds.length;
        }

     }
}

profileApp.component('profileUserNotifications', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-notifications.html',
    controller: ProfileUserNotificationsController,
    controllerAs: 'vm'
})
