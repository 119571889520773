class UserSubscriptionController {
    constructor(
        loaderService,
        profileService,
        classifierService,
        ntFlash,
        REGIONS_LIST
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.classifierService = classifierService;
        this.ntFlash = ntFlash;
        this.regions = REGIONS_LIST;
        this.isShort = false;

        this.isShowOwnSubscribes = false;
        this.listCheckedClassifiers = [];
        this.userSubscription = {
            regions: [],
            budget: {min: null, max: null},
            codes: [],
            is_extended: false
        };
        this.setListCheckedCPV();
    }

    setShortSubscription() {
        this.userSubscription.is_extended = !this.userSubscription.is_extended;
        this.saveListCPV();
    }

    setShowOwnSubscribes(isShow) {
        this.isShowOwnSubscribes = isShow;
    }

    setListCheckedCPV() {
        this.profileService
            .getUserSubscriptions()
            .then((result) => {
                const {regions, codes = [], budget, is_extended} = result.data;
                this.userSubscription.regions = regions || [];
                this.userSubscription.budget = budget || {min: null, max: null};
                this.userSubscription.is_extended = is_extended || false;
                this.isShort = !this.userSubscription.is_extended;
                codes.forEach((classifier) => {
                    this.listCheckedClassifiers.push(classifier);
                    this.userSubscription.codes.push(classifier.code);
                });
            });
    }

    checkedClassifier(classifier) {
        this.listCheckedClassifiers.push(classifier);
        this.saveListCPV();
    }

    uncheckedClassifier(classifier) {
        const removeClassifier = this.listCheckedClassifiers.find(item => item.code === classifier.code);
        const index = this.listCheckedClassifiers.indexOf(removeClassifier);
        if(index > -1) {
            this.listCheckedClassifiers.splice(index, 1);
            this.saveListCPV();
        }
    }

    removeClassifier(classifier) {
        let indexClassifier = this.listCheckedClassifiers.indexOf(classifier);
        this.listCheckedClassifiers.splice(indexClassifier, 1);

        let indexCode = this.userSubscription.codes.indexOf(classifier.code);
        this.userSubscription.codes.splice(indexCode, 1);

        this.saveListCPV();
    }

    saveListCPV() {
        this.loader.startLoading();
        console.log('this.userSubscription',this.userSubscription);
        this.profileService
            .saveListCPV(this.userSubscription)
            .then(() => {
                console.log('cpv saved');      //this.ntFlash.success(gettext('Успешно сохранено'));
            })
            .finally(() => this.loader.stopLoading());
    }
}

profileApp.controller('userSubscriptionController', UserSubscriptionController);