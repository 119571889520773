planningApp.controller('planningRationaleModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    planningService,
    data,
    ntFlash,
    $timeout
) {

    $scope.rationale = "";
    $scope.planId = data.planId;
    $scope.isCancelButtonDisabled = false;
    $scope.addRationale = function() {
        if ($scope.addPlanRationaleForm.$valid) {
            $scope.isCancelButtonDisabled = true;
            planningService.addReason($scope.planId, $scope.rationale)
                .then(function(resData) {
                    ntFlash.success(gettext('Обґрунтування додано'));
                    $uibModalInstance.close();
                    $timeout(function() {
                        $state.reload();
                    }, 1000);
                })
                .catch(function() {
                    // ntFlash.error(gettext('При спробі змінити статус на Скасовано сталася помилка. Спробуйте пізніше.'));
                    $scope.isCancelButtonDisabled = false;
                });
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

});
