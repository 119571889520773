qualityApp.factory('constructorService', function() {

    return {
        format: function(data) {
            return data;
        },
        coefficient: function(data) {
            return data;
        }
    }

});