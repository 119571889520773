monitoringApp.controller('monitoringListCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    monitoringService,
    $stateParams,
    $state,
    $location,
    tenderData,
    ) {

    $scope.actions = tenderData.action;
    $scope.tenderId = $stateParams.id;

    $scope.refreshMonitoringsList = function() {
        monitoringService.get($scope.tenderId)
            .then(function(data) {
                $scope.monitorings = data;
            });
    };

    $scope.refreshMonitoringsList();
});