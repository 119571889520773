class OraCodesService {
    constructor(
        API_BASE_URL,
        http
    ) {
        this.http = http;

        this.URL_CODES = `${API_BASE_URL}/catalog/ora-namespace-codes/`
    }

    getCodesByCategory(category) {
        const url = `${this.URL_CODES}?category=${category}`;
        return this.http
            .get(url, true)
            .then(res => res.data || []);
    }
}

profileApp.service('oraCodesService', OraCodesService);