monitoringApp.controller('monitoringOverviewCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    monitoringService,
    $stateParams,
    $state,
    $location,
    tenderData,
    ntFlash,
    documentsService
    ) {

    $scope.actions = {};
    $scope.tenderId = $stateParams.id;

    $scope.refreshMonitoring = function() {
        monitoringService.get($stateParams.id, $stateParams.monitoring_id)
        .then((result) => {
            let monitoring = result;

            // Group posts
            let relatedPosts = {};
            (monitoring.posts || []).forEach((post) => {
                if (relatedPosts[post.relatedPost] === undefined) {
                    relatedPosts[post.relatedPost] = [];
                }
                relatedPosts[post.relatedPost].push(post);
            });

            for (const key in relatedPosts) {
                relatedPosts[key].sort((a, b) => {
                    return Date.parse(a.datePublished) - Date.parse(b.datePublished);
                })
            }

            $scope.relatedPosts = relatedPosts;
            $scope.monitoring = monitoring;

            // Calculate actions
            let monitoringActions = {
                showBtnPostCreate: false,
            };

            if (tenderData.action.tender_owner) {
                switch ($scope.monitoring.status) {
                    case 'active':
                        monitoringActions.showBtnPostCreate = true;
                        break;

                    case 'addressed':
                    case 'declined':
                        let posts = [];
                        if ($scope.monitoring.posts) {
                            posts = $scope.monitoring.posts.filter(
                                (d) => {
                                    return d.author === 'tender_owner' && d.postOf === 'conclusion'
                                });
                        }
                        if (!posts.length) {
                            monitoringActions.showBtnPostCreate = true;
                        }
                        break;
                }
            }


            if (tenderData.action.tender_owner && monitoring.conclusion) {
                let datePublished = new Date(monitoring.conclusion.datePublished);
                let curDate = new Date();
                if (curDate > datePublished) {
                    const endDateEliminationPeriod = monitoring.eliminationPeriod
                        ? new Date(monitoring.eliminationPeriod.endDate)
                        : new Date(+datePublished + 10 * 86400000);
                    let days = Math.floor(Math.abs(curDate - datePublished) / 86400000);

                        if (monitoring.status === 'addressed' && curDate <= endDateEliminationPeriod) {  //  && days <= 10
                        monitoringActions.showBtnAppeal = true;
                    }

                    if ((monitoring.status === 'addressed' || monitoring.status === 'declined' && days <= 3)) {  // && days <= 3
                        monitoringActions.showBtnCreateDialogConclusion = true;
                    }
                }
            }

            $scope.actions = { ...tenderData.action, ...monitoringActions }
        });
    };

    $scope.refreshMonitoring();


    $scope.appeal = function (monitoring_id) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Monitoring/views/modals/monitoring.appeal.modal.html",
            controller: "monitoringAppealModalCtrl",

            resolve: {
                data: function() {
                    return {
                        modalTitle: $scope.monitoring && $scope.monitoring.appeal ? gettext('Додати файли до зверненняя') : gettext('Подати звернення'),
                        uploadedFiles: $scope.monitoring.appeal ? $scope.monitoring.appeal.documents : [] || [],
                        tenderId: $scope.tenderId,
                        description: $scope.monitoring.appeal ? $scope.monitoring.appeal.description : '',
                        monitoring_id,
                    }
                }
            },
            backdrop: 'static'
        }).result.then((result) => {
            if  (!result) return;

            return $scope.sign('appeal', result.documents).result
                .then((result, error) => {
                    if (!result) {
                        if (error) {
                            throw Error();
                        } else {
                            throw Error(gettext('Перервано накладання підпису'));
                        }
                    }
                });

        }).then((result)=>{
            if (!result) return;
            $scope.refreshMonitoring();
            ntFlash.success(gettext('Успішно виконано'));
        }).catch((e) => {
            $scope.refreshMonitoring();
            ntFlash.error(e || gettext('Виникла помилка'));
        });
    };

    $scope.newPost = function (monitoring_id, post_id) {
        const post = post_id ? $scope.monitoring.posts.filter((d) => { return d.id === post_id})[0] : null;
        let postgData;
        if (post) {
            if (post.author !== 'tender_owner') {
                postgData = {
                    author: post.author,
                    modalTitle: gettext('Відповісти на діалог'),
                    title: '',
                    description: '',
                    uploadedFiles: [],
                };
            } else {
                postgData = {
                    author: post.author,
                    modalTitle: gettext('Додати документ'),
                    title: post.title,
                    description: post.description,
                    uploadedFiles: post.documents,
                };
            }
        } else {
            postgData = {
                modalTitle: gettext('Новий діалог'),
                uploadedFiles: [],
            };
        }

        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Monitoring/views/modals/monitoring.post.modal.html",
            controller: "monitoringPostModalCtrl",

            resolve: {
                data: function() {
                    return {
                        ...postgData,
                        tenderId: $scope.tenderId,
                        monitoring_id,
                        post_id,
                    }
                }
            },
            backdrop: 'static'
        }).result.then((result) => {
            if  (!result) return;

            return $scope.sign('post', result.documents, result.id).result
                .then((result, error) => {
                    if (!result) {
                        if (error) {
                            throw Error();
                        } else {
                            throw Error(gettext('Перервано накладання підпису'));
                        }
                    }
                });

        })
            .then((result) => {
                if (!result) return;
                $scope.refreshMonitoring();
                ntFlash.success(gettext('Успішно виконано'));
            })
            .catch(e => {
                $scope.refreshMonitoring();
                ntFlash.error(e || gettext('Виникла помилка'));
            });
    };

    $scope.violationEliminationReport = function (monitoring_id) {
        let violationEliminationData;
        if ($scope.monitoring.eliminationReport) {
            violationEliminationData = {
                modalTitle: gettext('Редагування усунення порушення'),
                description: $scope.monitoring.eliminationReport.description,
                uploadedFiles: [],
            };
            if ($scope.monitoring.eliminationReport.documents) {
                let uploadedFiles = $scope.monitoring.eliminationReport.documents.filter((doc) => {
                    return doc.author === 'tender_owner'
                });
                if (uploadedFiles.length) {
                    violationEliminationData.uploadedFiles = uploadedFiles;
                }
            }
        } else {
            violationEliminationData = {
                modalTitle: gettext('Усунення порушення'),
                description: '',
                uploadedFiles: [],
            }
        }
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Monitoring/views/modals/monitoring.violation.elimination.modal.html",
            controller: "monitoringViolationEliminationModalCtrl",

            resolve: {
                data: function() {
                    return {
                        ...violationEliminationData,
                        tenderId: $scope.tenderId,
                        monitoring_id,
                    }
                }
            },
            backdrop: 'static'
        }).result.then((result)=>{
            if (!result) return;

            return $scope.sign('eliminationReport', result.documents).result
                .then((result, error) => {
                    if (!result) {
                        if (error) {
                            throw Error();
                        } else {
                            throw Error(gettext('Перервано накладання підпису'));
                        }
                    }
                });

        }).then((result)=>{
            if (!result) return;
            $scope.refreshMonitoring();
            ntFlash.success(gettext('Успішно виконано'));
        }).catch((e)=>{
            $scope.refreshMonitoring();
            ntFlash.error(e || gettext('Виникла помилка'));
        });
    };

    $scope.sign = function(entity, documents, post_id) {  // if post_id is undefined then sign eliminationReport


        const data ={
            tenderId: $scope.tender.id,
            monitoringId: $scope.monitoring.id,
            entity: entity,
            postId: post_id,
            documents: documents ||[],
        };

        documentsService.sign(data)
            .then((res) => {
                console.log('documentsService.sign data', res);
            })
            .catch(error => console.log('documentsService.sign error', error));

        // return $uibModal.open({
        //     templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
        //     controller: 'eSignModalCtrl',
        //     resolve: {
        //         data: function() {
        //             return {
        //                 tenderId: $scope.tender.id,
        //                 monitoringId: $scope.monitoring.id,
        //                 entity: entity,
        //                 postId: post_id,
        //                 documents: documents ||[],
        //             }
        //         }
        //     },
        //     windowClass: 'center-modal'
        // });
    };

});