tenderView.controller('tenderViewComplaintConfirmModalCtrl', function(
    $scope,
    $location,
    $window,
    $uibModalInstance,
    data,
    complaintService,
    ntFlash
) {
    var tenderId = data.tenderId,
        complaintId = data.complaintId;

    $scope.disablePublishBtn = false;
    $scope.formData = {
        paymentType: true,
        iban: '',
        edrpo: $window.OPC_CONFIG.PROCURING_ENTITY.identifier.id || '',
        result_url: $location.absUrl(),
        agreed: true
    }

    $scope.publicAsComplaint = function() {
        $scope.disablePublishBtn = true;
        complaintService.getPaymentData(tenderId, complaintId, $scope.formData)
            .then(data => {
                ntFlash.success(gettext('Рахунок сформовано'));
                let url = ($scope.formData) ? data.url_checkout : $location.protocol() + '//' + $location.host() + data.url_checkout;
                $window.open(url);
            })
            .catch(error => {
                ntFlash.error(gettext('Сталася помилка', error));
                console.log('complaintService.getLiqpayUrl/getInvoiceUrl error', error);
            })
            .finally(() => {
                $scope.disablePublishBtn = false;
                $uibModalInstance.close();
            });
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

});