lotApp.controller('lotCtrl', function (
    $scope,
    $rootScope,
    ntFlash,
    tenderService,
    validateService,
    uuid4,
    md5,
    $stateParams,
    uibDatepickerConfig
) {
    ntFlash.clean();
    $scope.tender = tenderService.currentTender();
    console.log($scope.tender)
    // $scope.lots = JSON.stringify($scope.tender.lots.data);
    // $scope.lots = JSON.parse($scope.lots);

    $scope.lot = $scope.tender.lots.filter((l) => l.id === $stateParams.lotId)[0];
    $scope.items = $scope.tender.items.filter((i) => i.relatedLot === $stateParams.lotId);
    $scope.milestones = $scope.tender.milestones?$scope.tender.milestones.filter((m) => m.relatedLot === $stateParams.lotId):[];

    $scope.isPMT = (name) => tenderService.isProcurementMethodType($scope.tender, name);


    if ($scope.tender.procurementMethodType === 'closeFrameworkAgreementSelectionUA') {
        uibDatepickerConfig.minDate = new Date('1960');
        // uibDatepickerConfig.maxDate = new Date();
    }

    if ($scope.lot && $scope.lot.title) {
        $scope.lotTitle = gettext('Редактирование лота');
    } else {
        $scope.lotTitle = gettext('Создание лота');
        $scope.lot = {
            id: md5.createHash(uuid4.generate()),
            title: '',
            description: '',
            guarantee: {
                amount: 0,
                currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : ''
            },
            value: {
                amount: 0,
                currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : '',
            },
            minimalStep: {
                amount: 0,
                currency: $scope.tender.procurementMethodType !== "esco" ? $scope.tender.value.currency : '',
            }
        };
    }

    $scope.guarantee = $scope.lot.guarantee && $scope.lot.guarantee.amount ? 'yes' : '';

    $scope.setGuarantee = function () {
        if (!$scope.guarantee) {
            $scope.lot.guarantee.amount = 0;
        }
    };

    function milestonesSumValid(){
        var total = 0;
        $scope.milestones.forEach(milestone => {
            if (milestone.percentage) {
                total += milestone.percentage;
            }
        });
        return total == 100;
    }

    $scope.save = function () {
        const lotHasItems = validateService.lotHasItem($scope.items);
        const isClassifiersValid = validateService.classifiers($scope.items);
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.lotForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
            return false;
        }

        if (lotHasItems) {
            ntFlash.error(gettext('Каждый лот должен иметь предмет закупки'));
            return false;
        }

        if ( $scope.milestones.length && !milestonesSumValid() ) {
            ntFlash.error(gettext('Сумма значений размеров оплаты всех этапов должна быть равна 100%'));
            return false;
        }

        if (!isClassifiersValid) {
            // ntFlash.error("classifier is not valid");
            return false;
        }

        // if (tenderService.currentTender().procurementMethodType === 'belowThreshold') {
        //     var stepValidation = true;
        //     $scope.lots.forEach(function (lot) {
        //         validateService.step(lot.value.amount, lot.minimalStep.amount) ? true : stepValidation = false;
        //     });
        //     if (!stepValidation) {
        //         return false;
        //     }
        // }

        // var data = $scope.lots;
        console.log('Lots data:',$scope.lot, $scope.items);
        $rootScope.$broadcast('editLot', $scope.lot, $scope.items, $scope.milestones);
    };

    $scope.close = function () {
        $rootScope.$broadcast('editLot');
    };
});
