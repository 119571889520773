class Http {
    constructor(
        $http,
        $rootScope,
        $q,
        ntFlash,
        baseUrl
    ) {
        const promiseDetector = (response, status, headers, config) => {
            let q = $q.defer();
            if (response.data.result === false) {
                ntFlash.error(gettext('Произошла ошибка. Пожалуйста, свяжитесь с нашей службой поддержки.'), response.data);
                q.reject(response);
            } else {
                q.resolve(response);
            }
            return q.promise;
        };
        const rejectDetector = (err, status, headers, config) => {
            let errors = err.data.errors;
            if (errors) {
                for (let i=0, size=errors.length; i < size; i++) {
                    var msg = errors[i].message;
                    switch (msg) {
                        case 'Modification factor should be in range 0.9 - 1.1':
                            msg = 'Зміна ціни за одиницю не може перевищувати 10%';
                            break;
                        case 'Broker Accreditation level does not permit plan creation':
                            msg = 'Рівень акредитації не дозволяє створити план';
                            break;
                        case 'Broker Accreditation level does not permit tender creation':
                            msg = 'Рівень акредитації не дозволяє створити закупівлю';
                            break;
                        case 'Can\'t update procuringEntity later than 2 business days before tenderPeriod.StartDate':
                            msg = 'Не можливо змінити закупівельника пізніше ніж за 2 робочих дня до початку процедури закупівель';
                            break;
                    }
                    console.log('%c'+msg, "color: #3faa98; font-size: 40px;");
                    ntFlash.error(msg);
                }
            } else {
                ntFlash.error(gettext('Произошла ошибка.'), err.data);
                console.groupCollapsed("Http error:");
                console.log('%c'+err.data, "color: #3faa98; font-size: 10px;");
                console.groupEnd();
            }
            return $q.reject(err);
        };
        const notifyDetector = (data) => {
            console.log('notifyDetected');
            return data;
        };

        this.getByPage = (url, page, noBaseUrl, cache) => {
            if (noBaseUrl) {
                return $http.get(url + '?page=' + page, {
                        cache: cache || false
                    })
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.get(baseUrl + url + '?page=' + page, {
                        cache: cache || false
                    })
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };
        this.get = (url, noBaseUrl, cache) => {
            if (noBaseUrl) {
                return $http.get(url, {
                        cache: cache || false
                    })
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.get(baseUrl + url, {
                        cache: cache || false
                    })
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };
        this.post = (url, params, noBaseUrl) => {
            if (noBaseUrl) {
                return $http.post(url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.post(baseUrl + url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };
        this.patch = (url, params, noBaseUrl) => {
            if (noBaseUrl) {
                return $http.patch(url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.patch(baseUrl + url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };
        this.delete = (url, noBaseUrl) => {
            if (noBaseUrl) {
                return $http.delete(url)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.delete(baseUrl + url)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };
        this.put = (url, params, noBaseUrl) => {
            if (noBaseUrl) {
                return $http.put(url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            } else {
                return $http.put(baseUrl + url, params)
                    .then(promiseDetector, rejectDetector, notifyDetector);
            }
        };

    }
}
commons.service('http', Http);