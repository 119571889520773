profileApp.factory('profileService', function (
    http,
    $http,
    $window,
    API_BASE_URL,
    $q
) {

    var userProfileResource = '/cab/profile/user/';
    var companyProfileResource = '/cab/profile/company/';


    const URL_SETTINGS = `${API_BASE_URL}/notifications-settings/`;

    const URL_USER = `${API_BASE_URL}/users`;
    const URL_USER_PASSWORD = `${URL_USER}/change-password/`;
    const URL_USER_RESET_PASSWORD = `${URL_USER}/reset-password/:hash/`;
    const URL_USER_LOGIN = `${URL_USER}/login/`;
    const URL_USER_RECOVERY = `${API_BASE_URL}/password-reset/`;
    const URL_USER_ITEM = `${URL_USER}/:id/`;
    const URL_USER_REGISTER = `${URL_USER}/register/`;
    const URL_USER_REGISTER_WITH_COMPANY = `${URL_USER_REGISTER}company/`;
    const URL_USER_REGISTER_WITH_REQUEST_TO_COMPANY = `${URL_USER_REGISTER}company-request/`;
    const URL_USER_REQUESTS = `${URL_USER}/:id/employee_requests/`;
    const URL_USER_REQUESTS_ITEM = `${URL_USER_REQUESTS}:request/`;
    const URL_USER_SETTINGS = `${URL_USER}/settings/`;
    const URL_USER_SUBSCRIPTIONS = `${API_BASE_URL}/user/subscription/cpv/`;
    const URL_USER_SUBSCRIPTIONS_SUBSCRIBE = `${URL_USER_SUBSCRIPTIONS}subscribe/`;

    const URL_USER_COMPANIES = `${API_BASE_URL}/companies`;
    const URL_USER_COMPANY = `${URL_USER_COMPANIES}/:id/`;
    const URL_USER_COMPANY_REGISTER = `${URL_USER_COMPANIES}/register/`;
    const URL_USER_COMPANY_REQUESTS = `${URL_USER_COMPANY}employee_requests/`;
    const URL_USER_COMPANY_REQUESTS_ITEM = `${URL_USER_COMPANY_REQUESTS}:request/`;
    const URL_USER_COMPANY_USERS = `${URL_USER_COMPANY}users/`;
    const URL_USER_COMPANY_USERS_ITEM = `${URL_USER_COMPANY_USERS}:user/`;

    const URL_USER_GET_INTEGRATION_TOKEN = `${URL_USER}/api-token/`;
    const URL_LOGS = `${API_BASE_URL}/activity-logger/`

    const USER_NEW_SUBSCRIPTION = `${API_BASE_URL}/user/distribution/rule/`


    let userPromise = null;

    let service = {
        current: null,
        requestsToCompanies: null,
        setCurrentUser: user => service.current = user,
        setRequestsToCompanies: requests => service.requestsToCompanies = requests,
        reloadCurrentUser: () => {
            return service.getUserForce()
                .then((result) => {
                    result.data.actions = result.data.companies[0] && result.data.companies[0].permissions;
                    service.setCurrentUser(result.data);
                    return service.current;
                });
        },
        getUser: function () {
            if (!userPromise) {
                userPromise = http.get(URL_USER_SETTINGS, true);
            }
            return userPromise;
        },
        getUserForce: () => {
            service.clearUserPromise();
            return service.getUser();
        },
        clearUserPromise: () => {
            userPromise = null;
        },
        getCompany: () => {
            return service.getUser()
                .then(result => result.data.companies[0])
                .then((company) => {
                    return company ? service.getCompanyById(company.id) : $q.resolve(null);
                });
        },
        getCompanyById: (companyId) => {
            const url = URL_USER_COMPANY.replace(':id', companyId);
            return http.get(url, true);
        },
        recoveryPassword: (data) => {
            return http.post(URL_USER_RECOVERY, data, true);
        },
        saveUser: function (user) {
            const url = URL_USER_ITEM.replace(':id', user.id);
            return http.patch(url, user, true);
        },
        saveUserPassword: function (user) {
            return http.patch(URL_USER_PASSWORD, user, true);
        },
        resetUserPassword: function (user, hash) {
            const url = URL_USER_RESET_PASSWORD.replace(':hash', hash);
            return http.patch(url, user, true);
        },
        checkResetPasswordHash: (hash) => {
            const url = URL_USER_RESET_PASSWORD.replace(':hash', hash);
            return http.get(url, true);
        },
        saveCompany: function (company) {
            const url = URL_USER_COMPANY.replace(':id', company.id);
            return http.patch(url, company, true);
        },
        signout: () => {
            $window.localStorage.setItem('is-anonymous', 'true');
            $window.location.href = '/signout';
        },
        createUser: function (data) {
            return http.post(URL_USER_REGISTER, data, true);
        },
        createUserWithCompany: function (data) {
            return http.post(URL_USER_REGISTER_WITH_COMPANY, data, true);
        },
        createUserWithRequestToCompany: function (data) {
            return http.post(URL_USER_REGISTER_WITH_REQUEST_TO_COMPANY, data, true);
        },
        loginUser: (data) => {
            return http.post(URL_USER_LOGIN, data, true);
        },
        createCompany: (company) => {
            return $http.post(URL_USER_COMPANY_REGISTER, company);
        },
        sendRequestToCompany: (user, data) => {
            const url = URL_USER_REQUESTS.replace(':id', user.id);
            return http.post(url, data, true);
        },
        removeRequestToCompany: (request) => {
            const url = URL_USER_REQUESTS_ITEM
                .replace(':id', request.user.pk)
                .replace(':request', request.id);
            return http.delete(url, true);
        },
        getRequestsToCompany: (company) => {
            const url = URL_USER_COMPANY_REQUESTS.replace(':id', company.id);
            return http.get(url, true);
        },
        getUserRequests(user) {
            const url = URL_USER_REQUESTS.replace(':id', user.id);
            return http.get(url, true);
        },
        updateRequestsToCompany: (status, request) => {
            const url = `${URL_USER_COMPANY_REQUESTS_ITEM}${status}`
                .replace(':id', request.company.pk)
                .replace(':request', request.id);
            return http.patch(url, {}, true);
        },
        getCompanyUsers: (company) => {
            const url = URL_USER_COMPANY_USERS.replace(':id', company.id);
            return http.get(url, true);
        },
        updateCompanyUser: (company, item, data) => {
            const url = URL_USER_COMPANY_USERS_ITEM
                .replace(':id', company.id)
                .replace(':user', item.user.id);
            return http.patch(url, data, true);
        },
        removeCompanyUser: (company, item) => {
            const url = URL_USER_COMPANY_USERS_ITEM
                .replace(':id', company.id)
                .replace(':user', item.user.id);
            return http.delete(url, true);
        },
        saveListCPV: (list) => {
            return http.post(URL_USER_SUBSCRIPTIONS_SUBSCRIBE, list, true);
        },
        getUserSubscriptions: () => {
            return http.get(URL_USER_SUBSCRIPTIONS, true);
        },
        getUserIntegrationToken: () => {
            return http.get(URL_USER_GET_INTEGRATION_TOKEN, true);
        },
        getNotificationsSettings: (userEmail) => {
            if (!userEmail) {
                return http.get(URL_SETTINGS, true);
            } else {
                return http.get(URL_SETTINGS.concat('?email=', userEmail), true);
            }
        },
        setNotificationsSetting: (setting) => {
            http.post(URL_SETTINGS, setting, true);
        },
        getAdminCompanyUsers: () => {
            return http.get(URL_SETTINGS.concat('admin-users/'), true)
        },
        getProfileActivityLogs: (company, page = 1, count = 10, queryData) => {
            let query = `activity-logs/?company=${company}`;
            query += `&page_size=${count}`
            query += `&page=${page}`
            if(queryData != null){
                query   += queryData
            }
            return http.get(URL_LOGS+query, true)
        },

        UoAuthorityRequest(data) {
            let request = `/api/v1/users/drfo-verify/?drfo=${data}`

            return http.get(request, true)
        },

        getUo : ()=>{

            const now2 = new Date()

            // const itemStr = $window.localStorage.getItem('AuthorityUO')
            const itemStr = JSON.stringify({value: now2.getTime(), expiry: now2.getTime() + 172800000});
            if (!itemStr) {
                return null
            }
            const item = JSON.parse(itemStr)
            const now = new Date()
            if (now.getTime() > item.expiry) {
                $window.localStorage.removeItem('AuthorityUO')
                return null
            }
            return item.value
        },

        setUo : (value)=>{
            if($window.localStorage.getItem('AuthorityUO')){
                $window.localStorage.removeItem('AuthorityUO')
            }
            const now = new Date()
            const item = {
                value: value,
                expiry: now.getTime() + 172800000,
            }
            $window.localStorage.setItem('AuthorityUO', JSON.stringify(item))
            return value
        },

        dropUo : ()=>{
            $window.localStorage.removeItem('AuthorityUO')
        },

        getUserSubscriptionRules(link = null){
            if(link){
                return http.get(link, true)

            } else {
                return http.get(USER_NEW_SUBSCRIPTION, true)

            }

        },

        getUserSingleSubscriptionRules(id){
            return http.get(`${USER_NEW_SUBSCRIPTION}${id}`, true)

        },

        newUserSubscriptionRules( setting ){
            return http.post(USER_NEW_SUBSCRIPTION, setting, true)

        },

        editUserSubscriptionRules(id, setting){
            return http.patch(`${USER_NEW_SUBSCRIPTION}${id}/`, setting, true)

        },

        deleteUserSubscriptionRules(id){
            return http.delete(`${USER_NEW_SUBSCRIPTION}${id}`, true)

        }


    };
    return service;
});
