profileApp.factory('receiptsService', function (
    http,
    ntFlash
) {
    var listReceiptsResource = '/api/v1/receipts';

    let service = {
        getReceipts: function (nextPage) {
            var resource = listReceiptsResource;
            if (nextPage) {
                resource = listReceiptsResource + '?page=' + nextPage;
            }
            return http.get(resource, true)
                .then(function (result) {
                        return result;
                    },
                    function (error) {
                        console.error('Create receipts error: ',error);
                    });
        }
    };

    return service;
});