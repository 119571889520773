class UntilPeriodEnd {
    constructor() {
        this.restrict = 'E';
        this.require = 'ngModel';
        this.transclude = true;
        this.scope = {
            periodEndDate: '@'
        };
        this.template = `
            <div ng-if="hasPeriodEndDate && willEndToday && !timeOver" class="period-end-today">
                {{:: 'Заканчивается сегодня' | translate}}
            </div>
            <div ng-if="hasPeriodEndDate && !timeOver" class="period-end">
                {{:: 'Еще' | translate }} {{ daysUntilPeriodEnd }}
                {{::
                    daysUntilPeriodEnd % 10 == 1 && daysUntilPeriodEnd % 100 != 11 ? 'день' :
                    daysUntilPeriodEnd % 10 >= 2 && daysUntilPeriodEnd % 10 <= 4 && ( daysUntilPeriodEnd % 100 < 10 || daysUntilPeriodEnd % 100 >= 20 ) ? 'дня' :
                        'дней' | translate
                }}, 
                {{ hoursUntilPeriodEnd }} {{:: 'ч.' | translate }} {{ minutesUntilPeriodEnd }} {{:: 'мин.' | translate }}
            </div>
        `;
    }
    link(scope, element, attrs, modelCtrl) {
        const
            currentTime = +(new Date()),
            toEndOfPeriod = +(new Date(scope.periodEndDate.replace(/"/g,'')));
        var endOfToday = new Date();
        endOfToday.setHours(23, 59, 59, 999);

        var now = new Date();
        var toEndOfToday = endOfToday - now;
        scope.hasPeriodEndDate = !!scope.periodEndDate;

        scope.willEndToday = toEndOfPeriod < toEndOfToday ? scope.willEndToday = true :  toEndOfPeriod <= endOfToday;

        var exectPeriod = toEndOfPeriod - currentTime;
        scope.daysUntilPeriodEnd = Math.floor(exectPeriod / (1000 * 60 * 60 * 24));
        scope.hoursUntilPeriodEnd = Math.floor((exectPeriod - scope.daysUntilPeriodEnd * 1000 * 60 * 60 * 24) / (1000 * 60 * 60));
        scope.minutesUntilPeriodEnd = Math.floor((exectPeriod - scope.daysUntilPeriodEnd * 1000 * 60 * 60 * 24 - scope.hoursUntilPeriodEnd * 1000 * 60 * 60) / (1000 * 60));
        scope.timeOver = (toEndOfPeriod - currentTime) <= 0;


    }
}
commons.directive('untilPeriodEnd', () => new UntilPeriodEnd);