qualityApp.controller('qualityCtrl', function (
    $scope,
    $rootScope,
    ntFlash,
    tenderService,
    validateService,
    constructorService
) {
    $scope.isCompetitiveDialogue = tenderService.isCompetitiveDialogue(tenderService.currentTender());
    $scope.isEsco = tenderService.isEsco(tenderService.currentTender());
    $scope.minValue = $scope.isCompetitiveDialogue ? 1 : 0;

    // $scope.features = [...tenderService.currentTender().features || []];
    if (tenderService.currentTender().features) {
        $scope.features = JSON.stringify(tenderService.currentTender().features); // need deep copy !!!
        $scope.features = JSON.parse($scope.features);
    } else {
        $scope.features = [];
    }

    $scope.items = tenderService.currentTender().items;
    $scope.lots = (tenderService.currentTender().lots) ? [...tenderService.currentTender().lots] : [];
    $scope.procurementMethodType = tenderService.currentTender().procurementMethodType;

    // Prepare show percents
    $scope.features.forEach((f) => {
        f.enum.forEach((e) => {
            if (e.value) e.value *= 100;
        });
    });

    $scope.addField = function (index, value) {
        if (typeof (index) === 'number') {
            $scope.features[index].enum.push({
                title: '',
                description: '',
                value: typeof (value) === 'number' ? value : ''
            });
        } else {
            $scope.features.push({
                title: '',
                description: '',
                featureOf: '',
                relatedItem: null,
                enum: [{
                    title: '',
                    description: '',
                    value: typeof (value) === 'number' ? value : ''
                }]
            });
        }
    };

    if ($scope.features.length === 0) {
        if ($scope.isCompetitiveDialogue || $scope.isEsco) {
            $scope.addField(null, 0);
            $scope.addField(0);
        } else {
            $scope.addField();
        }
    }

    $scope.addNewField = () => {
        if ($scope.isCompetitiveDialogue || $scope.isEsco) {
            $scope.addField(null, 0);
            $scope.addField($scope.features.length - 1);
        } else {
            $scope.addField();
        }
    };

    $scope.removeField = function (arr, index) {
        arr.splice(index, 1);
    };

    $scope.calculateMax = function (feature, option) {
        var value = $scope.features[feature].enum[option].value,
            total = 0,
            limit = $scope.isCompetitiveDialogue ? 100 : $scope.isEsco ? 25 : 30,
            featureRelatedItemId = $scope.features[feature].relatedItem;

        $scope.features.forEach(function (ftr, indx) {
            var max = 0;

            if (ftr.featureOf === "tenderer") {
                ftr.enum.forEach(function (optn) {
                    if (feature != indx) {
                        max = Number(optn.value) > max ? Number(optn.value) : max;
                    }
                });
                total += max;
            }
            if (ftr.featureOf === "lot") {
                if (ftr.relatedItem && ftr.relatedItem === featureRelatedItemId) {
                    // console.log('Учитываем: ', ftr.relatedItem);
                    ftr.enum.forEach(function (optn) {
                        if (feature != indx) {
                            max = Number(optn.value) > max ? Number(optn.value) : max;
                        }
                    });
                }
                total += max;
            }
            if (ftr.featureOf === "item") {
                if (ftr.relatedItem) {

                    let relatedLotIdx = $scope.items.map((el) => {
                        return el.id;
                    }).indexOf(ftr.relatedItem);
                    if (relatedLotIdx >= 0) {
                        let relatedLotId = $scope.items[relatedLotIdx].relatedLot;
                        $scope.features.forEach(function (lotFtr) {
                            var lotMax = 0;
                            if (lotFtr.featureOf === "lot") {
                                if (lotFtr.relatedItem && lotFtr.relatedItem === relatedLotId) {
                                    lotFtr.enum.forEach(function (optn) {
                                        lotMax = Number(optn.value) > max ? Number(optn.value) : max;
                                    });
                                }
                                total += lotMax;
                            }
                        });
                    }
                }
                if (ftr.relatedItem && ftr.relatedItem === featureRelatedItemId) {
                    console.log('Учитываем фичи айтема: ', ftr.relatedItem);
                    ftr.enum.forEach(function (optn) {
                        if (feature != indx) {
                            max = Number(optn.value) > max ? Number(optn.value) : max;
                        }
                    });
                }
                total += max;
            }
        });

        limit = limit - total;
        if (limit < value) {
            return value;
        }
        return limit;
    };

    $scope.save = function () {
        $scope.$broadcast('show-errors-check-validity');

        var isFeaturesValid = validateService.features($scope.features);

        if (!$scope.qualityForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
            return false;
        }

        if (isFeaturesValid) {
            var data = constructorService.format($scope.features);

            // Format value from percents
            data.forEach((f) => {
                f.enum.forEach((e) => {
                    if (e.value) e.value = parseFloat((e.value / 100).toFixed(2));
                });
            });


            $rootScope.$broadcast('addQualities', data);
        }
    };

    $scope.close = function () {
        $rootScope.$broadcast('addQualities');
    };
});