tenderView.controller('tenderDeleteModalCtrl', function(
    $rootScope,
    $scope,
    $uibModalInstance,
    $uibModal,
    $state,
    tenderService,
    documentsService,
    data,
    ntFlash,
    $q,
    $timeout
) {
    $scope.files = [];
    $scope.reason = "";
    $scope.reasonType = "";
    $scope.procurementMethodType = data.procurementMethodType;
    $scope.disableDeletehBtn = false;
    $scope.amount = data.amount
    $scope.kind = data.kind

    const tenderId = data.tenderId;
    const lotId = data.lotId;
    let cancellationId;

    // console.log(lotId);

    $scope.isPMT = types => types.includes($scope.procurementMethodType);

    $scope.delete = function() {
        if (!$scope.files.length) {
            ntFlash.error(gettext('Відміна Закупівлі без завантаження документів не можлива'));
            return;
        }
        if ($scope.cancelTenderForm.$valid) {
            $scope.disableDeletehBtn = true;



            if ($scope.isPMT(['belowThreshold', 'reporting', 'closeFrameworkAgreementSelectionUA'])) {
                tenderService.totalDelete(tenderId, lotId, $scope.reason, $scope.reasonType, $scope.files, function() {
                    $timeout(function() {
                        $state.reload();
                    }, 1000);
                    ntFlash.success(gettext('Тендер/Лот успешно отменен.'));
                    $uibModalInstance.close();
                })
                    .catch(() => $scope.disableDeletehBtn = false);
            } else {
                tenderService.delete(tenderId, lotId, $scope.reason, $scope.reasonType)
                    .then(result => {
                        console.log('tenderService.delete data', data);
                        cancellationId = result.data.response.id;
                        let accPromises = [];
                        for (const document of $scope.files) {
                            accPromises.push(documentsService.save(document, tenderId, {
                                cancellationId: cancellationId
                            }));
                        }
                        Promise.all(accPromises)
                            .then(data => {
                                console.log('documentsService.save data', data);
                                $scope.sign()
                                    .then((data)=>{
                                        if(data && data.ap) {
                                            tenderService.activateTenderCancellation({
                                                tenderId: tenderId,
                                                cancellationId: cancellationId
                                            })
                                                .then(data => {
                                                    console.log('tenderService.activateTenderCancellation data', data);
                                                    ntFlash.success(gettext('Тендер/Лот успешно отменен.'));
                                                    $uibModalInstance.close();
                                                    return data;
                                                })
                                                .catch(error => {
                                                    ntFlash.error(gettext('Сталася помилка ', error));
                                                    console.log('tenderService.activateTenderCancellation error', error);
                                                    return error;
                                                })
                                                .finally(() => $scope.disableDeletehBtn = false);
                                        } else {
                                            ntFlash.error(gettext('Сталася помилка'));
                                            $scope.disableDeletehBtn = false
                                        }
                                    });
                            })
                            .catch(error => {
                                ntFlash.error(gettext('Сталася помилка ', error));
                                console.log('documentsService.save error', error);
                            });
                    })
                    .catch(error => {
                        ntFlash.error(gettext('Сталася помилка ', error));
                        console.log('tenderService.delete error', error);
                    })
                    .finally(() => {
                        $timeout(() => $state.reload(), 3000);
                    });

            }
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.deleteDoc = function(index) {
        $scope.files.splice(index, 1);
    };

    $scope.$watch('file', function(val) {
        if ($scope.file && $scope.file.length) {
            $scope.files.push(val[0]);
        }
    });

    $scope.sign = function ( ) {
        const data = {
            tenderId: tenderId,
            cancellationId: cancellationId,
            // callback: callback
        };
        console.log(data);


        if (($scope.procurementMethodType !== 'reporting' || ($scope.procurementMethodType === 'reporting' && $scope.amount > 50000))
            && $scope.kind !== 'other'  ) {
            return documentsService.sign(data, {checkPerson: true})
                .then(data => {
                    console.log('documentsService.sign data', data)
                    return data
                })
                .catch(error => console.log('documentsService.sign error', error))
            // data.checkPerson = true


        } else {
            return documentsService.sign(data)
                .then(data => {
                    console.log('documentsService.sign data', data)
                    return data
                })
                .catch(error => console.log('documentsService.sign error', error))
        }


    };

    $scope.checkPerson = function(){
        return documentsService.sign({}, {checkPerson: ()=> {console.log('Checked')}})
            .then(data => {
                console.log('documentsService.sign data', data);
                // $scope.awardSign.signed = true;
                return data;
            })
            .catch(error => console.log('documentsService.sign error', error));
    }


});