planningApp.controller('planningOverviewCtrl', function(
    $scope,
    $rootScope,
    $state,
    $filter,
    $timeout,
    planningService,
    transferService,
    planData,
    $uibModal,
    ntFlash,
    formationService,
    documentsService,
    $window
) {
    ntFlash.clean();

    $scope.files = [];
    $scope.milestonesFiles = [];
    $scope.replaceFiles = {
        replaceId: [],
        replaceFile: [],
        setReplaceId: function(id) {
            $scope.replaceFiles.replaceId = id;
        }
    };
    $scope.replaceDocumentId = null;

    $scope.procuringEntity = $window.OPC_CONFIG.PROCURING_ENTITY;
    $scope.isSigned = false;
    $scope.isSignedLastMilestones = false;
    $scope.transfer = planData.data.transfer;
    $scope.plan = planData.data.response;
    $scope.actions = planData.data.action;
    $scope.relatedTender = planData.data.tender;

    $scope.isCZO = false;
    if ($scope.procuringEntity.hasOwnProperty('identifier')) {
        $scope.isCZO =  $scope.procuringEntity.identifier.id === $scope.plan.procuringEntity.identifier.id &&
                        $scope.procuringEntity.kind === 'central' &&
                        $scope.plan.tender.procurementMethodType === 'centralizedProcurement';
    }
    $scope.isDream = $scope.plan.project ? true : false

    $scope.isCZOAndOwnMilestones = false;
    $scope.isCZOActiveMilestones = false;
    if ($scope.plan.milestones && $scope.plan.milestones.length) {
        $scope.lastMilestones = [...$scope.plan.milestones].pop();
        $scope.isCZOAndOwnMilestones =  $scope.isCZO && $scope.lastMilestones.author.identifier.id === $scope.procuringEntity.identifier.id;
        $scope.isCZOActiveMilestones =  $scope.isCZOAndOwnMilestones && ['scheduled', 'met'].includes($scope.lastMilestones.status);
    }

    $scope.documents = $filter('versionFilter')(planData.data.response.documents);
    $scope.milestonesDocuments = $scope.lastMilestones ? $filter('versionFilter')($scope.lastMilestones.documents) : [];

    $scope.plan.budget.TaxIncluded = true;

    if ($scope.documents && $scope.documents.length) {
        $scope.documents.forEach(function (document) {
            if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                $scope.isSigned = true
                // $scope.isSigned = (new Date(document.dateModified).getTime() + 1000) > new Date($scope.plan.dateModified).getTime(); // + 1000 - fix diff in miliseconds
                // $scope.dateSigned = document.dateModified;
                // console.log(new Date(document.dateModified).getTime() + 20000)
                // console.log(new Date($scope.plan.dateModified).getTime())
            }
        });
    }

    if ($scope.milestonesDocuments && $scope.milestonesDocuments.length) {
        $scope.milestonesDocuments.forEach(function (document) {
            if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                // $scope.isSignedLastMilestones = (new Date(document.dateModified).getTime() + 10000) > new Date($scope.lastMilestones.dateModified).getTime(); // + 1000 - fix diff in miliseconds
                $scope.isSignedLastMilestones = true
                // $scope.dateSignedLastMilestones = document.dateModified;
            }
        });
    }

    if(!$scope.plan.additionalClassifications) {
        $scope.plan.additionalClassifications = [];
    }
    $scope.plan.additionalClassifications.data = [];
    $scope.plan.additionalClassifications.kekv = {
        field: '',
        data: []
    };

    $scope.plan.additionalClassifications.forEach(function(classification) {
        if (classification.scheme === 'КЕКВ') {
            $scope.plan.additionalClassifications.kekv.data.push(classification);
        } else {
            $scope.plan.additionalClassifications.data.push(classification);
        }
    });

    $scope.plan.additionalClassifications.KEKV_full = formationService.classifier($scope.plan.additionalClassifications.kekv.data);

    console.log('plan', $scope.plan);
    console.log('actions', $scope.actions);
    console.log('procuringEntity', $scope.procuringEntity);

    // $scope.canChangeStatus = ['belowThreshold', 'reporting', ''].includes($scope.plan.tender.procurementMethodType);

    $scope.transferTokenOfNewPlan = null;

    $scope.sendTransferToken = function(token) {
        if (token) {
            transferService.send('plans', token, $scope.plan.id)
                .then(function(data) {
                    console.log('response transferService', data);
                    if (data.data.action.budget_owner) {
                        ntFlash.success(gettext('Ваш запит на перенос виконано'));
                        $timeout(function () {
                            console.log('reload ...');
                            $state.reload();
                        }, 2000);
                    } else {
                        ntFlash.error(gettext('При спробі переносу виникла помилка'));
                    }
                })
                .catch(function(error) {
                    console.log('error', error);
                    ntFlash.error(error.errorMessages);
                })
        }
    }

    $scope.isTransferAllowed = true;

    if ($scope.plan.budget.breakdown !== undefined) {
        for (const breakdown of $scope.plan.budget.breakdown) {
            switch (breakdown.title) {
                case 'state' : breakdown.title = 'Державний бюджет України'; break;
                case 'crimea': breakdown.title = 'Бюджет Автономної Республіки Крим';  break;
                case 'local' : breakdown.title = 'Місцевий бюджет';  break;
                case 'own'   : breakdown.title = 'Власний бюджет (кошти від господарської діяльності підприємства)';  break;
                case 'fund'  : breakdown.title = 'Бюджет цільових фондів (що не входять до складу Державного або місцевого бюджетів)';  break;
                case 'loan'  : breakdown.title = 'Кредити та позики міжнародних валютно-кредитних організацій';  break;
                case 'other' : breakdown.title = 'Інше';  break;
            }
        }
    }

    $scope.showCustomer = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function() {
                    return $scope.plan.buyers[0];
                }
            },
            windowClass: 'center-modal'
        });
    };

    $scope.deletePlan = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Planning/views/modals/planning-delete-modal.html',
            controller: 'planningDeleteModalCtrl',
            resolve: {
                data: function() {
                    return $scope.plan._id;
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.replace = function(documentId) {
        $scope.replaceDocumentId = documentId;
    }

    $scope.$watch('files', function() {
        if ($scope.files && $scope.files.length) {
            documentsService.save($scope.files[0], null, {planId: $scope.plan.id})
                .then(function() {
                    ntFlash.success(gettext('Документ успешно загружен.'));
                    $state.reload();
                })
                .catch(function () {
                    ntFlash.error(gettext('Помилка при завантаженні документа.'));
                });
        }
    });

    $scope.$watch('milestonesFiles', function() {
        if ($scope.milestonesFiles && $scope.milestonesFiles.length && $scope.isCZOActiveMilestones) {
            documentsService.save($scope.milestonesFiles[0], $scope.plan.id, {milestoneId: $scope.lastMilestones.id})
                .then(function() {
                    ntFlash.success(gettext('Документ успешно загружен.'));
                    $state.reload();
                })
                .catch(function () {
                    ntFlash.error(gettext('Помилка при завантаженні документа.'));
                });
        }
    });

    $scope.$watch('replaceFiles.replaceFile', function() {
        if ($scope.replaceFiles.replaceFile && $scope.replaceFiles.replaceFile.length) {
            let request = $scope.isCZOActiveMilestones ?
                documentsService.replace($scope.replaceFiles.replaceFile[0], $scope.plan.id, $scope.replaceFiles.replaceId, {milestoneId: $scope.lastMilestones.id}) :
                documentsService.replace($scope.replaceFiles.replaceFile[0], null, $scope.replaceFiles.replaceId, {planId: $scope.plan.id});

            request
                .then(function(response) {
                    if (response) {
                        ntFlash.success(gettext('Документ успешно заменен'));
                        $state.reload();
                    }
                })
                .catch(function () {
                    ntFlash.error(gettext('Помилка при завантаженні документа.'));
                });
        }
    });

    $scope.sign = function(isPlan) {
            const data = isPlan ? {
                planId: $scope.plan.id,
                documents: $scope.documents || ($scope.documents = [])
            } : $scope.isCZOAndOwnMilestones ? {
                planId: $scope.plan.id,
                milestoneId: $scope.lastMilestones.id,
                documents: $scope.milestonesDocuments
            } : {};
        let procurementMethod = $scope.plan.tender.procurementMethodType
            if ((procurementMethod !== 'reporting' || (procurementMethod === 'reporting' && $scope.plan.budget.amount > 50000))
                && procurementMethod !== 'belowThreshold'
                && $scope.plan.procuringEntity.kind !== 'other') {
                return documentsService.sign(data, {checkPerson: true, reload: false})
                    .then(data => {
                        console.log('documentsService.sign data', data)
                    return data;})
                    .catch(error => console.log('documentsService.sign error', error));
            } else {
                return documentsService.sign(data, {reload: true})
                    .then(data => console.log('documentsService.sign data', data))
                    .catch(error => console.log('documentsService.sign error', error));
            }

    };
    $scope.publish = function(sign) {

        let procurementMethod = $scope.plan.tender.procurementMethodType
        if($scope.plan.procuringEntity.kind !== 'other'
            && (procurementMethod !== 'reporting' || (procurementMethod === 'reporting' && $scope.plan.budget.amount > 50000))
            && procurementMethod !== 'belowThreshold'){
            $scope.sign(true)
                .then((data)=>{
                    if(data && data.ap){
                        planningService.create($scope.plan.id)
                            .then((data)=>{
                                if(data.data)
                                    $state.reload();
                            })
                            .catch((error)=>console.log('planningService.create error', error))
                    }  else {
                        console.log('error');
                    }

                })
        } else {
            planningService.create($scope.plan.id)
                .then((data)=>{
                    if(data.data)  $state.reload();
                })
                .catch((error)=>console.log('planningService.create error', error))
        }

    }
    $scope.createTenderFromPlan = function() {
        if ($scope.plan.tender_id) {
            ntFlash.error(gettext('По данному Плану ранее уже был создан Тендер.'));
            return;
        }
        if ($scope.plan.tender.procurementMethodType === 'centralizedProcurement') {
            ntFlash.error(gettext('В цьюму Плані вказано, що Закупівля буде проходить через ЦЗО. Якщо це Ви, скористайтесь доступним для Вас функціоналом.'));
            return;
        }
        $state.go('editTender', {
            tenderProcedure: $scope.plan.tender.procurementMethodType,
            lots: ($scope.plan.tender.procurementMethodType !== 'reporting') ? 'multilot' : 'singlelot',
            entity: 'plan',
            id: $scope.plan.id
        }, {reload:true});
    }

    $scope.cancelPlan = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Planning/views/modals/planning-cancel-modal.html',
            controller: 'planningCancelModalCtrl',
            resolve: {
                data: function() {
                    return $scope.plan.id;
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };

    $scope.completePlan = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Planning/views/modals/planning-complete-modal.html',
            controller: 'planningCompleteModalCtrl',
            resolve: {
                data: function() {
                    return $scope.plan.id;
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    };
    $scope.checkDisabled = function (){
       if($scope.plan.procuringEntity.kind == 'other'){
           return true;
        } else {
           return false
       }

    }

    $scope.openMilestonesModal = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Planning/views/modals/planning-milestones-modal.html',
            controller: 'planningMilestonesModalCtrl',
            resolve: {
                data: function() {
                    return {
                        planId: $scope.plan.id,
                        milestones: $scope.isCZOActiveMilestones ? $scope.lastMilestones : null,
                        procuringEntity: $scope.procuringEntity
                    }
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    }

    $scope.addRationale = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Planning/views/modals/planning-rationale-modal.html',
            controller: 'planningRationaleModalCtrl',
            resolve: {
                data: function() {
                    return {
                        planId: $scope.plan.id,
                    }
                }
            },
            windowClass: 'center-modal',
            backdrop: 'static'
        });
    }

    $scope.saveToClipboard = function() {
        $window.getSelection().removeAllRanges();
        let copyText = document.getElementById("planID");
        let range = document.createRange();
        range.selectNode(copyText);
        $window.getSelection().addRange(range);
        try {  
            if (document.execCommand('copy'))
                ntFlash.success(gettext('Скопійовано в буфер клавіатури'));
        } catch(err) {  
                ntFlash.error(gettext('Помилка копіювання'));
        }
        setTimeout(() => {
            $window.getSelection().removeAllRanges();
        }, 50);
    }
});
