class NtBlockInfo {
    constructor() {
        this.transclude = true;
        this.restrict = 'E';
        this.scope = {
            name: '@'
        };
        this.template = `
        <div class="block-info">
            <div class="block-info__title">
                {{:: name | translate}}
            </div>
            <div class="block-info__text" ng-transclude></div>
        </div>
        `;
    }
}

commons.directive("ntBlockInfo", () => new NtBlockInfo);