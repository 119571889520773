class NtContent {
    constructor() {
        this.transclude = true;
        this.restrict = 'E';
        this.scope = {
            title: '@'
        };
        this.template = `
            <div class="page-form">
                <div layout="raw"
                     layout-align="start start">
                    <md-content layout-padding
                                md-whiteframe="2"
                                flex="70"
                                flex-sm="100"
                                flex-xs="100"
                                class="content-form">
                        <h2 class="content__title" md-colors="::{background: 'primary'}">
                            {{:: title | translate}}
                        </h2>
                        <div ng-transclude></div>
                    </md-content>
                </div>
            </div>
        `;
    }
}

commons.directive("ntContent", () => new NtContent);