commons.filter('negotiationReasonsFilter', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'twiceUnsuccessful':
                name = gettext('Двічі відмінено процедуру відкритих торгів, у тому числі частково (за лотом), через відсутність достатньої кількості тендерних пропозицій, визначеної цим Законом');
                break;

            case 'noCompetition':
                name = gettext('Роботи, товари чи послуги можуть бути виконані, поставлені чи надані виключно певним суб’єктом господарювання');
                break;

            case 'quick':
                name = gettext('У замовника виникла нагальна потреба здійснити закупівлю у разі настання підстав передбачених Законом');
                break;

            case 'additionalPurchase':
                name = gettext('Виникла необхідність у постачанні додаткового обсягу товару у того самого постачальника, що здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість такого постачання не перевищує 50 відсотків ціни договору про закупівлю');
                break;

            case 'additionalConstruction':
                name = gettext('Виникла необхідність у закупівлі додаткових аналогічних робіт чи послуг у того самого учасника, що передбачені в основному договорі про закупівлю, який укладений за результатами проведення тендера та/або здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість таких робіт чи послуг не перевищує 50 відсотків ціни основного договору про закупівлю');
                break;

            case 'resolvingInsolvency':
                name = gettext('Закупівля товарів за процедурою відновлення платоспроможності боржника згідно із законодавством');
                break;

            case 'stateLegalServices':
                name = gettext('Закупівля юридичних послуг, пов’язаних із захистом прав та інтересів України, захисту національної безпеки і оборони, а також закупівлі товарів, робіт і послуг у разі участі замовника на підставі рішення Кабінету Міністрів України в міжнародних виставкових заходах');
                break;
    
            case 'artContestIP':
                name = gettext('Закупівля творів мистецтва або закупівля, пов’язана із захистом прав інтелектуальної власності, або укладення договору про закупівлю з переможцем архітектурного чи мистецького конкурсу');
                break;

            case 'artPurchase':
                name = gettext('Предмет закупівлі полягає у створенні або придбанні витвору мистецтва');
                break;

            case 'contestWinner':
                name = gettext('Укладення договору з переможцем архітектурного або мистецького конкурсу');
                break;

            case 'technicalReasons':
                name = gettext('Відсутність конкуренції з технічних причин');
                break;

            case 'intProperty':
                name = gettext('Необхідність захисту прав інтелектуальної власності');
                break;

            case 'lastHope':
                name = gettext('Укладення договору з постачальником "останньої надії"');
                break;

            case 'emergency':
                name = gettext('Виникнення особливих економічних обставин з негайною ліквідацією наслідків надзвичайних ситуацій');
                break;

            case 'humanitarianAid':
                name = gettext('Надання Україною гуманітарної допомоги іншим державам');
                break;

            case 'contractCancelled':
                name = gettext('Розірвання договору про закупівлю з вини учасника');
                break;

            case 'activeComplaint':
                name = gettext('Оскарження прийнятих рішень, дій чи бездіяльності замовника щодо триваючого тендера');
                break;
        }

        return name;
    }
});