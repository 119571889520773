tenderEdit.controller('attachDocumentsModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    data
) {
    $scope.goToDocs = function() {
        $uibModalInstance.close();
        $state.go('tenderView.documents', {id: data});
    };

    $scope.goHome = function() {
        $uibModalInstance.close();
        $state.go('tenderView.overview', {id: data}, {
            reload: true
        });
    };

    $scope.close = function() {
        $scope.goHome();
    };

});