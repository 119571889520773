tendersList.factory('tenderListService', function (
    http
) {
    var listResource = 'tender/?page=:pageNum';
    var onlyUserTenderResource = 'tender/?page=:pageNum&only_users=1';
    var filteredListResource = 'tender/?page=:pageNum&:filter=1';

    var getList = function (page, url, filter, bookmarkFilter, archiveFilter) {
        if (!page || page < 1) {
            page = 1;
        }
        url = url.replace(/:pageNum/g, page);

        if (filter) {
            url += '&status=' + filter;
        }

        if (bookmarkFilter) {
            url += '&bookmark=true';
        }
        if (archiveFilter) {
            url += '&i=archive';
        }

        url += '&s_not=local_draft';

        return http.get(url).then(function (result) {
            return result.data;
        });
    };

    var detectList = function (page, typeFilter, userFilter) {
        var url = filteredListResource.replace(/:pageNum/g, page).replace(/:filter/g, userFilter);

        if (typeFilter) {
            url += '&status=' + typeFilter;
        }

        if (typeFilter !== 'local_draft') {
            url += '&s_not=local_draft';
        }

        return http.get(url)
            .then(function (result) {
                return result.data;
            });
    };

    return {
        getAll: function (page, filter) {
            return getList(page, listResource, filter);
        },
        getBookmarked: function (page) {
            return getList(page, listResource, false, true, false);
        },
        getArchived: function (page) {
            return getList(page, listResource, false, false, true);
        },
        getForUser: function (page, filter) {
            return getList(page, onlyUserTenderResource, filter);
        },
        getMine: function (page, filter) {
            return getList(page, onlyMineTenderResource, filter);
        },
        get: function (data) {
            return detectList(data.page, data.typeFilter, data.userFilter);
        }
    };
});