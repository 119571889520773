class AgreementChangeList {
    constructor(
        $scope,
        $stateParams,
        $rootScope,
        // ntFlash,
        agreementInstanceService
        // documentsService,
    ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;

        this.agreementId = $stateParams.id;
        this.agreementInstanceService = agreementInstanceService;

        this.changesArray = [];

        this.initAgreement();
    }

    $onInit() {}

    initAgreement() {
        this.agreementInstanceService.getAgreements(this.agreementId)
            .then((data) => {
                this.agreement = data.response;
                this.action = data.action;

                this.agreement.changes.map((change) => {

                    change._documents = (this.agreement.documents || []).filter((doc)=>(doc.documentOf='change' && doc.relatedItem === change.id));

                    if (this.action.contract_owner || change.status === 'active' || ( change.status ==='cancelled' && change._documents.length > 0)) {
                        this.changesArray.push(change);
                    }
                });

            });
    }

    dateFormat(date) {
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`;
    }

}

agreementModule.component('agreementChangeList', {
    templateUrl: '/static/scripts/modules/Agreements/components/AgreeChangeList/AgreeChangeList.html',
    controller: AgreementChangeList,
    controllerAs: 'vm',
    bindings: {
        resolve: '<',
        modalInstance: '<',
    },
});