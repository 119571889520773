class TenderItem {
    constructor($timeout, tenderService) {
        $timeout(() => {
            this.isCompetitiveDialogue = tenderService.isCompetitiveDialogue(this.tender);
            this.isCompetitiveDialogueStage2 = tenderService.isCompetitiveDialogue2Stage(this.tender);
            this.itemFeatures = this.getFeatures(this.item);
            this.isMedication = tenderService.isMedication(this.tender);
        });
    }

    getFeatures() {
        return this.tender.features && this.tender.features.filter((feature) => {
            return feature.featureOf === 'item' && feature.relatedItem === this.item.id;
        });
    }

}

tenderView.component('tenderItem', {
    templateUrl: '/static/scripts/modules/TenderView/views/components/tender-item.html',
    controller: TenderItem,
    controllerAs: 'vm',
    bindings: {
        tender: '=',
        item: '='
    }
});
