planningApp.controller('planningCreateCtrl', function (
    $scope,
    $stateParams,
    $state,
    $rootScope,
    $timeout,
    classifierService,
    planningService,
    tenderService,
    dateListService,
    unitsService,
    ntFlash,
    validateService,
    uibDatepickerConfig,
    currencyListService,
    oraCodesService,
    profileService,
    OPC_CONFIG,
) {
    ntFlash.clean();

    $scope.OPC_CREATE_PLAN_LEGACY = OPC_CONFIG.OPC_CREATE_PLAN_LEGACY; // Remove legacy after 20.12.2018

    $scope.codes = [];
    oraCodesService.getCodesByCategory('UA')
        .then(c => $scope.codes = c);

    uibDatepickerConfig.minDate = null;

    $scope.isCreateMode = true;

    $scope.plan = planningService.newPlan();

    $scope.plan.procuringEntity = angular.copy(OPC_CONFIG.PROCURING_ENTITY);
    delete $scope.plan.procuringEntity.contactPoint;
    $scope.plan.buyers[0] = angular.copy($scope.plan.procuringEntity);

    $scope.isCzo = false;
    $scope.isDream = false;
    $scope.hideAddress = false;
    $scope.czoList = [];
    $scope.czoSelected = null;

    $scope.plan.budget.TaxIncluded = true;

    $scope.plan.additionalClassifications = [];

    $scope.currencies = currencyListService.getCurrenciesList();

    $scope.currentDate = new Date();

    unitsService.get()
        .then(function (data) {
            $scope.units = data;
        });

    $scope.open = function ($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();

        obj[string + 'IsOpen'] = true;
    };


    $scope.UOCheck = function (){
        let procurementMethod = $scope.plan.tender.procurementMethodType
        if (procurementMethod !== 'reporting' && procurementMethod !== 'belowThreshold' ) {
            if(!profileService.getUo())   {
                ntFlash.error(gettext('Для создания данной процедуры Вам необходимы права <br /> Уполномоченного Лица.  Проверьте Ваш статус в Профиле Пользователя'))
                $scope.plan.tender.procurementMethodType = ''
            }
        }
    }

    $scope.selectDropdown = function (item, code) {
        item.unit.name = code.name;
        item.unit.code = code.code;
    };

    $scope.clearClassificator = function (type, index) {
        $scope.setCurrentClassifierIndex(index);
        classifierService.clearItemTypeClassification($scope.currentClassifierIndex, type);
    };

    $scope.clear = function (target) {
        target.field = "";
        target.data = [];
    };

    $scope.setCurrentClassifierIndex = function (index) {
        if (index === null || index === undefined) {
            $scope.currentClassifierIndex = $scope.plan;
        } else {
            $scope.currentClassifierIndex = $scope.plan.items[index];
        }
    };

    $scope.openClassifier = function (type, index) {
        $scope.setCurrentClassifierIndex(index);
        let codes, checked = [];
        let route = `planning.create.${type}`;
        if (type === 'cpv') {
            if ($scope.currentClassifierIndex.classification.id) {
                checked = [$scope.currentClassifierIndex.classification.id];
            }

        } else {
            let dkSet = classifierService.getGroupSchemeByType(type);
            let additionalClassifications = ($scope.currentClassifierIndex.additionalClassifications || []).filter((cl) => {
                return dkSet.has(cl.scheme);
            });
            checked = additionalClassifications.map((cl) => {
                return cl.id;
            });

            if (type === 'inn') {
                codes = classifierService.filterSchemes(
                    $scope.currentClassifierIndex.additionalClassifications, ['ATC']).map(
                    (cl) => {
                        return cl.id;
                    })
            } else if (type === 'atc') {
                codes = classifierService.filterSchemes(
                    $scope.currentClassifierIndex.additionalClassifications, ['INN']).map(
                    (cl) => {
                        return cl.id;
                    })
            }
        }
        $state.go(route, {
            checked: checked,
            codes,
        });
    };

    $scope.isMOZ = function (item) {
        return classifierService.isMOZ(item);
    };

    $scope.isINNRequired = function (item) {
        return classifierService.isINNRequired(item);
    };

    $rootScope.$on('addClasificators', function (event, data, type) {
        if ($scope.currentClassifierIndex) {
            let scheme = classifierService.getSchemeByType(type);
            classifierService.clearItemClassification($scope.currentClassifierIndex, scheme);
            classifierService.updateItemClassification($scope.currentClassifierIndex, data);
            $state.go('planning.create');
        }
    });

    $rootScope.$on('closeClasificators', function () {
        $state.go('planning.create');
    });

    $scope.addField = function () {
        $scope.plan.items.push({
            deliveryDate: {
                endDate: ""
            },
            additionalClassifications: [],
            unit: {
                code: "",
                name: gettext('единицы измерения')
            },
            classification: {},
            quantity: "",
            description: ""
        });
    };

    $scope.removeField = function (index) {
        if ($scope.plan.items.length > 0) {
            $scope.plan.items.splice(index, 1);
        }
    };

    $scope.addBreakDownField = function () {
        $scope.plan.budget.breakdown.push({
            description: "",
            title: "",
            value: {
                amount: null,
                currency: ""
            }
        });
    };

    $scope.removeBreakDownField = function (index) {
        if ($scope.plan.budget.breakdown.length > 0) {
            $scope.plan.budget.breakdown.splice(index, 1);
        }
    };

    $scope.publish = function () {
        $scope.plan.procuringEntity.identifier.legalName = $scope.plan.procuringEntity.name;

        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.createPlanForm.$valid) {
            ntFlash.error(gettext('Заповніть обов\'язкові поля (підкреслені червоним)'));
            $scope.disablePublishBtn = false;
            return false;
        }

        if (['belowThreshold', 'reporting', ''].includes($scope.plan.tender.procurementMethodType) && $scope.plan.budget.breakdown[0].title === '') {
            delete $scope.plan.budget.breakdown;
        } else {
            if ($scope.plan.tender.procurementMethodType === 'esco' && $scope.plan.budget.breakdown.some(el => el.value.currency !== 'UAH')) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Валюта джерел фінансування в процедурі ESCO має бути гривня'));
                return false;
            }

            if ($scope.plan.tender.procurementMethodType !== 'esco' && $scope.plan.budget.breakdown.some(el => el.value.currency !== $scope.plan.budget.currency)) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Валюта усіх джерел фінансування повинна збігатись з валютою плану'));
                return false;
            }

            let sumBreakDownAmount = 0;
            for (const breakdown of $scope.plan.budget.breakdown) {
                sumBreakDownAmount = sumBreakDownAmount + breakdown.value.amount;
            }
            if ($scope.plan.tender.procurementMethodType !== 'esco' && parseFloat(sumBreakDownAmount.toFixed(2)) > $scope.plan.budget.amount) {
                $scope.disablePublishBtn = false;
                ntFlash.error(gettext('Загальна вартість всіх джерел фінансування не може бути більшим за суму плану'));
                return false;
            }
        }
        
        if ($scope.plan.classification.id === "") {
            $scope.disablePublishBtn = false;
            ntFlash.error(gettext('Вкажіть класифікатор'));
            return false;
        }

        // dev hack
        if ($scope.plan.tender.procurementMethodType === 'esco') {
            $scope.plan.budget.amount = 0;
            if ($scope.plan.items && $scope.plan.items.length > 0) {
                $scope.plan.items.forEach((item) => {
                    item.quantity = 0;
                });
            }
        }

        // period validation
        if ($scope.plan.budget.period.startDate &&
            new Date($scope.plan.budget.period.startDate) > new Date($scope.plan.budget.period.endDate)) {
            ntFlash.error(gettext('Період вказано не вірно'));
            $scope.disablePublishBtn = false;
            return false;
        }

        if (!validateService.classifiers($scope.plan.items, $scope.plan.classification, $scope.plan.additionalClassifications)) {
            $scope.disablePublishBtn = false;
            return false;
        }

        if ($scope.createPlanForm.$valid) {
            console.log($scope.plan)
            planningService.save($scope.plan)
                .then(function (result) {
                    // console.log(result);

                    if (result.data.response) {
                        $timeout(function () {
                            $scope.disablePublishBtn = false;

                            $state.go('planning.overview', {
                                id: result.data.response.data.id
                            }, {
                                reload: true
                            });
                        }, 2000);
                        ntFlash.success(gettext('План успешно создан. Не забудьте подписать ключом КЕП и опубликовать'));
                    } else {
                        $timeout(function () {
                            $scope.disablePublishBtn = false;

                            $state.go('planning.list.type', {type: 'all'}, {
                                reload: true
                            });
                        }, 2000);
                        ntFlash.success(gettext('Неизвестный ответ от сервера.'));
                    }

                }, function (err) {
                    console.log(err);
                    $scope.disablePublishBtn = false;
                });
        } else {
            $scope.disablePublishBtn = false;
        }
    };

    $scope.checkDisabled = function (){
        if($scope.plan.procuringEntity.kind == 'other'){
            return true;
        } else {
            return false
        }

    }

    $scope.cancel = function () {
        $state.go('planning.list.type', {type: 'all'}, {
            reload: true
        });
    };

    $scope.isProcuringEntityChanged = false;
    $scope.notificationProcuringEntity = function () {
        if (!$scope.isProcuringEntityChanged) {
            ntFlash.error(gettext('Увага! Створити Закупівлю з цього Плану зможе тільки вказана організація'));
            $scope.isProcuringEntityChanged = true;
        }
    };

    $scope.$watch('isCzo', (isCzo) => {
        if (isCzo) {
            classifierService.loadCzo()
                .then(function(data) {
                    console.log('loadCzo data', data);
                    $scope.czoList = data.filter(item => item.active);
                    $scope.plan.tender.procurementMethod = '';
                    $scope.plan.tender.procurementMethodType = 'centralizedProcurement';
                    $scope.plan.procuringEntity = {
                        identifier: {
                            scheme: "",
                            id: "",
                            legalName: ""
                        },
                        name: "",
                        kind: "",
                        address: ""
                    };
                })
                .catch(function(resError) {
                    console.log('loadCzo error', resError);
                })
        } else {
            $scope.plan.tender.procurementMethod = 'open';
            $scope.plan.tender.procurementMethodType = '';
            $scope.plan.procuringEntity = angular.copy(OPC_CONFIG.PROCURING_ENTITY);
            delete $scope.plan.procuringEntity.contactPoint;
        }
    });

    $scope.$watch('hideAddress', (hideAddress) => {

        if (hideAddress) {
            delete $scope.plan.buyers[0].address.locality
            delete $scope.plan.buyers[0].address.postalCode
            delete $scope.plan.buyers[0].address.region
            delete $scope.plan.buyers[0].address.streetAddress

        } else {
            $scope.plan.buyers[0].address = angular.copy(OPC_CONFIG.PROCURING_ENTITY).address
        }
        // console.log($scope.plan.procuringEntity);
        // console.log($scope.plan.buyers[0]);

    });


    $scope.changedCzoSelected = function(czoSelected) {
        if (czoSelected !== null) {
            for (const czoItem of $scope.czoList) {
                if (czoItem.identifier.id === czoSelected) {
                    $scope.plan.procuringEntity = {
                        identifier: {
                            scheme: czoItem.identifier.scheme,
                            id: czoItem.identifier.id,
                            legalName: czoItem.identifier.legalName_uk
                        },
                        name: czoItem.name_uk,
                        kind: 'central',
                        address: czoItem.address
                    };
                    break;
                }                
            }
        }
    }
});
