profileApp.factory('billingService', function(
    http
    ) {

    var listWalletsResource = '/bill/wallets';
    var listInvoicesResource = '/bill/invoices';
    var listTransactionsResource = '/bill/history';


    let service = {
        wallet: null,
        setCurrentWallet: wallet => service.wallet = wallet,
        loadCurrentWallet: () => {
            service.getWallets()
                .then((result) => {
                    service.setCurrentWallet(result.data.response[0]);
                });
        },
        getWallets: function () {
            return http.get(listWalletsResource, true)
                .then(function (result) {
                    return result;
                });
        },
        getTransactions: function (nextPage) {
            var resource = listTransactionsResource;
            if (nextPage) {
                resource = listTransactionsResource + '?page=' + nextPage;
            }
            return http.get(resource, true)
                .then(function (result) {
                    return result;
                });
        },
        getInvoices: function (nextPage) {
            var resource = listInvoicesResource;
            if (nextPage) {
                resource = listInvoicesResource + '?page=' + nextPage;
            }
            return http.get(resource, true)
                .then(function (result) {
                    return result;
                });
        },
        getInvoice: function (id) {
            var resource = listInvoicesResource + id;
            return http.get(resource, true)
                .then(function (result) {
                    return result;
                });
        },
        saveInvoice: function (data) {
            var resource = listInvoicesResource;
            return http.post(resource, data, true)
                .then(function (result) {
                    return result;
                });
        },

    }

    return service;
});