class newSubscriptionEditCtrl {
    constructor(
        loaderService,
        profileService,
        classifierService,
        ntFlash,
        $state,
        $scope,
        REGIONS_LIST,
        subscriptionData,
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.classifierService = classifierService;
        this.ntFlash = ntFlash;
        this.regions = REGIONS_LIST;
        this.state = $state;
        this.scope = $scope;
        this.subscriptionData = subscriptionData

        this.titleDkppClassifier = ''
        this.titleCpvClassifier = ''
        this.dkpp_codes = []
        this.cpv_codes = []

        this.subsEdrpou = ''
        // this.label = 'label'
        this.new = true
        this.sortType = [
            {type: 'created_date', name: "Created"},
            {type: 'egrpou',  name: "EDRPOU codes"},
            {type: 'region', name: "Регион"},
            {type: 'max_budget', name: "Max Budget"},
            {type: 'min_budget', name: "Min budget"},
            {type: 'cpv_code', name: "CPV Code"},
            {type: 'dkpp_code', name: "Dkpp code"}
        ]

        this.Subs = {
            "name": "",
            "enabled": true,
            "entries_limit": 10,
            "use_or": false,
            "order_by": 'created_date',
            "min_budget": null,
            "max_budget": null,
            "regions": [],
            "clasifier_codes": [],
            // "dkpp_codes": [],
            "egrpou_codes": []
        }

        if(subscriptionData){
            this.profileService.getUserSingleSubscriptionRules(subscriptionData)
                .then((response)=> {

                    this.new = false
                        this.Subs.name = response.data.name;
                        this.Subs.enabled = response.data.enabled;
                        this.Subs.entries_limit = response.data.entries_limit;
                        this.Subs.use_or = response.data.use_or;
                        this.Subs.order_by = response.data.order_by;
                        this.Subs.min_budget = response.data.min_budget;
                        this.Subs.max_budget = response.data.max_budget;
                        this.Subs.regions = response.data.regions || []  ;
                        // this.Subs.cpv_codes = response.data.cpv_codes || [];
                        this.Subs.clasifier_codes = response.data.clasifier_codes || [];
                        this.Subs.egrpou_codes = response.data.egrpou_codes || [];

                    this.loadClassifier();
                })
                .catch((error)=> {
                    console.log(error);
                })
        }
    }

    openClassifier(codeType) {
        let codes = [];
        let checked = [];
        if(codeType === 'cpv'){
            checked = this.cpv_codes.map((el)=> el.code)
        } else if(codeType === 'dkpp' ) {
            checked = this.dkpp_codes.map((el)=> el.code)
        }

        let currentState = this.state.current.name; // After close we must returned to this state

        let addClasificatorsDestoroy = this.scope.$on('addClasificators', (event, data, type) => {

            if(codeType === 'cpv'){
                this.cpv_codes = data
            } else if(codeType === 'dkpp' ) {
                this.dkpp_codes = data
            }


            this.updateTitleClassifier(codeType)
            addClasificatorsDestoroy();
            closeClasificatorsDestroy();
            this.state.go(currentState);

        });

        let closeClasificatorsDestroy = this.scope.$on('closeClasificators', () => {
            addClasificatorsDestoroy();
            closeClasificatorsDestroy();
            this.state.go(currentState);
        });
        this.state.go(`${currentState}.classifier`, {
            type: codeType,
            checked,
            codes,
        });
    };

    loadClassifier(){
        this.Subs.clasifier_codes.forEach((el)=>{
            if(el.scheme === 'ДКПП'){
                this.dkpp_codes.push(el)
                this.titleDkppClassifier +=`${el.code}:${el.name}, `
            } else if(el.scheme === 'ДК021') {
                this.cpv_codes.push(el)
                this.titleCpvClassifier += `${el.code}:${el.name}, `
            }
        })
    }

    updateTitleClassifier(codeType) {
        this.titleDkppClassifier = ''
        this.titleCpvClassifier = ''
        if(codeType === 'cpv'){
            this.cpv_codes.forEach((el)=> {
                this.titleCpvClassifier += `${el.code}:${el.name}, `
            })
        } else if(codeType === 'dkpp' ) {
            this.dkpp_codes.forEach((el)=> {
                this.titleDkppClassifier += `${el.code}:${el.name}, `
            })
        }

    }

    clearClassificator(type){
        if(type === 'cpv'){
            this.cpv_codes = []
        } else {
            this.dkpp_codes = []
        }
        this.updateTitleClassifier()
    }

    edit(){
        this.Subs.clasifier_codes = [...this.cpv_codes, ...this.dkpp_codes]
        this.loader.startLoading()

        this.profileService.editUserSubscriptionRules(this.subscriptionData, this.Subs)
            .then((response)=> {
                this.loader.stopLoading()
                this.ntFlash.success(gettext('Обновлен'));
            })
            .catch((error)=> {
                console.log(error);
            })
    }
    create(form){
        console.log(form.$valid)
        if(form.$valid) {
            this.Subs.clasifier_codes = [...this.cpv_codes, ...this.dkpp_codes]
            this.loader.startLoading()
            this.profileService.newUserSubscriptionRules(this.Subs)
                .then((response) => {
                    this.loader.stopLoading()
                    this.ntFlash.success(gettext('Created'));
                    setTimeout(() => {

                        this.state.go('profile.subscription-edit', {
                            id: response.data.id
                        }, {reload: true})
                    }, 1000)
                })
                .catch((error) => {
                    this.ntFlash.error(gettext('Шось зламалось'));
                    console.log(error);
                })
        } else {
            this.ntFlash.error(gettext('Форма не валідна'));
        }

    }

    delete(){
        this.loader.startLoading()
        this.profileService.deleteUserSubscriptionRules (this.subscriptionData)
            .then((response)=> {
                this.loader.stopLoading()
                this.ntFlash.success(gettext('Видалено'));
                setTimeout(()=>{
                    this.state.go('profile.subscription-new', {}, {reload: true})
                }, 1000)
            })
            .catch((error)=> {
                this.ntFlash.error(gettext('Шось зламалось'));
                console.log(error);
            })

    }
    addEDRPOU(){
        if(this.subsEdrpou != '') {
            this.Subs.egrpou_codes.push(this.subsEdrpou)
            this.subsEdrpou = ''
        }
    }

    removeEDRPOU(egrpou_code){

        this.Subs.egrpou_codes = this.Subs.egrpou_codes.filter((el)=> el !== egrpou_code)
    }

    removeRegion(region){
        this.Subs.regions = this.Subs.regions.filter((el)=> el !== region)

    }

}




profileApp.controller('newSubscriptionEditCtrl', newSubscriptionEditCtrl);
