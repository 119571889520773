tenderView.controller('tenderStartStage2ModalCtrl', function(
    $scope,
    $uibModalInstance,
    tenderService,
    data,
    $window
) {
    $scope.tender = data.tender;

    $scope.start = () => {
        tenderService
            .updateTenderStatus($scope.tender.id, 'active.stage2.waiting')
            .then(res => {
                if(res.data.result) {
                    $window.location.reload();
                }
            })
    };

    $scope.close = () => {
        $uibModalInstance.close();
    };

});