class UserLoginForm {
    constructor(
        profileService,
        loaderService,
        validateService,
        $window,
        $cookies
    ) {
        this.profileService = profileService;
        this.loader = loaderService;
        this.$window = $window;
        this.errorMessages = {};
        this.$cookies = $cookies;
        
        this.user = {
            email: '',
            password: ''
        };
        this.sendingRequest = false;

        this.emailRegEx = validateService.regex.email;
    }

    loginUser() {
        this.errorMessages = {};
        this.loader.startLoading();
        this.sendingRequest = true;
        this.errorPasswordMessage = 'Проверьте корректность Email и Пароль';
        this.returnUrl = this.$cookies.get('returnUrl')
        this.profileService
            .loginUser(this.user)
            .then(() => {
                //this.$window.location.reload()
                this.returnUrl ? this.$window.location='/opc/provider/'+this.returnUrl : this.$window.location.reload();
            })
            .finally(() => this.loader.stopLoading())
            .catch((error) => {
                this.errorMessages = error.data.errors;
                this.user.password = '';
                this.sendingRequest = false;
            });
        }
        // to avoid arrow key interception by md-menu
        stopKeyPropagate(event) {
            event.stopPropagation();
        }
}

profileApp.component('userLoginForm', {
    template: `
        <form novalidate name="userLoginForm" ng-submit="vm.loginUser()" class="top-login-form content-form__form" layout-xs="column">
            <md-input-container md-no-float>
                <input type="email"
                       name="login-email"
                       ng-required="true"
                       placeholder="{{:: 'Email' | translate }}"
                       ng-model="vm.user.email"
                       md-prevent-menu-close="md-prevent-menu-close"
                       ng-keydown="vm.stopKeyPropagate($event)"
                       ng-pattern="emailRegEx">
            </md-input-container>
            <md-input-container md-no-float>
                <input type="password"
                       name="login-password"
                       ng-required="true"
                       placeholder="{{:: 'Пароль' | translate }}"
                       ng-model="vm.user.password"
                       ng-keydown="vm.stopKeyPropagate($event)">
            </md-input-container>
            <md-input-container style="text-align: right">
                    <md-button class="md-raised md-primary md-cornered"
                               md-prevent-menu-close="md-prevent-menu-close"
                               ng-disabled="userLoginForm.$invalid || vm.sendingRequest"
                               ng-click="vm.loginUser()"
                               type="submit">
                        {{:: 'Вход' | translate }}
                    </md-button>
            </md-input-container>
            <div class="dialog-form__link"
                 hide-gt-xs
                 style="padding-top: 10px"
                 flex="grow">
                <a ui-sref="profile.recovery">
                    {{:: 'Восстановить пароль' | translate }}
                </a>
            </div>
            <div ng-if="vm.errorMessages.non_field_errors" class="error-message">
                <!--{{ vm.errorMessages.non_field_errors[0]; }}-->
                {{ vm.errorPasswordMessage; }}
            </div>
        </form>
        <div class="dialog-form__link" hide-xs>
            <a ui-sref="profile.recovery">
                {{:: 'Восстановить пароль' | translate }}
            </a>
        </div>
    `,
    controller: UserLoginForm,
    controllerAs: 'vm'
});
