class CustomerModalCtrl {
    constructor(
        $scope,
        $uibModalInstance,
        data
    ) {
        $scope.modalData = data;
        $scope.close = () => {
            $uibModalInstance.close();
        }
    }
}
commons.controller('customerModalCtrl', CustomerModalCtrl);