profileApp.controller('companiesTendersCtrl', function (
    $scope,
    $state,
    $rootScope,
    $timeout,
    $filter,
    temporaryData,
    tendersSearchService,
    tenderStatesListService,
    ntFlash,
    exchangeService,
    searchBookmarksService,
    $mdDateLocale,
    REGIONS_LIST,
    $element
) {

    $scope.requestInProgress = true;
    $scope.hidePagination = true;
    $scope.itemsPerPage = 10;
    $scope.itemsPerSeacrhPage = 10;
    $scope.isSearchFieldFocused = false;

    $scope.searchTerm;
    $element.find('input.select_input_field').on('keydown', function(ev) {
        ev.stopPropagation();
    });

    var currentPage = $state.params.pageNum,
        cpvFiltersList = $state.params.cpv || '',
        dkppFiltersList = $state.params.dkpp || '',
        innFiltersList = $state.params.inn || '';

    $scope.classifiers = temporaryData.get('searchableClassifiers') || [];

    $scope.searchList = {
        statusesAmount: 0,
        procurementMethodTypesAmount: 0,
        statuses: tenderStatesListService.getStatusesList(),
        procurementMethodTypes: tenderStatesListService.getProcurementMethodTypesList(),
        regions: REGIONS_LIST
    };

    exchangeService.getExchangeRate()
        .then(function (result) {
            $scope.exchangeRates = result.response;
        });

    $scope.dateEdit = function (date) {
        const d = new Date(date);
        const m = ('0' + (d.getMonth() + 1)).slice(-2);
        const day = ('0' + d.getDate()).slice(-2);
        return d.getFullYear() + '-' + m + '-' + day;
    };

    $scope.statuses = $state.params.status ? $state.params.status.split(',') : [];
    $scope.PMTypes = $state.params.procurementMethodType ? $state.params.procurementMethodType.split(',') : [];
    $scope.regions = $state.params.region ? $state.params.region.split(',') : [];

    // Format date for md-datepicker
    $mdDateLocale.formatDate = date => {
        var res = $filter('date')(date, "yyyy/MM/dd");
        return res;
    };
    $scope.create_gte = $state.params.create_gte ? $state.params.create_gte : null;
    $scope.create_lte = $state.params.create_lte ? $state.params.create_lte : null;
    $scope.tp_gte = $state.params.tp_gte ? $state.params.tp_gte : null;
    $scope.tp_lte = $state.params.tp_lte ? $state.params.tp_lte : null;
    $scope.ap_gte = $state.params.ap_gte ? $state.params.ap_gte : null;
    $scope.ap_lte = $state.params.ap_lte ? $state.params.ap_lte : null;

    let complaints_statuses = 'answered,pending,invalid,declined,resolved,cancelled';

    $scope.searchData = {
        type: $state.params.type,
        edrpou: $state.params.edrpou,
        pageNum: $state.params.pageNum,
        query: $state.params.query || '',
        status: $state.params.status || '',
        procurementMethodType: $state.params.procurementMethodType || '',
        region: $state.params.region || '',
        cpv: $state.params.cpv || '',
        dkpp: $state.params.dkpp || '',
        inn: $state.params.inn || '',
        amount_gte: $state.params.amount_gte ? +$state.params.amount_gte : '',
        amount_lte: $state.params.amount_lte ? +$state.params.amount_lte : '',
        funders: $state.params.funders ? !!$state.params.funders : '',
        fund_name: $state.params.fund_name ? $state.params.fund_name : '',
        complaints_checked: $state.params.complaints_checked ? !!$state.params.complaints_checked : '',
        cs: $state.params.complaints_checked ? complaints_statuses : '',
        create_gte: $state.params.create_gte ? $scope.dateEdit($state.params.create_gte) : '',
        create_lte: $state.params.create_lte ? $scope.dateEdit($state.params.create_lte) : '',
        tp_gte: $state.params.tp_gte ? $scope.dateEdit($state.params.tp_gte) : '',
        tp_lte: $state.params.tp_lte ? $scope.dateEdit($state.params.tp_lte) : '',
        ap_gte: $state.params.ap_gte ? $scope.dateEdit($state.params.ap_gte) : '',
        ap_lte: $state.params.ap_lte ? $scope.dateEdit($state.params.ap_lte) : '',
        covid: $state.params.covid ? !!$state.params.covid : '',
    };

    tendersSearchService.get($scope.searchData)
        .then(result => {
            console.log('result', result);
            var itemsPerPage;
            $scope.tenders = result.data;
            $scope.pagerItemsPerPage = $scope.tenders.length;
            $scope.totalPages = result.total_pages;
            $scope.totalItems = result.total_items;
            itemsPerPage = $scope.itemsPerSeacrhPage;
    
            $scope.hidePagination = (function () {
                return Boolean($scope.totalItems <= $scope.itemsPerPage);
            })();

            $scope.paginationLabel = (function () {
                var startAt = (currentPage > 1) ?
                    ((currentPage - 1) * $scope.itemsPerPage) + 1 : 1;
    
                var endAt = ((startAt + $scope.itemsPerPage - 1) <= $scope.totalItems) ?
                    startAt + $scope.itemsPerPage - 1 : startAt + ($scope.totalItems - startAt);
    
                return startAt.toString() + "-" + endAt.toString() + ' ' + gettext('из') + ' ' + $scope.totalItems;
            })();
    
            temporaryData.set('paginationLabel', $scope.paginationLabel);
    
            $scope.requestInProgress = false;
            $scope.currentPage = currentPage;
            $scope.prevPage = Number(currentPage) <= 1 ? '1' : (currentPage - 1).toString();
            $scope.nextPage = $scope.pagerItemsPerPage < itemsPerPage ? $scope.currentPage.toString() : (Number(currentPage) + 1).toString();
        });

    function updateSearchData() {

        $scope.searchData.status = $scope.statuses.join(',');
        $scope.searchData.procurementMethodType = $scope.PMTypes.join(',');
        $scope.searchData.region = $scope.regions.join(',');

        $scope.searchData.create_gte = $scope.create_gte ? $scope.dateEdit($scope.create_gte) : '';
        $scope.searchData.create_lte = $scope.create_lte ? $scope.dateEdit($scope.create_lte) : '';
        $scope.searchData.tp_gte = $scope.tp_gte ? $scope.dateEdit($scope.tp_gte) : '';
        $scope.searchData.tp_lte = $scope.tp_lte ? $scope.dateEdit($scope.tp_lte) : '';
        $scope.searchData.ap_gte = $scope.ap_gte ? $scope.dateEdit($scope.ap_gte) : '';
        $scope.searchData.ap_lte = $scope.ap_lte ? $scope.dateEdit($scope.ap_lte) : '';
        $scope.searchData.pageNum = '1';

        if ($scope.searchData.complaints_checked) {
            $scope.searchData.cs = complaints_statuses;
        } else {
            delete($scope.searchData.complaints_checked);
            $scope.searchData.cs = '';
        }

        if (!$scope.searchData.funders) {
            delete($scope.searchData.funders);
            delete($scope.searchData.fund_name);
        }

        if (!$scope.searchData.covid) delete($scope.searchData.covid)
    }

    $scope.search = function () {
        updateSearchData();

        $state.go('profile.companies.tenders.type', $scope.searchData, {
            reload: true,
            inherit: false
        });
        $scope.searchData.createReport = '';
    };

    $scope.clearSearchData = function () {
        $scope.classifiers = [];
        $scope.PMTypes = [];
        $scope.statuses = [];
        temporaryData.set('searchableClassifiers', $scope.classifiers);
        cpvFiltersList = '';
        dkppFiltersList = '';
        innFiltersList = '';
        $state.go('profile.companies.tenders.type', {
            type: $state.params.type,
            edrpou: $state.params.edrpou,
            pageNum: 1,
            query: '',
            status: '',
            procurementMethodType: '',
            cpv: '',
            dkpp: '',
            inn: '',
            region: '',
            amount_gte: '',
            amount_lte: '',
            funders: '',
            fund_name: '',
            cs: '',
            complaints_checked: '',
            create_gte: '',
            create_lte: '',
            tp_gte: '',
            tp_lte: '',
            ap_gte: '',
            ap_lte: '',
            covid: ''
        }, {
            reload: true
        });
    };

    $scope.createReport = function () {
        updateSearchData();
        $scope.searchData.createReport = '1';
        ntFlash.success(gettext('Отчет будет доступен в профиле в разделе Отчеты'));
        tendersSearchService.get($scope.searchData);
    };

    $scope.canCreateReport = function () {
        return !$rootScope.isAnonymous;
    };

    /**
     * Create filtered request and draw list of classifiers.
     * @param event
     * @param classifier - selected classifiers list
     * @param type - 'cpv' or 'dkpp'
     */

    $scope.openClassifier = function(classifiersName) {
        let currentState = $state.current.name;
        let slassifierScheme;
        switch (classifiersName) {
            case 'cpv': slassifierScheme = 'ДК021'; break;
            case 'dkpp': slassifierScheme = 'ДКПП'; break;
            case 'inn': slassifierScheme = 'INN'; break;
        }
        let addClasificatorsDestroy = $rootScope.$on('addClasificators',  function(event, classifier, type) {
            let preClassifiers = $scope.classifiers.filter(el => el.scheme !== slassifierScheme);
            $scope.classifiers = preClassifiers;
            (classifier || []).forEach(el => {
                $scope.classifiers.push(el)
            });

            temporaryData.set('searchableClassifiers', $scope.classifiers);

            cpvFiltersList = '';
            dkppFiltersList = '';
            innFiltersList = '';

            $scope.classifiers.forEach(function (val) {
                switch (val.scheme) {
                    case 'ДК021': cpvFiltersList += val.code + ',';
                                  break;
                    case 'ДКПП':  dkppFiltersList += val.code + ',';
                                  break;
                    case 'INN':   innFiltersList += val.code + ',';
                                  break;
                }
            });

            cpvFiltersList = cpvFiltersList.substring(0, cpvFiltersList.length - 1);
            dkppFiltersList = dkppFiltersList.substring(0, dkppFiltersList.length - 1);
            innFiltersList = innFiltersList.substring(0, innFiltersList.length - 1);

            $scope.searchData.cpv = cpvFiltersList;
            $scope.searchData.dkpp = dkppFiltersList;
            $scope.searchData.inn = innFiltersList;

            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        let closeClasificatorsDestroy = $rootScope.$on('closeClasificators', function () {
            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        $state.go(`${currentState}.${classifiersName}`);
    }

    $scope.choosePrivacy = function () {
        if ($scope.searchData.bidderOnly) {
            $state.params.bidderOnly = $scope.searchData.bidderOnly.toString();
        } else if ($state.params.userOnly) {
            $state.params.userOnly = $scope.searchData.userOnly.toString();
        }
    };

    // $scope.deleteFilter = function (index) {
    //     $scope.classifiers.splice(index, 1);
    //     addClassifier(null, [], null);
    // };

    $scope.setIsSearchFieldFocused = (val) => {
        $timeout(() => $scope.isSearchFieldFocused = val, 200);
    };

    $scope.showSearchBookmarks = !$rootScope.isAnonymous;

    if ($scope.showSearchBookmarks) {
        getNewBookmarkName = (n) => {
            n = n > 0 ? n : 1;
            return gettext("Мой поиск") + " # " + n;
        };
        $scope.hasSavedBookmarks = false;
        $scope.newBookmarkName = getNewBookmarkName(1);

        searchBookmarksService.getList()
            .then((bookmarksList) => {
                $scope.bookmarksList = bookmarksList;
                $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount + 1);
            });

        $scope.deleteBookmark = (bookmarkId) => {
            if (bookmarkId) {
                searchBookmarksService.delete(bookmarkId)
                    .then(() => {
                        searchBookmarksService.getList()
                            .then((bookmarksList) => {
                                $scope.bookmarksList = bookmarksList;
                                $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount);
                                $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                                $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                            });
                    });
            }
        };

        let getBookmarkData = (id) => {
            return $scope.bookmarksList.find(bookmark => bookmark.id === id);
        };

        $scope.setBookmarkSearchData = (bookmarkId) => {
            $scope.showOptions = true;
            let bookmarkData = getBookmarkData(bookmarkId);
            if (bookmarkData && bookmarkData.flags) {
                let params = bookmarkData.flags;
                $scope.searchData = bookmarkData.flags;
                $scope.statuses = params.status ? params.status.split(',') : [];
                $scope.PMTypes = params.procurementMethodType ? params.procurementMethodType.split(',') : [];
                $scope.classifiers = params.cpv ? params.cpv.split(',').map(code=>{ return {code:code,name:''}; }) : [];
                $scope.regions = params.region ? params.region.split(',') : [];
                $scope.create_gte = params.create_gte ? params.create_gte : null;
                $scope.create_lte = params.create_lte ? params.create_lte : null;
                $scope.tp_gte = params.tp_gte ? params.tp_gte : null;
                $scope.tp_lte = params.tp_lte ? params.tp_lte : null;
                $scope.ap_gte = params.ap_gte ? params.ap_gte : null;
                $scope.ap_lte = params.ap_lte ? params.ap_lte : null;
                temporaryData.set('searchableClassifiers', $scope.classifiers);
            }
        };

        $scope.saveSearchBookmark = () => {
            updateSearchData();
            $scope.bookmarksCount++;
            searchBookmarksService.save($scope.searchData, $scope.newBookmarkName)
                .then(() => {
                    searchBookmarksService.getList()
                        .then((bookmarksList) => {
                            $scope.bookmarksList = bookmarksList;
                            $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount + 1);
                            ntFlash.success(gettext('Поиск сохранен') + ': ' + ($scope.bookmarksCount));
                            $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                            $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                        });
                });
        };
    }
    $scope.closeSelectBox = () => {
        $("md-backdrop").trigger ("click");
    };
});