class NotificationsAllController {
    constructor(
        loaderService,
        http,
        $timeout,
        $mdDateLocale,
        $filter,
        notificationService,
        profileService
    ) {

        this.ps = profileService;

        this.loader = loaderService;
        this.http = http;
        this.$timeout = $timeout;
        this.$filter = $filter;
        this.notificationService = notificationService;
        this.requestUrl = this.notificationService.requestUrl;

        //mainData

        this.notifications = [];
        this.loading = false;

        this.searchData = {};
        this.clearSearchData();

        this.types = [];

        //filter Data
        this.current = {
            id: '',
            name: '',
            email: '',
            company: '',
            role: ''
        }
        this.FilterUser = [];
        this.selectedUser = []
        // next data

        this.notificationService.getNotificationTypes()
            .then(data => {
                this.types = data;
                console.log('notificationService getNotificationTypes data', data);
            })
            .catch(error => {
                console.log('notificationService getNotificationTypes error', error);
            });

        $mdDateLocale.formatDate = date => {
            var res = this.$filter('date')(date, "yyyy/MM/dd");
            return res;
        };
    }

    $onInit = () => {
        this.getUser()
    };

    search() {

        this.notifications = [];
        this.requestUrl = this.notificationService.requestUrl + '?page=1';
        if (this.searchData.startDate)
            this.requestUrl += `&startDate=${this.$filter('date')(this.searchData.startDate, 'yyyy-MM-ddTHH:mm:ss')}`;
        if (this.searchData.endDate)
            this.requestUrl += `&endDate=${this.$filter('date')(this.searchData.endDate.setHours(23, 59, 59), 'yyyy-MM-ddTHH:mm:ss')}`;
        if (this.searchData.type && this.searchData.type.length)
            this.requestUrl += `&kind=${this.searchData.type}`;
        if (this.searchData.tenderID)
            this.requestUrl += `&tender_id=${this.searchData.tenderID}`;
        if (this.selectedUser.length > 0)
            this.requestUrl += `&from_user=${this.selectedUser.join(',')}`;

        console.log('requestUrl', this.requestUrl);
        this.loadItems();
    }

    clearSearchData() {
        this.searchData = {
            tenderID: '',
            type: null,
            startDate: null,
            endDate: null
        };
        this.selectedUser = []
    }

    onReadAllNotifications() {
        this.notificationService
            .setAllRead()
            .then(() => {
                this.notifications.forEach(notification => notification.is_read = true);
                this.notificationService.countNotReadedNotifications();
            })
    }

    loadItems() {
        if(this.loading || !this.requestUrl) {
            return ;
        }

        this.loading = true;
        this.http
            .get(this.requestUrl, true)
            .then((result) => {
                this.notifications.push(...result.data.results);
                this.requestUrl = result.data.next;
                this.$timeout(() => this.loading = false, 2000);
            });

    }

    getUser = () => {
        return this.ps.reloadCurrentUser()
            .then((user) => {
                if(user.companies[0].id) {
                    console.log(user)
                    this.current.company = user.companies[0];
                    this.current.name = user.name
                    this.current.email = user.email
                    this.current.id = user.id
                    this.current.role = user.companies[0].role
                    this.current.allow_admin_notifications = user.allow_admin_notifications
                    if (this.current.name) {
                        this.FilterUser.push(this.current)
                    }
                }
            })
            .then(() => {
                if(this.current.allow_admin_notifications){
                    this.loadUsers()
                }

            });
    }


    loadUsers = () => {
        this.ps
            .getCompanyUsers(this.current.company)
            .then((result) => {
                if(result.data.length > 0){
                    const users = result.data.filter(item => item.user.id !== this.current.id);
                    users.forEach((el)=>{
                        this.FilterUser.push({
                            id: el.user.id,
                            company: '',
                            name: el.user.name,
                            email: el.user.email,
                            role: el.role
                        })
                    })
                }
            });

    }
}
notificationsModule.controller('notificationsAllCtrl', NotificationsAllController);
