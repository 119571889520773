class TenderAuctionAwardModal {
    constructor(
        $window,
        $timeout,
        $mdDialog,
        data,
        awardsService,
        oraCodesService,
        googleMapsPlaces,
        loaderService,
        currencyListService,
        ntFlash
    ) {
        this.$window = $window;
        this.$timeout = $timeout;
        this.$mdDialog = $mdDialog;
        this.awardsService = awardsService;
        this.oraCodesService = oraCodesService;
        this.googleMapsPlaces = googleMapsPlaces;
        this.loader = loaderService;
        this.ntFlash = ntFlash;

        this.tenderId = data.tenderId;
        this.tender = data.tender;
        this.procurementMethod = this.tender.procurementMethod
        this.schemes = null;
        this.value = data.lot && data.lot.value ? data.lot.value : data.value;
        // console.log(this.tender)
        if(this.checkDefenseReporting()){
            let itemQuantity = 0
            data.tender.items.forEach((item) => {
                if(item.unit.value){
                    itemQuantity += Number(item.unit.value.amount * item.quantity)
                }

                })
            let value = {
                currency: 'UAH',
                amount: (itemQuantity > 0)? Number(itemQuantity.toFixed(2)) : data.value.amount,
                valueAddedTaxIncluded: true
            }
            this.value = data.value ? data.value : value
        }
        this.isDefense = false
        this.currencies = currencyListService.getCurrenciesList();
        this.phoneRegExp = '^(\\+)?[0-9]{2,}(,()?(\\+)?[0-9]{2,})*$';
        this.award = {
            status: "pending",
            subcontractingDetails: '',
            suppliers: [
                {
                    contactPoint: {
                        telephone: "",
                        name: "",
                        email: ""
                    },
                    identifier: {
                        scheme: null,
                        legalName: "",
                        id: "",
                        uri: ""
                    },
                    name: "",
                    address: {
                        countryName: "Україна",
                        postalCode: "",
                        region: "",
                        streetAddress: "",
                        locality: ""
                    },
                    scale: ""
                }
            ],
            qualified: false,
            value: {
                currency: this.value.currency,
                amount: this.value.amount,
                valueAddedTaxIncluded: this.value.valueAddedTaxIncluded
            }
        };

        if(this.checkProcurementMethod()){
            this.award.title = ''
            this.award.description = ''
        }

        if(data.lot) {
            this.award.lotID = data.lot.id;
        }

        this.loadCodesByCategory('UA');
        this.minRegistrationNumberLength = 8;
        this.maxRegistrationNumberLength = 10;
        this.countries = [];
        this.countries_datails = null;
        this.regions = [];
    }

    checkProcurementMethod(){
       return ['reporting', 'negotiation', 'negotiation.quick'].includes(this.tender.procurementMethod)
    }

    checkReportingDefense(){
        return this.tender.procuringEntity.kind === 'defense' && this.tender.procurementMethodType === 'reporting'
    }

    defenseAward(){
        if(!this.isDefense){
            this.award = {
                status: "pending",
                subcontractingDetails: '',
                suppliers: [
                    {
                        contactPoint: {
                            telephone: "",
                            name: "",
                            email: ""
                        },
                        identifier: {
                            scheme: null,
                            legalName: "",
                            id: "",
                            uri: ""
                        },
                        name: "",
                        address: {
                            countryName: "Україна",
                            postalCode: "",
                            region: "",
                            streetAddress: "",
                            locality: ""
                        },
                        scale: ""
                    }
                ],
                qualified: false,
                value: {
                    currency: this.value.currency,
                    amount: this.value.amount,
                    valueAddedTaxIncluded: this.value.valueAddedTaxIncluded
                }
            };
        } else {
            this.award = {
                status: "pending",
                subcontractingDetails: '',
                suppliers:  [
                    {
                        name: "Оборонний постачальник",
                        identifier: {
                            scheme: "UA-EDR",
                            id: "88888888",
                            legalName: "Оборонний постачальник"
                        },
                        address: {
                            countryName: "Україна"
                        },
                        scale: "micro"
                    }
                ],
                qualified: false,
                value: {
                    currency: this.value.currency,
                    amount: this.value.amount,
                    valueAddedTaxIncluded: this.value.valueAddedTaxIncluded
                }
            };
        }
    }

    $onInit() {

        this.$timeout(() => {
            let storageData = JSON.parse(this.$window.localStorage.getItem('CountriesRegions'));
            if (storageData) {
                this.countries = storageData.countries;
                this.countries_datails = storageData.countries_datails;
                this.regions = storageData.ua_regions;
            }
        }, 1000);
    }

    addressRequired(){
        return this.award.value.amount < 50000

    }

    setRegistrationNumberLengthLimits(country) {
        this.minRegistrationNumberLength = country==='UA' ? 8 : null;
        this.maxRegistrationNumberLength = country==='UA' ? 10 : null;
    }

    loadCodesByCategory(category) {
        return this.oraCodesService
            .getCodesByCategory(category)
            .then(codes => this.schemes = codes);
    }
    checkDefenseReporting() {
        return this.tender.procuringEntity.kind === 'defense' &&
        this.tender.procurementMethodType === 'reporting' &&
        this.tender.procurementMethodRationale === 'defense'
    }

    countryChangedList(place) {
        this.award.suppliers[0].identifier.scheme = null;
        this.loader.startLoading();
        if (place && this.countries_datails && this.countries_datails.hasOwnProperty(place)) {
            const category = this.countries_datails[place];
            this.setRegistrationNumberLengthLimits(category.alpha2);
            this.loadCodesByCategory(category.alpha2)
                .finally(() => this.loader.stopLoading());
        } else {
            this.ntFlash.error(gettext('Ошибка определения странны'));
            this.loader.stopLoading();
        }
    }

    countryChanged(place) {
        this.award.suppliers[0].identifier.scheme = null;
        this.loader.startLoading();
        const category = this.googleMapsPlaces.parseCountryShortName(place.address_components);
        if(category) {
            this.loadCodesByCategory(category)
                .finally(() => this.loader.stopLoading());
        } else {
            this.ntFlash.error(gettext('Ошибка определения странны'));
            this.loader.stopLoading();
        }
    }

    createAward() {
        if(this.award.suppliers[0].contactPoint){
            if (this.award.suppliers[0].identifier.uri == '') {
                delete this.award.suppliers[0].identifier.uri;
            }
            if (this.award.suppliers[0].contactPoint.email == '') {
                delete this.award.suppliers[0].contactPoint.email;
            }
        }

        this.awardsService
            .create(this.tenderId, this.award)
            .then(() => {
                this.ntFlash.success(gettext('Поставщик успешно добавлен'));
                this.$mdDialog.hide();
            });
    }

    close() {
        this.$mdDialog.hide();
    }
}


tenderView.controller('tenderViewAuctionAwardModalCtrl', TenderAuctionAwardModal);
