tenderView.factory('providersSearchService', function(http) {
    var searchResource = '/search/companies/?q=:query';
    return {
        get: function(data) {
            var searchUrl = searchResource.replace(/:query/, data.query);
            return http.get(searchUrl, true)
                .then(function(data) {
                    return data.data;
                });
        }
    }
});