class DateListService {
    constructor() {
        this.getMonthList = () => {
            return [
                {
                    title: gettext("Январь"),
                    value: "1"
                },
                {
                    title: gettext("Февраль"),
                    value: "2"
                },
                {
                    title: gettext("Март"),
                    value: "3"
                },
                {
                    title: gettext("Апрель"),
                    value: "4"
                },
                {
                    title: gettext("Май"),
                    value: "5"
                },
                {
                    title: gettext("Июнь"),
                    value: "6"
                },
                {
                    title: gettext("Июль"),
                    value: "7"
                },
                {
                    title: gettext("Август"),
                    value: "8"
                },
                {
                    title: gettext("Сентябрь"),
                    value: "9"
                },
                {
                    title: gettext("Октябрь"),
                    value: "10"
                },
                {
                    title: gettext("Ноябрь"),
                    value: "11"
                },
                {
                    title: gettext("Декабрь"),
                    value: "12"
                }
            ];
        };
        this.getYearList = () => {
            let year = (new Date()).getFullYear();
            result = [];
            for(let i=0; i < 5; i++) {
                result.push(year + i);
            }
            return result;
        };
    }
}
commons.service('dateListService', DateListService);