tenderView.controller('tenderViewDocumentsCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    tenderService,
    tenderBidService,
    documentsService,
    agreementInstanceService,
    ntFlash,
    $state,
    $timeout,
    $window
    ) {
    ntFlash.clean();

    $scope.files = [];
    $scope.replaceFiles = [];
    $scope.relatedItemsTitles = {};
    $scope.TendererByAwardId = {};
    $scope.replaceDocumentId = null;
    $scope.requestInProgress = false;
    $scope.canAddBidDocs = true;
    $scope.showMyDocs = true;
    $scope.isMultilotTender = $scope.tender.lots && $scope.tender.lots.length;
    $scope.isVisibleTooltips = false;
    $scope.isEsco = $scope.tender.procurementMethodType === 'esco';
    $scope.noDocuments = true;

    var bidId, refreshList;

    $scope.downloadAllDocumentsLink = $state.current.name === 'tenderView.documents' ? `/opc/tender/${$scope.tender.id}/all-documents/` : '';
    $scope.isBidDocuments = $state.current.name === 'tenderView.ownDocuments';

    $scope.isMilestones24h = false;
    $scope.isMilestonesAlp = false;

    $scope.showDocsStyles = [
        {value: 'asDates', name: gettext('По даті публікації')},
        {value: 'asTypes', name: gettext('За типами документів')},
        {value: 'asLots',  name: gettext('По лотах і позиціям')}
    ];
    $scope.showDocsStylesSelected = 'asTypes';

    if($scope.tender.lots) {
        $scope.tender.lots.forEach(lot => $scope.relatedItemsTitles[lot.id] = lot.title);
    }
    if($scope.tender.procurementMethodType === 'competitiveOrdering') {
        agreementInstanceService.getAgreements($scope.tender.agreements[0].id).then((res) => {
            $scope.agreemnt = res.response
        });

    }


    $scope.hideDocs = () =>{
        if($scope.tender.procurementMethodType === 'competitiveOrdering'){
            if($scope.actions.tender_owner){
                return true
            } else {
                let canBid = false
                if($scope.agreemnt && $window.OPC_CONFIG.PROCURING_ENTITY.identifier && $scope.agreemnt.status === 'active'){
                    const user_edrpou = $window.OPC_CONFIG.PROCURING_ENTITY.identifier.id
                    $scope.agreemnt.contracts.forEach((contract) => {
                        if(contract.suppliers[0].identifier.id === user_edrpou && contract.status == "active"){
                            canBid = true
                        }
                    })
                }
                return canBid
            }
        }
        return true
    }



    if($scope.tender.items) {
        $scope.tender.items.forEach(item => $scope.relatedItemsTitles[item.id] = item.description);
    }
    $scope.documentStatus = (function(tender) {
        if (tender.status === "complete") {
            return gettext("Договор подписан");
        }
        return gettext("Проект договора");
    })($scope.tender);

    $scope.initTooltips = () => {
        $timeout(() => $scope.isVisibleTooltips = true, 300);
    };

    var getRelatedDocuments = function(documents, type) {
        var specialDocuments = type === 'lots'
            ? documents.filter(document => document.documentOf === 'lot')
            : type === 'items'
                ? documents.filter(document => document.documentOf === 'item')
                : documents.filter(document => !['lot', 'item'].includes(document.documentOf));

        if (['lots', 'items'].includes(type) && specialDocuments.length && $scope.tender.hasOwnProperty(type)) {
            var modifiedSpecialDocuments = [];
            $scope.tender[type].forEach(elem => {
                var tempArray = [];
                for (const item of specialDocuments) {
                    if (elem.id === item.relatedItem) tempArray.push(item);
                }
                if (tempArray.length) {
                    tempArray.sort((a, b) => +new Date(a.dateModified) - +new Date(b.dateModified));
                    tempArray[0].firstRelatedItem = true;
                    modifiedSpecialDocuments = [...modifiedSpecialDocuments, ...tempArray];
                }
            });
            return modifiedSpecialDocuments;
        } else {
            return specialDocuments;
        }
    }

    var getAllDocuments = function(documents) {
        var documentsAll = [];
        for (const key in documents) {
            if (documents.hasOwnProperty(key) && Array.isArray(documents[key]))
                documentsAll = [...documentsAll, ...documents[key]];
        }
        if (documentsAll.length > 0) {
            $scope.noDocuments = false;
            documentsAll.sort((a, b) => +new Date(a.dateModified) - +new Date(b.dateModified));
        }
        return documentsAll;
    }

    var refreshProviderList = function() {
        $scope.requestInProgress = true;
        tenderBidService.get($scope.tenderCdbId, false, true)
            .then(function(data) {
                if (data.response.length) {
                    bidId = data.response[data.response.length - 1].id;
                    $scope.downloadAllDocumentsLink  = `/opc/tender/${$scope.tender.id}/bid/${bidId}/all-documents/`;

                    if ($scope.tender.status === 'active.pre-qualification') {
                        ($scope.tender.qualifications || []).forEach(qualification => {
                            if (bidId === qualification.bidID) {
                                (qualification.milestones || []).forEach(milestone => {
                                    if (milestone.code === '24h' && +new Date(milestone.dueDate) > +new Date())
                                        $scope.isMilestones24h = true;
                                });
                            }
                        });
                    } else if ($scope.tender.status === 'active.qualification') {
                        ($scope.tender.awards || []).forEach(award => {
                            if (bidId === award.bid_id) {
                                (award.milestones || []).forEach(milestone => {
                                    if (+new Date(milestone.dueDate) > +new Date()) {
                                        if (milestone.code === '24h') $scope.isMilestones24h = true;
                                        else if (milestone.code === 'alp') $scope.isMilestonesAlp = true;
                                    }
                                });
                            }
                        });
                    }

                    return tenderBidService.get($scope.tenderCdbId, bidId);
                }
            })
            .then(function(data) {
                if (data) {
                    console.log('provider', data);
                    $scope.actions = data.action;
                    $scope.lotValues = data.response.lotValues;
                    $scope.documents = {
                        bids: getRelatedDocuments(data.response.documents),
                        bidsLots: getRelatedDocuments(data.response.documents, 'lots'),
                        bidsItems: getRelatedDocuments(data.response.documents, 'items'),
                        financialDocuments: data.response.financialDocuments,
                        eligibilityDocuments: data.response.eligibilityDocuments,
                        qualificationDocuments: data.response.qualificationDocuments
                    };
                    $scope.documents.all = getAllDocuments($scope.documents);

                    $scope.documents.others = [];
                    ($scope.documents.all || []).forEach(el => {
                        if (el.documentOf === 'lot') {
                            if ($scope.documents.hasOwnProperty(el.relatedItem)) $scope.documents[el.relatedItem].push(el);
                            else $scope.documents[el.relatedItem] = [el];
                        } else {
                            $scope.documents.others.push(el);
                        }
                    });

                    $scope.canAddBidDocs = true;

                    if ($scope.tender.procurementMethodType === 'closeFrameworkAgreementUA' && $scope.tender.status === 'active.awarded') {
                        $scope.canAddBidDocs = false;
                    }

                    $scope.uploadDisabled = function() {
                        return !$scope.actions.can_upload_bid_docs;
                    };
                } else {
                    $scope.canAddBidDocs = false;
                }
                $scope.requestInProgress = false;
            });
    };

    var refreshCustomerList = function() {
        $scope.requestInProgress = true;
        documentsService.getListByTender($scope.tenderCdbId).then(function(data) {
            if (data && data.response) {
                console.log('customer', data);
                $scope.documents = data.response;
                $scope.documents.all = getAllDocuments($scope.documents);
                $scope.documents.others = [];
                ($scope.documents.all || []).forEach(el => {
                    if (el.documentOf === 'lot') {
                        if ($scope.documents.hasOwnProperty(el.relatedItem)) $scope.documents[el.relatedItem].push(el);
                        else $scope.documents[el.relatedItem] = [el];
                    } else {
                        $scope.documents.others.push(el);
                    }
                });
            }

            $scope.actions = data.action;
            $scope.requestInProgress = false;

            $scope.uploadDisabled = function() {
                return !$scope.actions.can_upload_tender_docs;
            };
        });
    };

    refreshList = $state.current.name === 'tenderView.ownDocuments' ? refreshProviderList : refreshCustomerList;

    refreshList();

    $scope.uploadDocument = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.documents.upload.modal.html",
            controller: "tenderViewDocumentsUploadModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        bidId: bidId,
                        lots: $scope.tender.lots,
                        items: $scope.tender.items,
                        procurementMethodType: $scope.tender.procurementMethodType,
                        tenderStatus: $scope.tender.status,
                        actions: $scope.actions,
                        isEsco: $scope.isEsco,
                        lotValues: $scope.lotValues,
                        isMilestones24h: $scope.isMilestones24h,
                        isMilestonesAlp: $scope.isMilestonesAlp
                    };
                }
            },
            backdrop: 'static'
        }).result.then(refreshList);
    };

    $scope.$watch('files', function() {
        if ($scope.files && $scope.files.length) {
            var request = !$scope.actions.tender_owner
                ? documentsService.save($scope.files[0], $scope.tenderCdbId, {bidId: bidId})
                : documentsService.save($scope.files[0], $scope.tenderCdbId);
            request
                .then(function() {
                    ntFlash.success(gettext('Документ успешно загружен.'));
                    refreshList();
                });
        }
    });

    $scope.$watch('replaceFiles', function() {
        if ($scope.replaceFiles && $scope.replaceFiles.length) {
            if ($scope.awardId) {
                documentsService.replace($scope.replaceFiles[0], $scope.tenderCdbId, $scope.replaceDocumentId, {awardId: $scope.awardId})
                    .then(function() {
                        ntFlash.success(gettext('Документ успешно заменен.'));
                        refreshList();
                    });
                $scope.awardId = null;
            } else if ($scope.documentType === "financialDocuments"
                        || $scope.documentType === "eligibilityDocuments"
                        || $scope.documentType === "qualificationDocuments") {

                documentsService.replace($scope.replaceFiles[0], $scope.tenderCdbId, $scope.replaceDocumentId, {bidId: bidId, documentsType: $scope.documentType})
                    .then(function() {
                        ntFlash.success(gettext('Документ успешно заменен.'));
                        refreshList();
                    });
            } else if ($scope.documentOf === "item") {
                documentsService.replace($scope.replaceFiles[0], $scope.tenderCdbId, $scope.replaceDocumentId, {itemId: $scope.relatedItem})
                    .then(function() {
                        ntFlash.success(gettext('Документ успешно заменен.'));
                        refreshList();
                    });
            } else if ($scope.documentOf === "lot") {
                documentsService.replace($scope.replaceFiles[0], $scope.tenderCdbId, $scope.replaceDocumentId, {lotId: $scope.relatedItem})
                    .then(function() {
                        ntFlash.success(gettext('Документ успешно заменен.'));
                        refreshList();
                    });
            } else {
                documentsService.replace($scope.replaceFiles[0], $scope.tenderCdbId, $scope.replaceDocumentId, {bidId: bidId})
                    .then(function() {
                        ntFlash.success(gettext('Документ успешно заменен.'));
                        refreshList();
                    });
            }
        }
    });

    $rootScope.$on('tenderDetermination', function() {
        refreshList();
    });

    $scope.replace = function(documentId, awardId, documentOf, relatedItem, documentType) {
        $scope.replaceDocumentId = documentId;
        $scope.awardId = awardId;
        $scope.documentOf = documentOf;
        $scope.relatedItem = relatedItem;
        $scope.documentType = documentType;
        if (!$scope.actions.tender_owner) {
            $scope.documentOf = 'tender';
            $scope.relatedItem = '';
        }
    };
    
    $scope.getTendererByAwardId = function(awardId) {
        if ($scope.TendererByAwardId[awardId])
            return $scope.TendererByAwardId[awardId];
        else {
            const award = awardId && ($scope.tender.awards || []).find(award => award.id === awardId);
            const bid = award && ($scope.tender.bids || []).find(bid => bid.id === award.bid_id);
            if (bid && bid.tenderers && bid.tenderers.length) {
                $scope.TendererByAwardId[awardId] = `${bid.tenderers[0].name} (${bid.tenderers[0].identifier.id})`;
                return $scope.TendererByAwardId[awardId];
            }
        }
    }
});
