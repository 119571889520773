class AgreementList {
    constructor(
        $scope,
        $state,
        $timeout,
        agreementInstanceService
    ) {
        'ngInject';

        $scope.requestInProgress = true;
        this.agreementInstanceService = agreementInstanceService;
        var currentPage = $state.params.pageNum;
        $scope.searchType = $state.params.type;

        $scope.items = [];
        $scope.itemsPerPage = 10;
        $scope.hidePagination = true;

        this.agreementInstanceService.getAgreementList(currentPage, $scope.searchType).then((res) => {
            resultHandling(res);
        });

        $scope.searchQuery = $state.params.q;
        $scope.isSearchMode = $state.params.list == 'search' ? true : false;
        $scope.searchData = {
            string: $state.params.list == 'search' ? $state.params.q : ''
        };

        $scope.search = function () {
            $scope.searchTimer && $timeout.cancel($scope.searchTimer);
            if ($scope.searchData.string) {
                // delaying the request in case the user is typing too fast,
                // so that we avoid extra requests.
                $scope.searchTimer = $timeout(function () {
                    agreementInstanceService.searchAgreements(currentPage, $scope.searchData.string).then((res) => {
                        resultHandling(res);
                    });
                }, 1600);
            }
        };

        const resultHandling = (result) => {
            if ( result ) {
                var response;
                if (result.response) {
                    response = result.response;
                } else {
                    response = result.data.response;
                }

                $scope.items = response.data;
                $scope.pagerItemsPerPage = $scope.items.length;
                $scope.totalPages = response.total_pages;
                $scope.totalItems = response.total_items;

                $scope.hidePagination = $scope.totalItems <= $scope.itemsPerPage;

                $scope.paginationLabel = (() => {
                    var startAt = (currentPage > 1) ?
                        ((currentPage - 1) * $scope.itemsPerPage) + 1 : 1;

                    var endAt = ((startAt + $scope.itemsPerPage - 1) <= $scope.totalItems) ?
                        startAt + $scope.itemsPerPage - 1 : startAt + ($scope.totalItems - startAt);

                    return startAt.toString() + "-" + endAt.toString() + ' из ' + $scope.totalItems;
                })();

                $scope.currentPage = currentPage;
                $scope.prevPage = Number(currentPage) <= 1 ? '1' : (currentPage - 1).toString();
                $scope.nextPage = $scope.pagerItemsPerPage < $scope.itemsPerPage ? $scope.currentPage.toString() : (Number(currentPage) + 1).toString();
            } else {
                console.warn('No data from searchAgreements');
            }
        };

        $scope.requestInProgress = false;
    }
}

agreementModule.component('agreementList', {
    templateUrl: '/static/scripts/modules/Agreements/components/AgreeList/AgreeList.html',
    controller: AgreementList,
});