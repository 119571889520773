class ProfileUserController {
    constructor (
        $q,
        $scope,
        $state,
        $filter,
        $uibModal,
        $timeout,
        ntFlash,
        profileService,
        userContactsService,
        documentsService,
        CONTACT_TYPES
    ) {
        this.ps = profileService;
        this.documentsService = documentsService;
        this.ucs = userContactsService;
        this.ntFlash = ntFlash;
        this.$state = $state;
        this.$timeout = $timeout;
        this.CT = CONTACT_TYPES;
        this.$q = $q;

        this.user = null;
        this.phones = null;
        this.contacts = null;
        this.disablePublishBtn = false;
        this.new_password_confirm = null;
        this.errorMessages = null;
        this.isUO = this.ps.getUo() ? true : false;
        // console.log(this.isUO)
        this.integrationToken = '';
        this.disableGetIntegrationTokenBtn = false;

        let self = this;
        let getUser = () => {
            return self.ps
                .getUser()
                .then((result) => {
                    self.user = angular.copy(result.data);
                    delete self.user.companies;
                });
        };

        let getContacts = () => {
            return self.ucs
                .getList(self.user.id)
                .then((result) => {
                    self.contacts = angular.copy(result.data);
                    self.phones = self.contacts
                        .filter((c) => c.type === self.CT.PHONE)
                        .map((c) => c.value)
                        .join();
                });
        };

        this.$onInit = () => {
            this.$q.resolve(null)
                .then(getUser)
                .then(getContacts);
        };
    }

    saveUserProfile () {
        this.disablePublishBtn = true;
        this.ps
            .saveUser(this.user)
            .then(() => {
                this.ntFlash.success(gettext('Информация успешно обновлена'));
                this.ps.reloadCurrentUser();
                this.$timeout(() => this.$state.reload(), 1000);
            })
            .catch(error => this.errorMessages = error.data.errors)
            .finally(() => this.disablePublishBtn = false);
    }

    checkUO(){
        const cont = {
            checkPerson: this.checkUOCallback.bind(this)
        }
        this.documentsService.sign( null, cont)
    }

    dropUO(){

            this.ps.dropUo()
            this.isUO = false

    }

    checkUOCallback(data){
        // this.ps.setUo(data)
        this.isUO = this.ps.getUo() ? true : false
    }

    getUserIntegrationToken() {
        this.disableGetIntegrationTokenBtn = true;
        this.ps.getUserIntegrationToken()
            .then(data => {
                this.integrationToken = data.data.token;
                console.log('getUserIntegrationToken data', data);
                this.ntFlash.success(gettext('Токен інтеграції отримано'));
            })
            .catch(error => {
                console.log('getUserIntegrationToken error', error);
                this.ntFlash.error(gettext('Сталася помилка при отриманні токену інтеграції'));
            })
            .finally(() => {
                this.disableGetIntegrationTokenBtn = false;
            });
    }
}

profileApp.component('profileUser', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-user.html',
    controller: ProfileUserController,
    controllerAs: 'vm'
})
