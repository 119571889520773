agreementModule.controller('agreementPriceModalCtrl', function(
    $scope,
    $state,
    $timeout,
    $uibModal,
    $filter,
    $stateParams,
    $rootScope,
    $uibModalInstance,
    // ntFlash,
    // tenderService,
    agreementInstanceService,
    // documentsService,
    data
) {
    $scope.$uibModal = $uibModal;

    $scope.agreementInstanceService = agreementInstanceService;

    $scope.rationaleTypes = '';

    $scope.itemPriceVariationMode = '';
    $scope.thirdPartyMode = '';
    $scope.taxRateMode = '';
    $scope.unitMoneyMode = '';

    $scope.rationale_ru = '';
    $scope.rationale_en = '';

    $scope.newPriceArray = [];

    $scope.agreementId = data.agreementId;

    $scope.priceAmount = [];
    $scope.priceAmountUah = [];

    $scope.priceAmountUahConverted = [];
    $scope.priceAmountConverted = [];

    $scope.operationTypeSign = [];

    $scope.changeId = data.changeId;
    $scope.changeAgreementStatus = data.statusOfChange;

    $scope.disableInput = true;

    $scope.items = data.items;
    $scope.changes = data.changes;

    $scope.save = (res) => {
        $uibModalInstance.close(res);
    };

    $scope.cancel = (res) => {
        $uibModalInstance.close();
    };

    if ($scope.changeAgreementStatus === 'pending') {
        const lastChange = $scope.changes[$scope.changes.length - 1];

        $scope.rationale_ru = lastChange.rationale;
        $scope.rationale_en = lastChange.rationale_en;
        $scope.rationaleTypes = lastChange.rationaleType;
    }

    function initItems(items) {
        items.map((el) => {
            el._toggle = false;

            // todo kludge will fix letter, maybe (its made for make radiobutton active on init)
            $scope.operationTypeSign.push({
                sign: 'plus'
            });
        });
    }

    initItems($scope.items);


    $scope.toogleItemInfo = (item, itemIndex) => {
        if ($scope.changeAgreementStatus === 'pending') {
            const lastChange = $scope.changes[$scope.changes.length - 1];
            console.log('lastChange: ',lastChange);
            const pendingFactor = lastChange.modifications.find(el => el.itemId === item.id);
            if (pendingFactor) {
                if (pendingFactor.factor) {
                    $scope.priceAmount[itemIndex] = Math.abs(parseFloat(((1 - pendingFactor.factor) * 100.0).toFixed(4)));
                    pendingFactor.factor < 1 ? $scope.operationTypeSign[itemIndex].sign = 'minus' : $scope.operationTypeSign[itemIndex].sign = 'plus';
                }
                if (pendingFactor.addend) {
                    $scope.priceAmountUah[itemIndex] = pendingFactor.addend;
                    pendingFactor.addend < 1 ? $scope.operationTypeSign[itemIndex].sign = 'minus' : $scope.operationTypeSign[itemIndex].sign = 'plus';
                }
            }
        }
        item._toggle = !item._toggle;
    };

    $scope.changeOperationType = () => {
        $scope.itemPriceVariationMode = $scope.rationaleTypes === 'itemPriceVariation';
        $scope.thirdPartyMode = $scope.rationaleTypes === 'thirdParty';
        $scope.taxRateMode = $scope.rationaleTypes === 'taxRate';
    };


    $scope.countPrice = (index, unitMoneyMode, sign, id) => {
        $scope.unitMoneyMode = unitMoneyMode;
        if (unitMoneyMode === 'uah') {
            if (sign === 'minus') {
                if ($scope.priceAmountUah[index]) {
                    $scope.priceAmountUahConverted[index] = {
                        itemId: id,
                        addend: -Math.abs(parseFloat($scope.priceAmountUah[index].toFixed(2)))
                    };
                    $scope.priceAmountUah[index] = -Math.abs(parseFloat($scope.priceAmountUah[index].toFixed(2)));
                }
            } else {
                if ($scope.priceAmountUah[index]) {
                    $scope.priceAmountUahConverted[index] = {
                        itemId: id,
                        addend: +Math.abs(parseFloat($scope.priceAmountUah[index].toFixed(2)))
                    };
                    $scope.priceAmountUah[index] = +Math.abs(parseFloat($scope.priceAmountUah[index].toFixed(2)));
                }
            }
        } else {
            if (sign === 'minus') {
                if ($scope.priceAmount[index]) {
                    $scope.priceAmountConverted[index] = {
                        itemId: id,
                        factor: parseFloat(((100.0 - $scope.priceAmount[index]) / 100.0).toFixed(4))
                    };
                }
            } else {
                if ($scope.priceAmount[index]) {
                    $scope.priceAmountConverted[index] = {
                        itemId: id,
                        factor: parseFloat(((100.0 + $scope.priceAmount[index]) / 100.0).toFixed(4))
                    };
                }
            }
        }
    };

    $scope.inputValidate = (e, index, sign) => {
        const taregtEl = e.target.value;
        const isLengthValid = taregtEl.length >= 5;
        const isValueValid = Number(taregtEl) >= 99.99;
        const isMinus = sign === 'minus';

        if ($scope.unitMoneyMode === 'uah') {
            return false;
        } else {
            if ($scope.itemPriceVariationMode) {
                const isLengthValidItemPriceVariation = Number(taregtEl.length) >= 2;
                const isValueValidItemPriceVariation = Number(taregtEl) >= 10;

                if (isValueValidItemPriceVariation || isLengthValidItemPriceVariation) {
                    e.preventDefault();
                }
            } else if (isLengthValid && isMinus || isMinus && isValueValid) {
                e.preventDefault();
            }
        }
    };

    $scope.chooseOperationTypeSign = (index, sign, itemId) => {
        $scope.countPrice(index, $scope.unitMoneyMode, sign, itemId);
    };

    $scope.setChanges = () => {
        let body = {
            "modifications": $scope.priceAmountConverted
        };

        let bodyUah = {
            "modifications": $scope.priceAmountUahConverted
        };

        const changesBody = {
            rationaleType: $scope.rationaleTypes,
            rationale: $scope.rationale_ru,
            rationale_en: $scope.rationale_en
        };

        if ($scope.changeAgreementStatus === 'pending') {
            let changeId = $scope.changeId;
            const bodyType = bodyUah.modifications.length > 0 ? bodyUah : body;
            const requestBody = Object.assign({}, changesBody, bodyType);

            $scope.agreementInstanceService.updateChangeAgreements($scope.agreementId, changeId, requestBody).then((result) => {
                if (result && result.status === 200) {
                    $scope.save(result.data.data);
                } else {
                    console.log(result);
                }
            });
        } else {
            $scope.agreementInstanceService.setChangeAgreements($scope.agreementId, changesBody).then((result) => {
                if (result.status === 200) {
                    result.data.data.changeType = "price";
                    const requestBody = bodyUah.modifications.length > 0 ? bodyUah : body;
                    $scope.agreementInstanceService.updateChangeAgreements($scope.agreementId, result.data.data.id, requestBody).then((res) => {
                        $scope.save(res.data.data);
                    });
                } else {
                    console.log(result);
                }
            });
        }
    };

    $scope.disableField = () => {
        $scope.disableInput = !$scope.disableInput;
    };

});