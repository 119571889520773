tenderView.controller('tenderViewPrequalificationCtrl', function(
    $scope,
    $uibModal,
    $state,
    $filter,
    $timeout,
    $rootScope,
    ntFlash,
    tenderData,
    tenderService,
    contractService,
    tenderBidService,
    documentsService,
    qualificationService
    ) {

    var awardId = false,
        contractId = false,
        qualificationId = false,
        tenderId = $scope.tenderCdbId;

    $scope.showContract = false;
    $scope.itemsRelations = {};

    $scope.upload = {
        qualificationFiles: [],
        setQualificationId: function(id) {
            qualificationId = id;
        }
    };

    $scope.visibleLots = {
        limit: 1,
        begin: '0'
    };

    if ($rootScope.prequalificationSelectedLot && $rootScope.prequalificationSelectedLot[tenderId]) {
        $scope.visibleLots.begin = $rootScope.prequalificationSelectedLot[tenderId];
    }

    $scope.init = function() {
        tenderService.get(tenderId)
            .then(function(response) {
                $scope.tender = response.data;
                $scope.actions = response.action;

                $scope.lots = $scope.tender.lots && $scope.tender.lots.length ? $scope.tender.lots : [];
                $scope.items = $scope.tender.items && $scope.tender.items.length ? $scope.tender.items : [];

                let qualifications = $scope.tender.qualifications && $scope.tender.qualifications.length ? $scope.tender.qualifications : [];
                //$scope.qualifications = qualifications.filter(qualification => qualification.status !== 'cancelled');

                qualifications = qualifications.sort((a, b) => {
                    let a_date = new Date(a.date);
                    let b_date = new Date(b.date);
                    return a_date < b_date ? -1 : a_date > b_date ? 1 : 0;
                });
                $scope.qualifications = qualifications.filter(q => q.status !== 'cancelled');

                $scope.items.forEach(function(item) {
                    $scope.itemsRelations[item.id] = item.relatedLot;
                });

                $scope.qualifications.forEach(function(qualification) {
                    qualification.documents = $filter('versionFilter')(qualification.documents);
                });

                if ($scope.lots.length) {
                    $scope.lots.forEach(function(lot) {
                        lot.actions = $scope.actions.lots ? $scope.actions.lots[lot.id] : $scope.actions;
                    });
                } else {
                    $scope.lots.push({
                        id: null,
                        title: $scope.tender.title,
                        status: $scope.tender.status
                    });
                    $scope.lots[0].actions = $scope.actions;
                }
            })
            .then(function() {
                tenderBidService.get(tenderId)
                    .then(function(data) {
                        $scope.tenderBids = data ? data : [];

                        $scope.tenderBids.forEach(function(bid) {
                            bid.documents = $filter('versionFilter')(bid.documents);
                            bid.documents.forEach(function(document) {
                                if (document.documentOf === 'item') {
                                    document.relatedItem = $scope.itemsRelations[document.relatedItem];
                                }
                            });
                            bid.eligibilityDocuments = $filter('versionFilter')(bid.eligibilityDocuments);
                            bid.eligibilityDocuments.forEach(function(document) {
                                if (document.documentOf === 'item') {
                                    document.relatedItem = $scope.itemsRelations[document.relatedItem];
                                }
                            });
                        });
                    });
            });
    };

    $scope.init();


    $scope.makeComplaint = function(qualificationId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        qualificationId: qualificationId,
                        isQuestion: true,
                        isMultilotTender: false,
                        isBelowThreshold: $scope.tender.procurementMethodType === 'belowThreshold'
                    }
                }
            },
            backdrop: 'static'
        })
    };

    let uploadFiles = (qualificationId, files) => {
        return Promise.all(
            files.map(
                f => documentsService.save(f, $scope.tender.id, {qualificationId})
            )
        );
    };

    let updateData = (qualificationId, data) => {
        return qualificationService.edit($scope.tender.id, qualificationId, data)
    };

    $scope.requirement = function(qualificationId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.pre-qualification.requirement.modal.html",
            controller: "tenderViewPreQualificationRequirementModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tender.cdb_id,
                        qualificationId: qualificationId
                    }
                }
            },
            backdrop: 'static'
        });
    }

    $scope.decide = function(qualificationId, isApproval) {
        const qualification = $scope.qualifications.filter(q => q.id === qualificationId)[0];

        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.pre-qualification.modal.html",
            controller: "tenderViewPreQualificationModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tender.cdb_id,
                        procurementMethodType: $scope.tender.procurementMethodType,
                        procurementMethodRationale: $scope.tender.procurementMethodRationale,
                        qualificationId: qualificationId,
                        documents: qualification.documents || [],
                        criterias: $scope.tender.criteria && $scope.tender.criteria.filter(criteria => criteria.source === 'procuringEntity'),
                        isApproval: isApproval,
                    }
                }
            },
            backdrop: 'static'

        }).result.then(function (result) {
            if (!result) return;

            const files = result.files;
            delete result.files;
            const status = result.status;
            delete result.status;

            let tasks = [updateData(qualificationId, result)];

            if (files && files.length > 0) {
                tasks.push(uploadFiles(qualificationId, files))
            }

            Promise.all(tasks)
                // .then(() => {
                //     // if ($scope.tender.procurementMethodType !== 'closeFrameworkAgreementUA') return;
                //
                //     if ($rootScope.oldEds) {
                //         return $scope.sign(qualificationId, qualification.documents).result
                //             .then((result, error) => {
                //                 if (!result) {
                //                     if (error) {
                //                         throw Error();
                //                     } else {
                //                         throw Error(gettext('Прервано подписания квалификации'));
                //                     }
                //                 }
                //             });
                //     } else {
                //         return $scope.sign(qualificationId, qualification.documents)
                //             .then(data => console.log('documentsService.sign data', data))
                //             .catch(error => {
                //                 console.log('documentsService.sign error', error);
                //                 throw Error(gettext('Прервано подписания квалификации'))
                //             });
                //     }
                // })
                .then(() => {
                    return updateData(qualificationId, {status})
                        .then(() => {
                            $timeout(function () {
                                $scope.init();
                                ntFlash.success(gettext('Решение принято'));
                            }, 2000);
                        });
                })
                .catch((e) => {
                    console.error(e);
                    $scope.init();
                    ntFlash.error(e || gettext('Виникла помилка'));
                });

        });
    };

    $scope.cancelDecision = function (qualificationId) {
        qualificationService.edit($scope.tender.cdb_id, qualificationId, {status: 'cancelled'})
            .then(function() {
                $timeout(function () {
                    $scope.init();
                }, 2000);
                ntFlash.success(gettext('Решение отменено'));
            });
    };

    $scope.approveQualifications = function(flag) {
        let msgSuccess = flag ? gettext('Протокол розгляду сформовано') : gettext('Квалификация успешно завершена');

                return $scope.sign()
                    .then(data => {
                        return tenderService.edit($scope.tender.cdb_id, {status: "active.pre-qualification.stand-still"})
                            .then(function() {
                                ntFlash.success(msgSuccess);
                                $timeout(function() {
                                    $scope.init();
                                }, 2000);
                            });
                    })
                    .catch(error => {
                        console.log('documentsService.sign error', error);
                        throw Error(gettext('Прервано подписания квалификации'))
                    });


    };

    $scope.showCustomer = function(organization) {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function() {
                    return organization;
                }
            },
            windowClass: 'center-modal'
        });
    };

    $scope.$watch('upload.qualificationFiles', function(val) {
        if ($scope.upload.qualificationFiles && $scope.upload.qualificationFiles.length) {
            documentsService.save($scope.upload.qualificationFiles[0], $scope.tender.cdb_id, {qualificationId: qualificationId})
                .success(function() {
                    $scope.init();
                });
        }
    });

    $scope.$watch('visibleLots.begin', function(lot) {
        if ($rootScope.prequalificationSelectedLot) {
            $rootScope.prequalificationSelectedLot[tenderId] = lot;
        } else {
            $rootScope.prequalificationSelectedLot = {};
            $rootScope.prequalificationSelectedLot[tenderId] = lot;
        }
    });

    $scope.sign = function(qualification_id = null , documents) {
        return documentsService.sign({
                tenderId: $scope.tender.id,

            },
            {
                documentType: 'evaluationReports'
            });
    };
});
