class TenderMilestoneInput {
    constructor(
        MULTILINGUAL_PROCEDURES,
        $state,
        $scope,
        unitsService,
        tenderService,
        classifierService,
    ) {
        this.MULTILINGUAL_PROCEDURES = MULTILINGUAL_PROCEDURES;
        this.state = $state;
        this.scope = $scope;
        this.units = [];

        unitsService.get()
            .then((data) => {
                this.units = data;
            });

        $scope.isPMT = (name) => tenderService.isProcurementMethodType({
            procurementMethodType: this.procurementMethodType}, name);
    }

    $onInit() {
        this.multilingual = this.MULTILINGUAL_PROCEDURES.has(this.procurementMethodType);
    }
}

tenderEdit.component('tenderMilestoneInput', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/tender-milestone-inputs.html',
    controller: TenderMilestoneInput,
    controllerAs: 'vm',
    bindings: {
        milestone: '=',
        procurementMethodType: '<',
        ngDisabled: '<',
        onDelete: '&',
    }
});