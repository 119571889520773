tenderView.factory('lowConsultationService', function (http, ntFlash, $filter) {
    var saveResource = '/api/v1/law-consultation/';
    var getResource = '/api/v1/law-consultation/:tenderId:lotId/';

    var formatData = function (data) {
        data.date_from = $filter('date')(data.date_from, 'yyyy-MM-dd');
        data.date_to = $filter('date')(data.date_to, 'yyyy-MM-dd');
        data.date_got = $filter('date')(data.date_got, 'yyyy-MM-dd');
        data.customer = data.customer ? 1 : 0;
        return data;
    };
    return {
        get: function (tenderId, lotId) {
            getUrl = getResource.replace(/:tenderId/g, tenderId).replace(/:lotId/g, lotId ? ":"+lotId : "");
            return http.get(getUrl, true)
                .then(function (data) {
                    return data;
                });
        },
        request: function (data) {
            return http.post(saveResource, formatData(data), true)
                .then(function (data) {
                    return data.data.response;
                });
        }
    };
});