agreementModule.controller('agreementExcludeUserModalCtrl', function(
    $scope,
    $state,
    // $timeout,
    $uibModal,
    // $filter,
    // $stateParams,
    $rootScope,
    $uibModalInstance,
    // ntFlash,
    // tenderService,
    agreementInstanceService,
    // documentsService,
    data
) {
    $scope.$uibModal = $uibModal;

    $scope.agreementInstanceService = agreementInstanceService;
    $scope.users = data.users;
    $scope.changes = data.changes;
    $scope.agreementId = data.agreementId;
    $scope.rationaleType = data.rationaleType;
    $scope.rationale_ru = '';
    $scope.rationale_en = '';

    // $scope.usersDeactivate = [];
    $scope.usersDeactivate = [];

    $scope.changeAgreementStatus = data.statusOfChange;

    $scope.changeId = data.changeId;


    $scope.save = () => {
        $uibModalInstance.close();
    };

    $scope.save = (res) => {
        $uibModalInstance.close(res);
    };

    if ($scope.changeAgreementStatus === 'pending') {
        const lastChange = $scope.changes[$scope.changes.length - 1];

        $scope.rationale_ru = lastChange.rationale;
        $scope.rationale_en = lastChange.rationale_en;
        $scope.rationaleTypes = lastChange.rationaleType;

        lastChange.modifications.map((el) => {
            $scope.users.map((user, index) => {
                if (user.id === el.contractId) {
                    $scope.users.splice(index, 1);
                }
            });
        });
    }

    $scope.setChanges = () => {

        let body = {
            "modifications": $scope.usersDeactivate
        };

        const changesBody = {
            rationaleType: $scope.rationaleType,
            rationale: $scope.rationale_ru,
            rationale_en: $scope.rationale_en
        };

        if ($scope.changeAgreementStatus === 'pending') {
            let changeId = $scope.changeId;

            const requestBody = Object.assign({}, changesBody, body);
            $scope.agreementInstanceService.updateChangeAgreements($scope.agreementId, changeId, requestBody).then((result) => {
                if (result && result.status === 200 && result.data && result.data.data && result.data.data.id) {
                    // $scope.$rootScope.$emit('updateChangeFlags');
                    $scope.save(result.data.data);
                } else {
                    console.error(result);
                }
            });
        } else {
            $scope.agreementInstanceService.setChangeAgreements($scope.agreementId, changesBody).then((result) => {
                if (result && result.status === 200) {
                    $scope.agreementInstanceService.updateChangeAgreements($scope.agreementId, result.data.data.id, body).then((result) => {
                        if (result && result.status === 200) {
                            $scope.save(result.data.data);
                        }
                    });
                } else {
                    console.log(result);
                }
            });
        }
    };

    $scope.changeUserStatus = (userId) => {
        if ($scope.usersDeactivate.length === 0) {
            $scope.usersDeactivate.push({
                contractId: userId
            });
        } else {
            const isNotUnique = $scope.usersDeactivate.some(el => el.contractsId === userId);
            if (isNotUnique) {
                $scope.usersDeactivate = $scope.usersDeactivate.filter(el => el.contractsId !== userId);
            } else {
                $scope.usersDeactivate.push({
                    contractId: userId
                });
            }
        }
    };

});