class FundersService {
    constructor(
        http
    ) {
        const fundersResource = '/api/v1/funders/';
        this.getFunders = (currentLang) => {
            let _langs = ['ru','en','uk'];
            return http.get(fundersResource, true)
                .then((result) => {
                    let funders = result.data.data.map((funder)=>{
                        // Parse prozorro funders new format
                        funder.name = funder['name_'+currentLang];
                        funder.contactPoint.name = funder.contactPoint['name_'+currentLang];
                        funder.identifier.legalName = funder.identifier['legalName_'+currentLang];
                        _langs.forEach(lang => {
                            delete funder['name_'+lang];
                            delete funder.contactPoint['name_'+lang];
                            delete funder.identifier['legalName_'+lang];
                        });

                        return funder;
                    });
                    return funders;
                });
        };
    }
}
commons.service('fundersService', FundersService);