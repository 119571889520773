contractApp.filter('changeStatusFilter', function() {
    return function(input) {
        var name = "";

        switch (input) {
            case 'active':
                name = gettext('Применено');
                break;

            case 'pending':
                name = gettext('Действует');
                break;
        }

        return name;
    }
});