contractApp.filter('contractStatusFilter', function() {
    return function(input) {
        var name = "";

        switch (input) {
            case 'pending':
                name = gettext('Ожидает рассмотрения');
                break;
            case 'active':
                name = gettext('Действующий');
                break;

            case 'terminated':
                name = gettext('Завершен');
                break;

            case 'cancelled':
                name = gettext('Отозвано');
                break;
        }

        return name;
    }
});
