monitoringApp.controller('monitoringViolationEliminationModalCtrl', function(
    $scope,
    $rootScope,
    data,
    monitoringService,
    documentsService,
    $uibModalInstance
) {

    $scope.data = { ...data };
    $scope.readOnly = $scope.data.description.length > 0;
    $scope.uploadedFiles = data.uploadedFiles || [];
    $scope.showProgress = null;

    $scope.files = [];

    $scope.submit = function() {
        $scope.disablePublishBtn = true;

        function uploadToDocumentService(filesList) {
            return new Promise((resolve) => {
                if (filesList.length) {
                    $scope.showProgress = gettext('Завантажуються файли');
                    return documentsService.uploadToDocumentService(filesList)
                        .then((documents) => {
                            resolve(documents);
                        });
                }
                return resolve()
            })
        }

        function addDocumentsToViolationElimination(tenderId, monitoring_id, documents) {
            if (!documents) {
                return null;
            }
            let promises = [];
            for (const doc of documents) {
                promises.push(monitoringService.addViolationEliminationDocument(tenderId, monitoring_id, doc))
            }
            return Promise.all(promises);
        }

        uploadToDocumentService($scope.files)
            .then((documents) => {
                let requestData = {
                    description: $scope.data.description
                };
                if (!data.description) {
                    if (documents) {
                        requestData.documents = documents;
                    }
                    $scope.showProgress = gettext('Створюється запис');
                    return monitoringService.violationEliminationReport(data.tenderId, data.monitoring_id, requestData);

                } else {
                    $scope.showProgress = gettext('Оновлюється запис');
                    if (documents) {
                        return addDocumentsToViolationElimination(data.tenderId, data.monitoring_id, documents)
                            .then((documents) => {
                                return [ ...(data.documents || []),...documents]
                            });
                    }
                    return [];

                }

            })
            .then((result) => {
                $scope.showProgress = null;
                $uibModalInstance.close({
                    documents: result ? result.documents : [],
                });
            })
            .catch((error) => {
                // console.error('Error: ', error);
                $uibModalInstance.close();
            });

    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.isDisabled = function() {
        return !$scope.data.description.length || $scope.disablePublishBtn;
    };

    $scope.deleteDoc = function(index) {
        $scope.files.splice(index, 1);
    };

    $scope.$watch('file', function(val) {
        if ($scope.file && $scope.file.length) {
            $scope.files = $scope.files.concat(val);
        }
    });

});