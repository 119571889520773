commons.filter('complaintStatusCaption', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'draft':
                name = gettext('Черновик');
                break;

            case 'claim':
                name = gettext('Опубликовано');
                break;

            case 'answered':
                name = gettext('Отвечено');
                break;

            case 'pending':
                name = gettext('Ожидает рассмотрения');
                break;

            case 'invalid':
                name = gettext('Залишено без розгляду');
                break;

            case 'declined':
                name = gettext('Відмовлено в задоволені');
                break;

            case 'resolved':
                name = gettext('Порушення усунуте');
                break;

            case 'cancelled':
                name = gettext('Отозвано');
                break;

            case 'accepted':
                name = gettext('Принята к рассмотрению');
                break;

            case 'satisfied':
                name = gettext('Удовлетворено');
                break;

            case 'stopping':
                name = gettext('Приостановлено');
                break;

            case 'stopped':
                name = gettext('Розгляд припинено');
                break;

            case 'mistaken':
                name = gettext('Отменен');
                break;
        }

        return name;
    }
});