commons.filter('currencyFilter', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'UAH':
                name = gettext('грн');
                break;

            case 'USD':
                name = gettext('дол');
                break;

            case 'GBP':
                name = gettext('фнт');
                break;

            case 'EUR':
                name = gettext('евро');
                break;

            case 'RUB':
                name = gettext('руб');
                break;
        }

        return name;
    }
});