class ClassifiersListForm {
    constructor(
        loaderService, 
        API_BASE_URL, 
        http, 
        $timeout, 
        classifierService
    ) {
        this.loader = loaderService;
        this.http = http;
        this.$timeout = $timeout;
        this.classifierService = classifierService;

        this.classifiers = [];
        this.loading = false;
        this.requestUrl = `${API_BASE_URL}/catalogs/${this.kind}/`;

        this.searchCodeText = '';
    }

    selectedCode(classifier) {
        this.searchCodeText = '';

        if(!classifier || this.checkedClassifiers.includes(classifier.code)) {
            return;
        }

        this.checkedClassifier(classifier);
        if(this.checkedClassifiers) {
            this.checkedClassifiers.push(classifier.code);
        }
    }

    searchCodes(text) {
        return this.classifierService
            .search(this.kind, text)
    }

    checkedClassifier(classifier) {
        if(this.onCheckClassifier) {
            this.onCheckClassifier({classifier});
        }
    }

    uncheckedClassifier(classifier) {
        if(this.onUncheckClassifier) {
            this.onUncheckClassifier({classifier});
        }
    }

    loadItems() {
        if(this.loading || !this.requestUrl) {
            return ;
        }

        this.loading = true;
        this.http
            .get(this.requestUrl, true)
            .then((result) => {
                let classifiers =  result.data.results;
                classifiers.forEach(item => item.scheme = this.kind.toUpperCase());
                this.classifiers = this.classifiers.concat(classifiers);
                this.requestUrl = result.data.next;
                this.$timeout(() => this.loading = false, 2000);
            });
    }
}

classifier.component('classifiersListForm', {
    templateUrl: '/static/scripts/modules/Classifier/views/components/classifiers-list-form.html',
    controller: ClassifiersListForm,
    controllerAs: 'vm',
    bindings: {
        kind: '@',
        checkedClassifiers: '=',
        scrollContainer: '@',
        onCheckClassifier: '&',
        onUncheckClassifier: '&'
    }
});