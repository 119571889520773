class SearchBookmarksService {
    constructor(
        http
    ) {
        this.http = http;
        const saveResource = '/api/v1/search-bookmarks/',
            getListResource = '/api/v1/search-bookmarks/',
            deleteResource = '/api/v1/search-bookmarks/:bookmarkId/';

        this.getList = () => {
            return this.http.get(getListResource, true)
                .then((result) => {
                    return result.data.results;
                });
        };

        this.save = (searchData, bookmarkName) => {
            let data = {
                doc_type: 1, //1 - tenders, 2 -contracts, 3-plans
                name: bookmarkName,
                flags: searchData
            };
            return this.http.post(saveResource, data, true)
                .then((result) => {
                    return result.data.id;
                });
        };

        this.get = (bookmarkId) => {
            return;
        };
        this.delete = (bookmarkId) => {
            var deleteUrl = deleteResource.replace(/:bookmarkId/g, bookmarkId);
            return http.delete(deleteUrl, true);
        };
    }
}
commons.service('searchBookmarksService', SearchBookmarksService);