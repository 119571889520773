class SeoService {
    constructor() {
        this.getTitle = () => {
            return document.title;
        };
        this.cutString = (string, stringLength = 80, maxStringLength = 160) => {
            if (!string) return '';
            let shortString = string.slice(0, stringLength),
                stopSymbols = [" ", ",", ".", "-", "!"];
            for (let i = stringLength; i < maxStringLength; i++) {
                if (i >= string.length || stopSymbols.indexOf(string[i]) > -1 ) break;
                shortString += string[i];
            }
            return shortString;
        };
        this.setTitle = (title) => {
            document.title = title;
        };
        this.setMetaDescription = (description) => {
            let metaDescr = document.querySelector('meta[name="description"]');
            if (!metaDescr) {
                let meta = document.createElement('meta');
                meta.name = "description";
                metaDescr = document.getElementsByTagName('head')[0].appendChild(meta);
            }
            metaDescr.setAttribute("content", description);
        };
    }
}

commons.service('seoService', SeoService);