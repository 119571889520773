commons.filter('planStatus', () => {
    "use strict";
    return (input, tenderID) => {
        let name = '';
        switch (input) {
            case 'draft':
                name = gettext('Чернетка');
                break;
            case 'scheduled':
                name = gettext('Запланований');
                break;
            case 'cancelled':
                name = gettext('Скасований');
                break;
            case 'complete':
                if (tenderID) {
                    name = gettext('Оголошено тендер');
                } else {
                    name = gettext('Завершений');
                }
                break;
            case 'unsuccessful':
                name = gettext('Тендер відхилено');
                break;
        }
        return name ? name : input;
    };
});
