providerCabinetApp.config(($mdThemingProvider) => {
    var palette = $mdThemingProvider.extendPalette('grey', {
        '50': 'ffffff'
    });
    $mdThemingProvider.definePalette('newtend-grey', palette);

    palette = $mdThemingProvider.extendPalette('green', {
        '50': '#eef7f4',
        '100': '#7fd0c2',
        '200': '#6dc9b9',
        '300': '#5ac2b0',
        '400': '#47bba8',
        '500': '#3faa98',
        '600': '#389787',
        '700': '#318577',
        '800': '#2a7266',
        '900': '#236055',
        'A100': '#a5ddd4',
        'A200': '#b7e4dd',
        'A400': '#caebe6',
        'A700': '#1d4d45'
    });
    $mdThemingProvider.definePalette('newtend-primary', palette);

    $mdThemingProvider.theme('default')
        .primaryPalette('newtend-primary')
        .warnPalette('red')
        .accentPalette('red')
        .backgroundPalette('newtend-grey');
});

providerCabinetApp.config(($locationProvider) => {
    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });
});

providerCabinetApp.config(function (
    $translateProvider,
    flashProvider,
    uibDatepickerPopupConfig,
    uibDatepickerConfig,
    $httpProvider
) {
    uibDatepickerPopupConfig.currentText = gettext('Сегодня');
    uibDatepickerPopupConfig.clearText = gettext('Очистить');
    uibDatepickerPopupConfig.closeText = gettext('Закрыть');
    uibDatepickerPopupConfig.showWeeks = false;

    uibDatepickerConfig.showWeeks = false;
    uibDatepickerConfig.startingDay = 1;
    uibDatepickerConfig.minDate = new Date();

    $translateProvider.useSanitizeValueStrategy('escape');

    flashProvider.errorClassnames.push('alert-danger');

    $httpProvider.defaults.xsrfHeaderName = "X-CSRFToken";
    $httpProvider.defaults.xsrfCookieName = "csrftoken";
});