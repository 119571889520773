tenderView.controller('tenderViewAgreementsCtrl', function(
    $scope,
    $uibModal,
    $filter,
    $timeout,
    $state,
    $stateParams,
    $rootScope,
    ntFlash,
    tenderData,
    documentsService,
    agreementService,
) {
    $scope.actions = tenderData.action;
    $scope.tender = tenderData.data;
    $scope.agreement = $scope.tender.agreements.filter((a) => (a.id === $stateParams.agreementId))[0];

    function listToObject(items) {
        let result = {};
        for (let i=0; i < items.length; i++) {
            result[items[i].id] = items[i];
        }
        return result;
    }

    // let items = listToObject($scope.tender.items);  // Now not need

    $scope.agreement.contracts.forEach((c) => {
        c._amountSet = true;
        for (let i=0; i < c.unitPrices.length; i++){
            let u = c.unitPrices[i];
            // items[u.relatedItem].quantity !== 0 && (!u.value.amount || u.value.amount === 0
            if (u.value.amount === undefined) {
                c._amountSet = false;
            }
        }
    });

    $scope.activate = () => {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderView/views/modals/tender-view.agreement.activate.modal.html',
            controller: 'tenderViewAgreementActivateModalCtrl',
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tender.id,
                        agreement: $scope.agreement,
                        activate: false,
                    };
                }
            },
            windowClass: 'center-modal'
        }).result.then(() => {
            $state.reload();
        });
    };

    // () => {$state.reload();}

    $scope.setStatus = (status) => {
        agreementService.patch({
            tenderId: $scope.tender.id,
            agreementId: $scope.agreement.id,
        }, {
            status: status
        }).then((() => {
            $state.reload();
            if (status === 'unsuccessful'){
                ntFlash.success(gettext('Угода скасована'));
            } else {
                ntFlash.error(gettext('Изменен статус согашения'));
            }
        }));
    };

    $scope.progressUpload = '';

    // $scope.$watch('file', function(files) {
    //     if (files && files.length) {
    //         $scope.progressUpload = gettext('Завантажуються файли');
    //         documentsService.uploadToDocumentService(files).then((documents) => {
    //             Promise.all(documents.map((document) => {
    //                 return agreementService.uploadDocument({
    //                     tenderId: $scope.tender.id,
    //                     agreementId: $scope.agreement.id,
    //                 }, document)
    //             })).then(()=>{
    //                 $scope.progressUpload = gettext('Документы загружены');
    //                 ntFlash.success($scope.progressUpload);
    //                 $timeout(() => {
    //                     $state.reload();
    //                 }, 2000);
    //             }).catch((e) => {
    //                 console.error(e);
    //                 $scope.progressUpload = gettext('Ошибка при загрузке документов');
    //                 ntFlash.error($scope.progressUpload);
    //             })
    //         })
    //     }
    // });

    $scope.uploadDocuments = () => {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.documents.upload.modal.html",
            controller: "tenderViewDocumentsUploadModalCtrl",
            resolve: {
                data: function() {
                    return {
                        actions: $scope.actions,
                        procurementMethodType: $scope.tender.procurementMethodType,
                        tenderId: $scope.tender.id,
                        agreementId: $scope.agreement.id,
                    }
                }
            },
            backdrop: 'static'
        }).result.then(()=>{
            $timeout(() => {
                $state.reload();
            }, 2000);
        });
    };

    $scope.sign = () => {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            tenderId: $scope.tender.id,
                            agreementId: $scope.agreement.id,
                            documents: $scope.agreement.documents || [],
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                tenderId: $scope.tender.id,
                agreementId: $scope.agreement.id,
                documents: $scope.agreement.documents || []
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

});
