commons.filter('versionFilter', () => {
    "use strict";
    return (value) => {
        let data = {},
            output = [],
            input = value ? value : [];

        input.forEach((element) => {
            if (data.hasOwnProperty(element.id)) {
                data[element.id].push(element);
            } else {
                data[element.id] = [];
                data[element.id].push(element);
            }
        });

        for (let key in data) {
            if (data[key].length > 1) {
                data[key].sort((first, second) => {
                    return first.dateModified - second.dateModified;
                });
                let element = data[key].pop();
                element.previous_versions = data[key];
                output.push(element);
            } else {
                output = output.concat(data[key]);
            }
        }

        return output;
    };
});