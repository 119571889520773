profileApp.factory('reportsService', function (
    http,
    ntFlash
) {
    var listReportsResource = '/api/v1/reports/documents';
    var saveReportResource = '/api/v1/report/generate/';

    let service = {
        getReports: function (nextPage) {
            var resource = listReportsResource;
            if (nextPage) {
                resource = listReportsResource + '?page=' + nextPage;
            }
            return http.get(resource, true)
                .then(function (result) {
                        return result;
                    },
                    function (error) {
                        console.error('Create report error: ',error);
                    });
        }
        // createReport: function (report) {
        //     return http.post(saveReportResource, report)
        //         .then(function(data) {
        //             ntFlash.success(gettext('Отчет сохранен'));
        //             return data.response;
        //     },
        //     function(data){
        //         ntFlash.success(gettext('Ошибка сохранения отчета. Попробуйте позже'));
        //         return data.response;
        //     }
        // );
        // }
    };

    return service;
});