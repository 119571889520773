class CompanyVerificationData {
    constructor() {
        this.data = this.verificationData
    }
}

profileApp.component('companyVerificationData', {
    template: `
        <div class="verification-data">
            <ul ng-if="vm.verificationData.data[0]" class="verification-data__list">
                <li> 
                    {{ vm.verificationData.data[0].identification.id }} 
                    {{ vm.verificationData.data[0].identification.schema }}
                </li>
                <li> {{ vm.verificationData.data[0].identification.legalName }}</li>
                <li>
                    <a ng-href="{{ vm.verificationData.data[0].identification.url }}" target="_blank">
                        {{ vm.verificationData.data[0].identification.url }}
                    </a>
                </li>
            </ul>
            <div ng-if="vm.verificationData === ''" class="verification-data__info">
                {{:: 'Отсутствует информация по этому регистрационному номеру' | translate}}
            </div>
        </div>
    `,
    controller: CompanyVerificationData,
    controllerAs: 'vm',
    bindings: {
        verificationData: '='
    }
});