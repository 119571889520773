commons.constant('CONTACT_TYPES', {
    PHONE: 10,
    EMAIL: 20
});

commons.constant('CONTACT_TYPES_CONFIG', {
    0: {
        canSetDefault: false,
        canConfirm: false
    },
    10: {
        canSetDefault: false,
        canConfirm: false
    },
    20: {
        canSetDefault: true,
        canConfirm: true
    },
    30: {
        canSetDefault: false,
        canConfirm: false
    },
    40: {
        canSetDefault: false,
        canConfirm: false
    }
});
