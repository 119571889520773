commons.filter('billOperationTypeFilter', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 0:
                name = gettext('UNKNOWN');
                break;

            case 1:
                name = gettext('CHARGE');
                break;

            case 2:
                name = gettext('REFUND');
                break;

            case 3:
                name = gettext('CREATE');
                break;

            case 4:
                name = gettext('UPDATE');
                break;

            case 5:
                name = gettext('DELETE');
                break;

            case 6:
                name = gettext('GET');
                break;

            case 7:
                name = gettext('COMMIT');
                break;

            case 8:
                name = gettext('ROLLBACK');
                break;

            case 9:
                name = gettext('PRE_REFUND');
                break;
        }

        return name;
    }
});