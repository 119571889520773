profileApp.controller('billingCtrl', function(
    $scope,
    $state,
    $filter,
    $uibModal,
    ntFlash,
    billingService
) {
    ntFlash.clean();
    $scope.invoices = [];
    $scope.transactions = [];
    $scope.requestInProgress = true;

    $scope.getTransactions = function(nextPage) {
        billingService.getTransactions(nextPage)
            .then(function (result) {
                result.data.response.forEach(function(transaction){
                    $scope.transactions.push(transaction);
                });
                $scope.nextPage = result.data.pages.next_page_number;
            });
    };

    $scope.getInvoices = function(nextPage) {
        billingService.getInvoices(nextPage)
            .then(function (result) {
                result.data.response.forEach(function(transaction){
                    $scope.invoices.push(transaction);
                });
                $scope.nextPage = result.data.pages.next_page_number;
            });
    };

    $scope.getTransactions();
    $scope.getInvoices();

    $scope.requestInProgress = false;
});