class BookmarksService {
    constructor(
        $upload,
        http,
        baseUrl,
        $q
    ) {
        this.http = http;
        this.save = (id) => {
            const saveBookmarkResource = '/api/v1/tender-bookmarks/';
            return this.http.post(saveBookmarkResource, {'tender_id':id}, true);
        };

        this.delete = (id) => {
            const deleteBookmarkResource = '/api/v1/tender-bookmarks/' + id;
            console.log('delete deleteBookmarkResource',deleteBookmarkResource);
            return this.http.delete(deleteBookmarkResource, true)
                .then(function(data) {
                    return data;
                });
        };
    }
}

commons.service('bookmarksService', BookmarksService);