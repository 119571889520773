class TenderFeature {
    constructor() {
    }
}

tenderView.component('tenderFeature', {
    template: `
        <div class="tender-feature">
            <div class="tender-feature__title">
                {{:: vm.feature.title }} 
                <span ng-show=":: vm.feature.title_en && vm.feature.title_en !== ''">
                    ({{:: vm.feature.title_en }})
                </span>
            </div>
            <div class="tender-feature__text">
                {{:: vm.feature.description }} 
                <span ng-show=":: vm.feature.description_en && vm.feature.description_en !== ''">
                    ({{:: vm.feature.description_en }})
                </span>
            </div>
            <table class="tender-feature__table table table-bordered" ng-if="vm.feature.enum">
                <thead>
                <tr>
                    <td>{{:: 'Критерий' | translate}}</td>
                    <td>{{:: 'Коэффициент коррекции' | translate}}</td>
                </tr>
                </thead>
                <tbody>
                <tr ng-repeat="option in vm.feature.enum track by $index" class="feature-table">
                    <td>
                        <div class="feature-table__title"> {{:: option.title }}</div>
                        <div class="feature-table__text">{{:: option.description }}</div>
                    </td>
                    <td class="feature-table__value">{{:: (option.value * 100).toFixed() }}%</td>
                </tr>
                </tbody>
            </table>
        </div>
    `,
    controller: TenderFeature,
    controllerAs: 'vm',
    bindings: {
        feature: '='
    }
});