commons.filter('monitoringAuthor', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'monitoring_owner':
                return gettext('Власник звернення');

            case 'tender_owner':
                return gettext('Власник закупівлі');

            default:
                return value;
        }
    }
});