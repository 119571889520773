class ESignModalCtrl {
    constructor(
        $scope,
        $timeout,
        $uibModalInstance,
        documentsService,
        monitoringService,
        agreementService,
        agreementInstanceService,
        ntFlash,
        data,
        $state
    ) {
        this.$state = $state;
        const opSign = window.opSign,
            baseUrl = OPC_CONFIG.OPC_API_PUBLIC_BASE_URL,
            monitUrl = OPC_CONFIG.OPC_API_MONITORING_PUBLIC_BASE_URL;

        let documents = data.documents || [],
            financialDocuments = data.financialDocuments, // bids financialDocuments for pre-qualification procedures
            tenderId = data.tenderId,
            planId = data.planId,
            milestoneId = data.milestoneId,
            awardId = data.awardId,
            agreementId = data.agreementId,
            agreementInstanceId = data.agreementInstanceId,
            contractId = data.contractId,
            qualificationId = data.qualificationId,
            complaintId = data.complaintId,
            bidId = data.bid && data.bid.id,
            monitoringId = data.monitoringId,
            cancellationId = data.cancellationId,
            postId = data.postId,
            entity = data.entity,
            skipPublic = data.skipPublic,
            callback = data.callback;

        // Copy bid fields
        // const BID_FIELDS = [
        //     'documents',
        //     'selfEligible',
        //     'value',
        //     'lotValues',
        //     'selfQualified',
        //     'financialDocuments',
        //     'eligibilityDocuments',
        //     'tenderers',
        //     'date',
        //     'subcontractingDetails',
        //     'id',
        //     'requirementResponses'
        // ];
        // const EXCLUDE_BID_FIELDS = [
        //     'value',
        //     'lotValues',
        //     'financialDocuments'
        // ];

        // function makeBidData(bid, priv) {
        //     let res = {};
        //     for (let i = 0, size = BID_FIELDS.length; i < size; i++) {
        //         if (bid[BID_FIELDS[i]] !== undefined && (!priv || !EXCLUDE_BID_FIELDS.includes(BID_FIELDS[i]))) {
        //             res[BID_FIELDS[i]] = bid[BID_FIELDS[i]];
        //         }
        //     }
        //     return res;
        // }

        function initOptions() {
            const options = { ...window.options,
                objs: null,
                apiResourceUrl: null
            }; // copy object

            if (monitoringId && entity === 'post') {
                options.apiResourceUrl = `${monitUrl}/monitorings/${monitoringId}/posts/${postId}`;
            } else if (monitoringId && entity === 'eliminationReport') {
                options.apiResourceUrl = `${monitUrl}/monitorings/${monitoringId}/eliminationReport`;
            } else if (monitoringId && entity === 'appeal') {
                options.apiResourceUrl = `${monitUrl}/monitorings/${monitoringId}/appeal`;
            } else if (qualificationId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/qualifications/${qualificationId}`;
            } else if (contractId && awardId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/contracts/${contractId}`;
            } else if (complaintId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/complaints/${complaintId}`;
            } else if (contractId) {
                options.apiResourceUrl = `${baseUrl}/contracts/${contractId}`;
            } else if (awardId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/awards/${awardId}`;
            } else if (agreementId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/agreements/${agreementId}`;
            } else if (agreementInstanceId) {
                options.apiResourceUrl = `${baseUrl}/agreements/${agreementInstanceId}`;
            } else if (bidId) {
                if (financialDocuments instanceof Array) {
                    options.apiResourceUrl = [
                        `/opc/tender/${tenderId}/bid/${bidId}/?private_mode=onlyPublic`,
                        `/opc/tender/${tenderId}/bid/${bidId}/?private_mode=onlyPrivate`
                    ];
                } else {
                    options.apiResourceUrl = `/opc/tender/${tenderId}/bid/${bidId}/?private_mode=all`;
                }
                // const bidModify = JSON.parse(angular.toJson(data.bid, false));
                // options.objs = [
                //     makeBidData(bidModify), // public bids data
                // ];
                // if (financialDocuments instanceof Array) {
                //     options.objs.unshift(makeBidData(bidModify, true)); // private bids data
                // }
            } else if (tenderId && cancellationId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}/cancellations/${cancellationId}`;
            } else if (tenderId) {
                options.apiResourceUrl = `${baseUrl}/tenders/${tenderId}`;
            } else if (planId && milestoneId) {
                options.apiResourceUrl = `${baseUrl}/plans/${planId}/milestones/${milestoneId}`;
            } else if (planId) {
                options.apiResourceUrl = `${baseUrl}/plans/${planId}`;
            }

            return options;
        }


        function getSignFileId(docs) {
            let s = null;
            (docs || []).forEach((document) => {
                if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                    s = document.id;
                }
            });
            return s;
        }

        window.postSign = (signature) => {
            let isMultiSign = signature instanceof Array;

            let request = null,
                signFile, signBlob;

            if (!isMultiSign) {
                signBlob = new Blob([signature]);
                signFile = new File([signBlob], 'sign.p7s', {
                    type: 'application/pkcs7-signature'
                });
            } else {
                signFile = [];
                for (const key in signature) {
                    if (signature.hasOwnProperty(key)) {
                        signBlob = new Blob([signature[key]]);
                        signFile.push(new File([signBlob], 'sign.p7s', {
                            type: 'application/pkcs7-signature'
                        }));
                    }
                }
                // for (let i = 0, size = signature.length; i < size; i++) {
                //     signBlob = new Blob([signature[i]]);
                //     signFile.push(new File([signBlob], 'sign.p7s', {
                //         type: 'application/pkcs7-signature'
                //     }));
                // }
            }

            if (monitoringId) {
                if (entity === 'post') {
                    request = documentsService.uploadToDocumentService([signFile])
                        .then((res) => {
                            return monitoringService.addPostDocument(tenderId, monitoringId, postId, res[0]);
                        });
                } else if (entity === 'eliminationReport') {
                    request = documentsService.uploadToDocumentService([signFile])
                        .then((res) => {
                            return monitoringService.addViolationEliminationDocument(tenderId, monitoringId, res[0]);
                        });
                } else if (entity === 'appeal') {
                    request = documentsService.uploadToDocumentService([signFile])
                        .then((res) => {
                            return monitoringService.addDocumentToAppeal(tenderId, monitoringId, res[0]);
                        });
                }
                request.then((response) => {
                    console.log('response: ', response);
                    if (response) {
                        documents.push(response);
                        ntFlash.success(gettext('Моніторинг підписано'));
                        $state.reload();
                        $uibModalInstance.close(response);
                    } else {
                        ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                    }
                });
            } else if (agreementId) {
                request = documentsService.uploadToDocumentService([signFile])
                    .then((res) => {
                        return agreementService.uploadDocument({
                            tenderId,
                            agreementId
                        }, res[0]);
                    });
                request.then((response) => {
                    console.log('response: ', response);
                    if (response) {
                        documents.push(response);
                        ntFlash.success(gettext('Угода підписана'));
                        $state.reload();
                        $uibModalInstance.close(response);
                    } else {
                        ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                    }
                });
            }
            // todo remake #1
            else if (agreementInstanceId) {
                request = documentsService.uploadToDocumentService([signFile])
                    .then((res) => {
                        res[0].documentOf = 'agreement'
                        console.log(res);
                        return agreementInstanceService.documentUpload(agreementInstanceId, res[0]);
                    });
                request.then((response) => {
                    console.log('response: ', response);
                    if (response) {
                        documents.push(response);
                        ntFlash.success(gettext('Угода підписана'));
                        // $state.reload();
                        $uibModalInstance.close(response);
                    } else {
                        ntFlash.success(gettext('Помилка завантаження цифрового підпису'));
                    }
                });

            } else {
                const signId = getSignFileId(documents);
                if (qualificationId) {
                    request = signId ?
                        documentsService.replace(signFile, tenderId, signId, {
                            qualificationId: qualificationId
                        }) :
                        documentsService.save(signFile, tenderId, {
                            qualificationId: qualificationId
                        });
                } else if (contractId && awardId) {
                    request = signId ?
                        documentsService.replace(signFile, tenderId, signId, {
                            awardId: awardId,
                            contractId: contractId
                        }) :
                        documentsService.save(signFile, tenderId, {
                            awardId: awardId,
                            contractId: contractId
                        });
                } else if (contractId) {
                    request = signId ?
                        documentsService.replace(signFile, null, signId, {
                            contractId: contractId
                        }) :
                        documentsService.save(signFile, null, {
                            contractId: contractId
                        });
                } else if (awardId) {
                    request = signId ?
                        documentsService.replace(signFile, tenderId, signId, {
                            awardId: awardId
                        }) :
                        documentsService.save(signFile, tenderId, {
                            awardId: awardId
                        });
                } else if (complaintId) {
                    request = signId ?
                        documentsService.replace(signFile, tenderId, signId, {
                            complaintId: complaintId
                        }) :
                        documentsService.save(signFile, tenderId, {
                            complaintId: complaintId
                        });
                }
                // todo remake #1
                // else if (agreementInstanceId) {
                //     request = signId ?
                //         documentsService.replace(signFile, agreementInstanceId, signId, {
                //             agreementId: agreementInstanceId
                //         }) :
                //         documentsService.save(signFile, agreementInstanceId, {
                //             agreementId: agreementInstanceId
                //         });
                // }
                else if (bidId) {
                    if (!isMultiSign) {
                        request = signId ?
                            documentsService.replace(signFile, tenderId, signId, {
                                bidId: bidId
                            }) :
                            documentsService.save(signFile, tenderId, {
                                bidId: bidId
                            });
                    } else {
                        let uploadPromises = [];

                        // Sign without value for pre-qualification procedures and full data for other procedures
                        if (!skipPublic) {
                            uploadPromises.push(signId ?
                                documentsService.replace(signFile[0], tenderId, signId, {
                                    bidId: bidId
                                }) :
                                documentsService.save(signFile[0], tenderId, {
                                    bidId: bidId
                                }));
                        }

                        // Sign with value for pre-qualification procedures
                        if (signFile.length > 1) {
                            const signFinId = getSignFileId(financialDocuments);
                            uploadPromises.push(signFinId ?
                                documentsService.replace(signFile[1], tenderId, signFinId, {
                                    bidId: bidId,
                                    documentsType: 'financialDocuments'
                                }) :
                                documentsService.save(signFile[1], tenderId, {
                                    bidId: bidId,
                                    documentsType: 'financialDocuments'
                                }))
                        }
                        request = Promise.all(uploadPromises);
                    }
                } else if (tenderId && cancellationId) {
                    request = documentsService.save(signFile, tenderId, {
                            cancellationId: cancellationId
                        });
                } else if (tenderId) {
                    request = signId ?
                        documentsService.replace(signFile, tenderId, signId) :
                        documentsService.save(signFile, tenderId);
                } else if (planId && milestoneId) {
                    request = signId ?
                        documentsService.replace(signFile, planId, signId, {
                            milestoneId: milestoneId
                        }) :
                        documentsService.save(signFile, planId, {
                            milestoneId: milestoneId
                        });
                } else if (planId) {
                    request = signId ?
                        documentsService.replace(signFile, null, signId, {
                            planId: planId
                        }) :
                        documentsService.save(signFile, null, {
                            planId: planId
                        });
                }

                if (request) {
                    request.then((response) => {
                        console.log(response);
                        console.log('Success: ', response);
                        if (planId) ntFlash.success(gettext('План підписаний'));
                               else ntFlash.success(gettext('Тендер подписан'));
                        $timeout(() => this.$state.reload(), 3000);
                        // TODO: refactor 
                        // (caused by impossibility to select winner)
                        data.awardSign ? data.awardSign.signed = true : '';
                        $uibModalInstance.close(response);
                        if (callback) callback();
                    }).catch((error => {
                        console.log('Errors: ', error);
                        ntFlash.error(gettext('Ошибка загрузки цифровой подписи'));
                        $uibModalInstance.close(null, error);
                    }));
                } else {
                    console.error('Object request is empty!');
                }
            }
        };

        $timeout(() => {
            opSign.init(initOptions());
        });

        $scope.close = () => {
            $uibModalInstance.close();
        };
    }
}
commons.controller('eSignModalCtrl', ESignModalCtrl);
