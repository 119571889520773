class ItemQuantity {
    constructor(
        $scope,
        unitsService,
    ) {
        this.units = [];
        unitsService.get()
            .then((data) => {
                this.units = data;
            });
    }
    selectDropdownUnit(item, code) {
        this.item.unit.name = code.name;
        this.item.unit.code = code.code;
    }
}

tenderEdit.component('itemQuantity', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/item-quantity.html',
    controller: ItemQuantity,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        unitAmount: '<',
        item: '<',
        itemIndex: '<?',
        ngDisabled: '<',
    }
});
