class SidebarFooter {
    constructor($window, $uibModal, langService, $rootScope) {
        this.$window = $window;
        this.langService = langService;
        this.$uibModal = $uibModal;
        this.$rootScope = $rootScope;
    }

    setLanguage(language) {
        this.langService
            .setCurrentLang(language)
            .then(resp => this.$window.location.reload());
    }

    openConfirmModal() {
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/confirm-modal.html",
            controller: "confirmModalCtrl",
            resolve: {
                data: () => ({
                    title: gettext('Зміна модулю КЕП'),
                    description: gettext('Будь ласка, підтвердіть заміну модулю КЕП. Увага! Сторінка буде перезавантажена'),
                    yesWord: gettext('Підтверджую'),
                    noWord: gettext('Назад')
                })
            },
            backdrop: 'static'
        }).result.then(result => {
            if (result && result.confirm) this.$rootScope.oldEds = !this.$rootScope.oldEds;
        });
    }
}

commons.component('sidebarFooter', {
    template: `
        <div class="company-info" ng-cloak>
            <div class="info">
            <!--
                <div class="google-tr">
                <style>.goog-te-banner-frame { bottom: 0 !important; top: auto !important; width: 70%;} .google-tr { margin-top: -60px; } </style>
                <div id="google_translate_element"></div><script type="text/javascript">
                function googleTranslateElementInit() {
                new google.translate.TranslateElement({pageLanguage: 'uk', includedLanguages: 'en,uk,zh-CN,zh-TW', layout: google.translate.TranslateElement.FloatPosition.BOTTOM_LEFT, multilanguagePage: true}, 'google_translate_element');
                }
                </script><script type="text/javascript" src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>
                </div>
            -->
                <div class="phones-list">
                    <div>
                        {{:: 'Служба поддержки' | translate}}
                    </div>
                    <ul>
                        <li>+38 (044) 355 61 80</li>
                        <li>+38 (050) 435 61 80</li>
                        <li>+38 (096) 355 61 80</li>
                        <li>+38 (093) 435 61 61</li>
                    </ul>
                </div>
                <div class="email">
                    <a href="mailto: support@newtend.com">
                        support@newtend.com
                    </a>
                </div>
                <div layout="row">
                    <div flex="50" class="reglament">
                        <a href="https://newtend.com/regulations/">
                            {{:: 'Регламент' | translate}}
                        </a>
                    </div>
                    <div class="tariffs">
                        <md-tooltip md-direction="right" class="tooltip-tariffs">
                            {{:: 'Закупка до 20 тыс грн: 20,4 грн' | translate}}<br>
                            {{:: 'Закупка до 50 тыс грн: 142,8 грн' | translate}}<br>
                            {{:: 'Закупка до 200 тыс. грн: 408 грн' | translate}}<br>
                            {{:: 'Закупка до 1 млн. грн: 612 грн' | translate}}<br>
                            {{:: 'Закупка до 4 млн. грн: 2040 грн' | translate}}<br>
                            {{:: 'От 4 млн. грн: 4080 грн' | translate}}
                        </md-tooltip>
                        <a href="https://newtend.com/tariff-policy/">
                            {{:: 'Тарифы' | translate}}
                        </a>
                    </div>
                </div>
                <md-divider></md-divider>
                    <a href="https://market.newtend.com/" target="_blank" class="text-warning">
                            {{:: 'Прозорро Маркет' | translate}}
                        </a>
                <md-divider></md-divider>
                <div class="languages">
                    <span>
                        {{:: 'Интерфейс' | translate}}:
                    </span>
                    <a href ng-click="vm.setLanguage('uk')">UA</a>
<!--                    <a href ng-click="vm.setLanguage('ru')">RU</a>-->
                    <a href ng-click="vm.setLanguage('en')">EN</a>
                </div>
                
<!--                <div class="check-new-eds" style="font-size: 12px;">-->
<!--                    <a href="javascript:" ng-click="javascript:void">Версія КЕП</a> :-->
<!--                    <span style="border: 1px solid #ccc; border-radius: 4px; padding: 1px 4px">-->
<!--                        {{ $root.oldEds ? 'Попередня' : 'Остання' }}-->
<!--                    </span>-->
<!--                </div>-->
            </div>
        </div>
    `,
    controller: SidebarFooter,
    controllerAs: 'vm'
});
