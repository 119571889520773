commons.filter('cancellationReasonType', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'cancelled':
                name = gettext('Торги отменены');
                break;

            case 'unsuccessful':
                name = gettext('Торги не состоялись');
                break;

            case 'noDemand':
                name = gettext('відсутність подальшої потреби в закупівлі товарів, робіт і послуг');
                break;

            case 'unFixable':
                name = gettext('неможливість усунення виявлених порушень законодавства у сфері публічних закупівель');
                break;

            case 'expensesCut':
                name = gettext('скорочення видатків на здійснення закупівлі товарів, робіт і послуг');
                break;

            case 'noObjectiveness':
                name = gettext('замовником допущено порушення, що вплинуло на об’єктивність визначення переможця процедури закупівлі');
                break;

            case 'dateViolation':
                name = gettext('непідписання договору про закупівлю у строк 35 днів (20 днів - у разі застосування скороченої переговорної процедури закупівлі)');
                break;

            case 'forceMajeure':
                name = gettext('неможливість здійснення закупівлі унаслідок непереборної сили');
                break;
        }

        return name ? name : input;
    }
});