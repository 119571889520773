class InfoPopover {}

profileApp.component('infoPopover', {
    templateUrl: '/static/scripts/modules/Profile/views/components/info-popover.html',
    controller: InfoPopover,
    controllerAs: 'vm',
    bindings: {
        user: '=',
        company: '=',
        requestsToCompanies: '='
    }
});