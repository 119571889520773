class calendarService {
    constructor(
        calendarRequest
    ) {
        this.request = calendarRequest;
        this.eventType2 = {
            "tender": {color: '#49f10a'},
            "auction":  {color: '#ecc90d'},
            "auction_lot":  {color: '#ecd508'},
            "award": {color: '#4178ec'},
            "complaint":  {color: '#f00'},
            "enquiry": {color: '#ff5100'},
        }
        this.eventType = {
            "tender": {
                name: gettext('tender'),
                color: '#49f10a',
                textColor: 'black',
                events: [],
                timeFormat: 'H(:mm)'
            },
            "auction": {
                name: gettext('auction'),
                color: '#f1e3a9',
                textColor: 'black',
                events: [],
                timeFormat: 'H(:mm)'
            },
            "auction_lot":  {
                name: gettext('auction_lot'),
                color: '#f8e104',
                textColor: 'black',
                events: [],
                timeFormat: 'H(:mm)'
            },
            "award":   {
                name: gettext('award'),
                color: '#4178ec',
                textColor: 'white',
                events: [],
                timeFormat: 'H(:mm)'
            },
            "complaint":   {
                name: gettext('complaint'),
                color: '#f00',
                textColor: 'white',
                events: [],
                timeFormat: 'H(:mm)'
            },
            "enquiry": {
                name: gettext('enquiry'),
                color: '#ff5100',
                textColor: 'white',
                events: [],
                timeFormat: 'H(:mm)'
            }

        }
    }

    loadEvents(dates){
        return this.request.getData(dates)
            .then((data)=>{
                if(data.data){
                    return data.data
                } else {
                    return []
                }
            })
            .catch((error)=>{
                console.log('calendarRequest.loadEvents; error', error);
            })
    }

    getEvents(dates){
        // console.log(dates)
        return this.loadEvents(dates)
            .then((data)=>{
                data.forEach((el)=>{
                    let item = {
                        title: el.title,
                        start: new Date (el.start),
                        // end: (el.end && el.end !== el.start) ? new Date (el.end): new Date(new Date(el.start).getTime() +  (1 * 24 * 60 * 60 * 1000)) ,
                        end: el.end,
                        allDay: false,
                        content: `${gettext(el.event_type)} - <a href="${el.url}" target="_blank"> ${el.tooltip}</a>`,
                        link: el.url,
                        url_auction: el.url_auction,
                        id: el.tooltip,
                        tooltip: gettext(el.event_type) + ' - ' + el.title
                    }
                    this.eventType[el.event_type].events.push(item)

                })
                return this.eventType;

            })

    }

    getCurrentMonth(date){
        let month = ((date.getMonth() + 1) < 9) ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        let lastDay = new Date (date.getFullYear(), date.getMonth() + 1, 0).getDate();
        let firstDay = `0${new Date (date.getFullYear(), date.getMonth() + 1, 1).getDate()}`;

        return {start: `${date.getFullYear()}-${month}-${firstDay}`, end:`${date.getFullYear()}-${month}-${lastDay}`}

    }

    getCurrentWeek(){

    }

    getCurrentDay(){

    }


}


calendarModule.service('calendarService', calendarService);