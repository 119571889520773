class tenderViewMilestones {
    constructor($scope, milestonesService) {
        this.getTitle = milestonesService.getTitle;
    }
    $onInit() {
        this.milestones = this.milestones || [];
        this.lotId = this.lot ? this.lot.id : 0;
    }
}

tenderEdit.component('tenderViewMilestones', {
    templateUrl: '/static/scripts/modules/TenderView/views/components/tender-view.milestones.html',
    controller: tenderViewMilestones,
    controllerAs: 'vm',
    bindings: {
        milestones: '<',
        lot: '<'
    }
});