class TenderProcedureModalCtrl {
    constructor($mdDialog, $state) {
        this.$mdDialog = $mdDialog;
        this.$state = $state;

        this.tenderProcedure = null;
        this.tenderLots = null;
        this.alowedMethodTypes = OPC_CONFIG.OPC_CREATE_PROCUREMENT_METHOD_TYPE;
        this.alowedMultiLot = OPC_CONFIG.OPC_OPEN_CREATE_MULTI_LOTS;
        this.alowedSingleLot = OPC_CONFIG.OPC_OPEN_CREATE_WITHOUT_LOTS;
        this.isCZO = OPC_CONFIG.PROCURING_ENTITY.kind === 'central';
        this.isDefense = OPC_CONFIG.PROCURING_ENTITY.kind === 'defense';
    }

    isNeedToCheckLots(tenderProcedure) {
        // return ['belowThreshold'].includes(tenderProcedure);
        return false
    }

    isCanCreateProcedure(tenderProcedure, tenderLots) {
        const isNeedToCheckLots = this.isNeedToCheckLots(tenderProcedure);
        return tenderProcedure && (!isNeedToCheckLots || (isNeedToCheckLots && tenderLots));
    }

    createTender(tenderProcedure, tenderLots) {
        const lots = tenderLots || (tenderProcedure === 'reporting' ? 'singlelot' : 'multilot');
        this.$state.go('editTender', {
            tenderProcedure: tenderProcedure,
            lots: lots,
            entity: 'tender',
            id: null
        });
        this.close();
    }


    isAllowedToMakeTender(tenderProcedure) {

        if(this.isDefense){
            return [
                'belowThreshold',
                'reporting',
                'aboveThreshold'
            ].includes(tenderProcedure)
        }


        return [
            'belowThreshold',
            'reporting'
        ].includes(tenderProcedure) || this.isCZO;
    }

    close() {
        this.$mdDialog.hide();
    }
}

tenderEdit.controller('tenderProcedureModalCtrl', TenderProcedureModalCtrl);