commons.filter('tendersListType', ($rootScope) => {
    "use strict";
    return (input) => {
        let link = input || '';

        switch (input) {
            case 'all':
                link = 'all';
                break;

            case 'home':
                link = 'all';
                break;

            case 'draft.pending':
                link = 'draft.pending';
                break;

            case 'draft.unsuccessful':
                link = 'draft.unsuccessful';
                break;

            case 'enquiries':
                link = 'active.enquiries';
                break;

            case 'tendering':
                link = 'active.tendering';
                break;

            case 'auction':
                link = 'active.auction';
                break;

            case 'qualification':
                link = 'active.qualification';
                break;

            case 'awarded':
                link = 'active.awarded';
                break;

            case 'archive':
                link = 'unsuccessful,complete,cancelled';
                break;

            case 'my-tenders':
                link = 'only_users';
                break;

            case 'bid-tenders':
                link = 'only_mine';
                break;

            case 'search':
                link = 'search';
                break;
        }

        return link;
    }
});