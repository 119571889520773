contractApp.controller('contractOverviewCtrl', function(
    $scope,
    $state,
    $filter,
    $uibModal,
    $timeout,
    ntFlash,
    contractData,
    tenderService,
    transferService,
    formationService,
    documentsService,
    contractingService
) {
    ntFlash.clean();
    var changeId = null;
    var contractId = $state.params.id;

    $scope.isSigned = false;
    $scope.transfer = contractData.data.transfer;
    $scope.contract = contractData.data.response;

    tenderService.get($scope.contract.tender_id).then((result) => {
        if (result) {
            $scope.procurementMethodType = result.data.procurementMethodType;
        }
    });

    $scope.actions = contractData.data.action;
    $scope.contract.documents = $filter('versionFilter')(contractData.data.response.documents);

    $scope.contract.items.forEach(function(item) {
        if (item.deliveryAddress) {
            item.deliveryAddress.field = formationService.address(item.deliveryAddress);
        }
    });

    if ($scope.contract.documents && $scope.contract.documents.length) {
        $scope.contract.documents.forEach(function (document) {
            if (document.title === 'sign.p7s' && document.format === 'application/pkcs7-signature') {
                $scope.isSigned = true;
                $scope.dateSigned = document.dateModified;
            }
        });
    }

    console.log('contract', $scope.contract);

    $scope.transferTokenOfNewContract = null;

    $scope.sendTransferToken = function(token) {
        if (token) {
            transferService.send('contracts', token, $scope.contract.id)
                .then(function(data) {
                    console.log('response transferService', data);
                    if (data.action.contract_owner) {
                        ntFlash.success(gettext('Ваш запит на перенос виконано'));
                        $timeout(function () {
                            console.log('reload ...');
                            $state.reload();
                        }, 2000);
                    } else {
                        ntFlash.error(gettext('При спробі переносу виникла помилка'));
                    }
                })
                .catch(function(error) {
                    console.log('error', error);
                    ntFlash.error(error.errorMessages);
                })
        }
    }

    $scope.isTransferAllowed = true;

    if ($scope.isTransferAllowed) {
        const forbiddenStatuses = ['pending', 'cancelled', 'terminated'];
    
        for (const forbiddenStatus of forbiddenStatuses) {
            if (forbiddenStatus === $scope.contract.status) $scope.isTransferAllowed = false;
        }
    }

    $scope.refreshList = function() {
        contractingService.getChanges(contractId)
            .then(function(result) {
                $scope.canMakeChange = true;
                $scope.canPublishChanges = false;
                $scope.changes = result.data.response;

                $scope.changes.forEach(function(change) {
                    if (change.status === 'pending') {
                        changeId = change.id;
                        $scope.canMakeChange = false;
                        $scope.canPublishChanges = true;
                    }
                });

                if ($scope.contract.status === 'terminated') {
                    $scope.canMakeChange = false;
                    $scope.canPublishChanges = false;
                }
            });
    };

    $scope.refreshList();

    $scope.showCustomer = function(contact) {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function() {
                    return contact;
                }
            },
            windowClass: 'center-modal'
        });
    };

    $scope.publicChange = function() {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Contract/views/modals/contract-public-change.modal.html',
            controller: 'publicChangeContractModalCtrl',
            resolve: {
                data: function() {
                    return {
                        changeId: changeId,
                        contractId: contractId,
                        contract: $scope.contract
                    };
                }
            },
            windowClass: 'center-modal'
        }).result.then(function(response) {
            $scope.refreshList();
        });
    };

    $scope.terminateContract = function(contractId, isCancellation) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Contract/views/modals/contract-changes.terminate.modal.html",
            controller: "terminateContractModalCtrl",
            resolve: {
                data: function() {
                    return {
                        contractId: contractId,
                        contract: $scope.contract,
                        isCancellation: isCancellation
                    };
                }
            },
            backdrop: 'static'
        }).result.then(function(result, submitted) {
            if (result){
                $scope.contract = result;
            }
            if (submitted){
                $state.go($state.current, {}, {reload: true});
                ntFlash.success(gettext('Контракт успешно завершен'));
            }
        });
    };

    $scope.sign = function() {
        // return $uibModal.open({
        //     templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
        //     controller: 'eSignModalCtrl',
        //     resolve: {
        //         data: function() {
        //             return {
        //                 contractId: contractId,
        //                 documents: $scope.contract.documents || ($scope.contract.documents = [])
        //             };
        //         }
        //     },
        //     windowClass: 'center-modal'
        // });
        const data ={
            contractId: contractId,
            documents: $scope.contract.documents || ($scope.contract.documents = [])
        };

        if ($scope.procurementMethodType !== 'reporting' && $scope.procurementMethodType !== 'belowThreshold' ) {
            // console.log($scope.procurementMethodType)

            documentsService.sign(data, {checkPerson : true})
                .then(data => {
                    console.log('documentsService.sign data', data);
                })
                .catch(error => console.log('documentsService.sign error', error));

        } else {
            documentsService.sign(data)
                .then(data => {
                    console.log('documentsService.sign data', data);
                })
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

    $scope.createChange = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Contract/views/modals/contract-changes.create.modal.html",
            controller: "contractChangesCreateModalCtrl",
            resolve: {
                data: function() {
                    return {
                        contractId: contractId,
                        contractNumber: $scope.contract.contractNumber
                    };
                }
            },
            backdrop: 'static'
        }).result.then(()=>$state.go('contract.edit', {}, {reload: true}));
    };
});