tenderView.controller('tenderViewComplaintModalCtrl', function(
    $scope,
    $timeout,
    $state,
    ntFlash,
    $rootScope,
    data,
    complaintService,
    $uibModalInstance
) {
    console.log('data', data);

    $scope.type = 'claim';
    $scope.title = '';
    $scope.message = '';
    $scope.paymentNumber = '';

    $scope.resolutionType = 'resolved';
    $scope.isQuestion = data.isQuestion;
    $scope.isSatisfied = data.isSatisfied;
    $scope.isCancellation = data.isCancellation;
    $scope.complaintType = data.complaintType;
    $scope.isMultilotTender = data.isMultilotTender;
    $scope.isBelowThreshold = data.isBelowThreshold;

    $scope.lots = data.lots;

    $scope.makeComplaint = function() {

        $scope.disablePublishBtn = true;
        var complaintData = {
            type: $scope.type,
            title: $scope.title,
            description: $scope.message
        };
        if ($scope.relatedLot && $scope.complaintOf === 'lot') {
            complaintData.relatedLot = $scope.relatedLot;
        }
        // if (!$scope.isBelowThreshold) {
        //     complaintData.paymentNumber = $scope.paymentNumber;
        // }
        // console.log("AwardId:" + data.awardId);
        complaintService.create(data.tenderId, complaintData, data.awardId, data.qualificationId, data.cancellationId)
            .then(function(data) {
                console.log('complaintService.create data', data);
                ntFlash.success(gettext('Скаргу успішно створено'));
                $timeout(() => {
                    $state.go('tenderView.complaint', {}, {
                        reload: true
                    });
                    $uibModalInstance.close();
                }, 500);
            })
            .catch(function(error) {
                console.log('complaintService.create error', error);
                ntFlash.error(gettext('Сталася помилка'));
                $uibModalInstance.close();
            });
    };

    $scope.sendAnswer = function() {
        var message = {};
        if ($scope.isSatisfied) {
            message.tendererAction = $scope.message;
            message.status = 'resolved';
        } else {
            message.resolutionType = $scope.resolutionType;
            message.resolution = $scope.message;
            message.status = 'answered';
        }
        $scope.disablePublishBtn = true;
        complaintService.edit(data.tenderId, message, data.complaintId, data.awardId, data.qualificationId, data.cancellationId)
            .then(function(data) {
                $uibModalInstance.close();
            });
    };

    $scope.cancelComplaint = function() {
        $scope.disablePublishBtn = true;
        var cancelData = {
            status: $scope.complaintType === 'complaint' ? 'mistaken' : 'cancelled'
        };
        if ($scope.complaintType === 'claim') {
            cancelData.cancellationReason = $scope.message;
        }
        complaintService.edit(data.tenderId, cancelData, data.complaintId, data.awardId, data.qualificationId, data.cancellationId)
            .then(function(data) {
                $uibModalInstance.close();
            });
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.isDisabled = function(isAnswerMode) {
        if (isAnswerMode) {
            return !$scope.message || $scope.disablePublishBtn;
        } else if(!$scope.isMultilotTender) {
            return !$scope.message || !$scope.title.length || $scope.disablePublishBtn;
        } else {
            return !$scope.message || !$scope.title.length || !$scope.complaintOf || ($scope.complaintOf === 'lot' && !$scope.relatedLot) || $scope.disablePublishBtn;
        }
    };

});
