tenderView.controller('tenderViewCloseBidsModalCtrl', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    data,
    ntFlash,
    uibDatepickerConfig,
    contractService,
    $filter,
    tenderService,
    DATETIME_FROMAT
) {
    // dev purpose only
    // $scope.isDev = !window.location.href.includes('b2b');

    uibDatepickerConfig.minDate = new Date('2015');

    $scope.isPMT = (name) => tenderService.isProcurementMethodType($scope.tender, name);

    $scope.datePickerIsOpen = {
        dateSigned: false,
        periodStartDate: false,
        periodEndDate: false
    };

    $scope.tender = data.tender;

    $scope.isEsco = $scope.tender.procurementMethodType === 'esco';
    let contract = ''
    if(data.contract){
       contract = data.contract
       $scope.fullContract =  contract
    } else {
        contract = $scope.tender.contracts.filter(contract => contract.id === data.contractId)[0];
    }
    $scope.contract = contract


    if(!$scope.isEsco) {

        $scope.tempItems = $scope.contract.items
        console.log($scope.tempItems)
        $scope.tempItems.map((el) => {
            const units = {
                code: el.unit.code,
                name: el.unit.name,
                value: {
                    currency: $scope.contract.value.currency,
                    amount: (el.unit.value && el.unit.value.amount) ? el.unit.value.amount : '',
                    valueAddedTaxIncluded: $scope.contract.value.valueAddedTaxIncluded
                }
            }
            el.unit = units
        })
        $scope.itemsEqual = $scope.tempItems
        $scope.currentAmount = 0
    }

    $scope.CheckVat = ()=>{
        if(!$scope.isEsco){
            $scope.tempItems.map((el)=>{

                el.unit.value.valueAddedTaxIncluded = $scope.valueAddedTaxIncluded
            })
        }
    }



    $scope.checkPrice = ()=>{
        let quantityPrice = 0
        $scope.itemsEqual.forEach((el)=>{
            quantityPrice = quantityPrice + el.quantity * el.unit.value.amount
        })
        return quantityPrice.toFixed(2) <= $scope.contract.value.amount
    }

    $scope.getMaxAmount = (amount)=>{

        let quantityPrice = 0
        $scope.itemsEqual.forEach((el)=>{
            quantityPrice += quantityPrice + (el.quantity * el.unit.value.amount)
        })

        return $scope.contract.value.amount - quantityPrice.toFixed(2)

    }


    $scope.checkValid = ($index)=>{
       if(!$scope.checkPrice()){
           $scope.closeBidsForm[$index + '_itemUnitValueAmount'].$setValidity("max", false);
       } else {
           $scope.closeBidsForm[$index + '_itemUnitValueAmount'].$setValidity("max", true);
       }
    }

    $scope.validateAmount = ()=>{
        $scope.currentAmount = 0
    }

    $scope.getCurrentPrice = (amount = 0, quantity = 0)=>{
        return Number(quantity * amount).toFixed(2);
    }

    $scope.contractAmountMax = contract && contract.value ? contract.value.amount : null;

    $scope.valueAddedTaxIncluded = $scope.tender.awards.filter(award => award.id === data.awardId)[0].value.valueAddedTaxIncluded;
    $scope.awardsValueAddedTaxIncluded = $scope.valueAddedTaxIncluded;

    $scope.awardValueAmount = $scope.tender.awards.filter(award => award.id === data.awardId)[0].value.amount;

    $scope.data = {
        awardId: data.awardId,
        tenderId: data.tenderId,
        contractId: data.contractId
    };

    $scope.itemUnitPrice = null;

    const unitsCount = $scope.tender.items.reduce((prev, item) => {
        const quantity = typeof prev === 'number' ? prev : prev.quantity;
        return quantity + item.quantity;
    });
    $scope.itemUnitPriceMax = Math.floor($scope.contractAmountMax / unitsCount);

    $scope.contract = {
        contractNumber: '',
        dateSigned: new Date(),
        period: {
            startDate: new Date(),
            endDate: new Date()
        },
        status: 'active',
        value: {
            amount: $scope.contractAmountMax,
            amountNet: $scope.contractAmountMax,
            valueAddedTaxIncluded: $scope.valueAddedTaxIncluded,
            currency:  $scope.contract.value.currency
        }
    };


    $scope.open = function ($event, string) {
        $event.preventDefault();
        $event.stopPropagation();

        $scope.datePickerIsOpen[string] = true;
    };
    $scope.checkDefenseReporting = () => {
        return $scope.tender.procuringEntity.kind === 'defense' &&
            $scope.tender.procurementMethodType === 'reporting' &&
            $scope.tender.procurementMethodRationale === 'defense'
    }

    $scope.closeBids = function () {
        $scope.contract.value.valueAddedTaxIncluded = $scope.valueAddedTaxIncluded;
        if ($scope.contract.value.amount <= 0 || $scope.contract.value.amountNet <= 0) {
            ntFlash.error(gettext('Значения "Цена договора" и "Цена договора без НДС" должны быть больше 0'));
            return;
        }

        if ($scope.valueAddedTaxIncluded) {
            if ($scope.contract.value.amount == $scope.contract.value.amountNet) {
                ntFlash.error(gettext('Значения "Цена договора" и "Цена договора без НДС" не должны совпадать'));
                return;
            }
            if ($scope.contract.value.amount <= $scope.contract.value.amountNet) {
                ntFlash.error(gettext('Значение "Цена договора" должно быть больше чем "Цена договора без НДС"'));
                return;
            }
        }

        if (!$scope.valueAddedTaxIncluded && ($scope.contract.value.amount !== $scope.contract.value.amountNet)) {
            ntFlash.error(gettext('Значения "Цена договора" и "Цена договора без НДС" должны совпадать'));
            return;
        }

        let contract = angular.copy($scope.contract);
        contract.dateSigned = $filter('date')(contract.dateSigned, DATETIME_FROMAT);
        contract.period.startDate = $filter('date')(contract.period.startDate, DATETIME_FROMAT);
        contract.period.endDate = $filter('date')(contract.period.endDate, DATETIME_FROMAT);
        if (contract.dateSigned && $scope.tender.procurementMethodType !== 'belowThreshold') {
            complaintDates = data.tender.awards.filter(element => {
                return (element.id == data.awardId && element.status == 'active' && (element.complaintPeriod && element.complaintPeriod.endDate > contract.dateSigned)) ? true : false;
            });
            if (complaintDates.length > 0) {
                ntFlash.error(gettext('Дата подписания должна быть позже даты завершения периода обжалований'));
                return;
            }
        }
        if(!$scope.isEsco) {
            let tempItems = []
            $scope.itemsEqual.forEach((el) => {
                if (el.unit.value.amount !== '') {
                    tempItems.push(el)
                }
            })
            if (tempItems.length > 0) {
                contract.items = tempItems
            }
        }
        if(data.contract){
            return contractService
                .saveEContract($scope.fullContract.id, contract)
                .then(() => {
                    $uibModalInstance.close();
                    ntFlash.success(gettext('Торги успешно закончены'));
                });
        } else {
            return contractService
                .save($scope.data, contract)
                .then(() => {
                    $uibModalInstance.close();
                    ntFlash.success(gettext('Торги успешно закончены'));
                });
        }
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };
});
