commons.filter('dateTimeDuration', (
    iso8601_duration,
) => {
    "use strict";

    return (data) => {
        let d = iso8601_duration.parse(data || 'PT0S');
        let result = '';
        if (d.years > 0) {
            let strYear = 'р';
            if (d.years === 1) {
                strYear = 'рік'
            } else  if ([2, 3, 4].indexOf(d.years) > -1) {
                strYear = 'роки';
            }
            result += `${d.years} ${strYear}`;
        }
        if (d.months > 0) {
            result += ` ${d.months} міс`;
        }
        if (d.days > 0) {
            result += ` ${d.days} дн`;
        }
        return result || data;
    }
});
