tenderView.factory('uControlService', function(http) {

    var getResource = '/info/?code=:edrpou';

    return {
        get: function(edrpou) {
            var getUrl = getResource.replace(/:edrpou/g, edrpou);
            return http.get(getUrl, true);
        }
    }
});
