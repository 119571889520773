contractApp.controller('publicChangeContractModalCtrl', function(
    $scope,
    $uibModalInstance,
    ntFlash,
    data,
    contractingService,
    uibDatepickerConfig,
    $filter
) {
    let changeId = data.changeId;
    let contractId = data.contractId;
    $scope.contract = data.contract;

    let changes = $scope.contract.changes.filter(change => change.status === 'active');
    $scope.lastContract =  $filter('orderBy')(changes, '-dateSigned')[0] || $scope.contract;

    $scope.publicData = {
        dateSigned: $scope.lastContract.dateSigned ? new Date($scope.lastContract.dateSigned) : null
    };
    uibDatepickerConfig.minDate = $scope.publicData.dateSigned;

    $scope.publicChange = (publicData) => {

        let lastContractSigned = new Date($scope.lastContract.dateSigned);
        let currentTime = new Date();
        if($scope.lastContract.dateSigned && +publicData.dateSigned < +lastContractSigned) {
            let lastSigned = $filter('date')(lastContractSigned, 'dd-MM-yyyy HH:mm');
            ntFlash.error(gettext('Время подписания не может быть раньше предыдущего подписания') + `: ${lastSigned}`);
            return;
        } else if(+publicData.dateSigned > +currentTime) {
            ntFlash.error(gettext('Вы не можете подписать будущим временем'));
            return;
        }

        contractingService.editChange(changeId, contractId, {
            status: 'active',
            dateSigned: publicData.dateSigned
        }).then(() => {
            $uibModalInstance.close();
        });
    };

    $scope.close = () => $uibModalInstance.close();

    $scope.open = ($event, obj, string) => {
        $event.preventDefault();
        $event.stopPropagation();
        obj[`${string}IsOpen`] = true;
    };
});