class InputDateTime {
    constructor(
        $scope,
    ) {
        $scope.isOpen = false;
        $scope.open = ($event, obj, string) => {
            $event.preventDefault();
            $event.stopPropagation();
            if (!this.ngDisabled){
                $scope.isOpen = true;
            }
        };
    }

    $onInit() {
        if (this.ngModel) {
            this.ngModel = new Date(this.ngModel);
        }
        this.format = this.format || 'yyyy-MM-dd';
    }

}

tenderEdit.component('inputDateTime', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/input-date-time.html',
    controller: InputDateTime,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        ngModel: '=',
        format: '<',
        datepickerOptions: '<',
        dateOnly: '<',
        item: '<',
        itemIndex: '<?',
        ngDisabled: '<',
        ngRequired: '<',
        uniqueId: '<?'
    }
});