planningApp.controller('planningCancelModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    planningService,
    data,
    ntFlash,
    $timeout
) {

    $scope.reason = "";
    $scope.isCancelButtonDisabled = false;

    $scope.cancel = function() {
        if ($scope.cancelPlanForm.$valid) {
            $scope.isCancelButtonDisabled = true;
            planningService.cancel(data, {
                reason: $scope.reason,
                status: 'active'
            })
                .then(function(resData) {
                    console.log('Response', resData);
                    // console.log('Response', resData);
                    if (resData.data.response.status === 'cancelled') {
                        ntFlash.success(gettext('Річний План успішно скасований'));
                        $uibModalInstance.close();
                        $timeout(function() {
                            $state.reload();
                        }, 1000);
                    } else {
                        ntFlash.error(gettext('Під час зміни статусу сталась помилка. Перезавантажте сторінку'));
                        $scope.isCancelButtonDisabled = false;
                    }

                    //
                    // if (resData.data.response.cancellation.status === 'pending') {
                    //     planningService.cancel(data, {status: 'active'})
                    //         .then(function(resData) {
                    //             console.log('Response', resData);
                    //             if (resData.data.response.status === 'cancelled') {
                    //                 ntFlash.success(gettext('Річний План успішно скасований'));
                    //                 $uibModalInstance.close();
                    //                 $timeout(function() {
                    //                     $state.reload();
                    //                 }, 1000);
                    //             } else {
                    //                 ntFlash.error(gettext('Під час зміни статусу сталась помилка. Перезавантажте сторінку'));
                    //                 $scope.isCancelButtonDisabled = false;
                    //             }
                    //         })
                    //         .catch(function() {
                    //             // ntFlash.error(gettext('При спробі змінити статус на Скасовано сталася помилка. Спробуйте пізніше.'));
                    //             $scope.isCancelButtonDisabled = false;
                    //         });
                    // } else {
                    //     ntFlash.error(gettext('Невдала спроба створити об\'єкт Cancellation зі статусом Pending. Перезавантажте сторінку'));
                    //     $scope.isCancelButtonDisabled = false;
                    // }
                })
                .catch(function() {
                    ntFlash.error(gettext('Невдала спроба створити об\'єкт Cancellation зі статусом Pending. Перезавантажте сторінку'));

                    // ntFlash.error(gettext('При спробі змінити статус на Скасовано сталася помилка. Спробуйте пізніше.'));
                    $scope.isCancelButtonDisabled = false;
                });
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

});
