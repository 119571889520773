agreementModule.controller('agreementConfirmChangesModalCtrl', function(
    $scope,
    $state,
    $timeout,
    $uibModal,
    $filter,
    $stateParams,
    $rootScope,
    $uibModalInstance,
    // ntFlash,
    tenderService,
    agreementInstanceService,
    uibDatepickerConfig
    // documentsService,
) {
    $scope.$uibModal = $uibModal;
    $scope.title = 'Оберить дату активации';
    $scope.startDate = new Date();

    uibDatepickerConfig.minDate = new Date('1960');
    uibDatepickerConfig.maxDate = new Date();

    $scope.startDateView = $scope.startDate;

    $scope.save = () => {
        $uibModalInstance.close({
            startDate: $scope.startDate,
            status: 1
        });
    };
    $scope.cancel = () => {
        $uibModalInstance.close({
            startDate: $scope.startDate,
            status: 0
        });
    }

});