class ShowPeriod {
    constructor() {
    }
}

monitoringApp.component('showPeriod', {
    template: `
        <div ng-show=":: vm.period || vm.showEmpty">
            <div class="block-info__title">{{:: vm.label }}</div>
            <div layout="column" layout-gt-sm="row">
                <div class="block-info__text block-info__text--bold" ng-show=":: vm.period.startDate">
                    <span>{{:: 'с' | translate}} </span>
                    <span class="period-date">{{:: vm.period.startDate | date:'yyyy-MM-dd HH:mm:ss'}}</span>
                </div>
                <div class="block-info__text block-info__text--bold" ng-show=":: vm.period.endDate">
                    <span>&nbsp;{{:: 'до' | translate}}&nbsp;</span>
                    <span class="period-date">{{:: vm.period.endDate | date:'yyyy-MM-dd HH:mm:ss'}}</span>
                </div>
            </div>
        </div>
    `,
    controller: ShowPeriod,
    controllerAs: 'vm',
    bindings: {
        showEmpty: '@',
        label: '@',
        period: '<',
    }
});