profileApp.controller('reportsCtrl', function (
    $scope,
    $state,
    $filter,
    $uibModal,
    ntFlash,
    uibDatepickerConfig,
    reportsService
) {
    var reportQuery = {};

    ntFlash.clean();
    $scope.reports = [];
    $scope.requestInProgress = true;

    $scope.getReports = function (nextPage) {
        reportsService.getReports(nextPage)
            .then(function (result) {
                //console.error(result);
                result.data.forEach(function (report) {
                    $scope.reports.push(report);
                });
                $scope.nextPage = result.data.pages ? result.data.pages.next_page_number : null;
            });
    };

    $scope.getReports();

    $scope.requestInProgress = false;

    // $scope.createReport = function() {
    //     console.log($scope.reportQuery);
    //     reportsService.createReport($scope.reportQuery)
    //         .then(function(result){
    //             console.log("Report saved:", result);
    //         });
    // };


    $scope.open = function($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();
        obj[string + 'IsOpen'] = true;
    };
});