class AgreementDocumentsList {
    constructor() {}
}

agreementModule.component('agreementDocumentsList', {
    template: `
        <div ng-show=":: vm.valueData">
            <div class="block-info__title">{{:: vm.label }}</div>
            <div layout="column" layout-gt-sm="row">
                <ul class="documents-list">
                    <li class="document__item"
                        ng-class=":: {
                        'document__item--doc-file': (document.format === 'text/plain' || document.format === 'application/msword'),
                        'document__item--img-file': (document.format === 'image/jpeg' || document.format === 'image/jpg' || document.format === 'image/png')
                        }"
                        ng-repeat="document in vm.valueData | versionFilter | orderBy:'-dateModified'"
                        ng-if="document.documentOf === 'agreement'"
                    >
                        <div class="document__title">
                            <a ng-href="{{ document.url }}" target="_blank">{{document.title}}</a>
                        </div>
                        <div class="document__show__type">{{ document.author | monitoringAuthor }}</div>
                        <div class="document__show__date">{{document.dateModified | date : "dd MMMM yyyy"}} {{ 'в' | translate }} {{document.dateModified | date : "HH:mm"}}</div>
                        </li>
                </ul>
            </div>
        </div>

    `,
    controller: AgreementDocumentsList,
    controllerAs: 'vm',
    bindings: {
        showEmpty: '@',
        label: '@',
        valueData: '<',
    }
});