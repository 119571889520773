tenderView.controller('bidModalCtrl', function (
    $scope,
    $uibModalInstance,
    $uibModal,
    tenderBidService,
    tenderService,
    exchangeService,
    agreementInstanceService,
    currencyListService,
    $stateParams,
    $rootScope,
    data,
    $state,
    ntFlash,
    documentsService,
    $timeout
) {
    $scope.procurementMethodType = data.currentTender.data.procurementMethodType;
    // console.log(data.currentTender);
    $scope.isEsco = $scope.procurementMethodType === 'esco';
    if (!$scope.isEsco) {
        $scope.maxAmount = data.currentTender.data.value.amount;
        $scope.currency = data.currentTender.data.value.currency;
        var valueAddedTaxIncluded = data.currentTender.data.value.valueAddedTaxIncluded;
    }
    $scope.isAgreementClose = $scope.procurementMethodType === 'closeFrameworkAgreementSelectionUA';

    $scope.tender = data.currentTender.data;
    $scope.config = data.config;
    $scope.currencies = currencyListService.getCurrenciesList();
    $scope.tenderCdbId = $stateParams.id;
    $scope.isCancel = data.isCancel;
    $scope.subcontractingDetails = data.currentBid ? data.currentBid.subcontractingDetails : '';
    $scope.bidAmount = data.bidAmount ? Number(data.bidAmount) : "";
    $scope.bidPlaced = false;
    $scope.bidItems = $scope.tender.items;
    $scope.isBidProposal = tenderService.isCompetitiveDialogue(data.currentTender.data);
    $scope.changeMode = (data.bidAmount || ($scope.isBidProposal && data.currentBid)) ? true : false;
    if($scope.isEsco){
        $scope.changeMode = (data.currentBid) ? true : false;
    }

    $scope.agree = {
        value: false,
        selfEligible: false,
        selfQualified: false
    };
    $scope.qualityIndicator = {
        total: 0,
        totalPrice: $scope.bidAmount
    };

    exchangeService.getExchangeRate({
            tender_id: $scope.tender.id
        })
        .then(function (result) {
            $scope.exchangeRates = result.response;
        })
        .catch((err) => {
            let value = $scope.tender.value ? $scope.tender.value.currency : $scope.tender.lotValue ? $scope.tender.lotValue.value.currency : '';
            if (value !== 'UAH') {
                ntFlash.error(gettext('Курс валют НБУ наразі недоступний, спробуйте пізнише'), err.data);
            }
        });


    $scope.procurementMethodType = data.currentTender.data.procurementMethodType;
    $scope.features = data.currentTender.data.features || [];
    $scope.items = data.currentTender.data.items || [];
    $scope.lots = data.currentTender.data.lots || [];

    if ($scope.isAgreementClose) {
        $scope.agree = true;
        $scope.selfEligible = true;
        $scope.selfQualified = true;

        const currentAgreement = data.currentTender.data.agreements[0];

        const agreementId = currentAgreement.id;
        // const tenderId = currentAgreement.tender_id;
        const tenderId = $scope.tenderCdbId;

        agreementInstanceService.getContracts(tenderId, agreementId, 'only_users').then((res) => {
            let lotsArray = [];

            if (res.data.response[0]) {
                $scope.maxAgreementAmount = res.data.response[0].value.amount;
                $scope.maxAgreementAmountCurrency = res.data.response[0].value.currency;
            }

            res.data.response.map((elC) => {
                elC.parameters.map((elP) => {
                     data.currentTender.data.features.map((elF) => {
                        if(elP.code == elF.code){
                            elF.enum.map((elE) => {
                                if(elE.value == elP.value){
                                    lotsArray.push(elE);
                                }
                            });
                        }
                     });
                });
            });
            $scope.bidDataParameters = res.data.response[0].parameters;
            $scope.features = lotsArray;
            if ($scope.lots.length && $scope.features.length) {
                $scope.lots.forEach(function (lot) {
                    lot.qualityIndicator = 0;
                    $scope.features.forEach(function (feature) {
                        lot.qualityIndicator += Number(feature.value);
                    });
                });
            }
        });
    }

    if (tenderService.isCompetitiveDialogue2Stage(data.currentTender.data)) {
        let bidToLots = data.currentTender.action.can_create_bid_to_lots || [];
        $scope.lots = $scope.lots.filter(lot => bidToLots.indexOf(lot.id) > -1);
    }

    $scope.lots.forEach(function (lot) {
        lot.procurementMethodType = $scope.procurementMethodType;
        lot.items = $scope.tender.items.filter((item) => {
            return item.relatedLot === lot.id;
        });

        if ($scope.isEsco) {
            lot.calcYears = [];
            lot.value = {};
            lot.value.annualCostsReduction = Array(21);
            lot.value.annualCostsReduction.fill(0);
        }
    });

    $scope.isMultilotTender = $scope.lots.length ? true : false;

    if ($scope.isEsco && !$scope.isMultilotTender) {
        $scope.value = data.value ? data.value : {};
        $scope.value.yearlyPaymentsPercentage = data.value && data.value.yearlyPaymentsPercentage ? Number(data.value.yearlyPaymentsPercentageRange) : "";
        $scope.value.contractDuration = data.value && data.value.contractDuration ? Number(data.value.contractDuration) : {};
    }

    $scope.features.forEach(function (feature) {
        if (feature.featureOf === "lot") {
            feature.relatedLot = feature.relatedItem;
        } else if (feature.featureOf === "item") {
            $scope.items.forEach(function (item) {
                if (feature.relatedItem === item.id) {
                    feature.relatedLot = item.relatedLot;
                }
            });
        } else {
            feature.relatedLot = "tenderer";
        }
    });

    // console.log("Current Bid: ", data.currentBid);


    var currentTender = data.currentTender,
        calculatePercentage = function () {

            // console.log('$scope.features',$scope.features);

            $scope.qualityIndicator.total = 0;

            $scope.features.forEach(function (feature) {
                if (feature.selectedValue) {
                    $scope.qualityIndicator.total += feature.selectedValue;
                }
            });

            if ($scope.lots.length && $scope.features.length) {
                $scope.lots.forEach(function (lot) {
                    lot.qualityIndicator = 0;
                    $scope.features.forEach(function (feature) {
                        if (feature.selectedValue && (feature.featureOf == "tenderer" || feature.relatedLot == lot.id)) {
                            lot.qualityIndicator += Number(feature.selectedValue);
                        } else if ($scope.isAgreementClose) {
                            lot.qualityIndicator += Number(feature.value);
                        }
                    });
                });
            }

        };

    calculatePercentage();

    if (data.currentBid && !$scope.isMultilotTender) {
        if ($scope.isEsco) {
            $scope.value.yearlyPaymentsPercentage = data.currentBid.value.yearlyPaymentsPercentage ? +(data.currentBid.value.yearlyPaymentsPercentage * 100).toFixed(3) : "";
            $scope.value.contractDuration = data.currentBid.value.contractDuration ? data.currentBid.value.contractDuration : {
                years: 0,
                days: 0
            };
            $scope.value.annualCostsReduction = data.currentBid.value.annualCostsReduction ? data.currentBid.value.annualCostsReduction : [];
        }
    }
    if (data.currentBid) {
        if (data.currentBid.parameters && data.currentBid.parameters.length) {
            $scope.features.forEach(function (feature) {
                data.currentBid.parameters.forEach(function (param) {
                    if (feature.code === param.code) {
                        feature.enum.forEach(function (option) {
                            if (param.value == option.value) {
                                feature.selectedTitle = option.title;
                                feature.selectedValue = option.value;
                            }
                        });
                    }
                });
            });
        }
        if (data.currentBid.lotValues && data.currentBid.lotValues.length) {

            $scope.lots.forEach(function (lot) {
                data.currentBid.lotValues.forEach(function (lotValue) {
                    if (lotValue.relatedLot == lot.id) {
                        $scope.bidAdded = true;
                        lot.lotValue = {};
                        if (lotValue.value && lotValue.value.currency) {
                            lot.lotValue.currency = lotValue.value.currency;
                            if ($scope.isEsco) {
                                lot.value.yearlyPaymentsPercentage = lotValue.value.yearlyPaymentsPercentage * 100;
                                lot.value.contractDuration = lotValue.value.contractDuration;
                                lot.value.annualCostsReduction = lotValue.value.annualCostsReduction;
                            }
                        }
                        lot.lotValue.subcontractingDetails = lotValue.subcontractingDetails || '';
                    }
                });
            });
        }
        calculatePercentage();
    }

    $scope.totalMaxFeaturesVal = (function (features) {
        if (features && features.length) {
            var total = 0;
            features.forEach(function (feature) {
                var optionMaxVal = 0;
                feature.enum.forEach(function (option) {
                    optionMaxVal = Number(option.value) > optionMaxVal ? Number(option.value) : optionMaxVal;
                });
                total += optionMaxVal;
            });
            return total;
        } else {
            return 0;
        }
    })($scope.features);

    if ($scope.lots.length && $scope.features.length) {
        $scope.lots.forEach(function (lot) {
            lot.totalMaxFeaturesVal = 0;
            $scope.features.forEach(function (feature) {
                if (feature.featureOf == "tenderer" || feature.relatedLot == lot.id) {
                    var optionMaxVal = 0;
                    feature.enum.forEach(function (option) {
                        optionMaxVal = Number(option.value) > optionMaxVal ? Number(option.value) : optionMaxVal;
                    });
                    lot.totalMaxFeaturesVal += optionMaxVal;
                }
            });
        });
    }

    $scope.calculateTotalPrice = function (lot) {
        var coefficient = 1,
            totalSelectedVal = 0;

        if ($scope.features && $scope.features.length) {
            $scope.features.forEach(function (feature) {
                if (feature.selectedValue) {
                    totalSelectedVal += Number(feature.selectedValue);
                }
            });

            $scope.lots.forEach(function (lot) {
                lot.coefficient = 1 + lot.qualityIndicator / (1 - lot.totalMaxFeaturesVal);

                lot.totalPrice = $scope.bidAmountForm.$valid && lot.lotValue ?
                    Number(lot.lotValue.amount / lot.coefficient).toFixed(2) + ' ' + $scope.currency :
                    gettext('Ставка не корректна');
            });
        }

        coefficient += totalSelectedVal / (1 - $scope.totalMaxFeaturesVal);

        if ($scope.lots.length) {
            return !$scope.isEsco ? lot.totalPrice : "ESCO";
        }

        if (!$scope.isEsco) {
            return $scope.bidAmountForm.amount.$valid ?
                Number($scope.bidAmount / coefficient).toFixed(2) + ' ' + $scope.currency :
                gettext('Ставка не корректна');
        } else {
            return 'ESCO';
        }
    };

    $scope.selectDropdown = function (item, feature) {
        feature.selectedValue = item.value;
        feature.selectedTitle = item.title;
        calculatePercentage();
    };

    $scope.showBid = function (lot, check) {
        $timeout(() => {
            $scope.lots[lot].lotValue = {};
        }, 200);
        $scope.bidAdded = true;
    };

    $scope.getMaxAmount = function (lot){
        if(($scope.procurementMethodType === 'aboveThreshold' || $scope.procurementMethodType === 'competitiveOrdering') && $scope.config.hasValueRestriction === false){
            return '99999999999999'
        } else {
            return lot
        }

    }

    $scope.hideBid = function (lot) {
        $timeout(() => {
            delete $scope.lots[lot].lotValue;
        }, 200);
        if ($scope.features) {
            $scope.features.forEach(function (feature) {
                if (feature.hasOwnProperty("selectedValue") && feature.relatedLot == $scope.lots[lot].id) {
                    delete feature.selectedValue;
                }
            });
        }
    };

    $scope.placeBid = function () {
        var bidData = $scope.prepareBid(true);

        if ($scope.isMultilotTender && !bidData.lotValues.length) {
            ntFlash.error(gettext('У багатолотовій закупівлі має бути хоча б одна пропозиція'));
            return;
        }

        if ($scope.bidAmountForm.$valid && $scope.agree) {
            $scope.bidPlaced = true;
            tenderBidService.save($scope.tenderCdbId, bidData)
                .then(function (data) {

                    $uibModalInstance.close(data);
                }, function (err) {
                    console.log(err);
                    $scope.bidPlaced = false;
                    if (err && err.data && err.data.errors && err.data.errors[0] === 'Low balance') {
                        ntFlash.error(gettext('Недостаточно средств на счету'));
                    }
                });
        }
    };

    $scope.changeBid = function () {

        var bidData = $scope.prepareBid();

        if ($scope.isMultilotTender && !bidData.lotValues.length) {
            ntFlash.error(gettext('У багатолотовій закупівлі має бути хоча б одна пропозиція'));
            return;
        }
        if ($scope.bidAmountForm.$valid) {
            tenderBidService
                .edit(currentTender.data.cdb_id, data.currentBid.id, bidData)
                .then((data) => $uibModalInstance.close());
        }
    };

    $scope.prepareBid = function (isCreate) {
        var bidData = {
            value: {
                currency: $scope.currency,
                amount: $scope.isBidProposal ? 0 : $scope.bidAmount,
                valueAddedTaxIncluded: valueAddedTaxIncluded
            },
            parameters: [],
            lotValues: [],
            agree: $scope.agree.value
        };

        if($scope.procurementMethodType == "competitiveDialogueUA" || $scope.procurementMethodType == "competitiveDialogueEU"){

            delete bidData.parameters
        }

        if ($scope.procurementMethodType !== "belowThreshold" && isCreate) {
            bidData.selfEligible = $scope.agree.selfEligible;
            bidData.selfQualified = $scope.agree.selfQualified;
            if($scope.tender.funders) {
                bidData.items = $scope.tender.items
            }
        }





        if ($scope.tender.criteria && isCreate) delete bidData.selfEligible;

        if ($scope.procurementMethodType !== "belowThreshold" && !$scope.isMultilotTender) {
            bidData.subcontractingDetails = $scope.subcontractingDetails;
        }

        if ($scope.features) {
            $scope.features.forEach(function (feature) {
                if (feature.hasOwnProperty("selectedValue")) {
                    bidData.parameters.push({
                        code: feature.code,
                        value: feature.selectedValue
                    });
                }
            });
        }

        if ($scope.isEsco && !$scope.isMultilotTender) {
            delete bidData.value.amount;
            bidData.value.contractDuration = $scope.value.contractDuration;
            bidData.value.yearlyPaymentsPercentage = $scope.value.yearlyPaymentsPercentage / 100;
            bidData.value.annualCostsReduction = $scope.value.annualCostsReduction;
        }

        if ($scope.isMultilotTender) {
            delete bidData.value;
            $scope.lots.forEach(function (lot) {
                if (lot.lotValue) {
                    var lotValue = {
                        relatedLot: lot.id
                    };
                    if (!$scope.isEsco) {
                        lotValue.value = {
                            amount: $scope.isBidProposal ? 0 : lot.lotValue.amount,
                            currency: lot.lotValue.currency,
                            valueAddedTaxIncluded: lot.value.valueAddedTaxIncluded
                        };
                    }
                    if ($scope.procurementMethodType === "belowThreshold" && isCreate && $scope.tender.funders) {

                        bidData.items = lot.items.map((item) => {
                            return {
                                id: item.id,
                                description: item.description,
                                unit: {
                                    code: item.unit.code,
                                    value: {
                                        amount: item.unit.value.amount,
                                        currency: lot.lotValue.currency,
                                        valueAddedTaxIncluded: lot.value.valueAddedTaxIncluded
                                    },
                                },
                                quantity: item.quantity

                            }
                        })
                    }
                    if($scope.procurementMethodType == "competitiveDialogueUA" || $scope.procurementMethodType == "competitiveDialogueEU"){
                        delete lotValue.value
                    }
                    if ($scope.isEsco) {
                        lotValue.value = {
                            contractDuration: {
                                years: lot.value.contractDuration.years,
                                days: lot.value.contractDuration.days
                            },
                            // amount: 0,
                            yearlyPaymentsPercentage: lot.value.yearlyPaymentsPercentage / 100,
                            annualCostsReduction: lot.value.annualCostsReduction,

                            // Defaults for Esco ?
                            currency: 'UAH',
                            valueAddedTaxIncluded: true,
                        };
                    }
                    bidData.lotValues.push(lotValue);
                    bidData.lotValues[bidData.lotValues.length - 1].subcontractingDetails = lot.lotValue.subcontractingDetails;

                }
            });
        }
        // // TODO REmove for subcontractingDetails
        // if($scope.procurementMethodType === "belowThreshold"){
        //     const tempLotValues = [...bidData.lotValues]
        //     bidData.lotValues = []
        //     tempLotValues.forEach((el, index) => {
        //         delete el.subcontractingDetails
        //         bidData.lotValues.push(el)
        //     })
        // }
        // // TODO REmove for subcontractingDetails

        if($scope.isAgreementClose){
            bidData.parameters = $scope.bidDataParameters;
        }
        return bidData;
    };

    $scope.cancelBid = function () {
        tenderBidService
            .delete(currentTender.data.cdb_id, data.currentBid.id)
            .then(() => {
                $scope.changeMode = false;
                $uibModalInstance.close();
            });
    };

    $scope.close = function () {
        $uibModalInstance.dismiss();
    };
});
