class InputDuration {
    constructor(
        $scope,
        iso8601_duration,
    ) {
        this.duration = iso8601_duration.parse(this.ngModel || 'PT0S');
        $scope.change = () => {
            this.ngModel = iso8601_duration.toIso8601(this.duration);
        };
    }

}

tenderEdit.component('inputDuration', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/input-duration.html',
    controller: InputDuration,
    controllerAs: 'vm',
    bindings: {
        id: '@',
        label: '@',
        ngModel: '=',
        ngDisabled: '<',
        ngRequired: '<',
    }
});