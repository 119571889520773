class ToastMessage {
    constructor($mdToast) {
        this.$mdToast = $mdToast;
        this.options = {
            hideDelay   : 1500,
            position    : 'top right',
            controller: ($scope, $mdToast) => {
                $scope.close = () => $mdToast.hide();
            }
        };
        this.$mdToast.hide();
    }

    getOptions(type, message) {
        this.options.template = `
            <md-toast>
                 <div class="md-toast-content">
                    <div class="toast-text">
                        <div class="pull-right" ng-click="close()">x</div>
                        ${message}
                    </div>
                 </div>
                 <style>
                    .toast-text {
                        width: 100%;
                        font-size: 16px;
                    }
                    .toast-text .pull-right {
                        margin-left: 15px;
                        font-weight: bold;
                        cursor: pointer;
                    }
                    md-toast.toast-success>.md-toast-content{
                        background: forestgreen;
                    }
                    md-toast.toast-error>.md-toast-content {
                        background: red;
                    }
                 </style>
             </md-toast>
         `;
        this.options.toastClass = `toast-${type}`;
        return this.options;
    }

    success(message) {
        const options = this.getOptions('success', message);
        this.$mdToast.show(options);
    }
}
commons.service('toastMessage', ToastMessage);