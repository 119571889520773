commons.constant('REGIONS_LIST', [
    {id: 'AB', name: gettext('Винницкая область')},
    {id: 'AC', name: gettext('Волынская область')},
    {id: 'AE', name: gettext('Днепропетровская область')},
    {id: 'AH', name: gettext('Донецкая область')},
    {id: 'AM', name: gettext('Житомирская область')},
    {id: 'AO', name: gettext('Закарпатская область')},
    {id: 'AP', name: gettext('Запорожская область')},
    {id: 'AT', name: gettext('Ивано-Франковская область')},
    {id: 'AI', name: gettext('Киевская область')},
    {id: 'AA', name: gettext('Киев')},
    {id: 'BA', name: gettext('Кировоградская область')},
    {id: 'AK', name: gettext('Крым')},
    {id: 'BB', name: gettext('Луганская область')},
    {id: 'BC', name: gettext('Львовская область')},
    {id: 'BE', name: gettext('Николаевская область')},
    {id: 'BH', name: gettext('Одесская область')},
    {id: 'BI', name: gettext('Полтавская область')},
    {id: 'BK', name: gettext('Ровенская область')},
    {id: 'BM', name: gettext('Сумская область')},
    {id: 'BO', name: gettext('Тернопольская область')},
    {id: 'AX', name: gettext('Харьковская область')},
    {id: 'BT', name: gettext('Херсонская область')},
    {id: 'BX', name: gettext('Хмельницкая область')},
    {id: 'CA', name: gettext('Черкасская область')},
    {id: 'CE', name: gettext('Черновицкая область')},
    {id: 'CB', name: gettext('Черниговская область')}
]);