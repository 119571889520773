class newSubscriptionCtrl {
    constructor(
        loaderService,
        profileService,
        classifierService,
        ntFlash,
        REGIONS_LIST
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.classifierService = classifierService;
        this.ntFlash = ntFlash;
        this.regions = REGIONS_LIST;
        this.subscriptions = []
        this.allCunt = 0
        this.minimalCunt = 10
        this.resultCunt = 0
        this.previous = null
        this.next = null
        this.hidePagination = true

        this.sortType = [
            {type: 'created_date', name: "Created"},
            {type: 'egrpou',  name: "EDRPOU"},
            {type: 'region', name: "Region"},
            {type: 'max_budget', name: "Max Budget"},
            {type: 'min_budget', name: "Min budget"},
            {type: 'cpv_code', name: "CPV Code"},
            {type: 'dkpp_code', name: "Dkpp code"}
        ]
        this.getSubscriptionList()
    }

    getSubscriptionList(link = null){
        this.loader.startLoading()
        this.profileService.getUserSubscriptionRules(link)
            .then((response)=> {
                this.loader.stopLoading()
                this.subscriptions = response.data.results
                this.allCunt = response.data.count
                this.resultCunt = response.data.results.length
                this.next = response.data.next
                this.previous = response.data.previous
                this.hidePagination = (this.allCunt > this.minimalCunt)
                // console.log(response.data.results.length)
            })
    }

    // getSortType(type){
    //     console.log(this.sortType.filter((el)=> el.type === type).name)
    //    return  this.sortType.filter((el)=> el.type === type).name
    // }

    prevLink(){
        this.getSubscriptionList(this.previous)
    }

    nextLink(){
        this.getSubscriptionList(this.next)
    }

}

profileApp.controller('newSubscriptionCtrl', newSubscriptionCtrl);
