agreementModule.controller('agreementDocumentModalCtrl', function(
    $scope,
    $state,
    $timeout,
    $uibModal,
    $filter,
    $stateParams,
    $rootScope,
    $uibModalInstance,
    ntFlash,
    // tenderService,
    agreementInstanceService,
    documentsService,
    // awardsService,
    data
) {
    $scope.documentsService = documentsService;
    $scope.agreementInstanceService = agreementInstanceService;

    $scope.files = [];
    $scope.$uibModal = $uibModal;
    $scope.document = {
        documentOf: "agreement",
        relatedItem: "",
        documentType: ""
    };

    this.lastChangeId = data.lastChangeId;

    $scope.isAgreementDocument = data.isAgreementDocument;
    $scope.endAgreement = data.endAgreement;
    $scope.agreementId = $stateParams.id;

    $scope.save = (res) => {
        $uibModalInstance.close(res);
    };

    $scope.cancel = () => {
        $uibModalInstance.close();
    };

    $scope.$watch('file', (val) => {
        // if (this.file && this.file.length) {
        $scope.files = val;
        // }
    });

    $scope.deleteDoc = (index) => {
        $scope.files.splice(index, 1);
    };

    $scope.upload = () => {
        const uploadToDocumentService = (filesList) => {
            return new Promise((resolve) => {
                if (filesList.length) {
                    // $scope.showProgress = gettext('Завантажуються файли');
                    return $scope.documentsService.uploadToDocumentService(filesList)
                        .then((documents) => {
                            resolve(documents);
                        });
                }
                return resolve();
            });
        };

        var _lastChangeId = this.lastChangeId;

        try {
            uploadToDocumentService($scope.files)
                .then((documents) => {
                    if (documents.length) {
                        const requestBody = {
                            url: documents[0].url,
                            documentOf: 'agreement',
                            title: documents[0].title,
                            format: documents[0].format,
                            hash: documents[0].hash
                        };

                        $scope.agreementInstanceService.documentUpload($scope.agreementId, requestBody).then((res) => {
                            if (res.status === 200 && !$scope.isAgreementDocument) {
                                const requestBody = {
                                    "documentOf": "change",
                                    "relatedItem": _lastChangeId
                                };

                                $scope.agreementInstanceService.documentChange($scope.agreementId, res.data.data.id, requestBody).then((res) => {
                                    if (res.status === 200 && !$scope.isAgreementDocument) {
                                        $scope.save(res);
                                    }
                                });
                            } else {
                                $scope.save(res);
                            }
                        });
                    }

                })
                .catch((error) => {
                    console.log(error);
                });
        } catch (err) {
            console.log(err);
        }
    };

});