class AgreementSidebar {
    constructor(
        $scope,
        $stateParams,
        $rootScope,
        // ntFlash,
        agreementInstanceService
        // documentsService,
    ) {
        this.$scope = $scope;
        this.$rootScope = $rootScope;

        this.agreementId = $stateParams.id;
        this.agreementInstanceService = agreementInstanceService;

        this.changesArray = [];

        this.initAgreement();
    }

    $onInit() {}

    initAgreement() {
        try {
            this.agreementInstanceService.getAgreements(this.agreementId)
                .then((data) => {
                    this.agreement = data.response;
                    if (this.agreement.documents) {
                        const changeDocuments = this.agreement.documents.filter(el => el.documentOf === 'change');
                        changeDocuments.map((doc) => {
                            this.agreement.changes.map((change) => {
                                if (doc.relatedItem == change.id || change.status == 'active') {
                                    change._doc = doc;
                                    this.changesArray.push(change);
                                }
                            });
                        });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    dateFormat(date) {
        const d = new Date(date);
        return `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`;
    }

}

agreementModule.component('agreementSidebar', {
    templateUrl: '/static/scripts/modules/Agreements/components/AgreeSidebar/AgreeSidebar.html',
    controller: AgreementSidebar,
    controllerAs: 'vm',
    bindings: {
        resolve: '<',
        modalInstance: '<',
    },
});