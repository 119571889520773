tenderView.controller('tenderViewAuctionCtrl', function (
    $scope,
    $uibModal,
    $state,
    $filter,
    $timeout,
    $rootScope,
    ntFlash,
    tenderData,
    tenderService,
    contractService,
    tenderBidService,
    documentsService,
    awardsService,
    $mdDialog,
    $stateParams
) {

    var awardId = false,
        contractId = false,
        qualificationId = false,
        tenderId = tenderData.data.id;

    $scope.showContract = false;
    $scope.canAddAward = true;

    $scope.currentLotHasAwards = false;

    var awardBeneficiaryId;

    $scope.upload = {
        files: [],
        replaceFiles: [],
        qualificationFiles: [],
        beneficiaryFiles: [],
        replaceDocumentId: null,
        replace: function (document_id, award_id, contract_id) {
            $scope.upload.replaceDocumentId = document_id;
            $scope.upload.setContract(award_id, contract_id);
        },
        setAwardBeneficiaryId: function(id) {
            awardBeneficiaryId = id;
        },
        setQualificationId: function (id) {
            qualificationId = id;
        },
        setContract: function (award_id, contract_id) {
            awardId = award_id ? award_id : awardId;
            contractId = contract_id ? contract_id : contractId;
        },
        uploadContract: function (award_id, contract_id) {
            return $uibModal.open({
                templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.documents.upload.modal.html",
                controller: "tenderViewDocumentsUploadModalCtrl",
                resolve: {
                    data: function () {
                        return {
                            tenderId: $scope.tender.cdb_id,
                            awardId: award_id ? award_id : awardId,
                            contractId: contract_id ? contract_id : contractId,
                            procurementMethodType: $scope.tender.procurementMethodType,
                            actions: $scope.actions
                        };
                    }
                },
                backdrop: 'static'
            }).result.then($state.reload);
        }
    };

    $scope.visibleLots = {
        limit: 1,
        begin: '0'
    };

    if ($rootScope.auctionSelectedLot && $rootScope.auctionSelectedLot[tenderId]) {
        $scope.visibleLots.begin = $rootScope.auctionSelectedLot[tenderId];
    }

    $scope.tender = tenderData.data;
    $scope.actions = tenderData.action;

    $scope.lots = $scope.tender.lots && $scope.tender.lots.length ? $scope.tender.lots : [];
    $scope.qualifications = $scope.tender.qualifications && $scope.tender.qualifications.length ? $scope.tender.qualifications : [];
    $scope.cancellations = $scope.tender.cancellations && $scope.tender.cancellations.length ? $scope.tender.cancellations : [];

    $scope.awards = $scope.tender.awards && $scope.tender.awards.length ? $scope.tender.awards : [];
    const contracts = $scope.tender.contracts && $scope.tender.contracts.length ? $scope.tender.contracts : [];
    $scope.awards.forEach((award) => {
        award.contracts = contracts.filter(contract => contract.awardID === award.id);
    });

    console.log($scope.awards)


    $scope.cancellations.forEach(function (cancellation) {
        cancellation.documents = $filter('versionFilter')(cancellation.documents);
    });

    $scope.isMultilotTender = $scope.lots && $scope.lots.length ? true : false;
    $scope.hasFeatures = $scope.tender.features && $scope.tender.features.length ? true : false;

    $scope.documentStatus = $scope.tender.status === "complete" ?
        gettext("Договор подписан") :
        gettext("Проект договора");

    $scope.isPMT = (name) => tenderService.isProcurementMethodType($scope.tender, name);

    if ($scope.lots.length > 0) {
        $scope.activeLotId = (typeof $stateParams.lotId !== "undefined") ? $stateParams.lotId : $scope.lots[0].id;
    }

    $scope.setActiveTender = (index) => {
        $scope.visibleLots.begin = index;
        $scope.activeLotId = $scope.lots[index].id;
    };

    if ( $scope.visibleLots && $scope.visibleLots.begin && $scope.lots[$scope.visibleLots.begin] ) {
        $scope.setActiveTender($scope.visibleLots.begin);
    }

    if ($scope.isMultilotTender) {
        $scope.lots.forEach(function (lot) {
            lot.auctionDate = lot.auctionPeriod ?
                $filter('date')(lot.auctionPeriod.startDate, "dd MMMM yyyy HH:mm:ss") :
                gettext('Ожидается получение даты аукциона');

            lot.auctionUrl = lot.auctionUrl ? lot.auctionUrl : '';
            lot.actions = $scope.actions.lots ? $scope.actions.lots[lot.id] : angular.copy($scope.actions);
            if ($scope.tender.procurementMethodType === "negotiation" ||
                $scope.tender.procurementMethodType === "negotiation.quick") {
                let activeAwards = $scope.awards.filter(award => {
                    let complaintPeriod = award.complaintPeriod && +new Date(award.complaintPeriod.endDate);
                    let currentDate = +new Date();
                    return award.lotID === lot.id && award.status === 'active' && currentDate > complaintPeriod;
                });
                if (lot.actions) {
                    lot.actions.can_signing_contract = lot.status === 'active' && activeAwards.length > 0;
                }
            }
            lot.cancellations = [];
            $scope.cancellations.forEach(function (cancellation) {
                if (cancellation.relatedLot === lot.id && ['active', 'pending'].includes(cancellation.status)) {
                    lot.cancellations.push(cancellation);
                }
            });
        });
    } else {
        $scope.lots.push({
            id: null,
            status: $scope.tender.status,
            actions: $scope.actions,
            cancellations: $scope.cancellations,
            auctionUrl: $scope.tender.auctionUrl ? $scope.tender.auctionUrl : '',
            auctionDate: $scope.tender.auctionPeriod ?
                $filter('date')($scope.tender.auctionPeriod.startDate, "dd MMMM yyyy HH:mm:ss") : gettext('Ожидается получение даты аукциона')
        });
    }

    $scope.hasActiveAward = false;

    $scope.awards.forEach(function (award) {
        var nowDate = new Date();
        award.can_claim = false;

        if (($scope.actions.awards && $scope.actions.awards[award.id] && $scope.actions.awards[award.id].can_award_claim) ||
            ($scope.actions.awards && $scope.actions.awards[award.id] && $scope.actions.awards[award.id].can_award_complain)
        ) {
            award.can_claim = true;
        }

        if ($scope.tender.procurementMethod === 'limited' && !$scope.isMultilotTender) {
            if (award.status === 'active') {
                $scope.hasActiveAward = true;
                $scope.canAddAward = false;
                $scope.lots[0].showContract = true;
                if (award.contracts && award.contracts.length) {
                    $scope.lots[0].contractDocs = $filter('versionFilter')(award.contracts[0].documents);
                    awardId = award.contracts[0].awardID;
                    contractId = award.contracts[0].id;
                    $scope.lots[0].awardId = award.contracts[0].awardID;
                    $scope.lots[0].contractId = award.contracts[0].id;
                }
            } else if (award.status === 'pending') {
                $scope.canAddAward = false;
            }

        } else if ($scope.tender.procurementMethod === 'limited' && $scope.isMultilotTender) {
            if (award.status === 'active') {
                $scope.lots.forEach(lot => {
                    if (lot.id === award.lotID) {
                        lot.showContract = true;
                        lot.tenderWinner = award.suppliers[0].name;
                        lot.awardId = award.contracts[0].awardID;
                        lot.contractId = award.contracts[0].id;
                        lot.contractDocs = $filter('versionFilter')(award.contracts[0].documents);
                    }
                });
            }
        }

        award.showBeneficiaryUpload = $scope.tender.procurementMethodType === 'reporting' &&
                $scope.tender.procurementMethodRationale === 'COVID-19' &&
                award.status === 'pending' &&
                !(award.documents || []).some(doc => ['registerExtract', 'awardNotice'].includes(doc.documentType));
    });

    $scope.calculateCoefficient = function (parameters) {
        var coefficient = 1,
            totalSelectedVal = 0,
            totalMaxVal = 0;

        if (parameters && parameters.length) {
            parameters.forEach(function (parameter) {
                $scope.tender.features.forEach(function (feature) {
                    if (parameter.code === feature.code) {
                        var optionMaxVal = 0;
                        feature.enum.forEach(function (option) {
                            optionMaxVal = Number(option.value) > optionMaxVal ? Number(option.value) : optionMaxVal;
                        });
                        totalMaxVal += optionMaxVal;
                    }
                });
                totalSelectedVal += Number(parameter.value);
            });
        }

        coefficient += totalSelectedVal / (1 - totalMaxVal);

        return coefficient.toFixed(2);
    };

    if ($scope.actions.show_bids) {
        tenderBidService.get($scope.tenderCdbId)
            .then(function (data) {
                //computing list of bids statuses
                if (data) {
                    $scope.tenderBids = data;
                    $scope.tenderBids.forEach(function (bid) {
                        // console.log("bid.lotValues:", bid.lotValues);

                        bid.lotValues = bid.lotValues && bid.lotValues.length ? bid.lotValues : [];

                        if (!bid.lotValues.length) {
                            bid.lotValues.push({
                                relatedLot: null,
                                date: bid.date,
                                value: bid.value,
                                participationUrl: bid.participationUrl,
                                subcontractingDetails: bid.subcontractingDetails
                            });
                        }

                        if ($scope.tender.procurementMethodType === 'belowThreshold' ||
                            (
                                bid.status === "active" ||
                                bid.status === "validBid" ||
                                (bid.status === 'unsuccessful' && $scope.tender.status === 'complete')
                            )
                        ) {

                            bid.lotValues.forEach(function (lotValue) {
                                let activeAwards = [];

                                if ($scope.tender.awards) {

                                    $scope.tender.awards.forEach(function (award) {
                                        if (bid.id === award.bid_id && (lotValue.relatedLot === award.lotID || lotValue.relatedLot === null)) {
                                            lotValue.awardId = award.id;

                                            if (!$scope.isMultilotTender) {
                                                if ($scope.actions.awards && $scope.actions.awards[award.id] && $scope.actions.awards[award.id].can_award_claim) {
                                                    lotValue.can_claim = true;
                                                }
                                            } else {
                                                lotValue.can_claim = false;
                                                if (
                                                    // $scope.tender.procurementMethodType != 'belowThreshold' &&
                                                    !$scope.actions.tender_owner
                                                ) {
                                                    if (
                                                        ($scope.actions.awards && $scope.actions.awards[award.id] && $scope.actions.awards[award.id].can_award_claim) ||
                                                        ($scope.actions.awards && $scope.actions.awards[award.id] && $scope.actions.awards[award.id].can_award_complain)
                                                    ) {
                                                        lotValue.can_claim = true;
                                                    }
                                                }
                                            }

                                            lotValue.complaintPeriod = award.complaintPeriod;
                                            lotValue.awardDocuments = award.documents;
                                            switch (award.status) {
                                                case 'active':
                                                    lotValue._status = gettext('Предложение принято');
                                                    lotValue._type = 'active';
                                                    activeAwards.push(award);

                                                    break;
                                                case 'pending':
                                                    lotValue._status = gettext('Ожидает решения');
                                                    lotValue._type = 'pending';
                                                    break;
                                                case 'canceled':
                                                    lotValue._status = gettext('Отменен');
                                                    lotValue._type = 'canceled';
                                                    break;
                                                case 'unsuccessful':
                                                    lotValue._status = gettext('Отклонен');
                                                    lotValue._type = 'canceled';
                                                    break;

                                            }

                                        }
                                    });
                                }

                                if (!lotValue._status) {
                                    lotValue._status = gettext('Ожидает рассмотрения');
                                    lotValue._type = 'default';
                                }

                                if (!$scope.isPMT('closeFrameworkAgreementUA') || activeAwards.length === $scope.tender.maxAwardsCount) {
                                    activeAwards.forEach((award) => {
                                        $scope.lots.forEach(function (lot) {
                                            if (lot.id === award.lotID || lot.id === null) {
                                                lot.showContract = true;
                                                lot.tenderWinner = award.suppliers[0].name;
                                                lot.awardId = award.contracts[0].awardID;
                                                lot.contractId = award.contracts[0].id;
                                                lot.contractDocs = $filter('versionFilter')(award.contracts[0].documents);
                                            }
                                        });
                                    });

                                }

                                if (lotValue.participationUrl && lotValue.participationUrl !== "") {
                                    $scope.lots.forEach(function (lot) {
                                        if (lot.id === lotValue.relatedLot) {
                                            lot.participationUrl = lotValue.participationUrl;
                                        }
                                    });
                                }

                                if (bid.parameters && bid.parameters.length) {
                                    lotValue.parameters = [];
                                    $scope.tender.features.forEach(function (feature) {
                                        bid.parameters.forEach(function (parameter) {
                                            if (parameter.code === feature.code) {
                                                if (feature.featureOf === 'item') {
                                                    $scope.tender.items.forEach(function (item) {
                                                        if (item.relatedLot === lotValue.relatedLot || lotValue.relatedLot === null) {
                                                            lotValue.parameters.push(parameter);
                                                        }
                                                    });
                                                } else if (feature.featureOf === 'lot') {
                                                    if (feature.relatedItem === lotValue.relatedLot || lotValue.relatedLot === null) {
                                                        lotValue.parameters.push(parameter);
                                                    }
                                                } else {
                                                    lotValue.parameters.push(parameter);
                                                }
                                            }
                                        });
                                    });
                                    lotValue.totalPrice = lotValue.value ? lotValue.value.amount / $scope.calculateCoefficient(lotValue.parameters) : null;
                                } else {
                                    lotValue.totalPrice = lotValue.value ? lotValue.value.amount : null;
                                }
                            });
                        }
                    });
                }
            });
    } else if ($scope.isMultilotTender) {
        tenderBidService.get($scope.tenderCdbId, null, true)
            .then(function (data) {
                //computing lots participationUrl
                if (data && data.response.length) {
                    var tenderBids = data.response;
                    tenderBids.forEach(function (bid) {
                        if (!bid.status || bid.status === "active" || bid.status === "validBid") {
                            bid.lotValues.forEach(function (lotValue) {
                                if (lotValue.participationUrl && lotValue.participationUrl != "") {
                                    $scope.lots.forEach(function (lot) {
                                        if (lot.id == lotValue.relatedLot) {
                                            lot.participationUrl = lotValue.participationUrl;
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
    } else {
        tenderBidService.get($scope.tenderCdbId, null, true)
            .then(function (data) {
                //get participationUrl from bid
                var response = data.response;
                if (response.length) {
                    currentBid = response[response.length - 1];
                    $scope.lots[0].participationUrl = currentBid.participationUrl && currentBid.participationUrl != "" ? currentBid.participationUrl : "";
                }
            });
    }


    $scope.selectedFeatures = function (parameters) {
        var title = "";
        var lineNew = " \n";
        if (parameters && parameters.length > 0) {
            parameters.forEach(function (parameter) {
                $scope.tender.features.forEach(function (feature) {
                    if (feature.code === parameter.code) {
                        feature.enum.forEach(function (option) {
                            if (option.value == parameter.value) {
                                title += feature.title + ": " + option.title + " - " + option.value + lineNew;
                            }
                        });
                    }
                });
            });
        }
        return title;
    };

    $scope.makeComplaint = function (awardId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.complaint.modal.html",
            controller: "tenderViewComplaintModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tender.id,
                        awardId: awardId,
                        isQuestion: true,
                        isMultilotTender: false,
                        isBelowThreshold: $scope.isPMT('belowThreshold')
                    };
                }
            },
            backdrop: 'static'
        });
    };

    $scope.refreshList = function () {
        documentsService.getContractDocs({
                tenderId: $scope.tender.cdb_id,
                awardId: awardId,
                contractId: contractId
            })
            .then(function (data) {
                $scope.contractDocs = $filter('versionFilter')(data.response.contract);
            });
    };



    $scope.createAward = function () {
        return $mdDialog.show({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.auction.award.modal.html",
            controller: "tenderViewAuctionAwardModalCtrl",
            controllerAs: "vm",
            resolve: {
                data: function () {
                    return {
                        tender: $scope.tender,
                        tenderId: $scope.tender.id,
                        value: $scope.tender.value,
                        lot: $scope.isMultilotTender && $scope.lots[$scope.visibleLots.begin]
                    };
                }
            }
        }).then(() => $state.reload());
    };

    $scope.closeBids = function (award_id, contract_id) {
        $scope.upload.setContract(award_id, contract_id);
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.close-bids.modal.html",
            controller: "tenderViewCloseBidsModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tender: $scope.tender,
                        tenderId: $scope.tender.cdb_id,
                        awardId: award_id ? award_id : awardId,
                        contractId: contract_id ? contract_id : contractId
                    };
                }
            },
            backdrop: 'static'
        }).result.then(function () {
            $timeout(function () {
                $state.reload();
            }, 2000);
        });
    };

    $scope.checkOldContract = function (awardId){

        const currentAward = $scope.awards.find((award) => award.id === awardId )
        if(currentAward){
            return !!currentAward.contracts[0].items
        }

        return false
    }

    $scope.decide = function (id, status) {
        var isAccepted = status === 'active';
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.bids.modal.html",
            controller: "tenderViewBidsModalCtrl",
            resolve: {
                modalData: function () {
                    return {
                        procurementMethodType: $scope.tender.procurementMethodType,
                        procurementMethodRationale: $scope.tender.procurementMethodRationale,
                        isAccepted: isAccepted,
                        awardId: id,
                        status: status
                    };
                }
            },
            backdrop: 'static'
        });
    };

    $scope.showCustomer = function (organization) {
        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function () {
                    return organization;
                }
            },
            windowClass: 'center-modal'
        });
    };

    $scope.isQualificationComplete = () => {
        if ($scope.isPMT('closeFrameworkAgreementUA')) {
            if ($scope.actions.tender_owner &&
                $scope.tender.status === 'active.qualification' &&
                ($scope.tender.awards || []).filter((award) => (award.status === 'pending')).length === 0) {

                return true;
            }
        }
        return false;
    };

    $scope.finishQualifications = function () {
        tenderService.edit($scope.tender.id, {
                status: "active.qualification.stand-still"
            })
            .then(function () {
                ntFlash.success(gettext('Квалификация успешно завершена'));
                $timeout(function () {
                    $state.reload();
                }, 2000);
            });
    };

    $scope.$watch('upload.beneficiaryFiles', function (val) {
        if ($scope.upload.beneficiaryFiles && $scope.upload.beneficiaryFiles.length) {
            documentsService.save($scope.upload.beneficiaryFiles[0], $scope.tender.cdb_id, {awardId: awardBeneficiaryId})
                .then(function(response) {
                    documentsService.edit($scope.tender.cdb_id, response.data.data.id, {awardId: awardBeneficiaryId}, {documentType: 'awardNotice'})
                        .then(function(response) {
                            ntFlash.success(gettext('Повідомлення про бенефіціарів завантажено'));
                            $timeout(function () {
                                $state.reload();
                            }, 1000);
                        })
                        .catch(function(error) {
                            console.log(error);
                            ntFlash.error(gettext('Помилка при зміні типу документу Повідомлення про бенефіціарів'));
                        });
                    
                })
                .catch(function(error) {
                    console.log(error);
                    ntFlash.error(gettext('Помилка при завантаженні Повідомлення про бенефіціарів'));
                });
        }
    });

    $scope.$watch('upload.qualificationFiles', function (val) {
        if ($scope.upload.qualificationFiles && $scope.upload.qualificationFiles.length) {
            documentsService.save($scope.upload.qualificationFiles[0], $scope.tender.cdb_id, {
                    qualificationId: qualificationId
                })
                .success(function () {
                    $state.reload();
                });
        }
    });

    $scope.$watch('upload.files', function (val) {
        if ($scope.upload.files && $scope.upload.files.length) {
            documentsService.save($scope.upload.files[0], $scope.tender.cdb_id, {
                    awardId: awardId,
                    contractId: contractId
                })
                .success(function () {
                    $scope.refreshList();
                });
        }
    });

    $scope.$watch('upload.replaceFiles', function (val) {
        if ($scope.upload.replaceFiles && $scope.upload.replaceFiles.length && awardId !== false) {
            documentsService.replace($scope.upload.replaceFiles[0], $scope.tender.cdb_id, $scope.upload.replaceDocumentId, {
                    awardId: awardId,
                    contractId: contractId
                })
                .success(function () {
                    $scope.refreshList();
                });
        }
    });

    $scope.$watch('visibleLots.begin', function (lot) {
        if ($rootScope.auctionSelectedLot) {
            $rootScope.auctionSelectedLot[tenderId] = lot;
        } else {
            $rootScope.auctionSelectedLot = {};
            $rootScope.auctionSelectedLot[tenderId] = lot;
        }
        $scope.currentLotHasAwards = $scope.awards.filter(award => {
            return award.lotID === $scope.lots[lot].id && (award.status === 'active' || award.status === 'pending');
        }).length > 0;
    });

    $scope.sign = function (qualification_id, documents, award_id, contract_id) {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            tenderId: $scope.tender.id,
                            awardId: award_id === 'local' ? awardId : award_id,
                            contractId: contract_id === 'local' ? contractId : contract_id,
                            qualificationId: qualification_id,
                            documents: documents || (documents = [])
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                tenderId: $scope.tender.id,
                awardId: award_id === 'local' ? awardId : award_id,
                contractId: contract_id === 'local' ? contractId : contract_id,
                qualificationId: qualification_id,
                documents: documents || (documents = [])
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };
});
