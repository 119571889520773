agreementModule.controller('agreementTerminateModalCtrl', function(
    $scope,
    $uibModal,
    $rootScope,
    agreementInstanceService,
    $uibModalInstance,
    data,
    documentsService
) {
    $scope.$uibModal = $uibModal;
    $scope.agreementInstanceService = agreementInstanceService;

    $scope.isStop = data.isStop;
    $scope.agreementId = data.id;
    $scope.agreement = data.agreement;
    $scope.title = data.modalTitle;

    $scope.data = {
        status: 'terminated',
        terminationDetails: ''
    };

    $scope.close = (res) => {
        $uibModalInstance.close(res);
    };

    $scope.uploadDocuments = () => {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementDocumentModal.html",
            controller: "agreementDocumentModalCtrl",
            resolve: {
                data: function() {
                    return {
                        endAgreement: true,
                        isAgreementDocument: true
                    };
                }
            },

        });
    };

    $scope.setChanges = () => {
        const body = {
            status: $scope.data.status
        };

        if ($scope.isStop) {
            body['terminationDetails'] = $scope.data.terminationDetails;
        }

        // $scope.$uibModal.open({
        //     templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
        //     controller: 'eSignModalCtrl',
        //     resolve: {
        //         data: () => {
        //             const doc = $scope.agreement.documents;
        //             return {
        //                 agreementInstanceId: $scope.agreementId,
        //                 documents: doc
        //             };
        //         }
        //     },
        //     windowClass: 'center-modal'
        // })
        //     .result
        //     .then((res) => {
        //         if (res) {
        //             $scope.agreementInstanceService.endAgreement($scope.agreementId, body).then((res) => {
        //                 if (res.status === 200) {
        //                     $scope.close(res);
        //                 } else {
        //                     console.error(res);
        //                 }
        //             });
        //         }
        //     });
        //
        // const doc = $scope.agreement.documents;
        // const data ={
        //     agreementInstanceId: $scope.agreementId,
        //     documents: doc
        // };

        documentsService.sign(data)
            .then((res) => {
                console.log('documentsService.sign data', res);
                if (res) {
                    $scope.agreementInstanceService.endAgreement($scope.agreementId, body).then((res) => {
                        if (res.status === 200) {
                            $scope.close(res);
                        } else {
                            console.error(res);
                        }
                    });
                }
            })
            .catch(error => console.log('documentsService.sign error', error));


    };

});