tendersList.controller('tendersCtrl', function (
    $scope,
    tenderListService,
    $state,
    $stateParams,
    $rootScope,
    $timeout,
    $filter,
    temporaryData,
    tendersSearchService,
    tenderStatesListService,
    profileService,
    companyService,
    ntFlash,
    exchangeService,
    seoService,
    searchBookmarksService,
    $mdDateLocale,
    REGIONS_LIST,
    DATETIME_FROMAT,
    $element
) {

    seoService.setTitle(gettext('Государственные закупки, тендеры Прозоро на Newtend'));
    seoService.setMetaDescription(gettext('Публичные электронные закупки Prozorro. Все государственные тендеры системы публичных электронных закупок на Ньютенд'));

    $scope.isTestMode = !window.location.hostname.includes('b2b');
    // console.log("$scope.isTestMode", $scope.isTestMode);

    $scope.requestInProgress = true;
    $scope.hidePagination = true;
    $scope.itemsPerPage = 10;
    $scope.itemsPerSeacrhPage = 10;
    $scope.user = profileService;
    $scope.isSearchFieldFocused = false;
    $scope.isGovernment = false;
    $scope.selectedBookmark = null;

    $scope.searchTerm;
    $element.find('input.select_input_field').on('keydown', function(ev) {
        ev.stopPropagation();
    });

    if (!$rootScope.isAnonymous) {
        profileService.getCompany()
            .then((res) => {
                $scope.isGovernment = res.data ? res.data.is_government : '';
            });
    }

    var currentPage = $state.params.pageNum,
        cpvFiltersList = $state.params.cpv || '',
        dkppFiltersList = $state.params.dkpp || '',
        innFiltersList = $state.params.inn || '',
        isSearchMode = $state.params.list === 'search',
        filterType = $filter('tendersListType')($state.params.status),
        filterUser = $filter('tendersListType')($state.params.list),
        bookmarkFilter = $state.params.bookmark == 'true',
        archiveFilter = $state.params.i == 'archive';

    $scope.isStatusLocalDraft = $state.params.status === 'local_draft' || $state.params.status === 'draft';

    $scope.classifiers = isSearchMode ?
        temporaryData.get('searchableClassifiers') || [] :
        temporaryData.set('searchableClassifiers', []);

    $scope.searchList = {
        statusesAmount: 0,
        procurementMethodTypesAmount: 0,
        statuses: tenderStatesListService.getStatusesList(),
        procurementMethodTypes: tenderStatesListService.getProcurementMethodTypesList(),
        tenderOwners: tenderStatesListService.getTenderOwners(),
        regions: REGIONS_LIST
    };

    exchangeService.getExchangeRate()
        .then(function (result) {
            $scope.exchangeRates = result.response;
        });

    $scope.dateEdit = function (date) {
        // const d = new Date(date);
        // const m = ('0' + (d.getMonth() + 1)).slice(-2);
        // const day = ('0' + d.getDate()).slice(-2);
        // return d.getFullYear() + '-' + m + '-' + day;
        return $filter('date')(date, 'yyyy-MM-ddTHH:mm:ss');
    };

    $scope.statuses = $state.params.status ? $state.params.status.split(',') : [];
    $scope.PMTypes = $state.params.procurementMethodType ? $state.params.procurementMethodType.split(',') : [];
    $scope.regions = $state.params.region ? $state.params.region.split(',') : [];

    // Format date for md-datepicker
    $mdDateLocale.formatDate = date => {
        var res = $filter('date')(date, "yyyy/MM/dd");
        return res;
    };
    $scope.create_gte = $state.params.create_gte ? $state.params.create_gte : null;
    $scope.create_lte = $state.params.create_lte ? $state.params.create_lte : null;
    $scope.tp_gte = $state.params.tp_gte ? $state.params.tp_gte : null;
    $scope.tp_lte = $state.params.tp_lte ? $state.params.tp_lte : null;
    $scope.ap_gte = $state.params.ap_gte ? $state.params.ap_gte : null;
    $scope.ap_lte = $state.params.ap_lte ? $state.params.ap_lte : null;

    let complaints_statuses = 'answered,pending,invalid,declined,resolved,cancelled';

    $scope.searchData = {
        list: 'search',
        pageNum: $state.params.pageNum,
        query: isSearchMode ? $state.params.query : '',
        tendersOnlyOf: isSearchMode ? $state.params.tendersOnlyOf : filterUser,
        status: isSearchMode ? $state.params.status : '',
        procurementMethodType: isSearchMode ? $state.params.procurementMethodType : '',
        region: isSearchMode ? $state.params.region : '',
        cpv: $state.params.cpv || '',
        dkpp: $state.params.dkpp || '',
        inn: $state.params.inn || '',
        scpv: $state.params.scpv ? $state.params.scpv : '',
        amount_gte: $state.params.amount_gte ? +$state.params.amount_gte : '',
        amount_lte: $state.params.amount_lte ? +$state.params.amount_lte : '',
        funders: $state.params.funders ? !!$state.params.funders : '',
        fund_name: $state.params.fund_name ? $state.params.fund_name : '',
        i: $state.params.i ? $state.params.i : '',
        complaints_checked: $state.params.complaints_checked ? !!$state.params.complaints_checked : '',
        cs: $state.params.complaints_checked ? complaints_statuses : '',
        create_gte: $state.params.create_gte ? $scope.dateEdit($state.params.create_gte) : '',
        create_lte: $state.params.create_lte ? $scope.dateEdit($state.params.create_lte) : '',
        tp_gte: $state.params.tp_gte ? $scope.dateEdit($state.params.tp_gte) : '',
        tp_lte: $state.params.tp_lte ? $scope.dateEdit($state.params.tp_lte) : '',
        ap_gte: $state.params.ap_gte ? $scope.dateEdit($state.params.ap_gte) : '',
        ap_lte: $state.params.ap_lte ? $scope.dateEdit($state.params.ap_lte) : '',
        covid: $state.params.covid ? !!$state.params.covid : '',
        edrpou_buyer: $state.params.edrpou_buyer ? $state.params.edrpou_buyer : '',
        edrpou_supplier: $state.params.edrpou_supplier ? $state.params.edrpou_supplier : '',
        bookmark: $state.params.bookmark ? Number($state.params.bookmark) : '',
        city: $state.params.city ? $state.params.city : ''
    };
    $scope.selectedBookmark =  $state.params.bookmark ? Number($state.params.bookmark) : '',

    console.log($scope.searchData)
    console.log($state.params)
    console.log(isSearchMode)
    console.log(bookmarkFilter)
    console.log(archiveFilter)

    var request = isSearchMode ? tendersSearchService.get($scope.searchData) :
        bookmarkFilter ? tenderListService.getBookmarked(currentPage) :
        archiveFilter ? tenderListService.getArchived(currentPage) :
        tenderListService.get({
            page: currentPage,
            typeFilter: filterType,
            userFilter: filterUser
        });

    request.then(resultHandling);

    function updateSearchData() {

        $scope.searchData.status = $scope.statuses.join(',');
        $scope.searchData.procurementMethodType = $scope.PMTypes.join(',');
        $scope.searchData.region = $scope.regions.join(',');

        $scope.searchData.create_gte = $scope.create_gte ? $scope.dateEdit($scope.create_gte) : '';
        $scope.searchData.create_lte = $scope.create_lte ? $scope.dateEdit($scope.create_lte) : '';
        $scope.searchData.tp_gte = $scope.tp_gte ? $scope.dateEdit($scope.tp_gte) : '';
        $scope.searchData.tp_lte = $scope.tp_lte ? $scope.dateEdit($scope.tp_lte) : '';
        $scope.searchData.ap_gte = $scope.ap_gte ? $scope.dateEdit($scope.ap_gte) : '';
        $scope.searchData.ap_lte = $scope.ap_lte ? $scope.dateEdit($scope.ap_lte) : '';
        $scope.searchData.pageNum = '1';

        if ($scope.searchData.complaints_checked) {
            $scope.searchData.cs = complaints_statuses;
        } else {
            delete($scope.searchData.complaints_checked);
            $scope.searchData.cs = '';
        }

        if (!$scope.searchData.funders) {
            delete($scope.searchData.funders);
            delete($scope.searchData.fund_name);
        }

        if (!$scope.searchData.covid) delete($scope.searchData.covid)
    }

    $scope.search = function () {
        updateSearchData();
        console.log($scope.searchData)

        $state.go('tendersList.type', $scope.searchData, {
            reload: true,
            inherit: false
        });
        $scope.searchData.createReport = '0';
    };

    $scope.clearSearchData = function () {
        $scope.classifiers = [];
        $scope.PMTypes = [];
        $scope.statuses = [];
        temporaryData.set('searchableClassifiers', $scope.classifiers);
        cpvFiltersList = '';
        dkppFiltersList = '';
        innFiltersList = '';
        $state.go('tendersList.type', {
            pageNum: 1,
            list: 'home',
            tendersOnlyOf: '',
            status: '',
            procurementMethodType: '',
            cpv: '',
            dkpp: '',
            inn: '',
            region: '',
            amount_gte: '',
            amount_lte: '',
            scpv: '',
            funders: '',
            fund_name: '',
            cs: '',
            complaints_checked: '',
            create_gte: '',
            create_lte: '',
            tp_gte: '',
            tp_lte: '',
            ap_gte: '',
            ap_lte: '',
            covid: '',
            edrpou_buyer: '',
            edrpou_supplier: '',
            city: '',
            bookmark: '',
        }, {
            reload: true
        });
    };

    $scope.createReport = function () {
        if ($rootScope.isAnonymous) {
            ntFlash.error(gettext('Для доступу до даного функціоналу необхідно авторизуватись!'));
            return;
        }
        updateSearchData();
        $scope.searchData.createReport = '1';
        ntFlash.success(gettext('Отчет будет доступен в профиле в разделе Отчеты') + ' <a href="/opc/provider/profile/reports" target="_blank">перейти ...</a>');
        tendersSearchService.get($scope.searchData);
    };

    // $scope.canCreateReport = function () {
    //     return !$rootScope.isAnonymous && isSearchMode;
    // };

    /**
     * Create filtered request and draw list of classifiers.
     * @param event
     * @param classifier - selected classifiers list
     * @param type - 'cpv' or 'dkpp'
     */

    $scope.openClassifier = function(classifiersName) {
        let currentState = $state.current.name;
        let slassifierScheme;
        switch (classifiersName) {
            case 'cpv': slassifierScheme = 'ДК021'; break;
            case 'dkpp': slassifierScheme = 'ДКПП'; break;
            case 'inn': slassifierScheme = 'INN'; break;
        }
        let addClasificatorsDestroy = $rootScope.$on('addClasificators',  function(event, classifier, type) {
            let preClassifiers = $scope.classifiers.filter(el => el.scheme !== slassifierScheme);
            $scope.classifiers = preClassifiers;
            (classifier || []).forEach(el => {
                $scope.classifiers.push(el)
            });
            
            temporaryData.set('searchableClassifiers', $scope.classifiers);

            cpvFiltersList = '';
            dkppFiltersList = '';
            innFiltersList = '';

            $scope.classifiers.forEach(function (val) {
                switch (val.scheme) {
                    case 'ДК021': cpvFiltersList += val.code + ',';
                                  break;
                    case 'ДКПП':  dkppFiltersList += val.code + ',';
                                  break;
                    case 'INN':   innFiltersList += val.code + ',';
                                  break;
                }
            });

            cpvFiltersList = cpvFiltersList.substring(0, cpvFiltersList.length - 1);
            dkppFiltersList = dkppFiltersList.substring(0, dkppFiltersList.length - 1);
            innFiltersList = innFiltersList.substring(0, innFiltersList.length - 1);

            $scope.searchData.cpv = cpvFiltersList;
            $scope.searchData.dkpp = dkppFiltersList;
            $scope.searchData.inn = innFiltersList;

            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        let closeClasificatorsDestroy = $rootScope.$on('closeClasificators', function () {
            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        $state.go(`${currentState}.${classifiersName}`);
    }

    /**
     * Formation data and draw some widgets after init request.
     * @param result
     */

    function resultHandling(result) {
        var itemsPerPage;

        if (isSearchMode) {
            $scope.tenders = result.data;
            $scope.pagerItemsPerPage = $scope.tenders.length;
            $scope.totalPages = result.total_pages;
            $scope.totalItems = result.total_items;
            itemsPerPage = $scope.itemsPerSeacrhPage;
        } else {
            $scope.totalPages = result.response.total_pages;
            $scope.totalItems = result.response.total_items;

            $scope.tenders = result.response.data;
            itemsPerPage = $scope.itemsPerPage;
        }

        /**
         * Function is hiding pagination widget.
         * @returns {Boolean}
         */

        $scope.hidePagination = (function () {
            return Boolean($scope.totalItems <= $scope.itemsPerPage);
        })();

        /**
         * Function used by full pagination widget, for displaying 'pages 10-19 из 4754' string.
         * @returns {string}
         */

        $scope.paginationLabel = (function () {
            var startAt = (currentPage > 1) ?
                ((currentPage - 1) * $scope.itemsPerPage) + 1 : 1;

            var endAt = ((startAt + $scope.itemsPerPage - 1) <= $scope.totalItems) ?
                startAt + $scope.itemsPerPage - 1 : startAt + ($scope.totalItems - startAt);

            return startAt.toString() + "-" + endAt.toString() + ' ' + gettext('из') + ' ' + $scope.totalItems;
        })();

        temporaryData.set('paginationLabel', $scope.paginationLabel);

        $scope.requestInProgress = false;
        $scope.currentPage = currentPage;
        $scope.prevPage = Number(currentPage) <= 1 ? '1' : (currentPage - 1).toString();
        $scope.nextPage = $scope.pagerItemsPerPage < itemsPerPage ? $scope.currentPage.toString() : (Number(currentPage) + 1).toString();
    }

    $scope.choosePrivacy = function () {
        if ($scope.searchData.bidderOnly) {
            $state.params.bidderOnly = $scope.searchData.bidderOnly.toString();
        } else if ($state.params.userOnly) {
            $state.params.userOnly = $scope.searchData.userOnly.toString();
        }
    };

    // $scope.deleteFilter = function (index) {
    //     $scope.classifiers.splice(index, 1);
    //     addClassifier(null, [], null);
    // };

    $scope.setIsSearchFieldFocused = (val) => {
        $timeout(() => $scope.isSearchFieldFocused = val, 200);
    };

    $scope.showSearchBookmarks = !$rootScope.isAnonymous;

    if ($scope.showSearchBookmarks) {
        getNewBookmarkName = (n) => {
            n = n > 0 ? n : 1;
            return gettext("Мой поиск") + " # " + n;
        };
        $scope.hasSavedBookmarks = false;
        $scope.newBookmarkName = getNewBookmarkName(1);

        searchBookmarksService.getList()
            .then((bookmarksList) => {

                $scope.bookmarksList = bookmarksList;
                $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount + 1);
            });

        $scope.deleteBookmark = (bookmarkId) => {
            if (bookmarkId) {
                searchBookmarksService.delete(bookmarkId)
                    .then(() => {
                        searchBookmarksService.getList()
                            .then((bookmarksList) => {
                                $scope.bookmarksList = bookmarksList;
                                $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount);
                                $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                                $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                            });
                    });
            }
        };
        $scope.getBookmarkName = () => {
            console.log($scope.selectedBookmark)

            if($scope.selectedBookmark && $scope.bookmarksList) {
                const current = $scope.bookmarksList.find((id) => id.id === $scope.selectedBookmark  )

                return (current)? current.name : 'Всі'
            }

            return  'Всі'
        }

        let getBookmarkData = (id) => {
            return $scope.bookmarksList.find(bookmark => bookmark.id === id);
        };

        $scope.setBookmarkSearchData = (bookmarkId) => {
            $scope.showOptions = true;
            let bookmarkData = getBookmarkData(bookmarkId);
            if (bookmarkData && bookmarkData.flags) {
                let params = bookmarkData.flags;
                $scope.searchData = bookmarkData.flags;
                $scope.searchData.bookmark = bookmarkId;
                $scope.statuses = params.status ? params.status.split(',') : [];
                $scope.PMTypes = params.procurementMethodType ? params.procurementMethodType.split(',') : [];
                $scope.classifiers = params.cpv ? params.cpv.split(',').map(code=>{ return {code:code,name:''}; }) : [];
                $scope.regions = params.region ? params.region.split(',') : [];
                $scope.create_gte = params.create_gte ? params.create_gte : null;
                $scope.create_lte = params.create_lte ? params.create_lte : null;
                $scope.tp_gte = params.tp_gte ? params.tp_gte : null;
                $scope.tp_lte = params.tp_lte ? params.tp_lte : null;
                $scope.ap_gte = params.ap_gte ? params.ap_gte : null;
                $scope.ap_lte = params.ap_lte ? params.ap_lte : null;
                $scope.ap_lte = params.ap_lte ? params.ap_lte : null;
                temporaryData.set('searchableClassifiers', $scope.classifiers);
            }
        };

        $scope.saveSearchBookmark = () => {
            updateSearchData();
            $scope.bookmarksCount++;
            searchBookmarksService.save($scope.searchData, $scope.newBookmarkName)
                .then(() => {
                    searchBookmarksService.getList()
                        .then((bookmarksList) => {
                            $scope.bookmarksList = bookmarksList;
                            $scope.newBookmarkName = getNewBookmarkName($scope.bookmarksCount + 1);
                            ntFlash.success(gettext('Поиск сохранен') + ': ' + ($scope.bookmarksCount));
                            $scope.bookmarksCount = bookmarksList.length > 0 ? bookmarksList.length : 0;
                            $scope.hasSavedBookmarks = $scope.bookmarksCount > 0;
                        });
                });
        };
    }
    $scope.closeSelectBox = () => {
        $("md-backdrop").trigger ("click");
    };

    $scope.setTime = function(date, isStart) {
        if (date) {
            if (isStart) date.setHours(0, 0, 0, 0);
            else date.setHours(23, 59, 59);
        }
    }

    $scope.getCompanies = function(nameOrId, type) {
        return companyService.getPublicCompanies({nameOrId, type, pageSize: 50})
            .then(response => response.results);
    };
});
