class NotificationService {
    constructor(
        http,
        API_BASE_URL
    ) {
        this.http = http;
        this.requestUrl = `${API_BASE_URL}/notifications/`;
        this.count = 0;
        this.last = [];
    }

    getById(id) {
        let url = `${this.requestUrl}${id}/`;
        return this.http.get(url, true);
    }

    getList(params) {
        let url = `${this.requestUrl}?page=${params.page}`;
        return this.http.get(url, true);
    }

    setNotificationRead(notification) {
        let url = `${this.requestUrl}${notification.id}/read/`;
        return this.http.post(url, {}, true);
    }

    countNotReadedNotifications() {
        let url = `${this.requestUrl}?is_read=true`;
        this.http
            .get(url, true)
            .then(res => this.count = res.data.count);
    }

    setAllRead() {
        let url = `${this.requestUrl}set_read/`;
        return this.http.put(url, {}, true);
    }

    getNotificationTypes() {
        const url = `${this.requestUrl}kinds/`;
        return this.http.get(url, true)
            .then(result => result.data);
    }
}

notificationsModule.service('notificationService', NotificationService);