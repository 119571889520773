tenderView.controller('tenderViewAgreementsContractCtrl', function(
    $scope,
    $uibModal,
    $filter,
    $timeout,
    $state,
    $stateParams,
    $rootScope,
    ntFlash,
    tenderData,
    agreementService,
    tenderBidService,
    documentsService,
) {
    let contractId = $stateParams.contractId;
    $scope.tender = tenderData.data;
    $scope.agreement = $scope.tender.agreements[0];
    $scope.replaceFiles = {
        replaceId: [],
        replaceFile: [],
        setReplaceId: function(id) {
            $scope.replaceFiles.replaceId = id;
        }
    };
    if (!$scope.agreement) return;

    $scope.contract = $scope.agreement.contracts.filter((c) => (c.id === contractId))[0];

    $scope.bidId = null;
    $scope.bid = null;
    if (!tenderData.action.tender_owner) {
        tenderBidService
            .get($scope.tender.id, null, true)
            .then((data) => {
                if (data.response.length) {
                    let bid = data.response[data.response.length - 1];
                    if ($scope.contract.bidID === bid.id) {
                        $scope.bidId = bid.id;
                        $scope.bid = bid;
                    }
                }
            });
    }

    $scope.$watch('replaceFiles.replaceFile', function() {
        if ($scope.replaceFiles.replaceFile && $scope.replaceFiles.replaceFile.length) {
            let request = documentsService.replace($scope.replaceFiles.replaceFile[0], $scope.tender.id, $scope.replaceFiles.replaceId, {
                bidId: $scope.bid.id,
                documentsType: 'financialDocuments'
            })
            request
                .then(function(response) {
                    if (response) {
                        ntFlash.success(gettext('Документ успешно заменен'));
                        $state.reload();
                    }
                })
                .catch(function () {
                    ntFlash.error(gettext('Помилка при завантаженні документа.'));
                });
        }
    });

    let bidsTender = $scope.tender.bids.find((b) => (b.id === $scope.contract.bidID));
    $scope.bidFinancialDocuments = bidsTender.length > 0 ? bidsTender.financialDocuments : null || [];


    $scope.bidFinancialDocuments = (tenderData.bid && tenderData.bid.id === $scope.contract.bidID) && tenderData.bid.financialDocuments ?
        tenderData.bid.financialDocuments :  bidsTender.financialDocuments ?
            bidsTender.financialDocuments : null || [];



    function listToObject(items) {
        let result = {};
        for (let i=0; i < items.length; i++) {
            result[items[i].id] = items[i];
        }
        return result;
    }

    $scope.items = listToObject($scope.tender.items);  // Mapping items
    $scope.bids = listToObject($scope.tender.bids);  // Mapping bids
    $scope.awards = listToObject($scope.tender.awards);  // Mapping awards

    // console.log('items: ', $scope.items);

    let amounts = {};
    for (let i=0; i < $scope.contract.unitPrices.length; i++) {
        amounts[$scope.contract.unitPrices[i].relatedItem] = $scope.contract.unitPrices[i].value.amount;
    }
    $scope.amounts = amounts;

    if ($scope.tender.contractPeriod && $scope.tender.contractPeriod.clarificationsUntil) {
        let clarificationsUntil = new Date($scope.tender.contractPeriod.clarificationsUntil);
        if (clarificationsUntil < new Date()) $scope.hideBtnUploadDocuments = true;
    }

    $scope.isDisableSaveBtn = () => {
        for(let i=0; i < $scope.contract.unitPrices.length; i++) {
            let u = $scope.contract.unitPrices[i];
            if (u.value.amount != $scope.amounts[u.relatedItem]) return false;
}
        return true;
    };

    $scope.changeUnitPrice = function() {
        let unitPrices = $scope.contract.unitPrices.map((u) => {
            u.value.amount = $scope.amounts[u.relatedItem] || 0;
            return u;
        });

        agreementService.contractPatch({
            tenderId: $scope.tender.id,
            agreementId: $scope.agreement.id,
            contractId: $scope.contract.id,
        }, {
            unitPrices: unitPrices
        }).then((() => {
            $state.reload();
            ntFlash.success(gettext('Цена успешно изменена'));
        }));
    };

    $scope.setStatus = function(status) {
        agreementService.contractPatch({
            tenderId: $scope.tender.id,
            agreementId: $scope.agreement.id,
            contractId: $scope.contract.id,
        }, {
            status: status
        }).then((() => {
            $state.reload();
            if (status === 'unsuccessful'){
                ntFlash.success(gettext('Участник отклонен'));
            } else {
                ntFlash.success(gettext('Участник принят'));
            }
        }));
    };

    $scope.$watch('file', function(files) {
        if (files && files.length > 0) {
            $scope.progressUpload = gettext('Завантажуються файли');

            Promise.all(files.map(file => {
                documentsService.save(
                    file, $scope.tender.id, {
                        bidId: $scope.bidId,
                        documentsType: 'financialDocuments',
                    })
            })).then(()=>{
                $scope.progressUpload = gettext('Документы загружены');
                ntFlash.success($scope.progressUpload);
                $timeout(() => {
                    $state.reload();
                }, 2000);
            }).catch((e) => {
                console.error(e);
                $scope.progressUpload = gettext('Ошибка при загрузке документов');
                ntFlash.error($scope.progressUpload);
            });
        }
    });

    const signBidSplitedValue = [
        'aboveThresholdEU',
        'competitiveDialogueUA',
        'competitiveDialogueEU',
        'competitiveDialogueEU.stage2',
        'esco',
        'closeFrameworkAgreementUA'
    ];

    $scope.signBid = function () {
            const data = {
                bid: $scope.bid,
                tenderId: $scope.tender.id,
                documents: $scope.bid.documents || ($scope.bid.documents = []),
                financialDocuments: signBidSplitedValue.includes($scope.tender.procurementMethodType)
                    ? $scope.bid.financialDocuments || ($scope.bid.financialDocuments = [])
                    : null,
                documentsType: 'financial_documents',
                skipPublic: true
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));

    };

});
