commons.filter('monitoringEliminationResultByType', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'eliminated':
                return gettext('так');  // eliminated

            case 'not_eliminated':
                return gettext('ні');  // not eliminated

            case 'no_mechanismd':
                return gettext('відсутній механізм');  // no mechanism

            default:
                return value;
        }
    }
});