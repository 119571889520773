var options = {
    /* {url} full address of object in API */
    apiResourceUrl: "https://lb.api-sandbox.openprocurement.org/api/0.11/tenders/b64001cdaa1540e7a17c14d0207e3feb",
    /* {string} element id (jquery) to render html */
    placeholderId: "#signPlaceholder",
    /* {boolean} verify signature on start, if exist */
    verifySignOnInit: true,
    /* {boolean} if verification error, allow sign whatever */
    ignoreVerifyError: true,
    /* callback obtaining json from API  */
    callbackRender: "renderJson",

    /* callback after put sign */
    callbackPostSign: "postSign",
    /* callback after init all libs */
    callbackOnInit: "onInit",
    /* callback before init all libs */
    callbackBeforeInit: "beforeInit",
    /* callback after verify signature */
    callbackCheckSign: "checkSign",
    /* using jsondiffpatch-formatters for render difference */
    userJsonDiffHtml: true
};

var _onError = function(error) {
    console.log('error', error);
    errorInfo = document.getElementById('errorInfo');
    errorInfo.innerHTML = error;
    errorInfo.style.display = 'block';
};

// $(function () {
//     options.customNewHtmlTemplate = false;
//     options.customNewHtmlTemplate = `
//     <div class="panel panel-default">
//         <div class="panel-heading">
//             Робота з підписом<span id="status"></span>
//         </div>
//         <div class="panel-body">
//             <div id="eu-sign-form">
//                 <div class="row v-align">
//                     <div class="col-sm-2">
//                         <label for="cas-select">АЦСК:</label>
//                     </div>
//                     <div class="col-sm-10">
//                         <div class="eu-cas">
//                             <select id="cas-select"></select>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row v-align">
//                     <div class="col-sm-2">
//                         <label for="keys-select">Тип пристрою:</label>
//                     </div>
//                     <div class="col-sm-10">
//                         <div class="eu-device-type">
//                             <select id="keys-select"></select>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row v-align">
//                     <div class="col-sm-4">
//                        <label for="devices-select">Пристрій:</label>
//                     </div>
//                     <div class="col-sm-8">
//                         <div class="eu-device">
//                             <select id="devices-select"></select>
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row v-align">
//                     <div class="col-sm-4">
//                         <label for="kmPassword">Пароль:</label>
//                     </div>
//                     <div class="col-sm-8">
//                         <div class="eu-device-password">
//                             <input type="password" id="kmPassword" value="" required="">
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row v-align">
//                     <div class="col-sm-12">
//                         <div class="eu-device-sign-btn">
//                             <button id="sign-from-device" class="btn btn-info">
//                                 Підписати
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//                 <hr>
//                 <div class="row v-align">
//                     <div class="col-sm-4">
//                         <label for="select-file-read-textfield">Файл із ключем:</label>
//                     </div>
//                     <div class="col-sm-4">
//                         <div class="eu-file-key">
//                             <input type="textfield" readonly="readonly"  id="select-file-read-textfield" value="" required="">
//                         </div>
//                     </div>
//                     <div class="col-sm-4">
//                         <div class="eu-file-select-btn">
//                             <button id="select-file-read-button" class="btn btn-default">
//                                 Обрати
//                             </button>
//                         </div>
//                     </div>
//                 </div>

//                 <div class="row v-align">
//                     <div class="col-sm-4">
//                         <label for="select-file-read-password">Пароль:</label>
//                     </div>
//                     <div class="col-sm-8">
//                         <div class="eu-file-password">
//                             <input type="password" id="select-file-read-password" value="" required="">
//                         </div>
//                     </div>
//                 </div>
//                 <div class="row v-align">
//                     <div class="col-sm-12">
//                         <div class="eu-file-sign-btn">
//                             <button id="sign-from-file" class="btn btn-info">
//                                 Підписати
//                             </button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//         <div class="panel-footer">
//             <div id="errorInfo" style="display: none" class="alert alert-danger"></div>
//             <div id="certInfo" style="display: none" class="alert alert-info"></div>
//         </div>
//     </div>
//     `;

//     /* {string} custom html for render */
//     options.customHtmlTemplate = '\
//         <div class="panel panel-default"> \
//             <div class="panel-heading">\
//                 Робота з підписом<span id="status"></span>\
//             </div>\
//             <div class="panel-body">\
//                 <div id="signPanel" style="display: none" class="panel panel-default">\
//                     <div class="panel-body">\
//                         <div class="row">\
//                             <div class="col-sm-2">\
//                                 <p>ЦСК:</p>\
//                             </div>\
//                             <div class="col-sm-10">\
//                                 <select id="CAsServersSelect"></select>\
//                             </div>\
//                         </div>\
//                         <div id="keyStatusPanel" class="panel panel-info">\
//                             <div class="panel-heading"><span id="PKStatusInfo"></span></div>\
//                             <div class="panel-body">\
//                                 <div id="PKCertsSelectZone" hidden="" class="row">\
//                                     <div class="col-sm-3">\
//                                         <p>Особисті сертифікати:</p>\
//                                     </div>\
//                                     <div class="col-sm-3">\
//                                         <button id="ChoosePKCertsButton"\
//                                                 onclick="euSignMgr.uiCertFileInput.click();"\
//                                                 class="btn btn-default">Обрати\
//                                         </button>\
//                                         <input type="file" multiple="" id="ChoosePKCertsInput" style="visibility: hidden">\
//                                     </div>\
//                                     <div class="col-sm-6">\
//                                         <output id="SelectedPKCertsList"></output>\
//                                     </div>\
//                                 </div>\
//                                 <div class="row v-align">\
//                                     <div class="col-sm-3">\
//                                         <p>Ключ:</p>\
//                                     </div>\
//                                     <div class="col-sm-6">\
//                                         <input readonly="readonly" id="PKeyFileName"\
//                                                onclick="euSignMgr.uiPkFileInput.click();"\
//                                                class="form-control form-control-sm">\
//                                     </div>\
//                                     <div class="col-sm-3 text-right">\
//                                         <button id="PKeySelectFileButton"\
//                                                 onclick="euSignMgr.uiPkFileInput.click();"\
//                                                 class="btn btn-default">Обрати\
//                                         </button>\
//                                         <input type="file" id="PKeyFileInput" style="display: none">\
//                                     </div>\
//                                 </div>\
//                                 <div class="row v-align">\
//                                     <div class="col-sm-3">\
//                                         <p>Пароль:</p>\
//                                     </div>\
//                                     <div class="col-sm-6">\
//                                         <input type="password" id="PKeyPassword"\
//                                                class="form-control form-control-sm" autocomplete="off">\
//                                     </div>\
//                                     <div class="col-sm-3 text-right">\
//                                         <button id="PKeyReadButton" onclick="euSignMgr.readPrivateKeyButtonClick()"\
//                                                 class="btn btn-default" title="Зчитати">Зчитати\
//                                         </button>\
//                                     </div>\
//                                 </div>\
//                                 <div class="row v-align">\
//                                     <div class="col-sm-12  text-right">\
//                                         <button id="SignDataButton" onclick="euSignMgr.signData()" disabled=""\
//                                                 class="btn btn-info pull-right">Підписати\
//                                         </button>\
//                                     </div>\
//                                 </div>\
//                             </div>\
//                         </div>\
//                     </div>\
//                 </div>\
//                 <div id="checkPanel" style="display: none" class="panel panel-default">\
//                     <div class="panel-heading">Перевірка підпису</div>\
//                     <div class="panel-body">\
//                         <div style="display: none; color: #3a7d2b;" id="verificationError">\
//                         Необхідно повторно накласти підпис \
//                         <!--<button id="diffDetail" class="btn btn-default">Детально</button>-->\
//                             <div class="alert alert-warning" id="verificationErrorDiff" style="display: none;"></div>\
//                         </div>\
//                         <div class="alert alert-success" id="verificationSuccess" style="display: none"></div>\
//                     </div>\
//                 </div>\
//             </div>\
//             <div class="panel-footer">\
//                 <div id="certInfo" style="display: none" class="alert alert-info"></div>\
//             </div>\
//         </div>';

//         // options.customNewHtmlTemplate = options.customHtmlTemplate;

// });

/**
 * Callback function, before init crypto libs, for redefine gui elements
 */
function beforeInit(obj) {
    //console.log('beforeInit', arguments);
    // override proxy
    //URL_XML_HTTP_PROXY_SERVICE = "https://vpn.unity-bars.com.ua/proxy/ProxyHandler.ashx";

    // if using custom html template - can redefine elements id
    //obj.uiSignPanel = document.getElementById('signPanel'); // панель наложения подписи
    //obj.uiCheckPanel = document.getElementById('checkPanel'); // панель верификации подписи
    //obj.uiVerifyBtn = document.getElementById('VerifyDataButton'); // кнопка проверки подписи
    //obj.uiSignBtn = document.getElementById('SignDataButton'); // кнопка наложения подписи
    //obj.uiPkSelectBtn = document.getElementById('PKeySelectFileButton'); // кнопка выбора файла ключа
    //obj.uiPkFileName = document.getElementById('PKeyFileName'); // поле с файлом ключа
    //obj.uiPkCertsDiv = document.getElementById('PKCertsSelectZone'); // блок с загрузкой личных сертификатов
    //obj.uiCaServersSelect = document.getElementById('CAsServersSelect'); // dropdown со списком ЦСК
    //obj.uiPkStatusInfo = document.getElementById('PKStatusInfo'); // span для сообщений о процессе работы с ключем
    //obj.uiPkPassword = document.getElementById('PKeyPassword'); // поле пароля для ключа
    //obj.uiPkReadBtn = document.getElementById('PKeyReadButton'); // кнопка чтения ключа
    //obj.uiPkFileInput = document.getElementById('PKeyFileInput'); // input(type=file) для загрузки ключа
    //obj.uiCertInfo = document.getElementById('certInfo'); // блок с информацией о сертификате
    //obj.uiVerifyCertInfo = document.getElementById('certInfo'); // блок с информацией о сертификате при проверке подписи
    //obj.uiVerifyErrorInfo = document.getElementById('verificationError'); // блок с информацией о неверной подписи
    //obj.uiVerifyDiffInfo = document.getElementById('verificationErrorDiff'); // блок с информацией о различиях данных
    //obj.uiVerifySuccessInfo = document.getElementById('verificationSuccess'); // блок с информацией о успешной проверке подписи
    //obj.uiCertFileInput = document.getElementById('ChoosePKCertsInput');// input(type=file) для загрузки сертификата
    //obj.uiCertList = document.getElementById('SelectedPKCertsList'); // output для отображения загруженого сертификата
}

/**
 * Callback function, after init crypto libs
 */
function onInit(obj) {
    //console.log('externalInit', arguments);
    // let btnDetail = document.getElementById("diffDetail");
    // if (btnDetail) {
    //     btnDetail.addEventListener('click', function() {
    //         document.getElementById("verificationErrorDiff").style.display = 'block';
    //     });
    // }
}

/**
 * Callback function, after obtaining json from API
 * @param {object} data - json object
 */
function renderJson(data) {
    //console.log('renderJson', data);
}

/**
 * Callback function, after sign verification
 * @param {object} signData - json object from signature
 * @param {object} currData - json object from database
 * @param {object} diff     - difference, json object (undefined if equal), see https://github.com/benjamine/jsondiffpatch
 * @param {object} obj      - reference to main lib
 */
function checkSign(signData, currData, diff, obj) {
    //console.log('externalcheckSign', diff);
    // if userJsonDiffHtml : false, use obj.showDiffError
    //if(diff)
    //    obj.showDiffError('custom error message');
}

/**
 * Callback function, post sign to server
 * @param {string} signature - base64 string with CMS signature
 */
function postSign(signature) {
    // console.log('sendSign', signature);
    // todo post to server with sign
    window.postSign(signature);
    // if  success
    setKeyStatus('Підпису успішно накладено та передано у ЦБД', 'success');
    // if error
    // setKeyStatus('Помилка при передачі підпису до ЦБД', 'error');
}
