commons.filter('qualificationStatus', () => {
    "use strict";
    return (input) => {
        let name = '';

        switch (input) {
            case 'pending':
                name = gettext('Ожидается квалификация');
                break;

            case 'active':
                name = gettext('Предложение принято');
                break;

            case 'unsuccessful':
                name = gettext('Предложение отклонено');
                break;

            case 'cancelled':
                name = gettext('Решение отменено');
                break;
        }

        return name ? name : input;
    }
});