class NtAutocompleteCity {
    constructor(
        loaderService,
        googleMapsPlaces
    ) {
        this.loader = loaderService;
        this.googleMapsPlaces = googleMapsPlaces;
        this.inputName = "company-city";
        this.label = this.label || gettext("Город компани");
    }

    search (query) {
        const text = query.trim();
        return this.googleMapsPlaces.findCities(text)
    }

    selectedItem(item) {
        if(item) {
            document.activeElement.blur();
            this.googleMapsPlaces
                .getPlaceDetails(item.place_id)
                .then((place) => {
                    if(this.onChange) {
                        this.onChange({
                            place
                        });
                    }
                });
        }
    }
}


commons.component('ntAutocompleteCity', {
    templateUrl: '/static/scripts/modules/Commons/views/components/nt-autocomplete.html',
    controller: NtAutocompleteCity,
    controllerAs: 'vm',
    bindings: {
        isDisabled: '=',
        required: '<?',
        name: '=',
        onChange: '&',
        label: '@?'
    }
});