tenderView.controller('tenderViewChatModalCtrl', function(
    $scope,
    $rootScope,
    data,
    chatService,
    $uibModalInstance
) {

    $scope.chatData = {
        title: "",
        message: data.answer ? data.answer : "",
        isQuestion: data.isQuestion,
        isMultilotTender: data.isMultilotTender,
        items: data.items,
        lots: data.lots,
        questionOf: null,
        relatedItem: null
    };

    $scope.questionTypes = {
        'tender': gettext("Тендер"),
        'lot': gettext("Лот"),
        'item': gettext("Предмет закупки")
    };

    $scope.sendAnswer = function() {
        $scope.disablePublishBtn = true;
        chatService.save('answer', data.tenderId, $scope.chatData, data.questionId)
            .then(function(data) {
                $uibModalInstance.close();
            })
    };

    $scope.sendQuestion = function() {
        $scope.disablePublishBtn = true;
        if ($scope.chatData.questionOf === "tender") {
            $scope.chatData.relatedItem = data.tenderId;
        }
        chatService.save('question', data.tenderId, $scope.chatData)
            .then(function(data) {
                $uibModalInstance.close();
            })
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.isDisabled = function(isAnswerMode) {
        if (isAnswerMode) {
            return !$scope.chatData.message.length || $scope.disablePublishBtn;
        } else {
            return !$scope.chatData.message.length || !$scope.chatData.title.length || $scope.disablePublishBtn;
        }
    }

});