var agreementModule = angular.module('agreementModule', [
        'commons.app',
        'ui.router',
    ])
    .filter('decorate', function () {
        return function (input) {
            input = input || '';
            switch (input) {
                case 'unsuccessful':
                    input = 'Виключено з рамкової угоди';
                    break;
                case 'active':
                    input = 'Сторона рамкової угоди';
                    break;
                default:
                    input = 'Статус не вiдомий';
            }
            return input;
        };
    })
    .filter('decorateValuePrice', function () {
        return function (input) {
            input = input || '';
            switch (input) {
                case 'itemPriceVariation':
                    input = 'Зміна ціни за одиницю товару';
                    break;
                case 'taxRate':
                    input = 'Зміна ціни у зв’язку із зміною ставок податків і зборів';
                    break;
                case 'thirdParty':
                    input = 'Зміна сторонніх показників (курсу, тарифів...)';
                    break;
                case 'partyWithdrawal':
                    input = 'Припинення участі у рамковій угоді учасника';
                    break;
                default:
                    input = 'Статус не вiдомий';
            }
            return input;
        };
    })
    .filter('decorateDocUpload', function () {
        return function (input) {
            input = input || '';
            switch (input) {
                case 'notice':
                    input = 'Повідомлення про рамкову угоду';
                    break;
                case 'contractSigned':
                    input = 'Підписана рамкова угода';
                    break;
                case 'contractArrangements':
                    input = 'Заходи для припинення рамкової угоди';
                    break;
                case 'contractSchedule':
                    input = 'Розклад та етапи';
                    break;
                case 'contractAnnexe':
                    input = 'Додатки до рамкової угоди';
                    break;
                case 'contractGuarantees':
                    input = 'Забезпечення тендерних пропозицій';
                    break;
                case 'subContract':
                    input = 'Субпідряд';
                    break;
                default:
                    input = 'Статус не вiдомий';
            }
            return input;
        };
    })
    .filter("decorateAgreementStatus", function () {
        return function (input) {
            input = input || '';
            switch (input) {
                case 'pending':
                    input = 'Непідтверджена зміна';
                    break;
                case 'active':
                    input = 'Підтверджена зміна';
                    break;
                case 'cancelled':
                    input = 'Скасована зміна';
                    break;
                default:
                    input = 'Статус не вiдомий';
            }
            return input;
        };
    });