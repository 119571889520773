tenderView.factory('tenderBidService', function(http) {
    var getResourse = 'tender/:tenderId/bid/:bidId/';
    var saveCriteria = 'tender/:tenderId/bid/:bidId/requirement_responses/';
    var saveResourse = 'tender/:tenderId/bid/';
    var onlyMineResourse = 'tender/:tenderId/bid/?only_users=1';
    var addMilestonesResource = 'tender/:tenderId/awards/:awardId/milestones';
    return {
        get: function(id, bidId, isOnlyMine) {
            var url;
            if (bidId) {
                url = getResourse.replace(/:tenderId/, id).replace(/:bidId/, bidId);
                return http.get(url)
                    .then(function(result) {
                        //console.log("tenderBidService.get:");
                        //console.log(result);
                        return result.data;
                    });
            } else {
                if (isOnlyMine) {
                    url = onlyMineResourse.replace(/:tenderId/, id);
                    return http.get(url)
                        .then(function(result) {
                            //console.log("tenderBidService.get:");
                            //console.log(result);
                            return result.data;
                        });
                } else {
                    url = saveResourse.replace(/:tenderId/, id);
                    return http.get(url)
                        .then(function(result) {
                            //console.log("tenderBidService.get:");
                            //console.log(result);
                            return result.data.response;
                        });
                }
            }
        },
        save: function(id, data) {
            var url = saveResourse.replace(/:tenderId/, id);
            return http.post(url, data)
                .then(function(result) {
                    return result.data;
                });
        },
        saveCriteria: function(id, bidId, requirementResponses) {
            var url = `tender/${id}/bid/${bidId}/requirement_responses/`
            return http.post(url, requirementResponses)
                .then(function(result) {
                    return result.data;
                });
        },
        editCriteria: function(id, bidId, singleCriteria) {
            // console.log(singleCriteria);

            var url = `tender/${id}/bid/${bidId}/requirement_responses/`
            return http.patch(url, singleCriteria)
                .then(function(result) {
                    return result.data;
                });
        },
        delete: function(id, bidId) {
            var url = getResourse.replace(/:tenderId/, id).replace(/:bidId/, bidId);
            return http.delete(url)
                .then(function(result) {
                    return result.data;
                });
        },
        edit: function(id, bidId, data) {
            var url = getResourse.replace(/:tenderId/, id).replace(/:bidId/, bidId);
            return http.put(url, data)
                .then(function(result) {
                    return result.data;
                });
        },
        addMilestones: function(tenderId, awardId, data) {
            var addUrl = addMilestonesResource.replace(/:tenderId/g, tenderId).replace(/:awardId/g, awardId);
            return http.post(addUrl, data);
        }
    };
});
