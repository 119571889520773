commons.filter('monitoringReasonName', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'indicator':
                // Automatic risks indicators
                return gettext('Дані автоматичних індикаторів ризиків');

            case 'authorities':
                // Information received from state authorities, bodies of local self-government, on the presence of signs of violation of legislation in the field of public procurement
                return gettext('Інформація, отримана від органів державної влади, органів місцевого самоврядування, про наявність ознак порушення (порушень) законодавства у сфері публічних закупівель');

            case 'media':
                // Messages in mass media containing information on the presence of signs of violation of legislation in the field of public procurement
                return gettext('Повідомлення в засобах масової інформації, що містять відомості про наявність ознаки порушення (порушень) законодавства у сфері публічних закупівель');

            case 'fiscal':
                // A sign of violation of legislation in the field of public procurement detected by the body of state financial control in information disclosure in the electronic procurement system
                return gettext('Виявлені органом державного фінансового контролю ознаки порушення (порушень) законодавства у сфері публічних закупівель в інформації, оприлюдненій в електронній системі закупівель');

            case 'public':
                // Information received from public associations about the presence of signs of violations of legislation in the field of public procurement revealed by the results of public control in the field of public procurement
                return gettext('Інформація, отримана від громадських об’єднань, про наявність ознак порушення (порушень) законодавства у сфері публічних закупівель, виявлених за результатами громадського контролю у сфері публічних закупівель відповідно до статті 9 цього Закону');

            default:
                return value;
        }
    }
});