tenderView.controller('tenderViewAgreementActivateModalCtrl', function(
    $scope,
    $rootScope,
    $state,
    $timeout,
    data,
    monitoringService,
    documentsService,
    $uibModalInstance,
    $uibModal,
    agreementService,
) {

    $scope.tenderId = data.tenderId;
    $scope.agreement = data.agreement;
    $scope.activate = data.activate;

    $scope.disablePublishBtn = false;

    let activate = () => {
        return agreementService.patch({
            tenderId: $scope.tenderId,
            agreementId: $scope.agreement.id,
        }, {
            period: {
                startDate: $scope.agreement.period.startDate,
                endDate: $scope.agreement.period.endDate,
            },
            status: 'active'
        }).then(()=>{
            ntFlash.success(gettext('Угода активована'));
            $timeout(()=>{
                $state.reload();
            }, 2000)
        });
    };

    $scope.submit = (useSign) => {
        $scope.disablePublishBtn = true;

        let  payload = {
            agreementNumber: $scope.agreement.agreementNumber,
            period: {
                startDate: $scope.agreement.period.startDate,
                endDate: $scope.agreement.period.endDate,
            }
        };

        if ($scope.activate) {
            payload.status = 'active';
        }

        if ($scope.agreement.dateSigned) {
            payload.dateSigned = $scope.agreement.dateSigned
        }

        agreementService.patch({
            tenderId: $scope.tenderId,
            agreementId: $scope.agreement.id,
        }, payload).then(()=>{
            $uibModalInstance.close();
            if (useSign) {
                if ($rootScope.oldEds) {
                    $scope.sign().result.then(() => {
                        activate();
                    });
                } else {
                    $scope.sign()
                        .then(data => {
                            console.log('documentsService.sign data', data);
                            activate();
                        })
                        .catch(error => console.log('documentsService.sign error', error));    
                }
            } else {
                activate()
            }

        }).finally(()=>{
            $scope.disablePublishBtn = false;
        });

    };

    $scope.isDisabled = () => {
        return $scope.disablePublishBtn
            || !$scope.agreement.agreementNumber
            || !$scope.agreement.period
            || !$scope.agreement.period.startDate
            || !$scope.agreement.period.endDate;
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.sign = () => {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: () => {
                        return {
                            tenderId: $scope.tenderId,
                            agreementId: $scope.agreement.id,
                            documents: $scope.agreement.documents || [],
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            return documentsService.sign({
                tenderId: $scope.tenderId,
                agreementId: $scope.agreement.id,
                documents: $scope.agreement.documents || []    
            });
        }
    };


});
