tenderView.controller('tenderEditSelectPlanModalCtrl', function(
    $scope,
    $rootScope,
    $uibModalInstance,
    tenderService,
    data,
    ntFlash
) {
    $scope.tenderData = data;
    $scope.planId = $scope.tenderData.plans && $scope.tenderData.plans[0].id;
    $scope.disablePublishBtn = false;

    function prefixClassifier(cl) {
        let prefix;
        if (cl) {
            prefix = cl.substr(0, 4);
            if (prefix.substr(0, 3) === '336') {
                prefix = '336';
            }
            return prefix;
        }
    }

    $scope.save = function () {
        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.addPlanIdForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
            $scope.disablePublishBtn = false;
            return false;
        }

        tenderService.checkEntity({ entityId: $scope.planId })
            .then(data => {
                console.log('tenderService.checkEntity data', data);

                const firstPlanClassif = prefixClassifier(data.data.classification.id);
                for (const item of $scope.tenderData.items) {
                    if (firstPlanClassif !== item.classification.id.substr(0, firstPlanClassif.length)) {
                        ntFlash.error(gettext('Классификаторы ДК021 должны быть из одной группы (первые {{n}} цифры)').replace('{{n}}', firstPlanClassif.length));
                        return;
                    }
                }

                if ($scope.tenderData.procurementMethodType !== data.data.tender.procurementMethodType) {
                    ntFlash.error(gettext('Неспівпадіння типу процедури Річного Плану і Закупівлі'));
                    return;
                }

                if ($scope.tenderData.value.currency !== data.data.budget.currency) {
                    ntFlash.error(gettext('Неспівпадіння валюти Річного Плану і Закупівлі'));
                    return;
                }

                if ($scope.tenderData.value.amount > data.data.budget.amount) {
                    ntFlash.error(gettext('Сума Річного Плану має бути не меньше суми Закупівлі'));
                    return;
                }

                $rootScope.$broadcast('addPlanId', data.cdb_id);
                $uibModalInstance.close();
            })
            .catch(error => {
                console.log('tenderService.checkEntity error', error);
                ntFlash.error(gettext('Річний План з таким Ідентифікатором не знайдено'));
            })
            .finally(() => $scope.disablePublishBtn = false);
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };
});