class ExchangeService {
    constructor(
        http
    ) {
        const exchangeRateResource = '/bill/exchange/';
        this.getExchangeRate = (params) => {
            let url = exchangeRateResource;
            if (params) {
                let query_params = [];
                for (var k in params) {
                    if (params.hasOwnProperty(k)) {
                        query_params.push(k+'='+params[k])
                    }
                }
                url += '?' + query_params.join('&');
            }
            return http.get(url, true)
                .then((result) => {
                    return result.data;
                });
        };

        this.getExchnageLimits = (limit) => {
            return this.getExchangeRate()
                .then(function(result) {
                    let exchangeRates = result.response;

                    let limitUAH = exchangeRates['EUR'].rate;

                    return {
                        EUR: limit,
                        UAH: limitUAH,
                        RUB: limitUAH / exchangeRates['RUB'].rate,
                        GBP: limitUAH / exchangeRates['GBP'].rate,
                        USD: limitUAH / exchangeRates['USD'].rate,
                    };
                });
        };
    }
}
commons.service('exchangeService', ExchangeService);