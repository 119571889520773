class SendRequestToCompanyCtrl {
    constructor(
        $mdDialog,
        profileService,
        data,
        ntFlash,
        loaderService
    ) {
        this.$mdDialog = $mdDialog;
        this.profileService = profileService;
        this.ntFlash = ntFlash;
        this.loader = loaderService;

        this.company = data.company;
        this.user = data.user;
    }

    sendRequest() {
        const data = {
            user: this.user,
            company_request: this.company,
            verification_data: this.company.verification_data
        };
        this.loader.startLoading();
        const self = this;
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(window.GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                if (token) {
                    data["g-recaptcha-response"] = token;
                    self.profileService
                        .createUserWithRequestToCompany(data)
                        .then(result => window.location.href="/opc/provider/profile/registered")
                        .catch(() => {
                            self.close();
                            self.ntFlash.error(gettext('Ошибка данных компании'))
                        })
                        .finally(() => self.loader.stopLoading());
                } else {
                    self.ntFlash.error(gettext('Помилка відправлення даних форми. Зверніться до адміністратора!')); 
                }
            });
        });
    }

    close() {
        this.$mdDialog.hide();
    }
}

profileApp.controller('SendRequestToCompanyCtrl', SendRequestToCompanyCtrl);