profileApp.controller('companiesListCtrl', function (
    $scope,
    $state,
    $rootScope,
    companyService,
    classifierService,
    temporaryData,
    ntFlash,
    $element
) {
    $scope.ntFlash = ntFlash;
    $scope.companyService = companyService;
    $scope.classifierService = classifierService;
    $scope.type = $state.params.type || 'buyer';

    $scope.hidePagination = true;
    $scope.companies = [];
    $scope.currentPage = $state.params.pageNum;
    $scope.itemsPerPage = 10;

    $scope.searchTerm;
    $element.find('input.select_input_field').on('keydown', function(ev) {
        ev.stopPropagation();
    });

    var cpvFiltersList = $state.params.cpv || '';
    $scope.classifiers = temporaryData.get('searchableClassifiers') || [];
    $scope.regions = $state.params.region ? $state.params.region.split(',') : [];

    $scope.classifierService.loadCountriesRegions()
        .then((data) =>  {
            console.log('loadCountriesRegions data', data);
            $scope.countriesList = data.countries;
            $scope.regionsList = data.ua_regions;
        })
        .catch((error) => {
            console.log('loadCountriesRegions error', error);
            $scope.ntFlash.error(gettext('При завантаженні списку держав і регіонів виникла помилка'));
        });

    $scope.searchData = {
        type: $state.params.type || 'buyer',
        pageNum: $state.params.pageNum,
        query: $state.params.query || '',
        region: $state.params.region || '',
        cpv: $state.params.cpv || '',
        createReport: ''
    };

    $scope.companyService.getPublicCompanies($scope.searchData)
        .then((data) =>  {
            $scope.companies = data.results;
            $scope.currentPage = $state.params.pageNum;
            $scope.totalItems = data.count;
            $scope.totalPages = Math.ceil(data.count / $scope.itemsPerPage);

            $scope.hidePagination = (function () {
                return Boolean($scope.totalItems <= $scope.itemsPerPage);
            })();

            $scope.paginationLabel = (function () {
                var startAt = ($scope.currentPage > 1) ?
                    (($scope.currentPage - 1) * $scope.itemsPerPage) + 1 : 1;
    
                var endAt = ((startAt + $scope.itemsPerPage - 1) <= $scope.totalItems) ?
                    startAt + $scope.itemsPerPage - 1 : startAt + ($scope.totalItems - startAt);
    
                return startAt.toString() + "-" + endAt.toString() + ' ' + gettext('из') + ' ' + $scope.totalItems;
            })();
    
            temporaryData.set('paginationLabel', $scope.paginationLabel);
            $scope.prevPage = Number($scope.currentPage) <= 1 ? '1' : ($scope.currentPage - 1).toString();
            $scope.nextPage = $scope.companies.length < $scope.itemsPerPage ? $scope.currentPage.toString() : (Number($scope.currentPage) + 1).toString();
        })
        .catch((error) => {
            console.log('getPublicCompanies error', error);
            $scope.ntFlash.error(gettext('При завантаженні списку компаній виникла помилка'));
        });

    $scope.search = function () {
        $scope.searchData.pageNum = '1';
        $scope.searchData.region = $scope.regions.join(',');
        $scope.searchData.createReport = '';
        $state.go('profile.companies.list', $scope.searchData, {
            reload: true,
            inherit: false
        });
    };

    $scope.clearSearchData = function () {
        $scope.classifiers = [];
        temporaryData.set('searchableClassifiers', $scope.classifiers);
        cpvFiltersList = '';

        $state.go('profile.companies.list', {
            pageNum: '1',
            query: '',
            region: '',
            cpv: '',
            createReport: ''
        }, {
            reload: true
        });
    };

    $scope.createReport = function () {
        $scope.searchData.pageNum = '1';
        $scope.searchData.region = $scope.regions.join(',');
        $scope.searchData.createReport = '1';
        ntFlash.success(gettext('Отчет будет доступен в профиле в разделе Отчеты'));
        $scope.companyService.getPublicCompanies($scope.searchData);
    };
    
    $scope.canCreateReport = function () {
        return !$rootScope.isAnonymous;
    };

    $scope.openClassifier = function() {
        let currentState = $state.current.name;

        let addClasificatorsDestroy = $rootScope.$on('addClasificators', function(event, classifier, type) {
            (classifier || []).forEach(function (val) {
                var notFindIdentical = true;
                $scope.classifiers.forEach(function (listItem) {
                    if (listItem.code == val.code) {
                        notFindIdentical = false;
                    }
                });
                if (notFindIdentical) {
                    $scope.classifiers.push(val);
                }
            });
            temporaryData.set('searchableClassifiers', $scope.classifiers);
            cpvFiltersList = '';
            $scope.classifiers.forEach(function (val) {
                cpvFiltersList += val.code + ',';
            });
            cpvFiltersList = cpvFiltersList.substring(0, cpvFiltersList.length - 1);
            $scope.searchData.cpv = cpvFiltersList;
    
            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        let closeClasificatorsDestroy = $rootScope.$on('closeClasificators', function () {
            addClasificatorsDestroy();
            closeClasificatorsDestroy();
            $state.go(currentState);
        });

        $state.go(`${currentState}.cpv`);
    }

    $scope.closeSelectBox = () => {
        $("md-backdrop").trigger ("click");
    };
});
