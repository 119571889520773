commons.filter('mainProcurementCategory', () => {
    "use strict";

    const MSG = {
        'goods': gettext('Товари та матеріали'),
        'services': gettext('Послуги'),
        'works': gettext('Роботи'),
    };

    const DESCRIPTION_MSG = {
        'goods': gettext('Основним предметом закупівлі є продукція, об’єкти будь-якого виду та призначення, у тому числі сировина, вироби, устаткування, технології, предмети у твердому, рідкому і газоподібному стані, а також послуги, пов’язані з постачанням таких товарів, якщо вартість таких послуг не перевищує вартості самих товарів.'),
        'services': gettext('Основним предметом закупівлі є будь-який предмет закупівлі, крім товарів і робіт, зокрема транспортні послуги, освоєння технологій, наукові дослідження, науково-дослідні або дослідно-конструкторські розробки, медичне та побутове обслуговування, лізинг, найм (оренда), а також фінансові та консультаційні послуги, поточний ремонт.'),
        'works': gettext('Основним предметом закупівлі є проектування, будівництво нових, розширення, реконструкція, капітальний ремонт та реставрація існуючих об’єктів і споруд виробничого і невиробничого призначення, роботи з нормування в будівництві, геологорозвідувальні роботи, технічне переоснащення діючих підприємств та супровідні роботам послуги, у тому числі геодезичні роботи, буріння, сейсмічні дослідження, аеро- і супутникова фотозйомка та інші послуги, що включаються до кошторисної вартості робіт, якщо вартість таких послуг не перевищує вартості самих робіт.'),
    };

    return (msgId, showDescription) => {
        return (!showDescription ? MSG[msgId] : DESCRIPTION_MSG[msgId]) || msgId;
    }
});
