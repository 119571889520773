commons.filter('monitoringProcuringStage', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'planning':
                // Planning of tender and publication of information on its conduct
                return gettext('Планування закупівлі та оприлюднення інформації про її проведення');

            case 'awarding':
                // Disclosure of tender offers, their consideration and evaluation
                return gettext(' Розкриття тендерних пропозиції, їх розгляд та оцінка');

            case 'contracting':
                // Conclusion and completion of a tender contract
                return gettext('Укладання та виконання договору про закупівлю (прийняття рішення про відміну торгів)');

            default:
                return value;
        }
    }
});