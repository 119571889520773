commons.filter('datesTypeFilter', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'tenderPeriod':
                name = gettext('предложений');
                break;

            case 'deliveryDate':
                name = gettext('поставки');
                break;

            case 'enquiryPeriod':
                name = gettext('уточнений');
                break;
        }

        return name;
    }
});