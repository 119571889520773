commons.filter('invoiceStateFilter', function() {
    return function(input) {
        var name = "";

        switch (input) {
            case false:
                name = gettext('Ожидает оплаты');
                break;

            case true:
                name = gettext('Оплачен');
                break;
        }

        return name;
    }
});