contractApp.controller('contractChangesCreateModalCtrl', function(
    $scope,
    $state,
    $uibModalInstance,
    ntFlash,
    data,
    contractingService,
    RATIONALE_TYPES,
    $timeout
) {
    var contractId = data.contractId;

    // $scope.isVisibleTooltips = false;

    // (function () {
    //     $timeout(() => $scope.isVisibleTooltips = true, 300);
    // })()
    $scope.changes = {
        contractNumber: data.contractNumber,
        rationale: '',
        rationaleTypes: [],
    };

    $scope.collectRationaleTypes = function() {
        $scope.changes.rationaleTypes = [];
        $scope.rationaleTypes.forEach(function(type) {
            console.log(type)
            if (type.checked) {
                $scope.changes.rationaleTypes.push(type.title);
            }
        });
    };

    $scope.createChange = function() {
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.changeForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
        } else {
            contractingService.saveChange(contractId, $scope.changes)
            .then(()=>{
                ntFlash.success(gettext('Процесс внесения изменений успешно инициализирован'));
                $uibModalInstance.close();
            });
        }
    };

    $scope.initTooltips = (item) =>{
        if(item) {
            item.info = !item.info
        }
    }


    $scope.close = function() {
        $uibModalInstance.dismiss();
    };

    $scope.rationaleTypes = RATIONALE_TYPES
    // $scope.rationaleTypes = [
    //     {
    //         checked: false,
    //         title: 'priceReductionWithoutQuantity' // погодження зміни ціни в договорі про закупівлю в бік зменшення (без зміни кількості (обсягу) та якості товарів, робіт і послуг)
    //     },
    //     {
    //         checked: false,
    //         title: 'taxationSystem' // Зміна ціни у зв’язку із зміною ставок податків і зборів
    //     },
    //     {
    //         checked: false,
    //         title: 'externalIndicators' // Зміна сторонніх показників (курсу, тарифів...)
    //     },
    //     {
    //         checked: false,
    //         title: 'itemPriceChange' // погодження зміни ціни за одиницю товару в договорі про закупівлю
    //     },
    //     {
    //         checked: false,
    //         title: 'volumeCuts' // Зменшення обсягів закупівлі
    //     },
    //     {
    //         checked: false,
    //         title: 'itemPriceVariation' // Збільшення ціни за одиницю товару до 10 відсотків
    //     },
    //     {
    //         checked: false,
    //         title: 'qualityImprovement' // Покращення якості предмета закупівлі
    //     },
    //     {
    //         checked: false,
    //         title: 'durationExtension' // Продовження строку дії договору (через документально підтверджені об’єктивні обставини)
    //     },
    //     {
    //         checked: false,
    //         title: 'priceReduction' // Узгоджене зменшення ціни
    //     },
    //     {
    //         checked: false,
    //         title: 'taxRate' // Зміна ціни у зв’язку із зміною ставок податків і зборів
    //     },
    //     {
    //         checked: false,
    //         title: 'thirdParty' // Зміна сторонніх показників (курсу, тарифів...)
    //     },
    //     {
    //         checked: false,
    //         title: 'fiscalYearExtension' // Продовження строку дії договору на наступний рік
    //     }
    // ];

});
