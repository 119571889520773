class UserContactListController {
    constructor(
        CONTACT_TYPES,
        CONTACT_TYPES_CONFIG,
        $q,
        validateService
    ) {

        this.CT = CONTACT_TYPES;
        this.CT_CONFIG = CONTACT_TYPES_CONFIG;
        this.$q = $q;
        this.emailRegEx = validateService.regex.email;

        this.statusMessagesTypes = {
            CREATED: 0,
            CONFIRMATION_SENDED: 10,
            CONFIRMATION_SUCCESSFUL: 20,
            SET_DEFAULT_COMPLETED: 30,
            DELETED: 40,
            NOTIFY_ENABLED: 50
        };

        this.CT_DATA = {
            0: {
                placeholder: '',
                statusMessages: {
                    0: gettext('Контакт {value} создан.'),
                    40: gettext('Контакт {value} удалён.')
                },
                optionName: gettext('другое')
            },
            10: {
                placeholder: gettext('+3800000000'),
                statusMessages: {
                    0: gettext('Номер телефона {value} успешно добавлен.'),
                    30: gettext('Установка номера телефона {value} по умолчанию прошла успешно.'),
                    40: gettext('Номер телефона {value} удалён.')
                },
                optionName: gettext('телефон')
            },
            20: {
                placeholder: gettext('name@yourdomain.com'),
                statusMessages: {
                    0: gettext('Адрес электронной почты {value} успешно добавлен. Вам отправлено письмо для подтверждения.'),
                    10: gettext('Вам отправлено письмо на адрес электронной почты {value} для подтверждения.'),
                    20: gettext('Адрес электронной почты {value} подтверждён.'),
                    30: gettext('Установка адреса электронной почты {value} по умолчанию прошла успешно.'),
                    40: gettext('Адрес электронной почты {value} удалён.'),
                    50: gettext('Настройки изменены')
                },
                optionName: gettext('email')
            },
            30: {
                placeholder: gettext('skype login'),
                statusMessages: {
                    0: gettext('Контакт {value} создан.'),
                    40: gettext('Контакт {value} удалён.')
                },
                optionName: gettext('skype')
            },
        };

        this.currentStatusMessage = null;

        this.isCreateButtonDisabled = false;
        this.contactTypes = [];

        this.newItem = {
            'value': null,
            'type': 0
        };

        this.contactsFilter = (value, index, array) => {
            let fct = this.filterContactTypes;
            return !fct || fct.length === 0 || fct.indexOf(value.type) !== -1;
        };

        this.$onChanges = (changes) => {
            return;
        };

        this.$onInit = () => {
            let ct = this.CT;
            let localCT = this.contactTypes;
            let fct = this.filterContactTypes;
            for (let k in ct) {
                if (!ct.hasOwnProperty(k)) {
                    continue;
                }
                let contactID = ct[k];
                if (!fct || fct.length === 0 || fct.indexOf(contactID) !== -1) {
                    localCT[localCT.length] = contactID;
                }
            }
            this.newItem.type = localCT[0];
        };
    }

    contactComparator(a, b) {
        let aVal = a.value;
        let bVal = b.value;

        if (aVal.type != bVal.type) {
            return aVal.type > bVal.type ? -1 : 1;
        }

        if (aVal.is_default) return -1;
        if (bVal.is_default) return 1;
        if (aVal.is_confirmed !== bVal.is_confirmed) {
            return aVal.is_confirmed ? -1 : 1;
        }


        return aVal.value
            .toString()
            .localeCompare(bVal.value.toString());
    }

    hideStatusMessage() {
        this.currentStatusMessage = null;
    }

    showStatusMessage(item, statusType) {
        let itemCtData = this.CT_DATA[item.type];
        let msg = itemCtData.statusMessages[statusType];
        if (msg) {
            this.currentStatusMessage = msg.replace('{value}', item.value);
        } else {
            this.currentStatusMessage = null;
        }
    }

    onCreateBtnClick() {
        if (!this.onCreate) {
            return this.$q.when();
        }

        let newItem = this.newItem;
        let items = this.items;
        for (let i = 0, l = items.length; i < l; i++) {
            let item = items[i];
            if (item.value === newItem.value && item.type === newItem.type) {
                return;
            }
        }
        this.isCreateButtonDisabled = true;
        return this.onCreate({
                item: this.newItem
            })
            .then((result) => {
                let statusType = this.statusMessagesTypes.CREATED;
                this.showStatusMessage(this.newItem, statusType);
                this.newItem.value = '';
            })
            .finally(() => {
                this.isCreateButtonDisabled = false;
            });
    }

    onConfirmBtnClick(item) {
        if (!this.onConfirm) {
            this.$q.when();
        }
        return this.onConfirm({
                item: item
            })
            .then(() => {
                let statusType = this.statusMessagesTypes.CONFIRMATION_SENDED;
                this.showStatusMessage(item, statusType);
            });
    }

    onSetDefaultBtnClick(item) {
        if (!this.onSetDefault) {
            return this.$q.when();
        }
        return this.onSetDefault({
                item: item
            })
            .then((status) => {
                if (!status) {
                    return;
                }
                let statusType = this.statusMessagesTypes.SET_DEFAULT_COMPLETED;
                this.showStatusMessage(item, statusType);
            });
    }

    onSetNotifyBtnClick(item) {
        console.log('onSetNotifyBtnClick click');
        if (!this.onSetNotify) {
            return this.$q.when();
        }
        return this.onSetNotify({
                item: item
            })
            .then((status) => {
                if (!status) {
                    return;
                }
                let statusType = this.statusMessagesTypes.NOTIFY_ENABLED;
                this.showStatusMessage(item, statusType);
            });
    }

    onDeleteBtnClick(item) {
        if (!this.onDelete) {
            return this.$q.when();
        }
        return this.onDelete({
                item: item
            })
            .then((status) => {
                if (!status) {
                    return;
                }
                let statusType = this.statusMessagesTypes.DELETED;
                this.showStatusMessage(item, statusType);
            });
    }
}

profileApp.component('userContactList', {
    templateUrl: '/static/scripts/modules/Profile/views/components/user-contact-list.html',
    controller: UserContactListController,
    controllerAs: 'vm',
    bindings: {
        hasTelegram: '<',
        items: '<',
        onCreate: '&',
        onConfirm: '&',
        onSetDefault: '&',
        onSetNotify: '&',
        onDelete: '&',
        filterContactTypes: '<'
    }
});