class criteriaCtrl {
    constructor(
        $scope,
        $rootScope,
        ntFlash,
        tenderService,
        loadExternalDataService
    ){
        this.tender = tenderService.currentTender()
        this.criteria = this.tender.criteria
        this.ExternalDataService = loadExternalDataService

        // console.log(this.tender)
        // console.log(this.tender)
    }

    $onInit() {
        this.loadCriteria()
        // this.milestones = this.milestones || [];
        // this.lotId = this.lot ? this.lot.id : 0;
    }


    loadCriteria(){
        if (!this.criteria) {

            this.ExternalDataService.getCriterias()
                .then(data => {
                    //console.log('loadExternalDataService.getCriterias data', data);
                    this.criteria= data;
                    if (!['aboveThresholdUA.defense', 'simple.defense', 'closeFrameworkAgreementSelectionUA'].includes($scope.tender.procurementMethodType))
                        return loadExternalDataService.getOtherCriterias();
                    else return [];
                })
                .then(data => {
                    //console.log('loadExternalDataService.getOtherCriterias data', data);
                    this.criteria = [...this.criteria, ...data];
                })
                .catch(error => {
                    console.log('loadExternalDataService.getCriterias error', error);
                });
        } else {
            this.criteria.forEach(criteria => {
                criteria.activate = false;
                criteria.requirementGroups.forEach(group => {
                    group.requirements.forEach(require => {
                        if (require.eligibleEvidences && require.eligibleEvidences.length) criteria.activate = true;
                    });
                });
            });
        }
    }



}

criteriaApp.controller('criteriaCtrl', criteriaCtrl)