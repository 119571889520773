contractApp.filter('rationaleTypesFilter', function() {
    return function(input) {
        var name = "";

        switch (input) {
            case 'priceReductionWithoutQuantity':
                name = gettext('Погодження зміни ціни в договорі про закупівлю в бік зменшення (без зміни кількості (обсягу) та якості товарів, робіт і послуг) (Постанова)');
                break;
            case 'taxationSystem':
                name = gettext('Зміни ціни в договорі про закупівлю у зв’язку зі зміною ставок податків і зборів, зміною системи оподаткування та/або зміною умов щодо надання пільг з оподаткування - пропорційно до зміни таких ставок та/або пільг з оподаткування (Постанова)');
                break;
            case 'externalIndicators':
                name = gettext('Зміни встановленого згідно із законодавством органами державної статистики індексу споживчих цін, зміни курсу іноземної валюти, зміни біржових котирувань або показників Platts, ARGUS, регульованих цін (тарифів), нормативів, середньозважених цін на електроенергію на ринку \\"на добу наперед\\", що застосовуються в договорі про закупівлю, у разі встановлення в договорі про закупівлю порядку зміни ціни (Постанова)');
                break;
            case 'itemPriceChange':
                name = gettext('Погодження зміни ціни за одиницю товару в договорі про закупівлю у разі коливання ціни такого товару на ринку, що відбулося з моменту укладання договору про закупівлю або останнього внесення змін до договору про закупівлю в частині зміни ціни за одиницю товару. Зміна ціни за одиницю товару здійснюється пропорційно коливанню ціни такого товару на ринку (відсоток збільшення ціни за одиницю товару не може перевищувати відсоток коливання (збільшення) ціни такого товару на ринку) за умови документального підтвердження такого коливання та не повинна призвести до збільшення суми, визначеної в договорі про закупівлю на момент його укладення (Постанова)');
                break;
            case 'volumeCuts':
                name = gettext('Зменшення обсягів закупівлі, зокрема з урахуванням фактичного обсягу видатків замовника');
                break;

            case 'itemPriceVariation':
                // name = gettext('Зміна ціни за одиницю товару');
                name = gettext('Збільшення ціни за одиницю товару до 10 відсотків пропорційно збільшенню ціни такого товару на ринку у разі коливання ціни такого товару на ринку за умови, що така зміна не призведе до збільшення суми, визначеної в договорі про закупівлю, - не частіше ніж один раз на 90 днів з моменту підписання договору про закупівлю. Обмеження щодо строків зміни ціни за одиницю товару не застосовується у випадках зміни умов договору про закупівлю бензину та дизельного пального, газу та електричної енергії');
                break;

            case 'qualityImprovement':
                // name = gettext('Покращення якості предмета закупівлі');
                name = gettext('Покращення якості предмета закупівлі, за умови що таке покращення не призведе до збільшення суми, визначеної в договорі про закупівлю');
                break;

            case 'durationExtension':
                // name = gettext('Продовження строку дії договору (через документально підтверджені об’єктивні обставини)');
                name = gettext('Продовження строку дії договору про закупівлю та строку виконання зобов’язань щодо передачі товару, виконання робіт, надання послуг у разі виникнення документально підтверджених об’єктивних обставин, що спричинили таке продовження, у тому числі обставин непереборної сили, затримки фінансування витрат замовника, за умови що такі зміни не призведуть до збільшення суми, визначеної в договорі про закупівлю');
                break;

            case 'priceReduction':
                // name = gettext('Узгоджене зменшення ціни');
                name = gettext('Погодження зміни ціни в договорі про закупівлю в бік зменшення (без зміни кількості (обсягу) та якості товарів, робіт і послуг), у тому числі у разі коливання ціни товару на ринку');
                break;

            case 'taxRate':
                // name = gettext('Зміна ціни у зв’язку із зміною ставок податків і зборів');
                name = gettext('Зміна ціни в договорі про закупівлю у зв’язку зі зміною ставок податків і зборів та/або зміною умов щодо надання пільг з оподаткування - пропорційно до змін таких ставок та/або пільг з оподаткування');
                break;

            case 'thirdParty':
                // name = gettext('Зміна сторонніх показників (курсу, тарифів...)');
                name = gettext('Зміна встановленого згідно із законодавством органами державної статистики індексу споживчих цін, зміни курсу іноземної валюти, зміни біржових котирувань або показників Platts, ARGUS регульованих цін (тарифів) і нормативів, що застосовуються в договорі про закупівлю, у разі встановлення в договорі про закупівлю порядку зміни ціни');
                break;

            case 'fiscalYearExtension':
                // name = gettext('Продовження строку дії договору на наступний рік');
                name = gettext('Дія договору про закупівлю може бути продовжена на строк, достатній для проведення процедури закупівлі/спрощеної закупівлі на початку наступного року в обсязі, що не перевищує 20 відсотків суми, визначеної в початковому договорі про закупівлю, укладеному в попередньому році, якщо видатки на досягнення цієї цілі затверджено в установленому порядку');
                break;
        }

        return name || input;
    }
});
