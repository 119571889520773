contractApp.controller('contractListCtrl', function(
    $scope,
    $state,
    $timeout,
    $rootScope,
    $stateParams,
    temporaryData,
    contractingService
) {
    $scope.requestInProgress = true;

    var tenderId = $state.params.id,
        currentPage = $state.params.pageNum,
        searchRoute = 'contracts.list.search';

    $scope.itemsPerPage = 10;
    $scope.hidePagination = true;

    $scope.contracts = [];

    $scope.searchQuery = $state.params.q;
    $scope.isSearchMode = $state.params.list == 'search' ? true : false;
    $scope.searchData = {
        string: $state.params.list == 'search' ? $state.params.q : ''
    };

    $scope.search = function() {
        $scope.searchTimer && $timeout.cancel($scope.searchTimer);

        if ($scope.searchData.string) {
            // delaying the request in case the user is typing too fast,
            // so that we avoid extra requests.
            $scope.searchTimer = $timeout(function() {
                $state.go(searchRoute, {
                    q: $scope.searchData.string
                }, {
                    reload: true
                });
            }, 1600);
        }
    };

    $scope.getContracts = function(nextPage) {
        if ($state.params.list === "user-contacts") {
            contractingService.getForUser(currentPage)
                .then(function(result) {
                    resultHandling(result);
                });
        } else if ($state.params.list === "company-contacts") {
            contractingService.getForCompany(currentPage)
                .then(function(result) {
                    resultHandling(result);
                });
        } else if ($state.includes('tenderView.contracts')) {
            contractingService.getForTender(tenderId, nextPage)
                .then(function(result) {
                    $scope.contracts = result.data.response;
                    $scope.nextPage = result.data.response.next;
                    if ($scope.nextPage != null) {
                        $scope.nextPage = result.data.response.next.replace(DZ_URL, '');
                    }
                });
        } else if ($state.params.list === "search") {
            contractingService.search(currentPage, {searchStr: $scope.searchQuery})
                .then(function(result) {
                    resultHandling(result);
                });
        } else {
            contractingService.getAll(currentPage)
                .then(function(result) {
                    resultHandling(result);
                });
        }
    };

    function resultHandling(result) {
        $scope.contracts = result.data.response.data;
        $scope.pagerItemsPerPage = $scope.contracts.length;
        $scope.totalPages = result.data.response.total_pages;
        $scope.totalItems = result.data.response.total_items;
        $scope.hidePagination = $scope.totalItems <= $scope.itemsPerPage;

        $scope.paginationLabel = (function() {
            var startAt = (currentPage > 1)
                ? ((currentPage-1) * $scope.itemsPerPage) + 1 : 1;

            var endAt = ((startAt + $scope.itemsPerPage - 1) <= $scope.totalItems)
                ? startAt + $scope.itemsPerPage - 1: startAt + ($scope.totalItems - startAt);

            return startAt.toString() + "-" + endAt.toString() + ' из ' + $scope.totalItems;
        })();

        temporaryData.set('paginationLabel', $scope.paginationLabel);
        $scope.currentPage = currentPage;
        $scope.prevPage = Number(currentPage) <= 1 ? '1' : (currentPage - 1).toString();
        $scope.nextPage = $scope.pagerItemsPerPage < $scope.itemsPerPage ? $scope.currentPage.toString() : (Number(currentPage) + 1).toString();
    }

    $scope.getContracts();

    $scope.requestInProgress = false;
});