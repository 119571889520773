class MainHead {

    constructor(
        $state,
        profileService,
        companyService,
        $uibModal,
        OPC_CONFIG,
        $rootScope,
        USER_ROLES
    ) {
        this.$state = $state;
        this.user = profileService;
        this.company = companyService;
        this.$uibModal = $uibModal;
        this.contractingState = OPC_CONFIG.OPC_MODULE_CONTRACTS;
        this.isAnonymous = $rootScope.isAnonymous;
        this.createTender = $rootScope.createTender;
        this.signout = profileService.signout;

        this.roles = {
            [USER_ROLES.PROVIDER]: gettext('Поставщик'),
            [USER_ROLES.CUSTOMER]: gettext('Закупщик')
        }
    }



    createInvoice() {
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/Profile/views/modals/profile.invoice.modal.html",
            controller: "profileInvoiceModalCtrl",
            backdrop: 'static'
        });
    }

    registration() {
        //this.$state.go('profile.registration');
        window.location = '/opc/provider/profile/registration';
    }
}

// make class properties transform 
MainHead.prototype.agree = [{
        "title": gettext('Усi угоди'),
        "link": "/opc/provider/agreement-list/1",
        "opts": {
            // inherit: false,
            // reload: true
        },
    },
    {
        "title": gettext('Угоди компанії'),
        "link": "/opc/provider/agreement-list/1?type=company",
        "opts": {
            // inherit: false,
            // reload: true
        }
    },
    {
        "title": gettext('Мої угоди'),
        "link": "/opc/provider/agreement-list/1?type=user",
        "opts": {
            // inherit: false,
            // reload: true
        }
    }
]



commons.component('mainHead', {
    templateUrl: "/static/scripts/modules/Provider/views/components/main-head.html",
    controller: MainHead,
    controllerAs: 'vm'
});