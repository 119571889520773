contractApp.controller('terminateContractModalCtrl', function(
    $scope,
    $state,
    $uibModalInstance,
    ntFlash,
    data,
    contractingService,
    documentsService,
    classifierService,
    $timeout
) {

    console.log("data", data);
    $scope.searchTerm = '';
    var contractId = data.contractId;
    $scope.isCancellation = data.isCancellation;
    $scope.contract = data.contract;

    $scope.terminateData = {
        // status: 'terminated',
        terminationDetails: data.contract ? data.contract.terminationDetails || '' : '',
        amountPaid: {
            amount: data.contract && data.contract.value.amount ? data.contract.value.amount : '',
            amountNet: data.contract.value && data.contract.value.amountNet ? data.contract.value.amountNet : data.contract.value.amount,
            valueAddedTaxIncluded: data.contract.value && data.contract.value.valueAddedTaxIncluded ? data.contract.value.valueAddedTaxIncluded : false
        }
    };

    $scope.additionalClassifications = [];

    classifierService.loadCountriesClassifiers()
        .then((data)=>{
            $scope.CountriesClassifications = data
            $scope.CountriesClassifications.unshift([])
        })


    $timeout(()=>{
        $( document ).find('input.select_input_field' ).on('keydown', function(ev) {
            console.log('test')
            ev.stopPropagation();
        });
    },500)


    $scope.closeSelectBox = () => {
        console.log($scope.contract)
        $("md-backdrop").trigger ("click");
    };


    $scope.contract.items.forEach((item)=>{
        if(item.additionalClassifications){
            item.additionalClassifications.forEach((ac)=>{
                if(ac.scheme === 'COO'){
                    $scope.additionalClassifications.push({
                        name: item.description,
                        type: $scope.CountriesClassifications.find(country=>country.name_uk === item.description)
                    })
                } else {
                    $scope.additionalClassifications.push({
                        name: item.description,
                    })
                }
            })
        } else {
            $scope.additionalClassifications.push({
                name: item.description
            })
        }

    })

    // additionalClassifications;


    $scope.minVATAmount = Math.round(100*$scope.terminateData.amountPaid.amount / 1.2)/100;

    let isIntegerOrFloatNumber = (value) => {

        if (value != undefined) {
            value = value.toString().replace(',', '.').replace(/\s*/, '');
            return value !== '' && !isNaN(value);
        } else return false;
    };

    function updateStatusSave(v) {
        let m = $scope.terminateData;
        $scope.isSaveEnabled = (!$scope.isCancellation || m.terminationDetails.length > 0)
            && m.amountPaid && isIntegerOrFloatNumber(m.amountPaid.amount);
    }

    $scope.$watch('terminateData.terminationDetails', updateStatusSave);
    $scope.$watch('terminateData.amountPaid.amount', updateStatusSave);
    $scope.$watch('terminateData.amountPaid.amountNet', updateStatusSave);


    $scope.checkAdditionalClassifications = ()=> {
        $scope.additionalClassifications.forEach((el)=>{
            if(el.type && el.type.id){
                let current = $scope.contract.items.find(item=>item.description === el.name)
                let element = {
                    "scheme": "COO",
                    "id": el.type.id,
                    "description": el.type.name_uk
                }

                if(current.additionalClassifications){
                    let currentScheme = current.additionalClassifications.find(el=>el.scheme === 'COO')
                    if(currentScheme){
                        currentScheme.id = element.id
                        currentScheme.description = element.description
                    } else {
                        current.additionalClassifications.push(element)
                    }
                } else {
                    current.additionalClassifications = []
                    current.additionalClassifications.push(element)
                }
            }


        })
    }

    // Step 1
    $scope.save = function() {

        $scope.checkAdditionalClassifications()


        $scope.$broadcast('show-errors-check-validity');

        // console.log("valueAddedTaxIncluded", $scope.contract.value.valueAddedTaxIncluded);
        // console.log("terminateData",$scope.terminateData);

        if (!$scope.contract.value.valueAddedTaxIncluded && ($scope.terminateData.amountPaid.amount !== $scope.terminateData.amountPaid.amountNet)) {
            ntFlash.error(gettext('Значения "Сумма оплаты по договору" и "Сумма оплаты по договору без НДС" должны совпадать'));
            return;
        }

        // let maxVATAmount = 100*(Math.round($scope.terminateData.amountPaid.amountNet*1.2/100));
        // if ($scope.contract.value.valueAddedTaxIncluded && $scope.terminateData.amountPaid.amount > maxVATAmount) {
        //     console.log($scope.terminateData.amountPaid.amount);
        //     console.log(maxVATAmount);

        //     ntFlash.error(gettext('Значення "Ціна договору" має бути більше ніж "Ціна договору без ПДВ" не більше ніж на 20%'));
        //     return;
        // }

        if (!$scope.terminateForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
        } else {

            if (!$scope.isCancellation) {
                if (data.contract.terminationDetails && data.contract.terminationDetails.length > 0) {
                    $scope.terminateData.terminationDetails = '';
                } else {
                    delete $scope.terminateData.terminationDetails;
                }
            }

            contractingService.terminateContract(contractId, $scope.terminateData)
                .then((result)=>{
                    if (result) {
                        $scope.contract = result.data.response;
                    }
                    $scope.isSignEnabled = true;
                    // temp fix
                    $scope.isTerminateContractEnabled = true;

                    if ($scope.isCancellation) {
                        $scope.isTerminateContractEnabled = true;
                    }

                    ntFlash.success(gettext('Контракт успешно обновлен'));
                });
        }
    };

    // Step 2
    $scope.sign = function() {

        const data ={
            contractId: contractId,
            documents: $scope.contract.documents || ($scope.contract.documents = [])
        };

        documentsService.sign(data)
            .then(data => {
                console.log('documentsService.sign data', data);
                $scope.isTerminateContractEnabled = true;
            })
            .catch(error => console.log('documentsService.sign error', error));
        // return $uibModal.open({
        //     templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
        //     controller: 'eSignModalCtrl',
        //     resolve: {
        //         data: function() {
        //             return {
        //                 contractId: contractId,
        //                 documents: $scope.contract.documents || ($scope.contract.documents = [])
        //             };
        //         }
        //     },
        //     windowClass: 'center-modal'
        // }).result
        //     .then((res) => {
        //         if (res) {
        //             $scope.isTerminateContractEnabled = true;
        //         }
        //     });
    };

    // Step 3
    $scope.terminateContract = function() {
        $scope.checkAdditionalClassifications()
        let patch = {
            status: 'terminated',
            items: $scope.contract.items
        };
        // hot fix Prozorro old Contracts bug
        if ( !$scope.contract.value.amountNet ) {
            patch.value = data.contract.value;
            patch.value.amountNet =  data.contract.value.amount;
        }
        contractingService.terminateContract(contractId, patch)
            .then((result)=>{
                if (result) {
                    $scope.contract = result.data.response;
                }
                $uibModalInstance.close($scope.contract, true);
            });
    };

    $scope.close = function() {
        $uibModalInstance.close($scope.contract);
    };

});