commons.filter('documentTypeCaption', (tenderService) => {
    "use strict";
    return (input, tender) => {
        let name = "";
        let isCompetitiveDialogue = tender && (tenderService.isCompetitiveDialogue(tender) || tenderService.isCompetitiveDialogue2Stage(tender));
        switch (input) {
            case 'tender':
                name = gettext('Тендерная документация');
                break;

            case 'lot':
                name = gettext('Тендерная документация(Лот)');
                break;

            case 'lots':
                name = gettext('Тендерная документация(Лот)');
                break;

            case 'item':
                name = gettext('Тендерная документация(Предмет закупки)');
                break;

            case 'items':
                name = gettext('Тендерная документация(Предмет закупки)');
                break;

            case 'awards':
                name = gettext('Протокол квалификации');
                break;

            case 'awardDocuments':
                name = gettext('Протокол квалификации');
                break;
    
            case 'qualifications':
                name = gettext('Протокол преквалификации');
                break;

            case 'contract':
                name = gettext('Проект договора');
                break;

            case 'bids':
                name = gettext('Документи (Загальні)');
                break;

            case 'bidsLots':
                name = gettext('Документи (Лот)');
                break;

            case 'bidsItems':
                name = gettext('Документи (Предмет закупки)');
                break;

            case 'technicalSpecifications':
                name = isCompetitiveDialogue ? gettext('Технічний опис предмету закупівлі') : gettext('Технические спецификации');
                break;

            case 'financialDocuments':
                name = gettext('Фінансові документи');
                break;

            case 'commercialProposal':
                name = gettext('Ценовое предложение');
                break;

            case 'billOfQuantity':
                name = gettext('Смета (расчет стоимости)');
                break;

            case 'evidence':
                name = gettext('Пояснення/обгрунтування');
                break;
    
            case 'winningBid':
                name = gettext('Ціна за одиницю товару (послуги)');
                break;

            case 'eligibilityDocuments':
                name = gettext('Документы, подтверждающие соответствие');
                break;

            case 'qualificationDocuments':
                name = gettext('Документы, подтверждающие квалификацию');
                break;

            case 'cancellations':
                name = gettext('Отклонение');
                break;

            case 'notice':
                name = gettext('Звiт про виконання договору');
                break;

            case 'biddingDocuments':
                name = isCompetitiveDialogue ? gettext('Тендерна документація') : gettext('Документи закупівлі');
                break;

            case 'evaluationCriteria':
                name = gettext('Критерії оцінки');
                break;

            case 'clarifications':
                name = gettext('Пояснення до питань заданих учасниками');
                break;

            case 'eligibilityCriteria':
                name = gettext('Критерії прийнятності');
                break;

            case 'shortlistedFirms':
                name = gettext('Фірми у короткому списку');
                break;

            case 'riskProvisions':
                name = gettext('Положення для управління ризиками та зобов’язаннями');
                break;

            case 'bidders':
                name = gettext('Інформація про учасників');
                break;

            case 'conflictOfInterest':
                name = gettext('Виявлені конфлікти інтересів');
                break;

            case 'debarments':
                name = gettext('Відмова у допуску до закупівлі');
                break;

            case 'contractProforma':
                name = gettext('Проект договору');
                break;

            case 'contractSigned':
                name = gettext('Підписаний договір');
                break;

            case 'contractArrangements':
                name = gettext('Заходи для припинення договору');
                break;

            case 'contractSchedule':
                name = gettext('Розклад та етапи');
                break;

            case 'contractAnnexe':
                name = gettext('Додатки до договору');
                break;

            case 'contractGuarantees':
                name = gettext('Звiт про змiни в договорi');
                break;

            case 'subContract':
                name = gettext('Субпідряд');
                break;

            case 'registerFiscal':
                name = gettext('ДФС');
                break;

            case 'registerExtract':
                name = gettext('Довідка з ЄДР про бенефіціарів');
                break;

            case 'awardNotice':
                name = gettext('Повідомлення про бенефіціарів');
                break;

            case 'all':
                name = gettext('Усі документи');
                break;

            case 'others':
                name = gettext('Документи не прикреплені до лотів');
                break;
        }

        return name || input;
    };
});