commons.filter('documentTypeFilter', function() {
    return function(input) {
        var name = "";

        switch (input) {
            case 'tender':
                name = gettext('Закупка');
                break;

            case 'lot':
                name = gettext('Лот');
                break;

            case 'item':
                name = gettext('Предмет закупки');
                break;

            case 'contract':
                name = gettext('Договор');
                break;

            case 'change':
                name = gettext('Изменение');
                break;

            case 'notice':
                name = gettext('Уведомление о соглашении');
                break;

            case 'contractSigned':
                name = gettext('Подписанный договор');
                break;

            case 'contractArrangements':
                name = gettext('Меры для прекращения договора');
                break;

            case 'contractSchedule':
                name = gettext('Расписание и этапы');
                break;

            case 'contractAnnexe':
                name = gettext('Дополнение к договору');
                break;

            case 'contractGuarantees':
                name = gettext('Обеспечение тендерных предложений');
                break;

            case 'subContract':
                name = gettext('Субподряд');
                break;
        }

        return name;
    }
});