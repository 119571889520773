commons.filter('showDocsStylesFilter', () => {
    "use strict";
    return (input, showDocStyle) => {

        let output = {};
        let allowProperties = [];
        let forbiddenProperties = [];

        if (showDocStyle === 'asDates')
            allowProperties = ['all'];
        else if (showDocStyle === 'asTypes') 
            allowProperties = [ 'bids', 'bidsLots', 'bidsItems', 'financialDocuments', 'eligibilityDocuments', 'qualificationDocuments',
                                'awards', 'tender', 'qualifications', 'contract', 'cancellations', 'items', 'lots'];
        else if (showDocStyle === 'asLots')
            forbiddenProperties = [ 'bids', 'bidsLots', 'bidsItems', 'financialDocuments', 'eligibilityDocuments', 'qualificationDocuments',
                                    'awards', 'tender', 'qualifications', 'contract', 'cancellations', 'items', 'lots', 'all'];

        for (const key in input) {
            if (input.hasOwnProperty(key)
                && (!allowProperties.length || allowProperties.includes(key))
                && (!forbiddenProperties.length || !forbiddenProperties.includes(key))
            ) {
                output[key] = input[key];
            }
        }

        return output;
    };
});