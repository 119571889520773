
class calendarCtrl{
    constructor($scope,
                $log,
                uiCalendarConfig,
                $timeout,
                $compile,
                calendarService) {
            this.$scope = $scope
            this.uiCalendarConfig = uiCalendarConfig
            this.$timeout = $timeout
            this.$compile = $compile
            this.calendarService = calendarService

        this.currentDate = new Date()

        this.dates = {
                start: '',
                end: ''
        }

        this.calendarService.getEvents(this.calendarService.getCurrentMonth(this.currentDate))
            .then((data)=>{
                Object.keys(data).forEach((key)=> {
                    // console.log(data[key])
                    this.eventSources.push(data[key])
                })

            })
        this.eventSources = [];

        this.uiConfig = {
            calendar:{
                firstDay: 1,
                height: 'auto',
                editable: false,
                locale: 'uk',
                header:{
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                eventClick: this.alertOnEventClick.bind(this),
                eventRender: this.eventRender.bind(this),
                defaultButtonText: {
                    today: gettext('Сегодня'),
                },
                allDayText: gettext('весь день'),
            }
        };

    }



    alertOnEventClick( date, jsEvent, view) {
        this.alertMessage = date.content;
    };

    changeView(view,calendar){
        this.uiCalendarConfig.calendars[calendar].fullCalendar('changeView',view);
    };
    renderCalender(calendar){
        if(this.uiCalendarConfig.calendars[calendar]){
            this.uiCalendarConfig.calendars[calendar].fullCalendar('render');
        }
    };
    eventRender( event, element) {
        let node = document.createElement("md-tooltip");
        node.innerText =  event.tooltip
        element.append( node)
        this.$compile(element)(this.$scope);
    };

    timeFormat(date){

        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()
        let hour = date.getHours()
        let minute = date.getMinutes()
        return `${hour} : ${minute}`
    }

    //TODO
    changeLang() {
            if(this.changeTo === 'Hungarian'){
                    this.uiConfig.calendar.dayNames = ["Vasárnap", "Hétfő", "Kedd", "Szerda", "Csütörtök", "Péntek", "Szombat"];
                    this.uiConfig.calendar.dayNamesShort = ["Vas", "Hét", "Kedd", "Sze", "Csüt", "Pén", "Szo"];
                    this.changeTo= 'English';
                } else {
                    this.uiConfig.calendar.dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                    this.uiConfig.calendar.dayNamesShort = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                    this.changeTo = 'Hungarian';
                }
        };

    setFullNotification(notification) {
        if(notification) {
            if(!notification.is_read) {
                // this.setReadedNotification(notification);
            }
            this.fullNotification = this.fullNotification && this.fullNotification.id === notification.id ? null : notification;
        } else {
            this.fullNotification = null
        }
    }

}

calendarModule.controller('calendarCtrl', calendarCtrl);
