planningApp.controller('planningMilestonesModalCtrl', function(
    $rootScope,
    $scope,
    $uibModalInstance,
    $uibModal,
    $state,
    planningService,
    documentsService,
    data,
    ntFlash,
    $timeout,
    $filter,
    DATETIME_FROMAT
) {
    ntFlash.clean();

    $scope.isCompleteButtonDisabled = false;
    $scope.planId = data.planId;
    $scope.procuringEntity = data.procuringEntity;

    $scope.isShowStatus = data.milestones !== null;
    $scope.disableDueDateAndStatus = data.milestones && data.milestones.status !== 'scheduled';

    $scope.milestones = {
        title: data.milestones ? data.milestones.title : 'Підготовка до проведення процедури',
        description: data.milestones ? data.milestones.description : 'Узагальнення та аналіз отриманої інформації щодо проведення закупівель товарів, послуг (крім поточного ремонту) в інтересах замовників',
        type: 'approval',
        status: data.milestones ? data.milestones.status : 'scheduled',
        dueDate: data.milestones ? data.milestones.dueDate : '',
        author: {
            identifier: $scope.procuringEntity.identifier,
            name: $scope.procuringEntity.name
        },
        documents: data.milestones && data.milestones.documents ? data.milestones.documents : []
    }
    if (data.milestones) $scope.milestones.id = data.milestones.id;

    console.log('$scope.milestones', $scope.milestones);

    $scope.createOrEditMilestones = function() {
        if ($scope.milestonesForm.$valid) {
            $scope.isCompleteButtonDisabled = true;
            $scope.milestones.dueDate = $filter('date')($scope.milestones.dueDate, DATETIME_FROMAT);

            if (data.milestones === null) {
                console.log('Request', $scope.milestones);
                planningService.createMilestones($scope.planId, $scope.milestones)
                    .then(function(resData) {
                        console.log('Response', resData);
                        if (resData.data.response.status === 'scheduled') {
                            ntFlash.success(gettext('Річний план взято в роботу'));
                            $scope.milestones.id = resData.data.response.id;
                            $uibModalInstance.close();
                            $scope.sign();
                            $timeout(function() {
                                $state.reload();
                            }, 1000);
                        } else {
                            ntFlash.error(gettext('Під час даної операції виникла помилка'));
                            $scope.isCompleteButtonDisabled = false;
                        }
                    })
                    .catch(function() {
                        ntFlash.error(gettext('При спробі прийняти пропозицію виникла помилка'));
                        $scope.isCompleteButtonDisabled = false;
                    });
            } else {
                const reqData = {};
                if ($scope.milestones.dueDate !== data.milestones.dueDate) reqData.dueDate = $scope.milestones.dueDate;
                if ($scope.milestones.status !== data.milestones.status) reqData.status = $scope.milestones.status;
                if ($scope.milestones.description !== data.milestones.description) reqData.description = $scope.milestones.description;

                if ($scope.milestones.dueDate === data.milestones.dueDate &&
                    $scope.milestones.status === data.milestones.status &&
                    $scope.milestones.description === data.milestones.description) {
                    ntFlash.error(gettext('Зробіть зміни перед тим, як публікувати'));
                    $scope.isCompleteButtonDisabled = false;
                    return;
                }

                console.log('Request', reqData);
                planningService.editMilestones($scope.planId, $scope.milestones.id, reqData)
                    .then(function(resData) {
                        console.log('Response', resData);
                        if (resData.data.response) {
                            ntFlash.success(gettext('Ваша участь в цьому Річному плані успішно модифікована'));
                            $uibModalInstance.close();
                            $scope.sign();
                            $timeout(function() {
                                $state.reload();
                            }, 1000);
                        } else {
                            ntFlash.error(gettext('Під час даної операції виникла помилка'));
                            $scope.isCompleteButtonDisabled = false;
                        }
                    })
                    .catch(function() {
                        ntFlash.error(gettext('При спробі модифікувати участь у Річному плані виникла помилка'));
                        $scope.isCompleteButtonDisabled = false;
                    });
            }
        }
    };

    $scope.sign = function() {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function() {
                        return {
                            planId: $scope.planId,
                            milestoneId: $scope.milestones.id,
                            documents: $filter('versionFilter')($scope.milestones.documents) || []
                        }
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                planId: $scope.planId,
                milestoneId: $scope.milestones.id,
                documents: $filter('versionFilter')($scope.milestones.documents) || []
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
});