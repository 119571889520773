class CurrencyListService {
    constructor() {
        this.getCurrenciesList = () => {
            return [
                'UAH',
                'USD',
                'EUR',
                'GBP',
                'RUB'
            ];
        };
    }
}
commons.service('currencyListService', CurrencyListService);