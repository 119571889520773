class tenderRejectReasonsListService {
    constructor() {
        this.getRejectReasonsList = (procurementMethodType, procurementMethodRationale) => {
            if (procurementMethodRationale === 'simple') {
                return [
                    {
                        checked: false,
                        title: 'пропозиція учасника не відповідає умовам, визначеним в оголошенні про проведення спрощеної закупівлі, та вимогам до предмета закупівлі'
                    },
                    {
                        checked: false,
                        title: 'учасник не надав забезпечення пропозиції, якщо таке забезпечення вимагалося замовником'
                    },
                    {
                        checked: false,
                        title: 'учасник, який визначений переможцем спрощеної закупівлі, відмовився від укладення договору про закупівлю'
                    },
                    {
                        checked: false,
                        title: 'якщо учасник протягом одного року до дати оприлюднення оголошення про проведення спрощеної закупівлі відмовився від підписання договору про закупівлю (у тому числі через неукладення договору з боку учасника) більше двох разів із замовником, який проводить таку спрощену закупівлю'
                    },
                ];
            } else if (procurementMethodType === 'aboveThresholdUA.defense' || procurementMethodType === 'simple.defense') {
                return [
                    {
                        checked: false,
                        title: 'ненадання або несвоєчасне надання учасником одиниці товару на перевірку відповідності зразку-еталону (якщо подання одиниці товару вимагалося в оголошенні про проведення відбору) та/або іншим вимогам замовника до предмета закупівлі, зазначеним в оголошенні про проведення відбору, або документів про підтвердження відповідності товару, роботи чи послуги технічним, якісним та кількісним характеристикам предмета закупівлі, визначеним замовником'
                    },
                    {
                        checked: false,
                        title: 'невідповідність товарів, робіт чи послуг учасника технічним, якісним та кількісним характеристикам предмета закупівлі'
                    },
                    {
                        checked: false,
                        title: 'відсутність підтвердження подання забезпечення цінової пропозиції (якщо подання забезпечення вимагалося замовником в оголошенні про проведення відбору)'
                    },
                    {
                        checked: false,
                        title: 'відмова або неучасть учасника відбору в переговорах, призначених замовником, у визначену замовником дату їх проведення'
                    },
                    {
                        checked: false,
                        title: 'відмова учасника відбору від участі в переговорах з ціною/приведеною ціною, поданою ним за результатами проведеного аукціону'
                    },
                    {
                        checked: false,
                        title: 'невідповідність учасника кваліфікаційним критеріям та вимогам, визначеним у статті 17 Закону України "Про публічні закупівлі"'
                    },
                    {
                        checked: false,
                        title: 'неусунення учасником недоліків у поданих ним документах протягом наступних 24 годин з моменту ознайомлення учасника з такими недоліками під час проведення переговорів'
                    },
                    {
                        checked: false,
                        title: 'не брав участі або відмовився від участі в переговорній процедурі закупівлі до оприлюднення повідомлення про намір укласти договір - більше трьох разів'
                    },
                    {
                        checked: false,
                        title: 'відмовився від підписання договору про закупівлю у визначені замовником терміни - більше двох разів'
                    },
                    {
                        checked: false,
                        title: 'не виконав умови (умов) договору щодо якості та/або терміну поставлених товарів, виконаних робіт, наданих послуг - більше одного разу'
                    },
                ];
            } else if (procurementMethodType === 'aboveThreshold' || procurementMethodType === 'competitiveOrdering') {
                return [
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не відповідає кваліфікаційним (кваліфікаційному) критеріям, установленим статтею 16 Закону та/або наявні підстави, встановлені частиною першою статті 17 Закону'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури не відповідає встановленим абзацом першим частини третьої статті 22 Закону вимогам до учасника відповідно до законодавства'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури зазначив у тендерній пропозиції недостовірну інформацію, що є суттєвою при визначенні результатів процедури закупівлі, яку замовником виявлено згідно з частиною п’ятнадцятою статті 29 Закону'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі не надав забезпечення тендерної пропозиції, якщо таке забезпечення вимагалося замовником, та/або забезпечення тендерної пропозиції не відповідає умовам, що визначені замовником у тендерній документації до такого забезпечення тендерної пропозиції'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі не виправив виявлені замовником після розкриття тендерних пропозицій невідповідності в інформації та/або документах, що подані ним у своїй тендерній пропозиції, протягом 24 годин з моменту розміщення замовником в електронній системі закупівель повідомлення з вимогою про усунення таких невідповідностей'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі не надав обґрунтування аномально низької ціни тендерної пропозиції протягом строку, визначеного в частині чотирнадцятій статті 29 Закону'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі визначив конфіденційною інформацію, що не може бути визначена як конфіденційна відповідно до вимог частини другої статті 28 Закону'
                    },
                    {
                        checked: false,
                        title: 'учасник не надав належного обґрунтування ціни або вартості тендерної пропозиції, що була визначена електронною системою закупівель як аномально низька (відповідно до частини 14 статті 29 Закону)'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі не виконав свої зобов’язання за раніше укладеним договором про закупівлю з цим самим замовником, що призвело до його дострокового розірвання, і було застосовано санкції у вигляді штрафів та/або відшкодування збитків протягом трьох років з дати дострокового розірвання такого договору.'
                    },
                    {
                        checked: false,
                        title: 'тендерна пропозиція не відповідає умовам технічної специфікації та іншим вимогам щодо предмета закупівлі тендерної документації'
                    },
                    {
                        checked: false,
                        title: 'тендерна пропозиція викладена іншою мовою (мовами), аніж мова (мови), що вимагається тендерною документацією'
                    },
                    {
                        checked: false,
                        title: 'тендерна пропозиція є такою, строк дії якої закінчився'
                    },
                    {
                        checked: false,
                        title: 'переможець процедури закупівлі відмовився від підписання договору про закупівлю відповідно до вимог тендерної документації або укладення договору про закупівлю'
                    },
                    {
                        checked: false,
                        title: 'переможець процедури закупівлі не надав у спосіб, зазначений в тендерній документації, документи, що підтверджують відсутність підстав, установлених статтею 17 Закону'
                    },
                    {
                        checked: false,
                        title: 'переможець процедури закупівлі не надав копію ліцензії або документа дозвільного характеру (у разі їх наявності) відповідно до частини другої статті 41 Закону'
                    },
                    {
                        checked: false,
                        title: 'переможець процедури закупівлі не надав забезпечення виконання договору про закупівлю, якщо таке забезпечення вимагалося замовником'
                    },
                ];
            } else {
                return [
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не відповідає кваліфікаційним (кваліфікаційному) критеріям, установленим статтею 16 Закону та/або наявні підстави, встановлені частиною першою статті 17 Закону'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не відповідає встановленим абзацом першим частини третьої статті 22 Закону вимогам до учасника відповідно до законодавства'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі зазначив у тендерній пропозиції недостовірну інформацію, що є суттєвою при визначенні результатів процедури закупівлі, яку замовником виявлено згідно з частиною п’ятнадцятою статті 29  Закону'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не надав забезпечення тендерної пропозиції, якщо таке забезпечення вимагалося замовником, та/або забезпечення тендерної пропозиції не відповідає умовам, що визначені замовником у тендерній документації до такого забезпечення тендерної пропозиції'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не виправив виявлені замовником після розкриття тендерних пропозицій невідповідності в інформації та/або документах, що подані ним у своїй тендерній пропозиції, протягом 24 годин з моменту розміщення замовником в електронній системі закупівель повідомлення з вимогою про усунення таких невідповідностей'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі не надав обґрунтування аномально низької ціни тендерної пропозиції протягом строку, визначеного в частині чотирнадцятій статті 29 Закону'
                    },
                    {
                        checked: false,
                        title: 'Учасник процедури закупівлі визначив конфіденційною інформацію, що не може бути визначена як конфіденційна відповідно до вимог частини другої статті 28 Закону'
                    },
                    {
                        checked: false,
                        title: 'учасник не надав належного обґрунтування ціни або вартості тендерної пропозиції, що була визначена електронною системою закупівель як аномально низька  (відповідно до частини 14 статті 29 Закону)'
                    },
                    {
                        checked: false,
                        title: 'учасник процедури закупівлі не виконав свої зобов’язання за раніше укладеним договором про закупівлю з цим самим замовником, що призвело до його дострокового розірвання, і було застосовано санкції у вигляді штрафів та/або відшкодування збитків протягом трьох років з дати дострокового розірвання такого договору.'
                    },
                    {
                        checked: false,
                        title: 'Тендерна пропозиція учасника не відповідає умовам технічної специфікації та іншим вимогам щодо предмета закупівлі тендерної документації'
                    },
                    {
                        checked: false,
                        title: 'Тендерна пропозиція учасника викладена іншою мовою (мовами), аніж мова (мови), що вимагається тендерною документацією'
                    },
                    {
                        checked: false,
                        title: 'Тендерна пропозиція учасника є такою, строк дії якої закінчився'
                    },
                    {
                        checked: false,
                        title: 'Переможець процедури закупівлі відмовився від підписання договору про закупівлю відповідно до вимог тендерної документації або укладення договору про закупівлю'
                    },
                    {
                        checked: false,
                        title: 'Переможець процедури закупівлі не надав у спосіб, зазначений в тендерній документації, документи, що підтверджують відсутність підстав, установлених статтею 17 Закону'
                    },
                    {
                        checked: false,
                        title: 'Переможець процедури закупівлі не надав копію ліцензії або документа дозвільного характеру (у разі їх наявності) відповідно до частини другої статті 41 Закону'
                    },
                    {
                        checked: false,
                        title: 'Переможець процедури закупівлі не надав забезпечення виконання договору про закупівлю, якщо таке забезпечення вимагалося замовником'
                    },
                ];
            }
        };
    }
}
commons.service('tenderRejectReasonsListService', tenderRejectReasonsListService);
