class ProfileRequestToCompany {
    constructor(profileService, loaderService, COMPANY_REQUEST_STATUSES) {
        this.profileService = profileService;
        this.loader = loaderService;

        this.statuses = {
            [COMPANY_REQUEST_STATUSES.PENDING]: gettext('Ожидает рассмотрения'),
            [COMPANY_REQUEST_STATUSES.ACCEPT]: gettext('Запрос принят'),
            [COMPANY_REQUEST_STATUSES.REJECT]: gettext('Запрос отклонен')
        }
    }
    removeRequestToCompany() {
        this.loader.startLoading();
        this.profileService
            .removeRequestToCompany(this.request)
            .then(() => {
                this.request = null;
                this.profileService.setRequestsToCompanies([]);
            })
            .finally(() => this.loader.stopLoading())
    }
}

profileApp.component('profileRequestToCompany', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-request-to-company.html',
    controller: ProfileRequestToCompany,
    controllerAs: 'vm',
    bindings: {
        request: '='
    }
});