class DeliveryAddress {
    constructor(
        $mdDialog,
        formationService,
    ) {
        this.formationService = formationService;
        this.mdDialog = $mdDialog;
        this.deliveryAddressTitle= null;
    }

    $onInit() {
        // this.currentState = this.state.current.name;  // After close we must returned to this state
        if (this.item.deliveryAddress && this.item.deliveryAddress.locality) {
            this.deliveryAddressTitle = this.formationService.address(this.item.deliveryAddress);
        }
    }

    showAddressPopUp() {
        this.mdDialog.show({
            templateUrl: '/static/scripts/modules/TenderEdit/views/modals/create-tender.show-address.modal.html',
            controller: 'showAddressModalCtrl',
            controllerAs: 'vm',
            resolve: {
                data: () => {
                    return this.item;
                },
                tenderType: ()=>{
                    return this.tenderType || '';
                }
            }
        }).then((data) => {
            if(data) {
                this.item.deliveryAddress = data.deliveryAddress;
                this.item.deliveryLocation = data.deliveryLocation;
                this.deliveryAddressTitle = data.field;
            }
        });

    }

    clearDeliveryAddress() {
        this.item.deliveryAddress = {
            countryName: "",
            postalCode: "",
            region: "",
            locality: "",
            streetAddress: "",
        };
        this.item.deliveryLocation = null;
        this.deliveryAddressTitle= null;
    }

}

tenderEdit.component('deliveryAddress', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/delivery-address.html',
    controller: DeliveryAddress,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        item: '<',
        tenderType: '<',
        itemIndex: '<?',
        ngDisabled: '<',
        ngRequired: '<',
    }
});