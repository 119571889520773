commons.filter('documentPrivateCaption', () => {
    "use strict";
    return (input) => {
        let status = "";

        switch (input) {
            case 'public':
                status = gettext('Публичный');
                break;

            case 'buyerOnly':
                status = gettext('Конфиденциальный');
                break;
        }

        return status;
    }
});