tenderView.factory('complaintService', function(http) {
    var createResource = 'tender/:tenderId/complaints/';
    var createAwardResource = 'tender/:tenderId/awards/:awardId/complaints/';
    var createQualificationResource = 'tender/:tenderId/qualifications/:qualificationId/complaints/';
    var createCancellationResource = 'tender/:tenderId/cancellations/:cancellationId/complaints/';
    var editResource = 'tender/:tenderId/complaints/:complaintId';
    var editAwardResource = 'tender/:tenderId/awards/:awardId/complaints/:complaintId';
    var editQualificationResource = 'tender/:tenderId/qualifications/:qualificationId/complaints/:complaintId';
    var editCancellationResource = 'tender/:tenderId/cancellations/:cancellationId/complaints/:complaintId';
    var getResource = 'tender/:tenderId/complaints/:complaintId';
    var getAwardResource = 'tender/:tenderId/awards/:awardId/complaints/:complaintId';
    var getQualificationResource = 'tender/:tenderId/qualifications/:qualificationId/complaints/:complaintId';
    var getCancellationResource = 'tender/:tenderId/cancellations/:cancellationId/complaints/:complaintId';
    var getListResource = 'tender/:tenderId/complaints/';
    var getAwardListResource = 'tender/:tenderId/awards/:awardId/complaints/';
    var getQualificationListResource = 'tender/:tenderId/qualifications/:qualificationId/complaints/';
    var getCancellationListResource = 'tender/:tenderId/cancellations/:cancellationId/complaints/';
    var getLiqpayResource = 'tender/:tenderId/complaints/:complaintId/checkout/liqpay?iban=:iban&edrpo=:edrpo&result_url=:result_url';
    var getInvoiceResource = '/opc/tender/:tenderId/complaints/:complaintId/checkout/bank/?iban=:iban&edrpo=:edrpo&result_url=:result_url';

    return {
        create: function(tenderId, data, awardId, qualificationId, cancellationId) {
            if (awardId) {
                var saveUrl = createAwardResource.replace(/:tenderId/g, tenderId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var saveUrl = createQualificationResource.replace(/:tenderId/g, tenderId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var saveUrl = createCancellationResource.replace(/:tenderId/g, tenderId).replace(/:cancellationId/g, cancellationId);
            } else {
                var saveUrl = createResource.replace(/:tenderId/g, tenderId);
            }
            return http.post(saveUrl, data);
        },
        edit: function(tenderId, data, complaintId, awardId, qualificationId, cancellationId) {
            if (awardId) {
                var saveUrl = editAwardResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var saveUrl = editQualificationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var saveUrl = editCancellationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:cancellationId/g, cancellationId);
            } else {
                var saveUrl = editResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId);
            }
            return http.patch(saveUrl, data);
        },
        getLinkForSign: (tenderId, complaintId, awardId, qualificationId, cancellationId) =>{
            const getAwardListRes = 'tenders/:tenderId/awards/:awardId/complaints/:complaintId';
            const getQualificationListRes = 'tenders/:tenderId/qualifications/:qualificationId/complaints/:complaintId';
            const getCancellationListRes = 'tenders/:tenderId/cancellations/:cancellationId/complaints/:complaintId';
            const editRes = 'tenders/:tenderId/complaints/:complaintId';

            if (awardId) {
                var saveUrl = getAwardListRes.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var saveUrl = getQualificationListRes.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var saveUrl = getCancellationListRes.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:cancellationId/g, cancellationId);
            } else {
                var saveUrl = editRes.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId);
            }
            return saveUrl
        },
        getLinkForSignDoc: (tenderId, complaintId, awardId, qualificationId, cancellationId) =>{
            if (awardId) {
                var saveUrl = editAwardResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var saveUrl = editQualificationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var saveUrl = editCancellationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:cancellationId/g, cancellationId);
            } else {
                var saveUrl = editResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId);
            }
            return saveUrl
        },
        get: function(tenderId, complaintId, awardId, qualificationId, cancellationId) {
            if (awardId) {
                var getUrl = getAwardResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var getUrl = getQualificationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var getUrl = getCancellationResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId).replace(/:cancellationId/g, cancellationId);
            } else {
                var getUrl = getResource.replace(/:tenderId/g, tenderId).replace(/:complaintId/g, complaintId);
            }
            return http.get(getUrl)
                .then(function(result) {
                    //console.log("tenderComplaintService.get:");
                    //console.log(result);
                    return result.data;
                });
        },
        getList: function(tenderId, awardId, qualificationId, cancellationId) {
            if (awardId) {
                var getUrl = getAwardListResource.replace(/:tenderId/g, tenderId).replace(/:awardId/g, awardId);
            } else if (qualificationId) {
                var getUrl = getQualificationListResource.replace(/:tenderId/g, tenderId).replace(/:qualificationId/g, qualificationId);
            } else if (cancellationId) {
                var getUrl = getCancellationListResource.replace(/:tenderId/g, tenderId).replace(/:cancellationId/g, cancellationId);
            } else {
                var getUrl = getListResource.replace(/:tenderId/g, tenderId);
            }
            return http.get(getUrl)
                .then(function(result) {
                    //console.log("tenderComplaintService.getList:");
                    //console.log(result);
                    return result.data;
                });
        },
        getPaymentData: function(tenderId, complaintId, data) {
            var url = data.paymentType ? getLiqpayResource : getInvoiceResource;
            var payUrl = url
                .replace(/:tenderId/g, tenderId)
                .replace(/:complaintId/g, complaintId)
                .replace(/:iban/g, data.iban)
                .replace(/:edrpo/g, data.edrpo)
                .replace(/:result_url/g, encodeURIComponent(data.result_url));
            return data.paymentType
                ? http.get(payUrl)
                    .then(function(result) {
                        return result.data;
                    })
                : Promise.resolve({
                        url_checkout: payUrl
                    });
        },
    }
});
