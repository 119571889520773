class UserCompanyController {
    constructor(
        loaderService,
        profileService,
        requestsToTheCompany,
        userCompanies,
        companyService,
        $state,
        ntFlash
) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.companyService = companyService;
        this.$state = $state;
        this.ntFlash = ntFlash;
        
        this.userCompany = userCompanies[0];
        this.userRequestToCompany = requestsToTheCompany[0];
        
        this.company = null;
        this.requestToCompany = null;

        this.isCompanyExist = false;
    }

    createCompany(company) {
        this.company = company;
        const data = {
            registration_number: this.company.registration_number,
            iati_ora_code: this.company.iati_ora_code,
            is_government: this.company.is_government,
            name: this.company.name,
            procuring_kind: this.company.procuring_kind,
            legal_address: this.company.legal_address
        };
        this.loader.startLoading();
        this.profileService.createCompany(data)
            .then(result => {
                this.userCompany = result.data;
                this.profileService.reloadCurrentUser();
                this.companyService.setCurrent(result.data);
                //this.$state.go('profile.company-created');
                window.location.href="/opc/provider/profile/company-created";
            })
            .catch((error) => {
                if(error.status===409) {
                    this.isCompanyExist = true;
                }
            })
            .finally(() => this.loader.stopLoading());
    }

    sendRequestToCompany(company) {
        this.company = company;

        const user = this.profileService.current;
        const data = {
            registration_number: this.company.registration_number,
            iati_ora_code: this.company.iati_ora_code,
        };
        this.loader.startLoading();
        this.profileService
            .sendRequestToCompany(user, data)
            .then((result) => {
                this.userRequestToCompany = result.data;
                this.profileService.setRequestsToCompanies([result.data]);
            })
            .catch((error) => {
                //this.userRequestToCompany = {};
                if(error.status===400) {
                    this.ntFlash.error(gettext('Ошибка данных компании'))
                }
            })
            .finally(() => this.loader.stopLoading());
    }

    clearCompanyCodes() {
        this.company = null;
        this.requestToCompany = null;
        this.isCompanyExist = false;
    }

}

profileApp.controller('userCompanyController', UserCompanyController);