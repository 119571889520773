profileApp.controller('receiptsCtrl', function (
    $scope,
    ntFlash,
    receiptsService
) {
    ntFlash.clean();
    $scope.receipts = [];
    $scope.nextPage = null;

    $scope.getTypeString = function(type) {
        let typesMap = new Map([
            ['tender_create', 'Створення Закупівлі'],
            ['tender_edit', 'Редагування Закупівлі'],
            ['tender_cancel', 'Скасування Закупівлі'],
            ['tender_documents', 'Завантаження Документу Закупівлі'],
            ['tender_questions', 'Задано Питання'],
            ['tender_answer', 'Отримана Відповідь'],
            ['tender_qualifications', 'Активність стосовно проходження Кваліфікації'],
            ['tender_awards', 'Активність стосовно проведення Конкурсу'],
            ['tender_complaints', 'Активність стосовно Оскарження'],
            ['tender_bids', 'Активність стосовно участі у Тендері'],
            ['plan_create', 'Створення Плану'],
            ['plan_edit', 'Редагування Плану'],
            ['plan_cancel', 'Скасування Плану'],
            ['contract_changes', 'Зміни до Договору'],
            ['contract_documents', 'Завантаження Документу Договору'],
            ['contract_complete', 'Завершення Договору'],
            ['agreements_changes', 'Зміни до Угоди'],
            ['agreements_documents', 'Завантаження Документу Угоди'],
            ['agreements_complete', 'Завершення Угоди']
        ]);
        return typesMap.get(type) || '';
    }

    $scope.getReceipts = function (nextPage) {
        receiptsService.getReceipts(nextPage)
            .then(function (result) {
                // console.log(result);
                result.data.results.forEach(function (receipt) {
                    $scope.receipts.push(receipt);
                });
                $scope.nextPage = result.data.count ? result.data.next : null;
            });
    };

    $scope.getReceipts();
});