class InfoMessageController {
    constructor(infoMessageService) {
        this.info = infoMessageService;
    }
    clearMessage() {
        this.info.clearMessage();
    }
}

commons.component('infoMessage', {
    template: `
        <div class="info-message" ng-if="vm.info.message">
            <i ng-if="vm.info.message.is_can_close"
               class="glyphicon glyphicon-remove pull-right"
               ng-click="vm.clearMessage()"></i>
            <span ng-bind="vm.info.message.text"></span>
        </div>
    `,
    controller: InfoMessageController,
    controllerAs: 'vm'
});