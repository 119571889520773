commons.filter('agreementStatus', () => {
    "use strict";

    const STATUS_MSG = {
        'pending': gettext('Підготовка до укладання рамкової угоди'),
        'active': gettext('Укладена рамкова угода'),
        'unsuccessful': gettext('Неукладена рамкова угода через недостатню кількість учасників'),
        'cancelled': gettext('Неукладена рамкова угода з інших причин'),
        'terminated': gettext('Виконана рамкова угода'),
    };

    return (status, termDetail) => {
        if (status === 'terminated' && termDetail && termDetail.length > 0) {
            return gettext('Розірвана рамкова угода')
        }
        return STATUS_MSG[status] || status;
    }
});
