profileApp.factory('userContactsService', function (
    profileService,
    http,
    $http,
    $window,
    API_BASE_URL,
    $q
) {

    const URL_USER_CONTACTS = `${API_BASE_URL}/users/:userID/contacts`;
    const URL_USER_CONTACT = `${URL_USER_CONTACTS}/:contactID`;
    const URL_USER_CONTACT_SET_DEFAULT = `${URL_USER_CONTACT}/set-default`;
    const URL_USER_CONTACT_SET_CONFIRMED = `${URL_USER_CONTACT}/set-confirmed`;
    const URL_USER_CONTACTS_COMPLETE_CONFIRMATION = `${URL_USER_CONTACTS}/confirm/:code`;

    let contacts = null;

    let service = {
        getList: (userID) => {
            if (!contacts) {
                const url = URL_USER_CONTACTS.replace(':userID', userID);
                contacts = http.get(url, true);
            }
            return contacts;
        },

        create: (userID, data) => {
            const url = URL_USER_CONTACTS.replace(':userID', userID);
            return http.post(url, data, true);
        },

        update: (userID, contactID, data) => {
            const url = URL_USER_CONTACT.replace(':userID', userID)
                .replace(':contactID', contactID);
            return http.patch(url, data, true);
        },

        remove: (userID, contactID) => {
            const url = URL_USER_CONTACT.replace(':userID', userID)
                .replace(':contactID', contactID);
            return http.delete(url, true);
        },

        setDefault: (userID, contactID) => {
            const url = URL_USER_CONTACT_SET_DEFAULT
                .replace(':userID', userID)
                .replace(':contactID', contactID);
            return http.post(url, null, true);
        },

        setConfirmed: (userID, contactID) => {
            const url = URL_USER_CONTACT_SET_CONFIRMED
                .replace(':userID', userID)
                .replace(':contactID', contactID);
            return http.post(url, null, true);
        },

        completeConfirmation: (userID, code) => {
            const url = URL_USER_CONTACTS_COMPLETE_CONFIRMATION
                .replace(':userID', userID)
                .replace(':code', code);
            return http.post(url, null, true);
        }
    };

    return service;
});