contractApp.controller('contractListAllCtrl', function(
    $scope,
    $rootScope,
    $uibModal
) {

    $scope.showCustomer = function($event, procuringEntity) {
        $event.stopPropagation();
        $event.preventDefault();

        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function() {
                    return procuringEntity;
                }
            },
            windowClass: 'center-modal'
        });
    };
});