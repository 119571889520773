tenderView.controller('tenderViewAuctionBidCtrl', function (
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $uibModal,
    $interval,
    tenderBidService,
    documentsService,
    uControlService,
    tenderService,
    tenderData,
    ntFlash,
    awardsService,
    OPC_CONFIG
) {
    var bidId = $stateParams.bidId;
    var lotId = $stateParams.lotId;
    var awardStatus = null;
    var awardDocuments = [];
    var tenderDocuments = [];
    var lotDocuments = [];
    var itemsDocuments = [];
    var lotStatus = null;
    var tenderStatus = null;
    $scope.baseUrl = OPC_CONFIG.OPC_API_PUBLIC_BASE_URL

    $scope.awardId = null;
    $scope.milestones = [];
    $scope.files = [];
    $scope.isWinner = false;
    $scope.isCancelable = false;
    $scope.currentLot = $stateParams.lotId;

    $scope.additionalInfoLink = '';
    $scope.additionalInfoTimer = 90;
    $scope.additionalInfoError = false;
    $scope.additionalInfoInProgress = false;
    $scope.additionalInfoEnable = OPC_CONFIG.YOU_CONTROL_ENABLE;

    $scope.procurementMethodType = tenderData.data.procurementMethodType;
    $scope.isAgreementClose = $scope.procurementMethodType === 'closeFrameworkAgreementSelectionUA';

    $scope.lots = tenderData.data.lots || [];
    $scope.items = tenderData.data.items || [];
    lotItems = [];
    $scope.items.forEach(function (item) {
        if (item.relatedLot === lotId) {
            lotItems.push(item.id);
        }
    });
    $scope.filterFunc = (document) => {
        return (!$scope.currentLot || document.relatedItem === $scope.currentLot) ||
            document.documentOf === 'tender' ||
            lotItems.includes(document.relatedItem)

    }

    $scope.isMultilotTender = $scope.lots && $scope.lots.length ? true : false;

    $scope.isTenderOwner = tenderData.action && tenderData.action.tender_owner;

    $scope.isPMT = (name) => tenderService.isProcurementMethodType(tenderData.data, name);

    $scope.setIsCancelable = function (award) {

        if ($scope.isMultilotTender) {
            $scope.lots.forEach(function (lot) {
                if (lot.id === award.lotID) {
                    lotStatus = lot.status;
                }
            });
        } else {
            if (tenderData.data.status === 'active.awarded') {
                tenderStatus = 'active.awarded';
            }
        }

        if ($scope.isPMT('closeFrameworkAgreementUA')) {
            if (['active.qualification'].indexOf(tenderData.data.status) > -1
                && ['active', 'unsuccessful'].indexOf(award.status) > -1) {

                $scope.isCancelable = true;

            }
            if (['active.qualification.stand-still'].indexOf(tenderData.data.status) > -1
                && ['active'].indexOf(award.status) > -1) {
                $scope.isCancelable = true;
            }

        } else if (award.status === 'unsuccessful' && award.complaints && award.complaints.length) {
            award.complaints.forEach(function (complaint) {
                // TODO comment it
                if (
                    (
                        (
                            lotStatus === 'active' ||
                            tenderStatus === 'active.awarded'
                        ) &&
                        complaint.type != "claim" &&
                        (
                            (complaint.status === 'claim' && tenderData.data.procurementMethodType === 'belowThreshold') ||
                            complaint.status === 'answered' ||
                            complaint.status === 'pending' ||
                            complaint.status === 'satisfied'
                        )
                    ) ||
                    (
                        (
                            lotStatus === 'unsuccessful' ||
                            tenderStatus === 'active.awarded'
                        ) &&
                        complaint.status === 'satisfied'
                    ) ||
                    (
                        complaint.type === "claim" &&
                        complaint.status === 'claim' &&
                        tenderData.data.procurementMethodType === 'belowThreshold'
                    )
                ) {
                    $scope.isCancelable = true;
                }
            });
        } else if (
            (
                lotStatus === 'active' ||
                tenderStatus === 'active.awarded'
            ) &&
            award.status === 'active'
        ) {
            $scope.isCancelable = true;
        }
    };

    tenderBidService.get($scope.tenderCdbId, bidId)
        .then(function (data) {
            $scope.bid = data.response;
            $scope.actions = tenderData.action;
            $scope.awards = tenderData.data.awards;
            if ($scope.awards && $scope.awards.length) {
                if ($scope.isMultilotTender) {
                    $scope.awards.forEach(function (award) {
                        if (bidId === award.bid_id && lotId === award.lotID) {
                            award.status === 'pending' ?
                                $scope.isWinner = true :
                                $scope.setIsCancelable(award);

                            $scope.awardId = award.id;
                            $scope.milestones = award.milestones || [];
                            awardStatus = award.status;
                            awardDocuments = award.documents;
                            $scope.title = award.title;
                            $scope.description = award.description;
                        }
                    });
                    $scope.bid.lotValues.forEach(function (lotValue) {
                        if (lotValue.relatedLot == lotId) {
                            $scope.bidAmount = lotValue.value.amount;
                            $scope.bidCurrency = lotValue.value.currency;
                            $scope.subcontractingDetails = lotValue.subcontractingDetails;
                        }
                    });
                } else {
                    $scope.awards.forEach(function (award) {
                        if ($scope.bid.id == award.bid_id) {
                            award.status == 'pending' ?
                                $scope.isWinner = true :
                                $scope.setIsCancelable(award);

                            $scope.awardId = award.id;
                            $scope.milestones = award.milestones || [];
                            awardStatus = award.status;
                            awardDocuments = award.documents;
                        }
                    });
                    $scope.bidAmount = $scope.bid.value.amount;
                    $scope.bidCurrency = $scope.bid.value.currency;
                    $scope.subcontractingDetails = $scope.bid.subcontractingDetails;
                }

                // For closeFrameworkAgreementSelectionUA show "Reject" if awards exists status pending and cancelled
                if ($scope.isWinner) {
                    if ($scope.isPMT('closeFrameworkAgreementSelectionUA')) {
                        $scope.awards.forEach(function (award) {
                            if (($scope.isMultilotTender && bidId === award.bid_id && lotId === award.lotID && award.status === 'cancelled') ||
                                (bidId === award.bid_id && lotId === award.lotID && award.status === 'cancelled')) {
                                $scope.isReject = true;
                            }
                        });
                    } else {
                        $scope.isReject = true;
                    }
                }
                data.response.documents.forEach(function (document) {
                    if (document.documentOf === 'tender') {
                        tenderDocuments.push(document);
                    }
                    if (document.documentOf === 'lot' && document.relatedItem == lotId) {
                        lotDocuments.push(document);
                    }
                    if (document.documentOf === 'item') {
                        for (var item in lotItems) {
                            if (lotItems[item] == document.relatedItem) {
                                itemsDocuments.push(document);
                            }
                        }
                    }
                });

                $scope.documents = {
                    tender: tenderDocuments,
                    lots: lotDocuments,
                    items: itemsDocuments,
                    // bids: data.response.documents,
                    awardDocuments: awardDocuments,
                    financialDocuments: data.response.financialDocuments,
                    eligibilityDocuments: data.response.eligibilityDocuments,
                    qualificationDocuments: data.response.qualificationDocuments
                };
                let countDocs =
                    ($scope.documents.bids || []).length +
                    ($scope.documents.awardDocuments || []).length +
                    ($scope.documents.financialDocuments || []).length +
                    ($scope.documents.eligibilityDocuments || []).length +
                    ($scope.documents.qualificationDocuments || []).length;
                if (countDocs > 1) {
                    $scope.isAllowedDownloadAllDocuments = true;
                }

            } else {
                if (data.response.documents) {
                    data.response.documents.forEach(function (document) {
                        if (document.documentOf === 'tender') {
                            tenderDocuments.push(document);
                        }
                        if (document.documentOf === 'lot' && document.relatedItem == lotId) {
                            lotDocuments.push(document);
                        }
                        if (document.documentOf === 'item') {
                            for (var item in lotItems) {
                                if (lotItems[item] == document.relatedItem) {
                                    itemsDocuments.push(document);
                                }
                            }
                        }
                    });
                    $scope.documents = {
                        tender: tenderDocuments,
                        lots: lotDocuments,
                        items: itemsDocuments
                    };
                    if ((data.response.documents || []).length > 1) {
                        $scope.isAllowedDownloadAllDocuments = true;
                    }
                }
            }
        });

    $scope.requirement = function(awardId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.bids.requirement.modal.html",
            controller: "tenderViewBidsRequirementModalCtrl",
            resolve: {
                data: function () {
                    return {
                        tenderId: $scope.tenderCdbId,
                        awardId: awardId
                    }
                }
            },
            backdrop: 'static'
        });
    }

    $scope.decide = function (status) {
        // if(awardsService.hasAcceptedComplaints(tenderData.data, $scope.awardId)) {
        //     ntFlash.error(gettext('HAS_ACCEPTED_COMPLAINTS_ERROR_MESSAGE'));
        //     return;
        // }

        // if (status === 'unsuccessful' && $scope.isPMT('closeFrameworkAgreementUA')) {
        //     $scope.cancel('unsuccessful')
        // } else {
            var isAccepted = status === 'active';
            return $uibModal.open({
                templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.bids.modal.html",
                controller: "tenderViewBidsModalCtrl",
                resolve: {
                    modalData: function () {
                        return {
                            isAccepted: isAccepted,
                            awardId: $scope.awardId,
                            status: status,
                            awardStatus: awardStatus,
                            isCancelable: $scope.isCancelable,
                            procurementMethodType: tenderData.data.procurementMethodType,
                            procurementMethodRationale: tenderData.data.procurementMethodRationale,
                            tenderCdbId: $scope.tenderCdbId,
                            bidId: bidId,
                            criterias: tenderData.data.criteria && tenderData.data.criteria.filter(criteria => criteria.source === 'procuringEntity'),
                            tenderData: tenderData
                        };
                    }
                },
                backdrop: 'static'
            });
        // }
    };

    $scope.cancel = (status) => {

        if(!$scope.awardId) return;

        awardsService.save({
            tenderId: tenderData.data.id,
            awardId: $scope.awardId
        }, {
            status: status || 'cancelled'
        })
            .then(function () {
                if (status === 'cancelled') {
                    ntFlash.error(gettext('Решение отменено'));
                } else if (status === 'unsuccessful') {
                    ntFlash.error(gettext('Участник отклонен'));
                } else {
                    ntFlash.error(status);
                }
                $state.go('tenderView.auction', null, {
                    reload: true
                });
            });
    };

    $scope.sign = function () {
        if ($rootScope.oldEds) {
            return $uibModal.open({
                templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
                controller: 'eSignModalCtrl',
                resolve: {
                    data: function () {
                        return {
                            tenderId: tenderData.data.id,
                            awardId: $scope.awardId,
                            documents: $scope.documents.awardDocuments
                        };
                    }
                },
                windowClass: 'center-modal'
            });
        } else {
            const data = {
                tenderId: tenderData.data.id,
                awardId: $scope.awardId,
                documents: $scope.documents.awardDocuments    
            };
            documentsService.sign(data)
                .then(data => console.log('documentsService.sign data', data))
                .catch(error => console.log('documentsService.sign error', error));
        }
    };

    $scope.loadAdditionalInfo = function (edrpou) {
        $scope.additionalInfoTimer = 90;
        $scope.additionalInfoInProgress = true;
        var progress = $interval(function () {
            $scope.additionalInfoTimer--;
        }, 1000, 90);
        uControlService.get(edrpou)
            .then(function (response) {
                // console.log(response);
                $scope.additionalInfoLink = response.data.response.url;
            })
            .catch(function (response) {
                $scope.additionalInfoInProgress = false;
                if (angular.isDefined(progress)) {
                    $interval.cancel(progress);
                    progress = undefined;
                }
            });
    };

    $scope.openNazk = () => {
        console.log( `${$scope.baseUrl}/tenders/${tenderData.data.id}/awards/${$scope.awardId}`)
        console.log( $scope.awards)

        window.eds.init()
            .then(()=>{

               return window.signToDoc.init(window.eds)


            })
            .then(()=>{
                const config = {
                    url: `${$scope.baseUrl}/tenders/${tenderData.data.id}/awards/${$scope.awardId}`,
                    type: window.signToDoc.TYPES.NAZK
                }
                return window.signToDoc.setConfig(config);
            })
            .then(()=>{
                window.signToDoc.open({title: 'document-1.XML.p7s'});
            })
    }

    $scope.saveNazk = () => {
        console.log( `${$scope.baseUrl}/tenders/${tenderData.data.id}/awards/${$scope.awardId}`)
        window.eds.init()
            .then(()=>{
                return window.signToDoc.init(window.eds)

            })
            .then(()=>{
                const config = {
                    url: `${$scope.baseUrl}/tenders/${tenderData.data.id}/awards/${$scope.awardId}`,
                    type: window.signToDoc.TYPES.NAZK
                }

                return window.signToDoc.setConfig(config);
            })
            .then(()=>{
                window.signToDoc.save({title: 'document-1.XML.p7s'});

            })
    }

    $scope.$watch('files', function () {
        if ($scope.files && $scope.files.length) {
            var request = documentsService.save($scope.files[0], $scope.tenderCdbId, {
                awardId: $scope.awardId
            });
            request
                .then(function () {
                    ntFlash.success(gettext('Документ успешно загружен.'));
                    $state.reload();
                });
        }
    });
});
