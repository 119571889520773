commons.filter('tariffsFilter', () => {
    "use strict";
    let tariffs = [{
            amount: 20000,
            value: 20.4
        },
        {
            amount: 50000,
            value: 142.8
        },
        {
            amount: 200000,
            value: 408
        },
        {
            amount: 1000000,
            value: 612
        },
        {
            amount: 4000000,
            value: 2040
        },
        {
            amount: 900000000000,
            value: 4080
        }
    ];
    return (amount, currency, exchangeRates) => {
        if (exchangeRates) {
            let amountExchanged = currency === 'UAH' ? amount : amount * exchangeRates[currency].rate;
            let tariff = amountExchanged <= tariffs[0].amount ? tariffs[0].value :
                amountExchanged > tariffs[0].amount && amountExchanged <= tariffs[1].amount ? tariffs[1].value :
                amountExchanged > tariffs[1].amount && amountExchanged <= tariffs[2].amount ? tariffs[2].value :
                amountExchanged > tariffs[2].amount && amountExchanged <= tariffs[3].amount ? tariffs[3].value :
                amountExchanged > tariffs[3].amount && amountExchanged <= tariffs[4].amount ? tariffs[4].value :
                tariffs[5].value;
            return tariff;
        } else {
            // console.warn('No exchangeRates' );
        }
    };
});
