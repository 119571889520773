class NtAutocompleteCountry {
    constructor(
        loaderService,
        googleMapsPlaces,
        oraCodesService,
        ntFlash
    ) {
        this.loader = loaderService;
        this.googleMapsPlaces = googleMapsPlaces;
        this.label = gettext('Страна компании');
        this.inputName = 'country_name';
    }

    selectedItem(country) {
        this.countryName = country.description;
        this.loader.startLoading();
        this.googleMapsPlaces
            .getPlaceDetails(country.place_id)
            .then((place) => {
                if(this.onChange) {
                    this.onChange({
                        place
                    });
                }
            })
            .finally(() => this.loader.stopLoading());
    }

    search (query) {
        const text = query.trim();
        return this.googleMapsPlaces
            .findCountries(text);
    }
}

commons.component('ntAutocompleteCountry', {
    templateUrl: '/static/scripts/modules/Commons/views/components/nt-autocomplete.html',
    controller: NtAutocompleteCountry,
    controllerAs: 'vm',
    bindings: {
        isDisabled: '=',
        isRequired: '=',
        name: '=',
        onChange: '&'
    }
});