tenderView.factory('contractService', function(http) {
    const saveResource = 'tender/:tenderId/contracts/:contractId/';
    const winnerResource = 'contracts/:contractId/suppliers/signer-info/';
    const authorResource = 'contracts/:contractId/buyer/signer-info/';


    return {
        save: function(additionalInfo, data) {
            var saveUrl = saveResource.replace(/:tenderId/g, additionalInfo.tenderId).replace(/:awardId/g, additionalInfo.awardId).replace(/:contractId/g, additionalInfo.contractId);

            return http.put(saveUrl, data);
        },

        saveEContract: function(contractID, data) {
            return http.patch(`contracts/${contractID}/`, data);
        },

        addData: function(additionalInfo, contractId, isWinner) {
            let saveUrl = saveResource.replace(/:tenderId/g, additionalInfo.tenderId).replace(/:awardId/g, additionalInfo.awardId).replace(/:contractId/g, additionalInfo.contractId);
            if(isWinner){
                saveUrl = winnerResource.replace(/:contractId/g, contractId);
            } else {
                saveUrl = authorResource.replace(/:contractId/g, contractId);
            }
            return http.put(saveUrl, additionalInfo);
        }
    }
});
