tenderView.controller('contractDocumentsUploadModalCtrl', function(
    $scope,
    $rootScope,
    $uibModalInstance,
    data,
    ntFlash,
    awardsService,
    documentsService
) {
    $scope.changeId = data.changeId;
    $scope.awardId = data.awardId;
    $scope.tenderId = data.tenderId;
    $scope.contractId = data.contractId;
    $scope.items = data.items;

    awardsService.getAward($scope.tenderId, $scope.awardId)
        .then(function(result) {
            $scope.lotId = result.data.response.lotID;
        });

    $scope.files = null;
    $scope.document = {
        documentOf: "change",
        relatedItem: "",
        documentType: ""
    };
    if($scope.changeId){
        $scope.document = {
            documentOf: "change",
            relatedItem: "",
            documentType: ""
        };
    } else {
        $scope.document = {
            documentOf: "contract",
            relatedItem: "",
            documentType: ""
        };
    }

    $scope.upload = function() {
        if ($scope.files && $scope.files.length) {
            documentsService.save($scope.files[0], null, {contractId: $scope.contractId})
                .then(function(response) {
                    if (response) {
                        if ($scope.document.documentOf === 'change') {
                            $scope.document.relatedItem = $scope.changeId;
                        } else if ($scope.document.documentOf === 'contract') {
                            $scope.document.relatedItem = $scope.contractId;
                        } else if ($scope.document.documentOf === 'lot') {
                            $scope.document.relatedItem = $scope.lotId;
                        } else if ($scope.document.documentOf === 'tender') {
                            $scope.document.relatedItem = $scope.tenderId;
                        }
                        documentsService.edit(null, response.data.data.id, {contractId: $scope.contractId}, $scope.document)
                            .then(function() {
                                ntFlash.success(gettext('Документ успешно загружен.'));
                                $uibModalInstance.close();
                            });
                    }
                });
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

    $scope.deleteDoc = function(index) {
        $scope.files.splice(index, 1);
    };

    $scope.$watch('file', function(val) {
        if ($scope.file && $scope.file.length) {
            $scope.files = val;
        }
    });

});
