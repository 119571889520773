tenderView.controller('profileInvoiceModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    billingService,
    ntFlash
) {
    $scope.amount;
    
    $scope.saveInvoice = function (amount) {
        billingService.saveInvoice({
            "amount": Number(amount) * 100,
            "currency": "UAH"
        }).then(function(response) {
            $uibModalInstance.close();
            $state.go('profile.invoices', {}, {reload: true});
        });
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
});