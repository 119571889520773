class MilestoneCode {
    constructor(
        $scope,
        milestonesService
    ) {
        this.getList = milestonesService.getList;
    }
}

tenderEdit.component('milestoneCode', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/milestone-code.html',
    controller: MilestoneCode,
    controllerAs: 'vm',
    bindings: {
        id: '@',
        label: '@',
        ngModel: '=',
        milestone: '<',
        ngDisabled: '<',
        ngRequired: '<',
    }
});