class FlashErrorDisplay {

    constructor($scope, ntFlash, reportService) {

        $scope.ntFlash = ntFlash;

        $scope.reportSending = false;
        $scope.sendReport = () => {
            $scope.reportSending = true;
            reportService
                .sendReport({
                    responseError: ntFlash.response
                })
                .then(() => ntFlash.success('Репорт успешно отправлен'))
                .finally(() => $scope.reportSending = false);
        };

    }

}

commons.directive('flashErrorDisplay', () => {
    return {
        controller: FlashErrorDisplay
    };
});