tenderView.controller('tenderViewOverviewDocumentsCtrl', function(
    $scope,
    $uibModal,
    tenderService,
    documentsService,
    ntFlash
) {
    ntFlash.clean();

    documentsService.getListByTender($scope.tenderCdbId)
        .then(function(data) {
            $scope.actions = data.action;
            if (!data.response.awards.length
                && !data.response.tender.length
                && !data.response.contract.length
                && !data.response.cancellations.length
                && !data.response.items.length
                && !data.response.lots.length) {
                $scope.noDocuments = true;
            } else {
                $scope.documents = data.response;
            }
        });

    $scope.documentStatus = (function(tender) {
        if (tender.status === "complete") {
            return gettext("Договор подписан");
        }
        return gettext("Проект договора");
    })($scope.tender);
});