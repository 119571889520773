tenderView.factory('agreementService', function (http) {

    return {
        contractPatch: function (p, data) {
            return http.patch(`tender/${p.tenderId}/agreements/${p.agreementId}/contracts/${p.contractId}`, data)
                .then(function(result) {
                    return result.data;
                })
        },

        uploadDocument: function (p, data) {
            return http.post(`tender/${p.tenderId}/agreements/${p.agreementId}/documents`, data)
                .then(function(result) {
                    return result.data;
                });

        },

        patch: function (p, data) {
            return http.patch(`tender/${p.tenderId}/agreements/${p.agreementId}`, data)
                .then(function(result) {
                    return result.data;
                });

        },
    };
});