classifier.factory('classifierService', function (http, API_BASE_URL, $q, $window) {

    const CLASSIFIER_CATALOG_URL = `${API_BASE_URL}/catalogs/`;
    var getCpvResource = 'dk021/';
    var getDkppResource = 'classification/dkpp/';
    var getDk003Resource = 'classification/dk003/';
    var getDk015Resource = 'classification/dk015/';
    var getDk018Resource = 'classification/dk018/';
    var getKekvResource = 'classification/kekv/';
    var getInnResource = 'classification/inn/';
    var getAtcResource = 'classification/atc/';
    var getGmdnResource = 'classification/gmdn/';
    var getUaroadResource = 'classification/uaroad/';

    var searchCpvResource = 'search/dk021?q=';
    var searchDkppResource = 'search/dkpp?q=';
    var searchDk003Resource = 'search/dk003?q=';
    var searchDk015Resource = 'search/dk015?q=';
    var searchDk018Resource = 'search/dk018?q=';
    var searchKekvResource = 'search/kekv?q=';
    var searchInnResource = 'search/inn?q=';
    var searchAtcResource = 'search/atc?q=';
    var searchGmdnResource = 'search/gmdn?q=';
    var searchUaroadResource = 'search/uaroad?q=';

    var getCzoResource = 'classification/authorized_cpb';
    var getCountriesResource = 'classification/countries';
    var getCountriesClassifiers = 'https://raw.githubusercontent.com/ProzorroUKR/standards/master/classifiers/countries.json';

    var nullClassifier = {
        cpv: {
            is_leaf: true,
            scheme: 'ДК021',
            code: '99999999-9',
            name: gettext('Не визначено')
        },
        dkpp: {
            is_leaf: true,
            scheme: 'specialNorms',
            code: '000',
            name: gettext('Не визначено')
        },
        inn: {
            is_leaf: true,
            scheme: 'NONE',
            code: '000',
            name: gettext('Не визначено')
        },
        atc: {
            is_leaf: true,
            scheme: 'NONE',
            code: '000',
            name: gettext('Не визначено')
        },
        gmdn: {
            is_leaf: true,
            scheme: 'NONE',
            code: '000',
            name: gettext('Не визначено')
        },
        uaroad: {
            is_leaf: true,
            scheme: 'NONE',
            code: '000',
            name: gettext('Не визначено')
        }
    };

    function getGroupSchemeByType(type) {
        switch (type) {
            case 'cpv':
                return new Set(['ДК021']);
            case 'dkpp':
                return new Set(['ДКПП', 'ДК003', 'ДК015', 'ДК018', 'specialNorms']);
            case 'kekv':
                return new Set(['КЕКВ']);
            case 'inn':
                return new Set(['INN']);
            case 'atc':
                return new Set(['ATC']);
            case 'gmdn':
                return new Set(['GMDN']);
            case 'uaroad':
                return new Set(['UA-ROAD']);
            default:
                console.warn(`Unknown group type: ${type}`);
                return new Set([]);
        }
    }

    function convertTypesToSchemes(types) {
        let schemes = [];
        for (let i=0; i < types.length; i++) {
            schemes = [...schemes, ...getGroupSchemeByType(types[i])];
        }
        return new Set([...schemes]);
    }

    function clearItemClassification(item, scheme) {
        if (scheme === 'ДК021') {
            item.classification.scheme = "";
            item.classification.id = "";
            item.classification.description = "";
        } else {
            item.additionalClassifications = item.additionalClassifications.filter((c) => {
                return c.scheme !== scheme;
            });
        }
        return item;
    }

    function requireClassifierTypes(type) {
        let result = [type];
        if (type === 'cpv') {
            result = result.concat(['dkpp', 'inn', 'atc']);
        } else if (type === 'inn') {
            result.push('atc');
        }
        return result;
    }


    return {
        getByPage: function (type, page) {
            var resource = '';
            switch (type) {
                case 'cpv':
                    resource = getCpvResource;
                    break;
                case 'dkpp':
                    resource = getDkppResource;
                    break;
                case 'dk003':
                    resource = getDk003Resource;
                    break;
                // case 'dk015':
                //     resource = getDk015Resource;
                //     break;
                case 'dk018':
                    resource = getDk018Resource;
                    break;
                case 'kekv':
                    resource = getKekvResource;
                    break;
                case 'inn':
                    resource = getInnResource;
                    break;
                case 'atc':
                    resource = getAtcResource;
                    break;
                case 'gmdn':
                    resource = getGmdnResource;
                    break;
                case 'uaroad':
                    resource = getUaroadResource;
                    break;
            }
            return http.getByPage(resource, page, null, true)
                .then(function (data) {
                    return data.data.response;
                });
        },
        get: function (type, id, map_codes) {
            var resource = '';
            switch (type) {
                case 'cpv':
                    resource = getCpvResource;
                    break;
                case 'dkpp':
                    resource = getDkppResource;
                    break;
                case 'dk003':
                    resource = getDk003Resource;
                    break;
                // case 'dk015':
                //     resource = getDk015Resource;
                //     break;
                case 'dk018':
                    resource = getDk018Resource;
                    break;
                case 'kekv':
                    resource = getKekvResource;
                    break;
                case 'inn':
                    resource = getInnResource;
                    break;
                case 'atc':
                    resource = getAtcResource;
                    break;
                case 'gmdn':
                    resource = getGmdnResource;
                    break;
                case 'uaroad':
                    resource = getUaroadResource;
                    break;
            }
            resource = id ? resource + id + '/' : resource;

            if (map_codes && map_codes.length > 0) {
                code = encodeURI(map_codes[0]);
                resource = `${resource}?code=${code}`;
            }

            return http.get(resource, null, true)
                .then(function (data) {
                    return data.data.response;
                });
        },
        getNullClassifier: function () {
            return nullClassifier;
        },
        treeLoop: function (arr, callback) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].isChecked) {
                    callback(arr[i]);
                }
                if (arr[i].children) {
                    this.treeLoop(arr[i].children, callback);
                }
            }
        },
        search: function (state, query, map_codes) {
            let code, resource = '';
            let query_encoded = encodeURI(query);

            if (map_codes && map_codes.length > 0) {
                code = encodeURI(map_codes[0]);
            }

            switch (state) {
                case 'cpv':
                    resource = searchCpvResource;
                    break;
                case 'dk021':
                    resource = searchCpvResource;
                    break;
                case 'dkpp':
                    resource = searchDkppResource;
                    break;
                case 'dk003':
                    resource = searchDk003Resource;
                    break;
                // case 'dk015':
                //     resource = searchDk015Resource;
                //     break;
                case 'dk018':
                    resource = searchDk018Resource;
                    break;
                case 'kekv':
                    resource = searchKekvResource;
                    break;
                case 'inn':
                    resource = searchInnResource;
                    break;
                case 'atc':
                    resource = searchAtcResource;
                    break;
                case 'gmdn':
                    resource = searchGmdnResource;
                    break;
                case 'uaroad':
                    resource = searchUaroadResource;
                    break;
            }
            let url = `${resource}${query_encoded}`;
            if (code) {
                url = `${url}&code=${code}`;
            }

            return http.get(url, null, true)
                .then(function (data) {
                    return data.data.response.data;
                });
        },
        getFullChildList: (type, parent) => {
            const deferred = $q.defer();
            const requestUrl = `${CLASSIFIER_CATALOG_URL}${type}/${parent.code}/`;
            let classifiers = [];

            load(requestUrl);

            return deferred.promise;

            function load(requestUrl) {
                http.get(requestUrl, true)
                    .then((result) => {
                        const list = result.data.results;
                        list.forEach(item => item.scheme = parent.scheme);
                        classifiers = classifiers.concat(list);

                        if (result.data.next) {
                            load(result.data.next);
                        } else {
                            deferred.resolve(classifiers);
                        }
                    });
            }
        },

        getGroupSchemeByType: (type) => {
            return getGroupSchemeByType(type);
        },

        getSchemeByType: (type) => {
            switch (type) {
                case 'cpv':
                    return 'ДК021';
                case 'dkpp':
                    return 'ДКПП';
                case 'kekv':
                    return 'КЕКВ';
                case 'inn':
                    return 'INN';
                case 'atc':
                    return 'ATC';
                case 'dk003':
                    return 'ДК003';
                // case 'dk015':
                //     return 'ДК015';
                case 'dk018':
                    return 'ДК018';
                case 'gmdn':
                    return 'GMDN';
                case 'uaroad':
                    return 'UA-ROAD';
                default:
                    return null;
            }
        },

        filterSchemes: (additionalClassifications, schemes) => {
            let dkSchems = new Set(schemes);
            return (additionalClassifications || []).filter((cl) => {
                return dkSchems.has(cl.scheme);
            });
        },

        updateItemClassification: (item, data) => {
            (data || []).forEach((d)=>{
                if (d.scheme === 'ДК021') {
                    item.classification.scheme = d.scheme;
                    item.classification.id = d.code;
                    item.classification.description = d.name;
                } else {
                    item.additionalClassifications.push({
                        scheme : d.scheme,
                        id : d.code,
                        description : d.name,
                    });
                }
            });
            return item;
        },

        clearItemClassification: (item, scheme) => {
            return clearItemClassification(item, scheme);
        },

        clearItemTypeClassification: (item, type) => {
            // Make array require types
            let types = requireClassifierTypes(type);

            // Convert types to classifier
            let schemes = [...convertTypesToSchemes(types)];

            // Clear classifier by scheme
            for (let i=0; i < schemes.length; i++) {
                clearItemClassification(item, schemes[i]);
            }
            return item;
        },

        clearClassifications: (classifications, schemes) => {
            if (classifications instanceof Array) {
                let clSchemes = new Set(schemes);
                let i = 0;
                while (i < classifications.length) {
                    if (clSchemes.has(classifications[i].scheme)) {
                        classifications.splice(i, 1);
                        continue;
                    }
                    i++;
                }
            } else {
                for (let key in classifications) {
                    classifications[key] = '';
                }
            }
        },

        isMOZ: (item) => {
            return item &&
                item.classification &&
                item.classification.id &&
                item.classification.id.slice(0,3) === '336'; // hardcode medication code for DK 021:2015
        },

        isINNRequired: (item) => {
            return item &&
                item.classification &&
                item.classification.id === '33600000-6'; // hardcode medication code for DK 021:2015
        },

        isGMDN: (item) => {
            let arr_gmdn = [
                '3311', '3312', '3313', '3315', '3316', '3317', '3318', '3319', '3369', '3371',
                '3372', '3373', '3374', '3375', '3377', '3379', '3391', '3392', '3393', '3394',
                '3395', '3397', '3841', '3842', '3843', '3851', '3852', '3894', '3895'
            ];
            return item &&
                item.classification &&
                item.classification.id &&
                arr_gmdn.includes(item.classification.id.slice(0,4)); // code for НК 024:2019
        },

        isUAROAD: (item) => {
            let arr_uaroad = [
                '3492', '3499', '4500', '4521', '4522', '4523', '4531', '4534', '4545', '5023',
                '5070', '6371', '7124', '7130', '7132', '7133', '7135', '7151', '7152', '7153',
                '7163', '7311'
            ];
            return item &&
                item.classification &&
                item.classification.id &&
                arr_uaroad.includes(item.classification.id.slice(0,4)); // code for CoST
        },

        loadCzo: () => {
            // let cache = $window.localStorage.getItem('CZO');
            return new Promise((resolve, reject) => {
                http.get(getCzoResource)
                    .then(data => {
                        // console.log('load CZO data', data);
                        // $window.localStorage.setItem('CZO', JSON.stringify(data.data.response.data));
                        resolve(data.data.response.data);
                    })
                    .catch(error => reject(error));

                // if (cache) {
                //     // console.log('get CZO cache');
                //     resolve(JSON.parse(cache));
                // } else {
                //     http.get(getCzoResource)
                //         .then(data => {
                //             // console.log('load CZO data', data);
                //             // $window.localStorage.setItem('CZO', JSON.stringify(data.data.response.data));
                //             resolve(data.data.response.data);
                //         })
                //         .catch(error => reject(error));
                // }
            });
        },

        loadCountriesRegions: () => {
            let cache = $window.localStorage.getItem('CountriesRegions');
            return new Promise((resolve, reject) => {
                if (cache) {
                    // console.log('get CountriesRegions cache');
                    resolve(JSON.parse(cache));
                } else {
                    http.get(getCountriesResource)
                        .then(data => {
                            // console.log('load CountriesRegions data', data);
                            $window.localStorage.setItem('CountriesRegions', JSON.stringify(data.data.response));
                            resolve(data.data.response);
                        })
                        .catch(error => reject(error));
                }
            });
        },

        loadCountriesClassifiers: () => {
            let cache = $window.localStorage.getItem('CountriesClassifiers');
            const proxyUrl = 'proxy_doc_download/?doc_url=';
            return new Promise((resolve, reject) => {

                if (cache) {
                    // console.log('get CountriesRegions cache');
                    resolve(JSON.parse(cache));
                } else {
                    http.get(proxyUrl + encodeURIComponent(getCountriesClassifiers) + '&view=proxy', false)
                        .then(data => {
                            // console.log('load CountriesRegions data', data);
                            let countries = []
                            for (const [key, value] of Object.entries(data.data)) {
                                // console.log(value)
                                countries.push({
                                    id: key,
                                    name_uk: value.name_uk,
                                    name_en: value.name_en,
                                })
                            }
                            countries.sort((a,b) => (a.name_uk.localeCompare(b.name_uk)))
                            $window.localStorage.setItem('CountriesClassifiers', JSON.stringify(countries));
                            resolve(countries);
                        })
                        .catch(error => reject(error));
                }
            });
        }
    };
});
