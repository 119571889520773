class MilestonesService {
    constructor() {
        this.codes = [{
                title: gettext("Аванс"),
                value: "prepayment"
            },
            {
                title: gettext("Послеоплата"),
                value: "postpayment"
            }
        ];
        this.titles = [{
                title: gettext("Выполнение работ"),
                value: "executionOfWorks"
            },
            {
                title: gettext("Поставка товара"),
                value: "deliveryOfGoods"
            },
            {
                title: gettext("Предоставление услуг"),
                value: "submittingServices"
            },
            {
                title: gettext("Подписание договора"),
                value: "signingTheContract"
            },
            {
                title: gettext("Дата подачи заявки"),
                value: "submissionDateOfApplications"
            },
            {
                title: gettext("Дата выставления счета"),
                value: "dateOfInvoicing"
            },
            {
                title: gettext("Дата окончания отчетного периода"),
                value: "endDateOfTheReportingPeriod"
            },
            {
                title: gettext("Другое событие"),
                value: "anotherEvent"
            },
        ];
        this.durations = [{
                title: gettext("рабочих"),
                value: "working"
            },
            {
                title: gettext("банковских"),
                value: "banking"
            },
            {
                title: gettext("календарных"),
                value: "calendar"
            }
        ];

        this.getTitle = (value, listName) => {
            let nameObj = this[listName].find(obj => {
                return obj.value === value;
            });
            if (!nameObj) return '-';
            return nameObj.title ? nameObj.title : '';
        };

        this.getList = (listName) => {
            return this[listName];
        };

    }

}
commons.service('milestonesService', MilestonesService);