class TenderPeriodsListService {
    getList() {
          return [{
              key: 'enquiryPeriod',
              label: gettext('Уточнения')
          }, {
              key: 'complaintPeriod',
              label: gettext('Обжалования')
          }, {
              key: 'tenderPeriod',
              label: gettext('Регистрация предложений')
          }, {
              key: 'qualificationPeriod',
              label: gettext('Период квалификации')
          }, {
              key: 'auctionPeriod',
              label: gettext('Ближайший аукцион')
          }, {
              key: 'awardPeriod',
              label: gettext('Выбор победителя')
          }];
    }
}
commons.service('tenderPeriodsListService', TenderPeriodsListService);