tenderView.factory('chatService', function(http) {
    return {
        save: function(string, id, data, questionId) {
            var chatData = {};
            if (string == 'question') {
                chatData.title = data.title;
                chatData.description = data.message;
                if (data.isMultilotTender) {
                    chatData.questionOf = data.questionOf;
                    chatData.relatedItem = data.relatedItem;
                }
                return http.post('tender/' + id + '/questions/', chatData)
            } else {
                if (string == 'answer') {
                    chatData.answer = data.message;
                    return http.post('tender/' + id + '/questions/' + questionId, chatData)
                }
            }
        },
        get: function(id) {
            return http.get('tender/' + id + '/questions/')
                .then(function(result) {
                    return result.data.response;
                })
        }
    }
});