contractApp.factory('contractingService', function(
    $filter,
    http,
    DATETIME_FROMAT
    ) {
    var getContractResource = 'contracts/:contractId/';
    var getChangesResource = 'contracts/:contractId/changes/';
    var saveChangesResource = 'contracts/:contractId/changes/';
    var editChangeResource = 'contracts/:contractId/changes/:changeId/';
    var editContractResource = 'contracts/:contractId/';
    var getTenderContractsResource = 'tender/:tenderId/contracts';

    var listResource = 'contracts/?page=:pageNum';
    var onlyUserContractResource = 'contracts/?page=:pageNum&user-tenders=1';
    var onlyCompanyContractResource = 'contracts/?page=:pageNum&company-tenders=1';

    var searchResource = 'contracts/?page=:pageNum&q=:searchStr';

    var getList = function(page, url) {
        if (!page || page < 1) {
            page = 1;
        }
        var url = url.replace(/:pageNum/g, page);

        return http.get(url)
            .then(function(result) {
                return result;
            });
    };

    var formatData = function(data) {

        var contractItems = data.items;

        var output = {
            title: data.title,
            description: data.description,
            value: {
                amount: data.value.amount,
                amountNet: data.value.amountNet,
                valueAddedTaxIncluded: data.value.valueAddedTaxIncluded
            },
            items: [],
            period: {
                endDate: data.period.endDate == null
                    ? null
                    : $filter('date')(data.period.endDate, DATETIME_FROMAT),
                startDate: data.period.startDate == null
                    ? null
                    : $filter('date')(data.period.startDate, DATETIME_FROMAT)
            }
        };

        contractItems.forEach(function(item) {
            let unit
            delete item.unit.required
            unit = item.unit

            const newItem = {
                id: item.id.toString(),
                description: item.description,
                classification: {
                    scheme: "ДК021",
                    id: item.classification.id,
                    description: item.classification.description
                },
                additionalClassifications: item.additionalClassifications,
                unit: unit,
                quantity: item.quantity,
                deliveryDate: {
                    endDate: item.deliveryDate.endDate == null ? null : $filter('date')(item.deliveryDate.endDate, DATETIME_FROMAT)
                },
                deliveryAddress: {
                    countryName: item.deliveryAddress.countryName,
                    postalCode: item.deliveryAddress.postalCode,
                    region: item.deliveryAddress.region,
                    locality: item.deliveryAddress.locality,
                    streetAddress: item.deliveryAddress.streetAddress
                }
            }
            if(item.deliveryDate.startDate){
                newItem.deliveryDate.startDate = $filter('date')(item.deliveryDate.startDate, DATETIME_FROMAT)
            }
            if(item.description_en){
                newItem.description_en = item.description_en
            }
            if(item.relatedLot){
                newItem.relatedLot = item.relatedLot
            }

            output.items.push(newItem);
        });

        return output;
    };

    return {
        get: function(id) {
            var contractUrl = getContractResource.replace(/:contractId/, id);
            return http.get(contractUrl)
                .then(function(result) {
                    return result;
                });
        },
        getAll: function(page) {
            return getList(page, listResource);
        },
        getForUser: function(page) {
            return getList(page, onlyUserContractResource);
        },
        getForCompany: function(page) {
            return getList(page, onlyCompanyContractResource);
        },
        getForTender: function(id, nextPage) {
            var resource = getTenderContractsResource.replace(/:tenderId/, id);
            if (undefined !== nextPage) {
                resource = nextPage;
            }
            return http.get(resource)
                .then(function(result) {
                    return result;
                });
        },
        getChanges: function(id) {
            var contractUrl = getChangesResource.replace(/:contractId/, id);
            return http.get(contractUrl)
                .then(function(result) {
                    return result;
                });
        },
        saveChange: function(id, data) {
            var contractUrl = saveChangesResource.replace(/:contractId/, id);
            return http.post(contractUrl, data)
                .then(function(result) {
                    return result;
                });
        },
        editChange: function(changeId, contractId, data) {
            var editUrl = editChangeResource.replace(/:changeId/, changeId).replace(/:contractId/, contractId);
            return http.patch(editUrl, data)
                .then(function(data) {
                    return data;
                });
        },
        editContract: function(contractId, data) {
            var editUrl = editContractResource.replace(/:contractId/, contractId);
            return http.patch(editUrl, formatData(data))
                .then(function(data) {
                    return data;
                });
        },
        terminateContract: function(contractId, data) {
            var editUrl = editContractResource.replace(/:contractId/, contractId);
            return http.patch(editUrl, data)
                .then(function(data) {
                    return data;
                });
        },
        search: function(page, data) {
            var resource = searchResource.replace(/:searchStr/g, data.searchStr || '');
            return getList(page, resource);
        }
    };
});
