class AddressFormFields {
    constructor(
        googleMapsPlaces,
        $window
    ) {
        this.$window = $window;
        this.googleMapsPlaces = googleMapsPlaces;
        this.regions = [];
    }

    $onInit() {
        let storageData = JSON.parse(this.$window.localStorage.getItem('CountriesRegions'));
        if (storageData && Array.isArray(storageData.ua_regions)) {
            this.regions = storageData.ua_regions;
        }
    }

    updateLegalAddressByPlace(place) {
        if(place) {
            const {region, locality, postalCode} = this.googleMapsPlaces.parsePlaceAddressComponents(place.address_components);
            this.address.region = region || '';
            this.address.locality = locality || '';
            this.address.postal_code = postalCode || '';
        }
    }

}

profileApp.component('addressFormFields', {
    templateUrl: '/static/scripts/modules/Profile/views/components/address-form-fields.html',
    controller: AddressFormFields,
    controllerAs: 'vm',
    bindings: {
        address: '=',
    }
});
