planningApp.controller('planningCompleteModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    planningService,
    data,
    ntFlash,
    $timeout
) {

    $scope.isCompleteButtonDisabled = false;

    $scope.complete = function() {
        $scope.isCompleteButtonDisabled = true;
        planningService.updatePlanStatus(data, 'complete')
            .then(function(resData) {
                console.log('Response', resData);
                if (resData.data.response.status === 'complete') {
                    ntFlash.success(gettext('Річний План успішно завершений'));
                    $uibModalInstance.close();
                    $timeout(function() {
                        $state.reload();
                    }, 1000);
                } else {
                    ntFlash.error(gettext('Під час зміни статусу сталась помилка. Перезавантажте сторінку'));
                    $scope.isCompleteButtonDisabled = false;
                }
            })
            .catch(function() {
                // ntFlash.error(gettext('При спробі змінити статус на Завершено сталася помилка. Спробуйте пізніше.'));
                $scope.isCompleteButtonDisabled = false;
            });
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
});