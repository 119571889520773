class ReportService {

    constructor(http) {
        this.http = http;
        this.requestUrl = '/report/';
    }

    sendReport(report) {
        return this.http.post(this.requestUrl, report, true);
    }

}
commons.service('reportService', ReportService);