class LoaderService {
    constructor() {
       this.isLoading = false;
    }

    startLoading() {
        this.isLoading = true;
    }

    stopLoading() {
        this.isLoading = false;
    }
}
commons.service('loaderService', LoaderService);