class MilestonePercentage {
    constructor(
        $scope
    ) { }
}

tenderEdit.component('milestonePercentage', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/milestone-percentage.html',
    controller: MilestonePercentage,
    controllerAs: 'vm',
    bindings: {
        id: '@',
        label: '@',
        ngModel: '=',
        milestone: '<',
        ngDisabled: '<',
        ngRequired: '<',
    }
});