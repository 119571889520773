class DigitsOnly {
    constructor() {
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    link(scope, element, attrs, modelCtrl) {
        modelCtrl.$parsers.push((inputValue) => {
            if (!inputValue) {
                return '';
            }
            attrs.placeholder="123";
            let transformedInput = inputValue.replace(/[^0-9]+/g, '');
            if (Number(transformedInput) > Number(attrs.max)) {
                transformedInput = attrs.max;
            }
            if (transformedInput != inputValue) {
                modelCtrl.$setViewValue(transformedInput);
                modelCtrl.$render();
            }
            return transformedInput;
        });
    }
}
commons.directive('digitsOnly', () => new DigitsOnly);