commons.filter('pqTariffsFilter', () => {
    "use strict";
    return (tender) => {
        if (tender.amount < 50000){
            return `${(tender.amount * 0.03).toFixed(2)} ${tender.currency}`
        } else if (tender.amount >= 50000 && tender.amount < 200000){
            return `${(tender.amount * 0.02).toFixed(2)} ${tender.currency}`
        } else if (tender.value.amount >= 200000){
            return `${(tender.amount * 0.01).toFixed(2)} ${tender.currency}`
        } else {
            return ``
        }
    };
});
