class CompanyRequests {
    constructor(loaderService, profileService, COMPANY_REQUEST_STATUSES) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.COMPANY_REQUEST_STATUSES = COMPANY_REQUEST_STATUSES;
    }
    updateRequestStatus(status, request) {
        this.loader.startLoading();
        this.profileService
            .updateRequestsToCompany(status, request)
            .then((result) => {
                if(result.data.status === this.COMPANY_REQUEST_STATUSES.ACCEPT && this.onRequestAccept) {
                    this.onRequestAccept({request: request});
                }
                const index = this.requests.indexOf(request);
                this.requests.splice(index, 1);
            })
            .finally(() => this.loader.stopLoading());
    }
}

profileApp.component('companyRequests', {
    templateUrl: '/static/scripts/modules/Profile/views/components/company-requests.html',
    controller: CompanyRequests,
    controllerAs: 'vm',
    bindings: {
        requests: '=',
        onRequestAccept: '&'
    }
});