contractApp.controller('contractEditCtrl', function(
    $scope,
    $stateParams,
    $state,
    $rootScope,
    contractingService,
    contractData,
    dateListService,
    unitsService,
    ntFlash,
    $uibModal,
    validateService,
    formationService,
    uibDatepickerConfig
) {
    // dev purpose only
    // $scope.isDev = !window.location.href.includes('b2b');

    ntFlash.clean();

    uibDatepickerConfig.minDate = null;

    unitsService.get()
        .then(function(data) {
            $scope.units = data;
        });

    $scope.contract = formationService.contract(contractData.data.response);

    $scope.contract.value.amountNet = $scope.contract.value.amountNet ? $scope.contract.value.amountNet : $scope.contract.value.amount;

    $scope.minVATAmount =  Math.round($scope.contract.value.amount/1.2*100)/100;

    $scope.fixAmount = $scope.contract.value.amount;
    $scope.fixAmountNet = $scope.contract.value.amountNet;
    $scope.contract.items.forEach(el => el.fixQuantity = el.quantity);

    $scope.valueAddedTaxIncluded = $scope.contract.value.valueAddedTaxIncluded;

    $scope.currentRationaleTypes = $scope.contract.changes ? [...$scope.contract.changes].pop().rationaleTypes : [];
    console.log("$scope.rationaleTypes",$scope.currentRationaleTypes);

    $scope.isCurrentRT = types => $scope.currentRationaleTypes.some(

        el => {
            return types.includes(el)
        }
    );

    $scope.open = function($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();
        obj[string + 'IsOpen'] = true;
    };
    $scope.changeTax = ()=>{
        $scope.valueAddedTaxIncluded = $scope.contract.value.valueAddedTaxIncluded;
    }


    if(!$scope.isEsco) {
        $scope.contract.items.map((el) => {
            if(el.unit.value){
                el.unit.required = true
                return el.unit
            } else {
                el.unit.required = false
                return el.unit.value = {
                    currency: $scope.contract.value.currency,
                    amount: '',
                    valueAddedTaxIncluded: $scope.contract.value.valueAddedTaxIncluded
                }
            }
        })

        $scope.currentAmount = 0
    }


    $scope.checkValid = ($index)=>{
        if(!$scope.checkPrice()){
            $scope.contract.items.forEach((el, index)=>{
                $scope.contractEditForm[index + '_itemUnitValueAmount'].$setValidity("max", false);
            })
        } else {
            $scope.contract.items.forEach((el, index)=>{
                $scope.contractEditForm[index + '_itemUnitValueAmount'].$setValidity("max", true);
            })

        }
    }

    $scope.getCurrentPrice = (amount = 0, quantity = 0)=>{
        return Number(quantity * amount).toFixed(2);
    }

    $scope.checkPrice = ()=>{
        let quantityPrice = 0
        $scope.contract.items.forEach((el)=>{
            if(el.unit.value){
                quantityPrice += Number(el.quantity * el.unit.value.amount)
            }
        })
        return Number(Number(quantityPrice).toFixed(2)) <= $scope.contract.value.amount
    }

    $scope.selectDropdown = function(item, code) {
        item.unit.name = code.name;
        item.unit.code = code.code;
    };

    $scope.setCurrentClassifierIndex = function(index) {
        $scope.currentClassifierIndex = $scope.tender.items[index];
    };

    $scope.clearClassificator = function(type) {
        $rootScope.$emit('addClasificators', null, type);
    };

    $rootScope.$on('addClasificators', function(event, data, type) {
        if ($scope.currentClassifierIndex) {
            if (type == 'cpv') {
                if (data == null || data.length == 0) {
                    $scope.currentClassifierIndex.classification.id = "";
                    $scope.currentClassifierIndex.classification.description = "";
                    $scope.currentClassifierIndex.classification.field = "";
                } else {
                    $scope.currentClassifierIndex.classification.id = data[0].code;
                    $scope.currentClassifierIndex.classification.description = data[0].name;
                    $scope.currentClassifierIndex.classification.field = data[0].code + " - " + data[0].name;
                }
            } else {
                $scope.currentClassifierIndex.additionalClassifications.field = "";
                $scope.currentClassifierIndex.additionalClassifications.data = [];
                if (data != null || data.length != 0) {
                    for (var i = 0; i < data.length; i++) {
                        $scope.currentClassifierIndex.additionalClassifications.field += data[i].code + " - " + data[i].name + " ; ";
                        $scope.currentClassifierIndex.additionalClassifications.data.push({
                            id: data[i].code,
                            description: data[i].name,
                            scheme: data[i].scheme
                        });
                    }
                }
            }
            $state.go('contract.edit');
        }
    });

    $rootScope.$on('closeClasificators', function() {
        $state.go('contract.edit');
    });

    $scope.showAddressPopUp = function(item) {
        var modal = $uibModal.open({
            templateUrl: '/static/scripts/modules/TenderEdit/views/modals/create-tender.show-address.modal.html',
            controller: 'showAddressModalCtrl',
            resolve: {
                data: function() {
                    return item;
                }
            },
            windowClass: 'center-modal'
        });

        modal.result
            .then(function(data) {
                item.deliveryAddress = data.data;
                item.deliveryAddress.field = data.field;
            });
    };

    $scope.clear = function(target) {
        target.field = "";
        target.data.length = 0;
    };

    $scope.addField = function() {
        $scope.tender.items.push({
            id: md5.createHash(uuid4.generate()),
            additionalClassifications: {
                field: "",
                data: []
            },
            classification: {
                scheme: "",
                description: "",
                id: "",
                field: ""
            },
            deliveryAddress: {
                countryName: "",
                postalCode: "",
                region: "",
                locality: "",
                streetAddress: "",
                field: ""
            },
            deliveryDate: {
                endDate: null,
                startDate: null
            },
            // description: "",
            // description_en: "",
            quantity: "",
            relatedLot: null,
            unit: {
                code: "",
                name: gettext('единицы измерения')
            }
        });
    };

    $scope.removeField = function(index) {
        if ($scope.contract.items.length > 1) {
            $scope.contract.items.splice(index, 1);
        }
    };

    $scope.publish = function(contract) {
        if ($scope.valueAddedTaxIncluded) {
            if ($scope.contract.value.amount == $scope.contract.value.amountNet) {
                ntFlash.error(gettext('Значения "Цена договора" и "Цена договора без НДС" не должны совпадать'));
                return;
            }
            if ($scope.contract.value.amount <= $scope.contract.value.amountNet) {
                ntFlash.error(gettext('Значение "Цена договора" должно быть больше чем "Цена договора без НДС"'));
                return;
            }
        }

        if (!$scope.valueAddedTaxIncluded && ($scope.contract.value.amount !== $scope.contract.value.amountNet)) {
            ntFlash.error(gettext('Значения "Цена договора" и "Цена договора без НДС" должны совпадать'));
            return;
        }

        // if ($scope.isDev) return;

        $scope.disablePublishBtn = true;
        $scope.$broadcast('show-errors-check-validity');

        if (!$scope.contractEditForm.$valid) {
            ntFlash.error(gettext('Заполните обязательные поля (подчеркнуты красным)'));
        }

        if ($scope.contractEditForm.$valid) {
            contract.items.map((el) => {
                if (typeof el.unit.value.amount == 'number' ){
                    return el.unit
                } else {
                    return el.unit = {
                        code: el.unit.code,
                        name: el.unit.name,
                    }
                }

            })

            contractingService.editContract(contract.id, contract)
                .then(function(result) {
                    if (result.data.response) {
                        ntFlash.success(gettext('Контракт успешно изменен. Не забудьте подписать ключом КЕП'));
                        $scope.disablePublishBtn = false;
                        $state.go('contract.overview', {}, {reload: true});
                    }
                }, function(err) {
                    console.log(err);
                    $scope.disablePublishBtn = false;
                });
        } else {
            $scope.disablePublishBtn = false;
        }
    };

    $scope.cancel = function() {
        $state.go('contract.overview');
    };

});
