commons.filter('unsuccessfulReasonText', () => {
    "use strict";

    return (unsuccessfulReason) => {
        if (!unsuccessfulReason) {
            return ''
        }
        const result = unsuccessfulReason.map((orig) => {
            switch (orig) {
                case 'agreements[0] has pending change':
                    return gettext('В рамковій угоді є непідтверджена зміна');
                case 'agreements[0] status is not active':
                    return gettext('Рамкову угоду виконано або розірвано');
                case 'agreements[0] items is not subset of tender items':
                    return gettext('Позиції в даному запрошенні на відбір не зазначені в рамковій угоді');
                case 'agreements[0].period.startDate is > tender.date':
                    return gettext('Початок строку дії рамкової угоди ще не настав');
                case 'agreement[0] not found in agreements':
                    return gettext('Рамкової угоди не існує');
                case 'tender.procuringEntity.identifier (scheme or id), doesnt match tender.agreements[0].procuringEntity.identifier (scheme of id)':
                    return gettext('Код ЄДРПОУ замовника, що публікує запрошення на відбір, відрізняється від коду, що визначено рамковою угодою');
                default:
                    let res;
                    if (res = orig.match(/^agreements\[0\] ends less than (.*) days$/)) {
                        return `Строк дії рамкової угоди спливе через ${res[1]} днів`;
                    } else if (/^agreements\[0\] has less than (.*) active contracts$/.test(orig)) {
                        return 'Кількість учасників рамкової угоди недостатня для проведення відбору';
                    } else
                        return orig;
            }
        });

        return result.join('; ');
    }
});
