providerCabinetApp.controller('sidebarTenderCtrl', function (
    $scope,
    tenderData,
    profileService
) {
    $scope.actions = tenderData.action;
    $scope.tender = tenderData.data;
    $scope.config = tenderData.config;
    $scope.user = profileService;
    if ($scope.tender.contracts && $scope.tender.contracts.length) {
        $scope.contractId = $scope.tender.contracts[0].id;
    }

    $scope.savedSearchParams = sessionStorage.lastTenderSearchParams?sessionStorage.lastTenderSearchParams:"";

    let counter = function(prop, props) {
        let count = 0;
        if ($scope.tender[prop] && $scope.tender[prop].length) {
            if (prop === 'complaints')
                count += $scope.tender[prop].filter(el => el.status !== 'draft').length;
            else
                count += $scope.tender[prop].length;
        }

        props.forEach(el => {
            if ($scope.tender.hasOwnProperty(el)) {
                $scope.tender[el].forEach(item => {
                    if (item[prop] && item[prop].length) count += item[prop].length;
                });
            }
        });

        return count;
    }

    $scope.countDocuments = counter('documents', ['awards', 'qualifications', 'cancellations', 'contracts']);
    $scope.countComplaints = counter('complaints', ['awards', 'qualifications', 'cancellations']);

    $scope.countQualifications = $scope.tender.qualifications && $scope.tender.qualifications.filter(el => el.status !== 'cancelled').length;

    $scope.countBids = $scope.actions.show_bids &&
                       $scope.tender.status !== 'active.pre-qualification' &&
                       $scope.tender.status !== 'active.pre-qualification.stand-still' &&
                       $scope.tender.bids &&
                       $scope.tender.bids.filter(el => ['active', 'validBid'].includes(el.status) ||
                                                       ($scope.tender.status === 'complete' && el.status === 'unsuccessful') ||
                                                       $scope.tender.procurementMethodType === 'belowThreshold').length;
        
    if (!$scope.countBids && $scope.tender.procurementMethod === 'limited')
        $scope.countBids = $scope.tender.awards && $scope.tender.awards.filter(el => el.status !== 'cancelled').length;

});
