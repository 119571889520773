commons.filter('monitoringStatus', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'draft':
                // Draft monitoring
                return gettext('проект');

            case 'active':
                // Active monitoring
                return gettext('здійснення моніторингу');

            case 'addressed':
                // Violations found monitoring
                return gettext('порушення виявлені');

            case 'declined':
                // declined
                return gettext('порушення не виявлені');

            case 'completed':
                // Completed monitoring (violations found)
                return gettext('вирішено');

            case 'closed':
                // Completed monitoring (no violations found)
                return gettext('завершено');

            case 'cancelled':
                // Archived monitoring
                return gettext('архів');

            case 'stopped':
                // Stopped monitoring
                return gettext('рішення зупинено');

            default:
                return value;
        }
    }
});