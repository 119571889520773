class UserWallet {
    constructor(
        profileService,
        $uibModal,
        $rootScope,
        billingService
    ) {
        this.user = profileService;
        this.billing = billingService;
        this.$uibModal = $uibModal;
        if(!$rootScope.isAnonymous && !this.billing.wallet) {
            this.billing.loadCurrentWallet();
        }
    }

    createInvoice() {
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/Profile/views/modals/profile.invoice.modal.html",
            controller: "profileInvoiceModalCtrl",
            backdrop: 'static'
        });
    }
}

profileApp.component('userWallet', {
    template: `
        <div ng-show="vm.billing.wallet.is_low_balance" class="low-balance-warning">
            {{'На Вашем счете низкий баланс. Внесите средства заранее, чтобы иметь возможность подавать предложения.' | translate}}
        </div>
        <div ng-if="vm.user.current.actions.can_participate_in_tenders"
             class="user-wallet"
             ng-class="{
                'low-ballance': vm.billing.wallet && vm.billing.wallet.amount < 0
             }"
             md-whiteframe="2">
            {{:: 'Баланс' | translate}}:
            <span ng-if="vm.billing.wallet">
                {{vm.billing.wallet.amount / 100 | currency : ''}} {{vm.billing.wallet.currency | translate}}
            </span>
            <span ng-if="!vm.billing.wallet">
                0 {{:: 'UAH' | translate}}
            </span>
            <a href ng-click="vm.createInvoice()">{{:: 'Пополнить' | translate}}</a>
            <div class="user-wallet__overdraft" ng-if="vm.billing.wallet.overdraft > 0">
                {{:: 'Овердрафт' | translate}}:
                <b>
                    {{vm.billing.wallet.overdraft / 100 | currency : ''}}
                    {{vm.billing.wallet.currency | translate}}
                </b>
            </div>
        </div>
    `,
    controller: UserWallet,
    controllerAs: 'vm'
});