commons.filter('monitoringViolationType', () => {
    "use strict";
    return (value) => {
        switch (value) {

            case 'corruptionDescription':
                return gettext('встановлено порушення законодавства в частині визначення предмета закупівлі');

            case 'corruptionProcurementMethodType':
                return gettext('встановлено порушення законодавства в частині неправомірного обрання та застосування процедури закупівлі');

            case 'corruptionPublicDisclosure':
                return gettext('встановлено порушення законодавства в частині неоприлюднення, несвоєчасного оприлюднення інформації на веб-порталі Уповноваженого органу');

            case 'corruptionBiddingDocuments':
                return gettext('встановлено порушення законодавства в частині складання тендерної документації та/або внесення змін до неї');

            case 'documentsForm':
                return gettext('встановлено порушення законодавства в частині складання форм документів у сфері публічних закупівель');

            case 'corruptionAwarded':
                return gettext('встановлено порушення законодавства в частині розгляду та/або відхилення (не відхилення) замовником тендерної (тендерних) пропозиції (пропозицій)');

            case 'corruptionCancelled':
                return gettext('встановлено порушення законодавства в частині не відміни замовником закупівлі');

            case 'corruptionContracting':
                return gettext('встановлено порушення законодавства в частині укладання договору про закупівлю');

            case 'corruptionChanges':
                return gettext('встановлено порушення законодавства в частині внесення змін до договору про закупівлю (укладання додаткових угод)');

            case 'other':
                return gettext('встановлено інші порушення законодавства у сфері закупівель');

            default:
                return value;
        }
    }
});