commons.directive('enterKey', function() {
    return {
        restrict: 'A',
        scope: {
            model: '=enterKey' // Прив'язуємо змінну через атрибут директиви
        },
        link: function(scope, element) {
            element.bind('keydown keypress', function(event) {
                if(event.which === 13) {
                    event.preventDefault();
                    scope.$apply(function() {
                        // Додаємо символ переносу рядка
                        scope.$eval(function() {
                            console.log(scope)
                            scope.model += '\n';
                        });
                    });
                }
            });
        }
    };
});
