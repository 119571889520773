class UibDatepickerPopup {
    constructor() {
        this.restrict = 'EA';
        this.require = 'ngModel';
    }
    link(scope, element, attr, controller) {
        //remove the default formatter from the input directive to prevent conflict
        controller.$formatters.shift();
    }
}
commons.directive('uibDatepickerPopup', () => new UibDatepickerPopup);