commons.filter('complaintTypeCaption', () => {
    "use strict";
    return (input) => {
        let name = "";

        switch (input) {
            case 'claim':
                name = gettext('Требование');
                break;

            case 'complaint':
                name = gettext('Жалоба');
                break;

            case 'tender_owner':
                name = gettext('Закупщик');
                break;

            case 'complaint_owner':
                name = gettext('Жалобщик');
                break;
        
            case 'aboveThresholdReviewers':
                name = gettext('Рішення Комітету');
                break;
        }

        return name;
    }
});