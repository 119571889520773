tenderView.factory('qualificationService', function(http) {

    var editResource = 'tender/:tenderId/qualifications/:qualificationId/';
    var addMilestonesResource = 'tender/:tenderId/qualifications/:qualificationId/milestones';

    return {
        edit: function(tenderId, qualificationId, data) {
            var saveUrl = editResource.replace(/:tenderId/g, tenderId).replace(/:qualificationId/g, qualificationId);
            return http.patch(saveUrl, data);
        },
        addMilestones: function(tenderId, qualificationId, data) {
            var addUrl = addMilestonesResource.replace(/:tenderId/g, tenderId).replace(/:qualificationId/g, qualificationId);
            return http.post(addUrl, data);
        }
    }
});
