class ScrollToElement {
    constructor(scrollToService) {
        this.restrict = 'A';
        this.scrollToService = scrollToService;
    }
    link(scope, elm, attrs) {
        elm.on('click', () => {
            const target = document.querySelector(attrs.scrollToElement);
            this.scrollToService.scroll(target);
        });

    }
}

commons.directive('scrollToElement', (scrollToService) => new ScrollToElement(scrollToService));