classifier.controller('classifierCtrl', function (
    $scope,
    $rootScope,
    $state,
    $stateParams,
    classifierService,
    $timeout
) {
    var stateType = '';
    var timer;
    $state.current.name.search(/cpv/) > 0 ?
        stateType = 'cpv' : $state.current.name.search(/dkpp/) > 0 ?
        stateType = 'dkpp' : $state.current.name.search(/kekv/) > 0 ?
        stateType = 'kekv' : $state.current.name.search(/inn/) > 0 ?
        stateType = 'inn' : $state.current.name.search(/atc/) > 0 ?
        stateType = 'atc': $state.current.name.search(/gmdn/) > 0 ?
        stateType = 'gmdn' : $state.current.name.search(/uaroad/) > 0 ?
        stateType = 'uaroad' : stateType = $stateParams.type;

    $scope.isMedication = stateType === 'inn' || stateType === 'atc' || stateType === 'gmdn';
    $scope.isUaRoad = stateType === 'uaroad';

    $scope.clasificatorsToSend = [];
    $scope.searchTerm = '';
    $scope.isSearchMode = false;
    $scope.scrollLimit = 100;

    let page = 1;
    let downloadPageCompleted = false;

    const map_codes = $stateParams.codes || [];  // cross mapping, example: atc -> inn and inn -> atc
    const multiple = $state.params.multi;

    const checked =  new Set ( $state.params[stateType]?$state.params[stateType].split(','):[]);

    $scope.clearSearch = function () {
        $scope.searchTerm = '';
        page = 1;
        getFullList(map_codes);
    };

    $scope.search = function () {
        if ($scope.searchTerm.length < 3) {
            timer && $timeout.cancel(timer);
            return;
        }

        if ('' == $scope.searchTerm) {
            timer && $timeout.cancel(timer);
            page = 1;
            getFullList(map_codes);
        } else {
            // delaying the request in case the user is typing too fast,
            // so that we avoid extra requests.
            timer && $timeout.cancel(timer);
            timer = $timeout(function () {
                if (stateType === 'cpv') {
                    classifierService.search(stateType, $scope.searchTerm)
                        .then(function (data) {
                            if (data && data.length) {
                                data.forEach(function (item) {
                                    item.scheme = 'ДК021';
                                    item.isChecked = checked.has(item.code);
                                });
                            }
                            $scope.isSearchMode = true;
                            $scope.tree = data;
                        });
                } else if (stateType === 'kekv') {
                    classifierService.search(stateType, $scope.searchTerm)
                        .then(function (data) {
                            if (data && data.length) {
                                data.forEach(function (item) {
                                    item.scheme = 'КЕКВ';
                                    item.isChecked = checked.has(item.code);
                                });
                            }
                            $scope.isSearchMode = true;
                            $scope.tree = data;
                        });
                } else if (stateType === 'inn') {
                    classifierService.search(stateType, $scope.searchTerm, map_codes)
                    .then(function (data) {
                        if (data && data.length) {
                            data.forEach(function (item) {
                                item.scheme = 'INN';
                                item.is_leaf = 'false';
                                item.isChecked = checked.has(item.code);
                            });
                        }
                        $scope.isSearchMode = true;
                        $scope.tree = data;
                    });
                } else if (stateType === 'atc') {
                    classifierService.search(stateType, $scope.searchTerm, map_codes)
                    .then(function (data) {
                        if (data && data.length) {
                            data.forEach(function (item) {
                                item.scheme = 'ATC';
                                item.is_leaf = 'false';
                                item.isChecked = checked.has(item.code);
                            });
                        }
                        $scope.isSearchMode = true;
                        $scope.tree = data;
                    });
                } else if (stateType === 'gmdn') {
                    classifierService.search(stateType, $scope.searchTerm)
                    .then(function (data) {
                        if (data && data.length) {
                            data.forEach(function (item) {
                                item.scheme = 'GMDN';
                                item.is_leaf = 'false';
                                item.isChecked = checked.has(item.code);
                            });
                        }
                        $scope.isSearchMode = true;
                        $scope.tree = data;
                    });
                } else if (stateType === 'uaroad') {
                    classifierService.search(stateType, $scope.searchTerm)
                    .then(function (data) {
                        if (data && data.length) {
                            data.forEach(function (item) {
                                item.scheme = 'UA-ROAD';
                                item.is_leaf = 'false';
                                item.isChecked = checked.has(item.code);
                            });
                        }
                        $scope.isSearchMode = true;
                        $scope.tree = data;
                    });
                } else {
                    var tempTree = [];
                    classifierService.search('dkpp', $scope.searchTerm)
                        .then(function (data) {
                            if (data && data.length) {
                                data.forEach(function (item) {
                                    item.scheme = 'ДКПП';
                                    item.isChecked = checked.has(item.code);
                                });
                                tempTree = tempTree.concat(data);
                            }
                            classifierService.search('dk003', $scope.searchTerm)
                                .then(function (data) {
                                    if (data && data.length) {
                                        data.forEach(function (item) {
                                            item.scheme = 'ДК003';
                                            item.isChecked = checked.has(item.code);
                                        });
                                        tempTree = tempTree.concat(data);
                                    }
                                    classifierService.search('dk018', $scope.searchTerm)
                                        .then(function (data) {
                                            if (data && data.length) {
                                                data.forEach(function (item) {
                                                    item.scheme = 'ДК018';
                                                    item.isChecked = checked.has(item.code);
                                                });
                                                tempTree = tempTree.concat(data);
                                            }
                                            $scope.tree = tempTree;
                                        });
                                    // classifierService.search('dk015', $scope.searchTerm)
                                    //     .then(function (data) {
                                    //         if (data && data.length) {
                                    //             data.forEach(function (item) {
                                    //                 item.scheme = 'ДК015';
                                    //                 item.isChecked = checked.has(item.code);
                                    //             });
                                    //             tempTree = tempTree.concat(data);
                                    //         }
                                    //
                                    //     });
                                });
                        });
                }
            }, 300);
        }
    };

    var getFullList = function (map_codes) {
        if (stateType === 'cpv') {
            classifierService.get(stateType)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'ДК021';
                        item.isChecked = checked.has(item.code);
                        if(!item.isChecked && $stateParams.checked){
                            item.isChecked = $stateParams.checked.includes(item.code)
                        }
                    });
                    data.push(classifierService.getNullClassifier().cpv);
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                });

        } else if (stateType === 'kekv') {
            classifierService.get(stateType)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'КЕКВ';
                        item.isChecked = checked.has(item.code);
                    });
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                });
        } else if (stateType === 'inn') {
            classifierService.get(stateType, false, map_codes)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'INN';
                        item.is_leaf = true;
                        item.isChecked = checked.has(item.code);
                    });
                    // data.push(classifierService.getNullClassifier().inn);
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                });
        } else if (stateType === 'atc') {
            classifierService.get(stateType, false, map_codes)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'ATC';
                        item.is_leaf = true;
                        item.isChecked = checked.has(item.code);
                    });
                    // data.push(classifierService.getNullClassifier().atc);
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                });
        } else if (stateType === 'gmdn') {
            downloadPageCompleted = false;
            classifierService.getByPage(stateType, page)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'GMDN';
                        item.is_leaf = true;
                        item.isChecked = checked.has(item.code);
                    });
                    // data.push(classifierService.getNullClassifier().gmdn);
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                    downloadPageCompleted = true;
                    page++;
                });
        } else if (stateType === 'uaroad') {
            classifierService.get(stateType)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'UA-ROAD';
                        item.is_leaf = true;
                        item.isChecked = checked.has(item.code);
                    });
                    // data.push(classifierService.getNullClassifier().uaroad);
                    $scope.isSearchMode = false;
                    $scope.tree = data;
                });
        } else {
            $scope.isSearchMode = false;

            var tempTree = [];
            classifierService.get('dkpp')
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'ДКПП';
                        item.isChecked = checked.has(item.code);
                    });
                    tempTree = tempTree.concat(data);
                    classifierService.get('dk003')
                        .then(function (data) {
                            data.forEach(function (item) {
                                item.scheme = 'ДК003';
                                item.isChecked = checked.has(item.code);
                            });
                            tempTree = tempTree.concat(data);
                            classifierService.get('dk018')
                                .then(function (data) {
                                    data.forEach(function (item) {
                                        item.scheme = 'ДК018';
                                        item.isChecked = checked.has(item.code);
                                    });
                                    data.push(classifierService.getNullClassifier().dkpp);
                                    tempTree = tempTree.concat(data);
                                    $scope.tree = tempTree;
                                });
                            // classifierService.get('dk015')
                            //     .then(function (data) {
                            //         data.forEach(function (item) {
                            //             item.scheme = 'ДК015';
                            //             item.isChecked = checked.has(item.code);
                            //         });
                            //         tempTree = tempTree.concat(data);
                            //
                            //     });
                        });
                });
        }
    };

    getFullList(map_codes);

    $scope.infiniteLoadItems = function () {
        if (stateType === 'gmdn' && page > 1 && downloadPageCompleted && !$scope.isSearchMode) {
            downloadPageCompleted = false;
            classifierService.getByPage(stateType, page)
                .then(function (data) {
                    data.forEach(function (item) {
                        item.scheme = 'GMDN';
                        item.is_leaf = true;
                        item.isChecked = checked.has(item.code);
                    });
                    $scope.isSearchMode = false;
                    $scope.tree = $scope.tree.concat(data);
                    $scope.scrollLimit = $scope.tree.length;
                    downloadPageCompleted = true;
                    page++;
                });
        } else {
            let step = 100;
            if ($scope.tree) {
                $scope.scrollLimit = $scope.scrollLimit < $scope.tree.length ? $scope.scrollLimit+step : $scope.tree.length;
            } else $scope.scrollLimit = step;
        }
    };

    $scope.toggleTree = function (item) {
        if (item.collapse) {
            item.collapse = false;
        } else {
            item.collapse = true;
        }
        if (item.children) {
            item.isOpen = false;
            return;
        }
        if (!item.is_leaf) {
            if (item.isOpen) {
                item.isOpen = true;
                return;
            }
            item.treeItemUrl = '/static/scripts/modules/Classifier/views/classifier.item.html';
            var temp = null;
            switch (item.scheme) {
                case 'ДК021':
                    temp = 'cpv';
                    break;
                case 'ДКПП':
                    temp = 'dkpp';
                    break;
                case 'ДК003':
                    temp = 'dk003';
                    break;
                // case 'ДК015':
                //     temp = 'dk015';
                //     break;
                case 'ДК018':
                    temp = 'dk018';
                    break;
                case 'КЕКВ':
                    temp = 'kekv';
                    break;
            }
            classifierService.get(temp, item.code)
                .then(function (data) {
                    data.forEach(function (child) {
                        child.isChecked = checked.has(child.code);
                        child.scheme = item.scheme;
                    });
                    item.children = data;
                    item.isOpen = true;
                });
        }
    };

    $scope.chooseClassificator = function (item) {
        if (multiple) {
            return;
        } else {
            let checked = item.isChecked;
            classifierService.treeLoop($scope.tree, function (el) {
                el.isChecked = false;
            });
            item.isChecked = checked;
        }
    };

    $scope.addToForm = function () {
        $scope.clasificatorsToSend = [];

        classifierService.treeLoop($scope.tree, function (el) {
            $scope.clasificatorsToSend.push({
                code: el.code,
                name: el.name,
                scheme: el.scheme
            });
        });

        $rootScope.$broadcast('addClasificators', $scope.clasificatorsToSend, stateType);
    };

    $scope.closeClassificator = function () {
        $rootScope.$broadcast('closeClasificators');
    };
});
