class AgreementInstanceService {
    constructor(
        http,
        $q
    ) {
        this.q = $q;
        this.http = http;
    }

    getAgreements(id) {
        // console.log(temp)
        // return Promise.resolve(temp)
        return this.http.get(`agreements/${id}/`).then(response => {
            return response.data;
        }, error => {
            console.log('err', error)
        });
    }

    getAgreementList(pageNumber, searchType) {
        let searchLink;
        switch (searchType) {
            case 'user':
                searchLink = `agreements?page=${pageNumber}&user-tenders=1`;
                break
            case 'company':
                searchLink = `agreements?page=${pageNumber}&company-tenders=1`;
                break
            default:
                searchLink = `agreements/?page=${pageNumber}`;
                break
        }

        return this.http.get(searchLink).then(response => {
            console.log(response);
            return response.data;
        }, error => {
            console.log('err', error)
        });
    }

    setChangeAgreements(id, body) {
        return this.http.post(`agreements/${id}/changes/`, body).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    updateChangeAgreements(id, changeId, body) {
        return this.http.patch(`agreements/${id}/changes/${changeId}/`, body).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    documentUpload(id, body) {
        return this.http.post(`agreements/${id}/documents/`, body).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    documentChange(id, changeId, body) {
        return this.http.patch(`agreements/${id}/documents/${changeId}/`, body).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    getChanges(id) {
        return this.http.get(`agreements/${id}/preview/`).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    endAgreement(id, body) {
        return this.http.patch(`agreements/${id}/`, body).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    searchAgreements(pageNumber, query) {
        // let searchLink;
        // switch (searchType) {
        //     case 'user':
        //         searchLink = `agreements?page=${pageNumber}&user-tenders=1`;
        //         break
        //     case 'company':
        //         searchLink = `agreements?page=${pageNumber}&company-tenders=1`;
        //         break
        //     default:
        //         searchLink = `agreements/?page=${pageNumber}&q=${query}`;
        //         break
        // }
        return this.http.get(`agreements/?page=${pageNumber}&q=${query}`).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

    getContracts(id_tender, agreement_id, query_type) {
        return this.http.get(`tender/${id_tender}/agreements/${agreement_id}/contracts/?${query_type}`).then(response => {
        // return this.http.get(`agreements/${agreement_id}/contracts/?${query_type}`).then(response => {
            return response;
        }, error => {
            console.log('err', error)
        })
    }

}


agreementModule.service('agreementInstanceService', AgreementInstanceService);

const temp = {
    "agreementID": "UA-2024-02-20-000035",
    "frameworkID": "6d681a5dcf19424d808959d6af2dc8b3",
    "agreementType": "dynamicPurchasingSystem",
    "status": "active",
    "period": {
        "startDate": "2024-02-20T20:47:42.981169+02:00",
        "endDate": "2024-02-21T00:00:00+02:00"
    },
    "procuringEntity": {
        "name": "test",
        "identifier": {
            "scheme": "UA-EDR",
            "id": "909000000",
            "legalName": "test"
        },
        "address": {
            "countryName": "Україна",
            "streetAddress": "test 123",
            "locality": "Київ",
            "region": "Вінницька область",
            "postalCode": "08215"
        },
        "contactPoint": {
            "name": "test1",
            "telephone": "+1231231212",
            "email": "test.gov@gmail.com"
        },
        "kind": "defense"
    },
    "classification": {
        "description": "Сільськогосподарська, фермерська продукція, продукція рибальства, лісівництва та супутня продукція",
        "scheme": "ДК021",
        "id": "03000000-1"
    },
    "contracts": [
        {
            "id": "0fa5fc1748a1475a80f2e911492b6c2b",
            "qualificationID": "ba253d25c0de4da58fc61d2502ada99a",
            "submissionID": "3872fe11e6bb477da51ae3c99658c421",
            "status": "active",
            "suppliers": [
                {
                    "name": "test 1123",
                    "identifier": {
                        "scheme": "UA-EDR",
                        "id": "45678526",
                        "legalName": "test 1123"
                    },
                    "address": {
                        "countryName": "Приховано",
                        "streetAddress": "Приховано",
                        "locality": "Приховано",
                        "region": "Приховано",
                        "postalCode": "Приховано"
                    },
                    "contactPoint": {
                        "name": "Приховано",
                        "telephone": "Приховано",
                        "email": "Приховано"
                    },
                    "scale": "micro"
                }
            ],
            "milestones": [
                {
                    "id": "1b8dc05dd02d43b785b9a113613549f6",
                    "status": "scheduled",
                    "type": "activation",
                    "dueDate": "2024-02-21T00:00:00+02:00",
                    "dateModified": "2024-02-20T20:47:42.981169+02:00"
                }
            ],
            "date": "2024-02-20T20:47:42.981169+02:00"
        },
        {
            "id": "59e6d4d99269436ba5ab102363cbcd4e",
            "qualificationID": "b30ec8bfc0b648028b82567d5902f6db",
            "submissionID": "2146670e1d634490982c37bc7a962dd5",
            "status": "active",
            "suppliers": [
                {
                    "name": "test vendor",
                    "identifier": {
                        "scheme": "UA-EDR",
                        "id": "13540086",
                        "legalName": "test vendor"
                    },
                    "address": {
                        "countryName": "Приховано",
                        "streetAddress": "Приховано",
                        "locality": "Приховано",
                        "region": "Приховано",
                        "postalCode": "Приховано"
                    },
                    "contactPoint": {
                        "name": "Приховано",
                        "telephone": "Приховано",
                        "email": "Приховано"
                    },
                    "scale": "sme"
                }
            ],
            "milestones": [
                {
                    "id": "ee5d16ea583c4deab98b08a974e79701",
                    "status": "scheduled",
                    "type": "activation",
                    "dueDate": "2024-02-21T00:00:00+02:00",
                    "dateModified": "2024-02-20T20:47:49.783922+02:00"
                }
            ],
            "date": "2024-02-20T20:47:49.783922+02:00"
        },
        {
            "id": "5fa78da2bbc44bebaa5f24c4133ef9b0",
            "qualificationID": "fc6d9fdd4e03486fbf085ff4b0d59e36",
            "submissionID": "8bfae5d6950f44898f76bf5774b56cb8",
            "status": "active",
            "suppliers": [
                {
                    "name": "test vendor 22",
                    "identifier": {
                        "scheme": "UA-EDR",
                        "id": "23050874",
                        "legalName": "test vendor 22"
                    },
                    "address": {
                        "countryName": "Приховано",
                        "streetAddress": "Приховано",
                        "locality": "Приховано",
                        "region": "Приховано",
                        "postalCode": "Приховано"
                    },
                    "contactPoint": {
                        "name": "Приховано",
                        "telephone": "Приховано",
                        "email": "Приховано"
                    },
                    "scale": "sme"
                }
            ],
            "milestones": [
                {
                    "id": "4b792f4485494c168b2159419cc52183",
                    "status": "scheduled",
                    "type": "activation",
                    "dueDate": "2024-02-21T00:00:00+02:00",
                    "dateModified": "2024-02-20T20:47:59.136509+02:00"
                }
            ],
            "date": "2024-02-20T20:47:59.136509+02:00"
        }
    ],
    "owner": "newtend.com",
    "mode": "test",
    "dateModified": "2024-02-20T20:47:59.136509+02:00",
    "date": "2024-02-20T20:47:42.981169+02:00",
    "next_check": "2024-02-21T00:00:00+02:00",
    "dateCreated": "2024-02-20T20:47:42.981169+02:00",
    "id": "dd9c2f44e35b4e67bd162fbdfbb39bc1"
}
