var providerCabinetApp = angular.module('providerCabinetApp', [
    'ui.router',
    'permission',
    'permission.ui',
    'ngAnimate',
    'ui.bootstrap',
    'angular-loading-bar',
    'angular-flash.service',
    'angular-flash.flash-alert-directive',
    'pascalprecht.translate',
    'angularFileUpload',
    'angular-md5',
    'ncy-angular-breadcrumb',
    'cfp.loadingBar',
    'ui.bootstrap.showErrors',
    'commons.app',
    'classifier',
    'criteria',
    'quality',
    'lot',
    'tendersList',
    'tenderView',
    'tenderEdit',
    'planning',
    'contract',
    'monitoring',
    'profile',
    'ngMaterial',
    'infinite-scroll',
    'ngMessages',
    'app.notifications',
    'ngSanitize',
    'agreementModule',
    'calendarModule'
]);

providerCabinetApp.config(function ($mdAriaProvider) {
    $mdAriaProvider.disableWarnings();
});

// providerCabinetApp.run(function($trace) { $trace.enable('TRANSITION'); });

providerCabinetApp.run(($mdSidenav, $rootScope, $mdMedia) => {
    $rootScope.$mdMedia = $mdMedia;
    $rootScope.toggleSidebar = () => {
        $mdSidenav('left').toggle();
    };
});

providerCabinetApp.run((PermRoleStore, $rootScope) => {
    PermRoleStore
        .defineManyRoles({
            'GUEST': () => $rootScope.isAnonymous,
            'AUTHORIZED': () => !$rootScope.isAnonymous
        });
});

providerCabinetApp.run(($transitions, $timeout) => {
    $transitions.onSuccess({}, function (transition) {
        const toStateScrollPage = !transition.to().data || !transition.to().data.disableScrollPageToTop;
        const fromStateScrollPage = !transition.from().data || !transition.from().data.disableScrollPageToTop;
        if (toStateScrollPage && fromStateScrollPage) {
            $timeout(function () {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
            }, 100);
        }
        if (transition.router.globals.$current.name == "tendersList.type") {
            sessionStorage.lastTenderSearchParams = JSON.stringify(transition.router.globals.params);
        }
    });
});

providerCabinetApp.run((infoMessageService) => {
    if (window.APP_ALERT_MESSAGE) {
        infoMessageService.setMessage(window.APP_ALERT_MESSAGE);
    }
});

providerCabinetApp.run([
    '$location',
    '$state',
    '$rootScope',
    '$mdDialog',
    function ($location, $state, $rootScope, $mdDialog) {
        /* Collect router errors message instead error */
        window.routerErrors = [];
        $state.defaultErrorHandler(function (error) {
            window.routerErrors.push(error);
            // console.log('defaultErrorHandler',error);
        });

        var currentHash = $location.hash();
        $rootScope.initializeFinished = true;
        $rootScope.contractingState = OPC_CONFIG.OPC_MODULE_CONTRACTS;
        if (currentHash == "") {
            $state.go('tendersList.type', {
                pageNum: 1,
                status: 'all',
                list: 'home'
            }, {
                reload: true
            });
        }
        $rootScope.createTender = function ($event) {
            $mdDialog.show({
                templateUrl: '/static/scripts/modules/TenderEdit/views/modals/tenderProcedureModal.html',
                controller: 'tenderProcedureModalCtrl',
                controllerAs: 'vm',
                targetEvent: $event,
                parent: angular.element(document.body),
                clickOutsideToClose: false
            });
        };
    }
]);

providerCabinetApp.run((loaderService, $rootScope) => {
    $rootScope.loader = loaderService;
});

providerCabinetApp.run((profileService, companyService) => {
    if (window.authentication_and_user_info.is_authenticated) {
        profileService
            .reloadCurrentUser()
            .then(user => {
                if (user.companies[0]) {
                    companyService.setCurrentById(user.companies[0].id);
                } else {
                    profileService
                        .getUserRequests(user)
                        .then(res => profileService.setRequestsToCompanies(res.data));
                }
            });
    }
});

providerCabinetApp.run((notificationService) => {
    if (window.authentication_and_user_info.is_authenticated) {
        notificationService.countNotReadedNotifications();
        setInterval(() => notificationService.countNotReadedNotifications(), 90000);
    }
});

providerCabinetApp.run(($rootScope) => {
    $rootScope.edsSignData = {};
    $rootScope.edsSignIsOpened = false;
    $rootScope.edsSignResolved = function(response) {
        $rootScope.$broadcast('edsSignResponse', response);
    }
    $rootScope.oldEds = localStorage.getItem('oldEds') === 'true';
    $rootScope.$watch('oldEds', (newValue, oldValue) => {
        localStorage.setItem('oldEds', newValue);
        if (newValue !== oldValue) location.reload();
    });
});