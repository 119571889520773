providerCabinetApp.controller('navigatePanelCtrl', function(
    $scope,
    $state,
    $timeout
    ) {
    var searchTimer = null;
    $scope.tendersSearchStr = $state.params.searchStr;

    $scope.search = function() {
        if ($scope.tendersSearchStr && $scope.tendersSearchStr.length > 1) {
            // delaying the request in case the user is typing too fast,
            // so that we avoid extra requests.
            searchTimer && $timeout.cancel(searchTimer);
            searchTimer = $timeout(function() {
                $state.go('search.all', {searchStr: $scope.tendersSearchStr, pageNum: 1}, {reload: true});
            }, 300);
        } else {
            if ($scope.tendersSearchStr == '') {
                $state.go('tendersList.type', {list: 'home', status: 'all', pageNum: 1}, {reload: true});
            }
        }
    };
});