commons.filter('monitoringEliminationResolutionResult', () => {
    "use strict";
    return (value) => {
        switch (value) {
            case 'completely':
                return gettext('Усунуто');

            case 'partly':
                return gettext('Частково усунуто');

            case 'none':
                return gettext('Не усунуто');

            default:
                return value;
        }
    }
});