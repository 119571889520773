class InfoMessageService {
    constructor($cookies) {
        this.message = null;
        this.$cookies = $cookies;
    }
    setMessage(message) {
        const isClosedCookieName = this.getCookieName(message);
        const isClosed = !!this.$cookies.get(isClosedCookieName);

        const currentTime = +(new Date());
        const minTime = +new Date(message.minTime);
        const maxTime = +new Date(message.maxTime);
        const isVisibleMessage = currentTime > minTime && currentTime < maxTime;

        if(!isClosed && isVisibleMessage) {
            this.message = message;
        }
    }

    clearMessage() {
        const now = new Date();
        const nextMonth = new Date(now.getFullYear(), now.getMonth()+1, now.getDate());
        const isClosedCookieName = this.getCookieName(this.message);
        this.$cookies.put(isClosedCookieName, 'true', {
            expires: nextMonth
        });

        this.message = null;
    }

    getCookieName(message) {
        return `closed-message-${message.id}`;
    }
}
commons.service('infoMessageService', InfoMessageService);