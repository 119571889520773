class UserRegistrationController {
    constructor($injector, utmSource) {
        this.loader = $injector.get('loaderService');
        this.profileService = $injector.get('profileService');
        this.ntFlash = $injector.get('ntFlash');
        this.$timeout = $injector.get('$timeout');
        this.$window = $injector.get('$window');
        this.$rootScope = $injector.get('$rootScope');
        this.$state = $injector.get('$state');
        this.CONTACT_TYPES = $injector.get('CONTACT_TYPES');
        this.errorMessages = {};
        this.user = {
            name: '',
            name_en: '',
            email: '',
            contacts: [],                       
            password: '',
            accept_offer: false,
            accept_reglament: false
        };
        this.password_confirm = '';
        this.isRegisteredSuccessfully = false;

        if (!this.$rootScope.isAnonymous) {
            this.$state.go('profile.company-create');
        }

        const captchaScript = document.createElement('script');
        captchaScript.type = "text/javascript";
        captchaScript.src = `https://www.google.com/recaptcha/api.js?render=${window.GOOGLE_RECAPTCHA_SITE_KEY}`;
        document.querySelector('body').appendChild(captchaScript);
    }

    emailRegEx(){
        return this.validateService.regex.email;
    }

    getSource() {
        var source;
        let utmSource = this.utmSource;
        if (utmSource.utm_source) {
            source = {
                'utm_source': utmSource.utm_source,
                'utm_medium': utmSource.utm_medium,
                'utm_campaign': utmSource.utm_campaign
            }
        }
        console.log('Source: ' + source);
        return source;
    }

    addContact(cValue, cType) {
        let contacts = this.user.contacts;
        let added = false;

        contacts.forEach((obj) => {
            if (obj.type === cType) {
                obj.value = cValue;
                added = true;
                return;
            }
        });
        if (!added) {
            contacts[contacts.length] = {
                value: cValue,
                'type': cType
            };
        }
    }

    getContactsByType(cType) {
        return this.user.contacts.filter((obj) => {
            return obj.type == cType;
        });
    }

    userEmail(email) {
        if (arguments.length) {
            this.addContact(email, this.CONTACT_TYPES.EMAIL);
            this.user.email = email;
        }
        let contacts = this.getContactsByType(this.CONTACT_TYPES.EMAIL);
        return contacts.length ? contacts[0]['value'] : '';
    }

    userPhoneNumber(phoneNumber) {
        if (arguments.length) {
            if (phoneNumber && phoneNumber[0] != "+") phoneNumber = "+" + phoneNumber;
            this.addContact(phoneNumber, this.CONTACT_TYPES.PHONE);
        }
        let contacts = this.getContactsByType(this.CONTACT_TYPES.PHONE);
        return contacts.length ? contacts[0]['value'] : '';
    }

    registration() {
        this.loader.startLoading();
        this.errorMessages = {};
        this.profileService.createUser(this.user)
            .then(() => {
                // this.ntFlash.success(gettext('Пользователь успешно зарегистрирован'));
                // this.isRegisteredSuccessfully = true;
                //this.$timeout(() => window.location.href='/', 1000);
                window.location.href = "/opc/provider/profile/registered";
            })
            .catch((error) => {
                this.errorMessages = error.data.errors;
            })
            .finally(() => {
                this.loader.stopLoading();
            });
    }
}

class UserRegistrationWithCompanyController extends UserRegistrationController {
    constructor(
        $injector,
        utmSource,
        validateService,
        classifierService,
        ntFlash
    ) {
        super($injector);
        this.utmSource = utmSource;
        this.$mdDialog = $injector.get('$mdDialog');
        this.ntFlash = ntFlash;
        this.scrollToService = $injector.get('scrollToService');
        let source = this.getSource();
        this.user['source'] = source;
        this.company = {
            registration_number: '',
            iati_ora_code: "UA-EDR",
            legal_address: {
                country_name: 'Україна'
            },
            is_government: 'true',
            procuring_kind: null,
            name: '',
            verification_data: null,
            source: source
        };
        this.phoneRegEx = validateService.regex.phone;
        this.emailRegEx = validateService.regex.email;

        this.classifierService = classifierService;
        this.czoList = [];
        this.countriesList = [];

        this.classifierService.loadCzo()
            .then((data) =>  {
                console.log('loadCzo data', data);
                this.czoList = data;
            })
            .catch((error) => {
                console.log('loadCzo error', error);
                this.ntFlash.error(gettext('При завантаженні списку ЦЗО виникла помилка'));
            });

        this.classifierService.loadCountriesRegions()
            .then((data) =>  {
                console.log('loadCountriesRegions data', data);
                this.countriesList = data.countries;
            })
            .catch((error) => {
                console.log('loadCountriesRegions error', error);
                this.ntFlash.error(gettext('При завантаженні списку держав і регіонів виникла помилка'));
            });
    }

    checkCompanyAsCZO() {
        if (this.company.procuring_kind !== 'central') return true;
        for (let czo of this.czoList) {
            if (this.company.registration_number == czo.identifier.id && czo.active) return true;
        }
        return false;
    }

    checkCountryIsInList() {
        if (this.countriesList.length === 0) return true;
        if (this.countriesList.includes(this.company.legal_address.country_name)) return true; 
        return false;
    }

    registration() {
        this.loader.startLoading();
        this.errorMessages = {};
        const data = {
            user: this.user,
            company: this.company
        };

        if (!this.checkCompanyAsCZO()) {
            this.ntFlash.error(gettext('Введений ідентифікаційний код компанії не належить зареєстрованим ЦЗО'));
            this.disablePublishBtn = false;
            this.loader.stopLoading();
            return;
        }

        if (!this.checkCountryIsInList()) {
            this.ntFlash.error(gettext('Введена некоректна назва країни або ви використовуєте не українську мову'));
            this.disablePublishBtn = false;
            this.loader.stopLoading();
            return;
        }

        const self = this;
        window.grecaptcha.ready(function() {
            window.grecaptcha.execute(window.GOOGLE_RECAPTCHA_SITE_KEY, {action: 'submit'}).then(function(token) {
                if (token) {
                    data["g-recaptcha-response"] = token;
                    self.profileService
                        .createUserWithCompany(data)
                        .then(() => {
                            console.log('send GA');
                            try {
                                ga('send', 'event', 'button', 'hit', 'new_user_b2b');
                            } catch (e) {
                                console.error('Google Analytics not found!');
                            }
                            window.location.href = "/opc/provider/profile/registered";
                        })
                        .catch((error) => {
                            if (error.status === 409) {
                                self.ntFlash.clean();
                                self.showConfirmModal(data.user, data.company);
                            } else if (error.status === 400) {
                                self.errorMessages = error.data.errors;
                                const target = document.querySelector('input.ng-invalid');
                                self.scrollToService.scroll(target);
                            } else {
                                console.error(error);
                            }
                        })
                        .finally(() => {
                            self.loader.stopLoading();
                        });
                } else {
                    self.ntFlash.error(gettext('Помилка відправлення даних форми. Зверніться до адміністратора!')); 
                }
            });
        });
    }

    showConfirmModal(user, company) {
        const data = {
            user,
            company
        };
        this.$mdDialog.show({
            templateUrl: '/static/scripts/modules/Profile/views/modals/profile.send-request-to-company.html',
            controller: 'SendRequestToCompanyCtrl',
            controllerAs: 'vm',
            resolve: {
                data: () => data
            }
        });
    }
}


profileApp
    .controller('userRegistrationController', UserRegistrationController)
    .controller('userRegistrationWithCompanyController', UserRegistrationWithCompanyController);