class ShowValue {
    constructor() {
    }
}

monitoringApp.component('showValue', {
    template: `
        <div ng-show=":: vm.valueData">
            <div class="block-info__title">{{:: vm.label }}</div>
            <div layout="column" layout-gt-sm="row">
                <div class="block-info__text">
                    <span>{{:: vm.valueData }}</span>
                </div>
            </div>
        </div>
    `,
    controller: ShowValue,
    controllerAs: 'vm',
    bindings: {
        showEmpty: '@',
        label: '@',
        valueData: '<',
    }
});