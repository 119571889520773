 class ShowAddressModalCtrl {
     constructor(
         $mdDialog,
         formationService,
         classifierService,
         ntFlash,
         data,
         tenderType,
         $timeout,
         googleMapsPlaces
     ) {
         console.log(data);
         this.tenderType = tenderType
         this.$mdDialog = $mdDialog;
         this.ntFlash = ntFlash;
         this.formationService = formationService;
         this.googleMapsPlaces = googleMapsPlaces;

         this.address = null;

         this.deliveryAddress = data.deliveryAddress;
         if (!this.deliveryAddress.countryName) this.deliveryAddress.countryName = 'Україна';
         this.deliveryLocation = data.deliveryLocation;

         this.isDeliveryByDocumentation = this.deliveryAddress.locality === 'Відповідно до документації';

         this.addresses = [];

         $timeout(() => {
             const position = {
                 lat: this.deliveryLocation ? this.deliveryLocation.latitude : 50.4546600,
                 lng: this.deliveryLocation ? this.deliveryLocation.longitude : 30.5238000
             };
             this.map = new google.maps.Map(document.getElementById('delivery-map'), {
                 zoom: this.deliveryLocation ? 15 : 10,
                 center: position
             });
             this.marker = new google.maps.Marker({
                 position: position,
                 map: this.map,
                 draggable:true
             });
             this.marker.addListener('dragend', (dragend) => {
                 if(this.deliveryLocation) {
                     this.updateDeliveryLocation(dragend.latLng.lat(), dragend.latLng.lng());
                 }
             });
         }, 1000);

         this.countries = [];
         this.regions = [];
         classifierService.loadCountriesRegions()
            .then((data) =>  {
                console.log('loadCountriesRegions data', data);
                this.countries = data.countries;
                this.regions = data.ua_regions;
            })
            .catch((error) => {
                console.log('loadCountriesRegions error', error);
                this.ntFlash.error(gettext('При завантаженні списку держав і регіонів виникла помилка'));
            });
     }

     isRequired(){
        return  this.tenderType !== 'reporting'
     }

     checkCountryIsInList() {
        if (this.countries.length === 0) return true;
        if (this.countries.includes(this.deliveryAddress.countryName)) return true;
        return false;
    }

    checkRegionIsInList() {
        if (this.regions.length === 0) return true;
        if (this.regions.includes(this.deliveryAddress.region)) return true;
        return false;
    }

     save() {
        if (!this.checkCountryIsInList() && !this.isDeliveryByDocumentation) {
            this.ntFlash.error(gettext('Введена некоректна назва країни або ви використовуєте не українську мову'));
            return;
        }
        if (this.deliveryAddress.countryName === 'Україна') {
            if (!this.checkRegionIsInList() && !this.isDeliveryByDocumentation && this.tenderType !== 'reporting') {
                this.ntFlash.error(gettext('Введена некоректна назва області'));
                return;
            }
        }
        const addressByDocumentation = {
             countryName: "Україна",
             postalCode: "",
             region: "",
             locality: "Відповідно до документації",
             streetAddress: ""
         };
         const address = this.isDeliveryByDocumentation ? addressByDocumentation : this.deliveryAddress;
         // if(address.locality == "Відповідно до документації" && )
         this.$mdDialog.hide({
             deliveryAddress: address,
             deliveryLocation: this.isDeliveryByDocumentation ? null : this.deliveryLocation,
             field: this.formationService.address(address)
         });
     }

     close() {
         this.$mdDialog.hide();
     }

     onChangeDeliveryAddress() {
         if(this.deliveryAddress.locality == "Відповідно до документації" && !this.isDeliveryByDocumentation){
             this.deliveryAddress.locality = ''
         }
         console.log('test')
        this.deliveryLocation = this.deliveryAddress.location;
        if(this.deliveryLocation) {
            this.setMapPosition({
                lat: this.deliveryLocation.latitude,
                lng: this.deliveryLocation.longitude
            });
        }
     }

     setMapPosition(position, zoom) {
         this.map.setCenter(position);
         if(zoom) {
             this.map.setZoom(zoom);
         }
         this.marker.setPosition(position);
     }

     selectedAddress(address) {
         this.address = null;
         this.updateLegalAddressByPlace(address, 15);
         this.address = address;
     }

     updateLegalAddressByPlace(place, zoomMap = 10) {
         if(place) {
             const placeInfo = this.googleMapsPlaces.parsePlaceAddressComponents(place.address_components);
             const items = ['region','country','locality','countryName','postalCode'];
             items.forEach((item) => {
                 this.deliveryAddress[item] = placeInfo[item] || '';
             });
             if(place.geometry && !this.address) {
                 const position = {
                     lat: place.geometry.location.lat(),
                     lng: place.geometry.location.lng()
                 };
                 this.setMapPosition(position, zoomMap);
                 this.updateDeliveryLocation(position.lat, position.lng);
             }
         }
     }

     updateDeliveryLocation(latitude, longitude) {
         this.deliveryLocation = {latitude, longitude};
     }
 }

tenderEdit.controller('showAddressModalCtrl', ShowAddressModalCtrl);