class NegotiationListService {
    constructor(
        http
    ) {
        this.getReasonsList = () => {
            return [
                {
                    value: 'twiceUnsuccessful',
                    description: gettext('Двічі відмінен процедуру відкритих торгів, у тому числі частково (за лотом), через відсутність достатньої кількості тендерних пропозицій, визначеної цим Законом'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'noCompetition',
                    description: gettext('Роботи, товари чи послуги можуть бути виконані, поставлені чи надані виключно певним суб’єктом господарювання'),
                    archive: true,
                    parent: 'None'
                },
                {
                    value: 'artPurchase',
                    description: gettext('Предмет закупівлі полягає у створенні або придбанні витвору мистецтва'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'contestWinner',
                    description: gettext('Укладення договору з переможцем архітектурного або мистецького конкурсу'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'technicalReasons',
                    description: gettext('Відсутність конкуренції з технічних причин'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'intProperty',
                    description: gettext('Необхідність захисту прав інтелектуальної власності'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'lastHope',
                    description: gettext('Укладення договору з постачальником "останньої надії"'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'additionalPurchase',
                    description: gettext('Виникла необхідність у постачанні додаткового обсягу товару у того самого постачальника, що здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість такого постачання не перевищує 50 відсотків ціни договору про закупівлю'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'additionalConstruction',
                    description: gettext('Виникла необхідність у закупівлі додаткових аналогічних робіт чи послуг у того самого учасника, що передбачені в основному договорі про закупівлю, який укладений за результатами проведення тендера та/або здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість таких робіт чи послуг не перевищує 50 відсотків ціни основного договору про закупівлю'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'resolvingInsolvency',
                    description: gettext('Закупівля товарів за процедурою відновлення платоспроможності боржника згідно із законодавством'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'stateLegalServices',
                    description: gettext('Закупівля юридичних послуг, пов’язаних із захистом прав та інтересів України, захисту національної безпеки і оборони, а також закупівлі товарів, робіт і послуг у разі участі замовника на підставі рішення Кабінету Міністрів України в міжнародних виставкових заходах'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'artContestIP',
                    description: gettext('Закупівля творів мистецтва або закупівля, пов’язана із захистом прав інтелектуальної власності, або укладення договору про закупівлю з переможцем архітектурного чи мистецького конкурсу'),
                    archive: true,
                    parent: 'None'
                }
            ];
        };
        this.getQuickReasonsList = () => {
            return [
                {
                    value: 'twiceUnsuccessful',
                    description: gettext('Двічі відмінено процедуру відкритих торгів, у тому числі частково (за лотом), через відсутність достатньої кількості тендерних пропозицій, визначеної цим Законом'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'noCompetition',
                    description: gettext('Роботи, товари чи послуги можуть бути виконані, поставлені чи надані виключно певним суб’єктом господарювання'),
                    archive: true,
                    parent: 'None'
                },
                {
                    value: 'artPurchase',
                    description: gettext('Предмет закупівлі полягає у створенні або придбанні витвору мистецтва'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'contestWinner',
                    description: gettext('Укладення договору з переможцем архітектурного або мистецького конкурсу'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'technicalReasons',
                    description: gettext('Відсутність конкуренції з технічних причин'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'intProperty',
                    description: gettext('Необхідність захисту прав інтелектуальної власності'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'lastHope',
                    description: gettext('Укладення договору з постачальником "останньої надії"'),
                    archive: false,
                    parent: 'noCompetition'
                },
                {
                    value: 'quick',
                    description: gettext('У замовника виникла нагальна потреба здійснити закупівлю у разі настання підстав передбачених Законом'),
                    archive: true,
                    parent: 'None'
                },
                {
                    value: 'emergency',
                    description: gettext('Виникнення особливих економічних обставин з негайною ліквідацією наслідків надзвичайних ситуацій'),
                    archive: false,
                    parent: 'quick'
                },
                {
                    value: 'humanitarianAid',
                    description: gettext('Надання Україною гуманітарної допомоги іншим державам'),
                    archive: false,
                    parent: 'quick'
                },
                {
                    value: 'contractCancelled',
                    description: gettext('Розірвання договору про закупівлю з вини учасника'),
                    archive: false,
                    parent: 'quick'
                },
                {
                    value: 'activeComplaint',
                    description: gettext('Оскарження прийнятих рішень, дій чи бездіяльності замовника щодо триваючого тендера'),
                    archive: false,
                    parent: 'quick'
                },
                {
                    value: 'additionalPurchase',
                    description: gettext('Виникла необхідність у постачанні додаткового обсягу товару у того самого постачальника, що здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість такого постачання не перевищує 50 відсотків ціни договору про закупівлю'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'additionalConstruction',
                    description: gettext('Виникла необхідність у закупівлі додаткових аналогічних робіт чи послуг у того самого учасника, що передбачені в основному договорі про закупівлю, який укладений за результатами проведення тендера та/або здійснюється протягом трьох років після укладення договору про закупівлю і загальна вартість таких робіт чи послуг не перевищує 50 відсотків ціни основного договору про закупівлю'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'resolvingInsolvency',
                    description: gettext('Закупівля товарів за процедурою відновлення платоспроможності боржника згідно із законодавством'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'stateLegalServices',
                    description: gettext('Закупівля юридичних послуг, пов’язаних із захистом прав та інтересів України, захисту національної безпеки і оборони, а також закупівлі товарів, робіт і послуг у разі участі замовника на підставі рішення Кабінету Міністрів України в міжнародних виставкових заходах'),
                    archive: false,
                    parent: 'None'
                },
                {
                    value: 'artContestIP',
                    description: gettext('Закупівля творів мистецтва або закупівля, пов’язана із захистом прав інтелектуальної власності, або укладення договору про закупівлю з переможцем архітектурного чи мистецького конкурсу'),
                    archive: true,
                    parent: 'None'
                }
            ];
        };
    }
}
commons.service('negotiationListService', NegotiationListService);