// wrapper for the wmluke/angular-flash
class NtFlash {
    constructor(
        flash
    ) {
        this.response = null;
        this.clean = () => {
            flash.info = '';
            flash.success = '';
            flash.error = '';
            this.response = null;
            return this;
        };
        this.success = (msg) => {
            this.clean();
            flash.success = msg;
        };
        this.error = (msg, error) => {
            this.clean();
            flash.error = msg;
            this.response = error;
        };
        this.info = (msg) => {
            this.clean();
            flash.info = msg;
        };
    }
}
commons.service('ntFlash', NtFlash);
