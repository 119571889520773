planningApp.controller('planningDeleteModalCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    planningService,
    data,
    ntFlash
) {
    $scope.delete = function() {
        planningService.delete(data);
        ntFlash.success(gettext('План успешно удален'));
        $uibModalInstance.close();
        $state.go('planning.list.type', {type: 'all'}, {
            reload: true
        });
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

});