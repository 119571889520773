commons.constant('PROCUREMENTS', {
    BELOW_THRESHOLD: 'belowThreshold',
    ABOVE_THRESHOLD_UA: 'aboveThresholdUA',
    ABOVE_THRESHOLD: 'aboveThreshold',
    COMPETITIVE_ORDERING: 'competitiveOrdering',
    ABOVE_THRESHOLD_UA_DEFENSE: 'aboveThresholdUA.defense',
    SIMPLE_DEFENSE: 'simple.defense',
    ABOVE_THRESHOLD_EU: 'aboveThresholdEU',
    REPORTING: 'reporting',
    NEGOTIATION: 'negotiation',
    NEGOTIATION_QUICK: 'negotiation.quick',
    COMPETITIVE_DIALOGUE_UA: 'competitiveDialogueUA',
    COMPETITIVE_DIALOGUE_EU: 'competitiveDialogueEU',
    ESCO: 'esco',
    ESCO_NEGOTIATION: 'esco.negotiation'
});
