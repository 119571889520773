class PrintEdr {
    constructor($mdDialog) {
        this.$mdDialog = $mdDialog;
        this.restrict = 'A';
    }

    link(scope, elm, attrs) {
        const documentsForPrint = ['edr_request.yaml', 'edr_identification.yaml'];
        if(scope.document && documentsForPrint.includes(scope.document.title)) {
            elm.on('click', (e) => {
                e.preventDefault();
                this.openEDR(scope.document);
            });
        }
    }

    openEDR(document) {
        const data = () => document;
        this.$mdDialog.show({
            templateUrl: '/static/scripts/modules/Commons/views/modals/overview-edr.html',
            controller: 'overviewEDRController',
            controllerAs: 'vm',
            fullscreen: true,
            resolve: {data}
        });
    }
}

commons.directive('printEdr', ($mdDialog) => new PrintEdr($mdDialog));