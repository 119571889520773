tenderView.controller('tenderViewChatCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    chatService,
    $stateParams,
    md5,
    $state,
    $location,
    tenderData
    ) {

    $scope.isMultilotTender = $scope.tender.lots && $scope.tender.lots.length ? true : false;
    $scope.lots = $scope.isMultilotTender ? $scope.tender.lots :[];
    $scope.actions = tenderData.action;
    $scope.tenderId = $stateParams.id;

    
    //TODO: Hide functionality in provider, remove rootscope statechange handler

    $scope.refreshQuestionsList = function() {
        chatService.get($scope.tenderId)
            .then(function(data) {
                $scope.questions = data;
                if ($scope.isMultilotTender && $scope.questions) {
                    $scope.questions.forEach(function(question) {
                        if (question.questionOf === "item") {
                            $scope.tender.items.forEach(function(item) {
                                if (item.id === question.relatedItem) {
                                    question.relatedItemTitle = item.description;
                                }
                                question.questionOfTitle = gettext("Предмет закупки");
                            });
                        } else if (question.questionOf === "lot") {
                            $scope.tender.lots.forEach(function(lot) {
                                if (lot.id === question.relatedItem) {
                                    question.relatedItemTitle = lot.title;
                                }
                                question.questionOfTitle = gettext("Лот");
                            });
                        } else {
                            question.questionOfTitle = gettext("Тендер");
                        }
                    });
                }
            });
    }

    $scope.refreshQuestionsList();

    $scope.answer = function (questionId, answer) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.chat.modal.html",
            controller: "tenderViewChatModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tenderId,
                        questionId: questionId,
                        answer: answer,
                        isQuestion: false,
                        isMultilotTender: $scope.isMultilotTender,
                        items: $scope.tender.items,
                        lots: $scope.lots
                    }
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshQuestionsList);
    };

    $scope.askQuestion = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/TenderView/views/modals/tender-view.chat.modal.html",
            controller: "tenderViewChatModalCtrl",
            resolve: {
                data: function() {
                    return {
                        tenderId: $scope.tenderId,
                        isQuestion: true,
                        isMultilotTender: $scope.isMultilotTender,
                        items: $scope.tender.items,
                        lots: $scope.lots
                    }
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshQuestionsList);
    }
});