tenderView.controller('tenderViewLawConsultationModalCtrl', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    modalData,
    lowConsultationService,
    dateListService,
    uibDatepickerConfig,
    ntFlash
) {
    $scope.data = {
        tender_id: modalData.tenderId,
        lot_id: modalData.lotId,
        note: '',
    };

    $scope.request = function () {
        lowConsultationService.request($scope.data)
            .then((data) => {
                ntFlash.success(gettext('Заявку відправлено. З Вами зв\'яжеться представник майданчика.'));
                $uibModalInstance.close();
            }).catch((error) => {
                ntFlash.error(gettext('Ваш запит вже в роботі'));
        });
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };

    $scope.open = function ($event, obj, string) {
        $event.preventDefault();
        $event.stopPropagation();
        obj[string + 'IsOpen'] = true;
    };
});