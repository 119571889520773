class LangService {

    constructor($http,$window) {
        this.current = null;
        this.requestUrl = '/i18n/setlang/';
        this.http = $http;
        this.$window = $window;
    }

    getCurrentLang() {
        return this.$window.CURRENT_LANG;
    }

    setCurrentLang(lang) {
        let formData = new FormData();
        formData.append("language", lang);
        return this.http({
            method: 'POST',
            url: this.requestUrl,
            headers: {
                'Content-Type': undefined
            },
            data: formData
        });
    }
}

commons.service('langService', LangService);