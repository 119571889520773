commons.filter('tenderStatusCaption', () => {
    "use strict";
    return (input, field, procurementMethodType) => {
        let name = "";

        switch (input) {
            case 'draft':
                name = {
                    label: gettext('Чернетка'),
                    short: 'draft'
                };
                break;

            case 'local_draft':
                name = {
                    label: gettext('Чернетка'),
                    short: 'local_draft'
                };
                break;

            case 'draft.pending':
                name = {
                    label: gettext('Неактивоване запрошення'),
                    short: 'draft.pending'
                };
                break;

            case 'draft.unsuccessful':
                name = {
                    label: gettext('Неуспішна закупівля'),
                    short: 'draft.unsuccessful'
                };
                break;

            case 'active':
                name = {
                    label: gettext('Активная закупка'),
                    short: 'active'
                };
                break;

            case 'active.enquiries':
                name = {
                    label: procurementMethodType !== 'closeFrameworkAgreementSelectionUA' ? gettext('Уточнение') : gettext('Період запрошення'),
                    short: 'enquiries'
                };
                break;

            case 'active.tendering':
                name = {
                    label: gettext('Предложения'),
                    short: 'tendering'
                };
                break;

            case 'active.auction':
                name = {
                    label: gettext('Аукцион'),
                    short: 'auction'
                };
                break;

            case 'active.pre-qualification':
                name = {
                    label: gettext('Преквалификация'),
                    short: 'qualification'
                };
                break;

            case 'active.pre-qualification.stand-still':
                name = {
                    label: procurementMethodType == "competitiveDialogueUA" || procurementMethodType == "competitiveDialogueEU"
                        ? gettext('Проведение переговоров') : gettext('Прекваліфікація (період оскарження)'),
                    short: 'qualification'
                };
                break;

            case 'active.qualification':
                name = {
                    label: gettext('Квалификация'),
                    short: 'qualification'
                };
                break;

            case 'active.qualification.stand-still':
                name = {
                    label: gettext('Кваліфікація переможців (період оскарження)'),
                    short: 'active.qualification.stand-still'
                };
                break;

            case 'active.awarded':
                name = {
                    label: gettext('Рассмотрен'),
                    short: 'awarded'
                };
                break;

            case 'unsuccessful':
                name = {
                    label: gettext('Несостоявшийся'),
                    short: 'unsuccessful'
                };
                break;

            case 'complete':
                name = {
                    label: gettext('Завершен'),
                    short: 'complete'
                };
                break;

            case 'cancelled':
                name = {
                    label: gettext('Отменен'),
                    short: 'cancelled'
                };
                break;

            case 'all':
                name = {
                    label: gettext('Все'),
                    short: 'all'
                };
                break;

            case 'unsuccessful,complete,cancelled':
                name = {
                    label: gettext('Архив'),
                    short: 'archive'
                };
                break;

            case 'draft.stage2':
                name = {
                    label: gettext('Черновик. Второй этап'),
                    short: 'draft.stage2'
                };
                break;

            case 'active.stage2.pending':
                name = {
                    label: gettext('Объявление второго этапа'),
                    short: 'active.stage2.pending'
                };
                break;

            case 'active.stage2.waiting':
                name = {
                    label: gettext('Ожидание второго этапа'),
                    short: 'active.stage2.waiting'
                };
                break;
            default:
                name = {
                    label: input,
                    short: input
                };
                break;

        }

        return field ? name[field] : name['label'];
    }
});