tenderView.factory('awardsService', function (http) {
    var saveResource = 'tender/:tenderId/awards/:awardId/';
    var getAwardResource = 'tender/:tenderId/awards/:awardId/';
    var getAllResource = 'tender/:tenderId/awards/';

    return {
        save: function (info, data) {
            var saveUrl = saveResource.replace(/:tenderId/g, info.tenderId).replace(/:awardId/g, info.awardId);
            return http.put(saveUrl, data);
        },
        get: function (tenderId) {
            var getUrl = getAllResource.replace(/:tenderId/g, tenderId);
            return http.get(getUrl);
        },
        getAward: function (tenderId, awardId) {
            var getUrl = getAwardResource.replace(/:tenderId/g, tenderId).replace(/:awardId/g, awardId);
            return http.get(getUrl);
        },
        create: function (tenderId, data) {
            var createUrl = getAllResource.replace(/:tenderId/g, tenderId);
            return http.post(createUrl, data);
        },
        hasAcceptedComplaints: (tender, awardId) => {
            const award = tender.awards.find(award => award.id === awardId);
            if (!award || !award.complaints) return false;

            return award.complaints.filter(complaint => complaint.status === 'accepted').length > 0;
        }
    };
});