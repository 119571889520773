profileApp.controller('companiesTendersListCtrl', function (
    $scope,
    $rootScope,
    $uibModal,
    $state,
    bookmarksService,
    tenderPeriodsListService,
    ) {

    $scope.isCanCreateCopy = false;

    $scope.createCopy = (tender) => {
        $state.go('editTender', {
            lots: tender.counters.lots > 0 ? "multilot" : "singlelot",
            tenderProcedure: tender.procurementMethodType,
            entity: 'tender',
            id: tender.id
        });
    };

    $scope.showCustomer = function($event, procuringEntity) {
        $event.stopPropagation();
        $event.preventDefault();

        return $uibModal.open({
            templateUrl: '/static/scripts/modules/Commons/views/customer-modal.html',
            controller: 'customerModalCtrl',
            resolve: {
                data: function() {
                    return procuringEntity;
                }
            },
            windowClass: 'center-modal'
        });
    };

    $scope.addBookmark = function($event, tenderId) {
        $event.stopPropagation();
        $event.preventDefault();
        bookmarksService.save(tenderId)
            .then(()=>{
                $state.reload();
            });
    };

    $scope.deleteBookmark = function($event, tenderId) {
        $event.stopPropagation();
        $event.preventDefault();
        bookmarksService.delete(tenderId)
            .then(()=>{
                $state.reload();
            });
    };

    $scope.currentTime = new Date();
    $scope.tenderPeriods = tenderPeriodsListService.getList();

    $scope.isDateInPeriod = (date, from, to) => {
        return to ? date > (new Date(from)) && date < (new Date(to)) : date > (new Date(from));
    };

    $scope.isDatePassed = (date, to) => {
        return to && date > (new Date(to));
    };

});