class TenderClassifier {
    constructor(
        MULTILINGUAL_PROCEDURES,
        $state,
        $scope,
        unitsService,
        classifierService,
        tenderService,
        validateService,
        ntFlash,
    ) {
        this.tender = tenderService.currentTender();
        this.classifierService = classifierService;
        this.validateService = validateService;
        this.ntFlash = ntFlash;
        this.state = $state;
        this.scope = $scope;
        this.titleClassifier = null;

    }

    updateTitleClassifier() {
        if (this.type === 'cpv') {
            if (this.item.classification && this.item.classification.id && this.item.classification.id.length > 0) {
                return this.item.classification.id + '-' + this.item.classification.description;
            } else {
                return ''
            }
        } else {
            let dkSchems = this.classifierService.getGroupSchemeByType(this.type);
            let classifications = (this.item.additionalClassifications || []).filter((cl) => {
                return dkSchems.has(cl.scheme);
            });
            let names = classifications.map((cl) => {
                return cl.id + '-' + cl.description;
            });
            return names.join('; ');
        }
    }

    $onInit() {
        // this.currentState = this.state.current.name;  // After close we must returned to this state
        this.titleClassifier = this.updateTitleClassifier();
    }

    openClassifier() {
        if (!this.item) return;

        let codes, checked = [];

        if (this.type === 'cpv') {
            if (this.item.classification && this.item.classification.id) {
                checked = [this.item.classification.id];
            }

        } else {
            let dkSet = this.classifierService.getGroupSchemeByType(this.type);
            let additionalClassifications = (this.item.additionalClassifications || []).filter((cl) => {
                return dkSet.has(cl.scheme);
            });
            checked = additionalClassifications.map((cl) => {
                return cl.id;
            });

            if (this.type === 'inn') {
                codes = this.classifierService.filterSchemes(
                    this.item.additionalClassifications, ['ATC']).map(
                    (cl) => {
                        return cl.id;
                    })
            } else if (this.type === 'atc') {
                codes = this.classifierService.filterSchemes(
                    this.item.additionalClassifications, ['INN']).map(
                    (cl) => {
                        return cl.id;
                    })
            } else if (this.type === 'gmdn') {
                codes = this.classifierService.filterSchemes(
                    this.item.additionalClassifications, ['GMDN']).map(
                    (cl) => {
                        return cl.id;
                    })
            } else if (this.type === 'uaroad') {
                codes = this.classifierService.filterSchemes(
                    this.item.additionalClassifications, ['UA-ROAD']).map(
                    (cl) => {
                        return cl.id;
                    })
            }
        }

        let currentState = this.state.current.name; // After close we must returned to this state

        let addClasificatorsDestoroy = this.scope.$on('addClasificators', (event, data, type) => {
            if (this.type === 'cpv') {
                this.item.classification = {
                    id: data[0].code,
                    scheme: data[0].scheme,
                    description: data[0].name,
                };

            } else {
                let scheme = this.classifierService.getSchemeByType(type);
                let additionalClassifications = [];
                if (this.item.additionalClassifications) {
                     additionalClassifications = this.item.additionalClassifications.filter((c) => {
                        return c.scheme !== scheme;
                    });
                }
                for (let i = 0; i < data.length; i++) {
                    additionalClassifications.push({
                        id: data[i].code,
                        scheme: data[i].scheme,
                        description: data[i].name,
                    });
                }
                this.item.additionalClassifications = additionalClassifications;
            }

            this.titleClassifier = this.updateTitleClassifier();
            addClasificatorsDestoroy();
            closeClasificatorsDestroy();
            this.state.go(currentState);

            if (this.tender
                && this.tender.mainProcurementCategory
                && !this.validateService.isValidItemsCpvByCategory(this.tender.mainProcurementCategory, this.item)) {
                this.ntFlash.error('Вид предмету закупівлі може не відповідати вказаному коду CPV(Єдиний закупівельний словник) розглянте можливість його зміни');
            }

        });

        let closeClasificatorsDestroy = this.scope.$on('closeClasificators', () => {
            addClasificatorsDestoroy();
            closeClasificatorsDestroy();
            this.state.go(currentState);
        });
        this.state.go(`${currentState}.classifier`, {
            type: this.type,
            checked: checked,
            codes,
        });
    };

    clearClassificator() {
        if (this.type === 'cpv') {
            this.item.classification = {
                id: '',
                scheme: '',
                description: '',
            };
        } else {
            let scheme = this.classifierService.getSchemeByType(this.type);
            this.item.additionalClassifications = this.item.additionalClassifications.filter((c) => {
                return c.scheme !== scheme;
            });
        }
        this.titleClassifier = this.updateTitleClassifier();
    }

    isClearable() {
        if (this.ngDisable) {
            return false;
        }
        if (this.type === 'cpv') {
            if (this.item.classification && this.item.classification.id && this.item.classification.id.length > 0) {
                return true;
            }
        } else {
            let scheme = this.classifierService.getSchemeByType(this.type);
            let additionalClassifications = (this.item.additionalClassifications || []).filter((c) => {
                return c.scheme === scheme;
            });
            if (additionalClassifications.length > 0) {
                return true;
            }
        }
        return false;
    }

}

tenderEdit.component('tenderClassifier', {
    templateUrl: '/static/scripts/modules/TenderEdit/views/components/tender-classifier.html',
    controller: TenderClassifier,
    controllerAs: 'vm',
    bindings: {
        label: '@',
        type: '@',
        item: '<',
        itemIndex: '<?',
        ngRequired: '<',
        ngDisabled: '<'
    }
});