class BidPublishDraft {
    constructor(
        $mdDialog,
        tenderBidService,
        data,
        ntFlash,
        $state,
        loaderService,
        billingService
    ) {
        this.$mdDialog = $mdDialog;
        this.tenderBidService = tenderBidService;
        this.ntFlash = ntFlash;
        this.$state = $state;
        this.loader = loaderService;
        this.billing = billingService;

        this.tender = data.tender;
        this.bid = data.bid;
    }

    publishBid() {
        this.loader.startLoading();
        this.tenderBidService
            .edit(this.tender.id, this.bid.id, {status: "publish"})
            .then(() => {
                this.$state.reload();
                this.billing.loadCurrentWallet();
                this.close();
            })
            .finally(() => this.loader.stopLoading());
    }

    close() {
        this.$mdDialog.hide();
    }
}

tenderView.controller('bidPublishDraft', BidPublishDraft);