class NotificationsBellController {
    constructor(notificationService) {
        this.notifications = notificationService;
    }
}

notificationsModule.component('notificationsBell', {
    template: `
        <a ui-sref="notificationsList.all" 
           class="notifications-bell" 
           title="{{'Ваши уведомления' | translate}}">
            <span class="glyphicon glyphicon-bell"></span>
            <span ng-if="vm.notifications.count > 0"
                  class="notifications-bell__badge">
                  {{ vm.notifications.count < 100 ? vm.notifications.count : '99+' }}
            </span>
        </a>
    `,
    controller: NotificationsBellController,
    controllerAs: 'vm'
});