class DateFormat {
    constructor($filter) {
        this.$filter = $filter;
        this.restrict = 'A';
        this.require = 'ngModel';
    }
    compile(tElem) {
        tElem.on('click', () => {
            tElem.val(this.$filter('date')(tElem.val(), 'yyyy-MM-dd'));
        });
    }
}
commons.directive('dateFormat', () => new DateFormat);