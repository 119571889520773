tenderView.controller('confirmModalCtrl', function(
    $scope,
    $uibModalInstance,
    data
) {
    $scope.title = data.title;
    $scope.description = data.description;
    $scope.yesWord = data.yesWord;
    $scope.noWord = data.noWord;

    $scope.confirm = function() {
        $uibModalInstance.close({confirm: true});
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };

});