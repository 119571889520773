commons.filter('titleShorter', () => {
    "use strict";

    return (input, min, max) => {
        let i = min;
        if (input) {
            while (input[i] !== ' ' && i < max) {
                i++;
            }
        }

        return input && input.length > i ? input.substring(0, i) + ' ...' : input;
    }
});