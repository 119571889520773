commons.filter('tenderStatusCancelledCaption', () => {
    "use strict";
    return (input, procurementMethodType) => {
        let name = gettext('Торги не состоялись');
        if (
            ['noDemand', 'unFixable', 'noObjectiveness', 'dateViolation'].includes(input) ||
            (input === 'expensesCut' && ['negotiation', 'negotiation.quick', 'belowThreshold', 'aboveThresholdUA.defense', 'simple.defense'].includes(procurementMethodType))
        ) {
            name = gettext('Торги отменены');
        }
        return name;
    }
});