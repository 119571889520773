class CompanyUsersController {
    constructor(
        loaderService,
        profileService,
        COMPANY_USER_STATUSES,
        COMPANY_USER_ROLES,
        ntFlash
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.ntFlash = ntFlash;
        this.COMPANY_USER_STATUSES = COMPANY_USER_STATUSES;
        this.COMPANY_USER_ROLES = COMPANY_USER_ROLES;
        this.roles = {
            [COMPANY_USER_ROLES.EMPTY]: gettext('Неназначена'),
            [COMPANY_USER_ROLES.ADMINISTRATOR]: gettext('Администратор'),
            [COMPANY_USER_ROLES.MANAGER]: gettext('Менеджер'),
            [COMPANY_USER_ROLES.AUDITOR]: gettext('Аудитор')
        };
        
        this.company = null;
        this.user = null;
        this.requests = [];
        this.users = null;

        this.$onInit();
    }

    $onInit() {
        this.profileService
            .getUser()
            .then((user) => {
                this.user = user.data;
                if(this.user.companies[0]) {
                    this.company = this.user.companies[0];
                    this.loadRequests();
                    this.loadUsers();
                }
            });
    }

    loadRequests() {
        this.profileService
            .getRequestsToCompany(this.company)
            .then((result) => {
                const requests = result && result.data || [];
                this.requests = requests.filter(request => request.status === 0);
            });
    }

    loadUsers() {
        this.profileService
            .getCompanyUsers(this.company)
            .then((result) => {
                const users = result && result.data || [];
                this.users = users.filter(item => item.user.id !== this.user.id);
            });
    }

    updateUserRole(role, item) {
        this.loader.startLoading();
        this.profileService
            .updateCompanyUser(this.company, item, {role})
            .then((result) => {
                item.role = result.data.role;
                this.ntFlash.success(gettext('Роль успешно изменена'))
            })
            .finally(() => this.loader.stopLoading());
    }

    removeUser(item) {
        this.loader.startLoading();
        this.profileService
            .removeCompanyUser(this.company, item)
            .then(() => {
                const index = this.users.indexOf(item);
                this.users.splice(index, 1);
            })
            .finally(() => this.loader.stopLoading());
    }

    updateUserStatus(item, status) {
        const data = {user_status: status};
        this.loader.startLoading();
        this.profileService
            .updateCompanyUser(this.company, item, data)
            .then(result => item.user_status = result.data.user_status)
            .finally(() => this.loader.stopLoading());
    }
}

profileApp.controller('userCompanyUsersController', CompanyUsersController);