tendersList.factory('plansSearchService', function (
    http
) {
    var searchResource = 'plans/?:pageNum:query:status:procurementMethodType:cpv:dkpp:amount_gte:amount_lte:createReport:create_gte:create_lte:tp_gte:tp_lte:edrpou';

    return {
        get: function (data) {
            var query = searchResource
                .replace(/:query/g, (data.query ? '&q=' + encodeURIComponent(data.query) : ''))
                .replace(/:status/g, (data.status ? '&s=' + data.status : ''))
                .replace(/:procurementMethodType/g, (data.procurementMethodType ? '&m=' + data.procurementMethodType : ''))
                .replace(/:cpv/g, (data.cpv ? '&c=' + data.cpv : ''))
                .replace(/:dkpp/g, (data.dkpp ? '&d=' + data.dkpp : ''))
                .replace(/:pageNum/g, (data.pageNum ? 'page=' + data.pageNum : 'page=1'))
                .replace(/:amount_gte/g, (data.amount_gte ? '&amount_gte=' + data.amount_gte : ''))
                .replace(/:amount_lte/g, (data.amount_lte ? '&amount_lte=' + data.amount_lte : ''))
                .replace(/:createReport/g, (data.createReport ? '&createReport=' + data.createReport : ''))
                .replace(/:create_gte/g, (data.create_gte ? '&publish_gte=' + data.create_gte : ''))
                .replace(/:create_lte/g, (data.create_lte ? '&publish_lte=' + data.create_lte : ''))
                .replace(/:tp_gte/g, (data.tp_gte ? '&tp_gte=' + data.tp_gte : ''))
                .replace(/:tp_lte/g, (data.tp_lte ? '&tp_lte=' + data.tp_lte : ''))
                .replace(/:edrpou/g, (data.edrpou ? '&edrpou=' + data.edrpou : ''));

            if (data.type === 'my-plans') query += '&user-plans=1';
            else if (data.type === 'company-plans') query += '&company-plans=1';

            return http.get(query)
                .then(function (result) {
                    return result.data.response;
                });
        }
    };
});