classifier.filter('showClassifiers', (classifierService) => {
    "use strict";
    return (additionalClassifications, type) => {
        if (type === 'cpv') {
            if (additionalClassifications && additionalClassifications.id && additionalClassifications.id.length > 0) {
                return additionalClassifications.id + '-' + additionalClassifications.description;
            } else {
                return ''
            }
        } else {
            let dkSchems = classifierService.getGroupSchemeByType(type);
            let classifications = (additionalClassifications || []).filter((cl) => {
                return dkSchems.has(cl.scheme);
            });
            let names = classifications.map((cl) => {
                return cl.id + '-' + cl.description;
            });
            return names.join('; ');
        }
    }
});