tenderView.factory('notificationsUserService', function (http) {
    let settingsNotifications = '/api/v1/notifications-settings/';


    // /api/v1/notifications-settings/?email=example@example.com

    return {
        getNotificationsSettings: function () {
            return http.get(settingsNotifications);
        }
    };
});
