class ModalDraggable {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            modalDraggable: '='
        };
        this.modal = null;
        this.startModalPositionTop = 0;
        this.startModalPositionLeft = 0;
        this.startCursorPositionTop = 0;
        this.startCursorPositionLeft = 0;
        this.isMouseDown = false;

    }
    
    link(scope, element) {
        if(!scope.modalDraggable) return;

        this.modal = element[0].parentNode.parentNode.parentNode;
        element[0].onmousedown = this.onmousedown.bind(this);
        element[0].onmouseup = this.onmouseup.bind(this);
        this.modal.parentNode.onmousemove = this.onmousemove.bind(this);
    }

    onmousedown(e) {
        this.startModalPositionTop = this.modal.offsetTop;
        this.startModalPositionLeft = this.modal.offsetLeft;
        this.startCursorPositionTop = e.clientY;
        this.startCursorPositionLeft = e.clientX;
        this.modal.classList.add('modal-drag');
        this.isMouseDown = true;
        this.updateModalPosition(this.startCursorPositionTop, this.startCursorPositionLeft);
    }

    onmouseup() {
        this.isMouseDown = false;
    }

    onmousemove(e) {
        if(this.isMouseDown) {
            this.updateModalPosition(e.clientY, e.clientX);
        }
    }

    updateModalPosition(cursorPositionY, cursorPositionX) {
        const top = this.startModalPositionTop + (cursorPositionY - this.startCursorPositionTop);
        this.modal.style.top = `${top}px`;
        const left = this.startModalPositionLeft + (cursorPositionX - this.startCursorPositionLeft);
        this.modal.style.left = `${left}px`;
    }
}
commons.directive('modalDraggable', () => new ModalDraggable);