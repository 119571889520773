contractApp.controller('contractDocumentsCtrl', function(
    $scope,
    $state,
    $filter,
    $uibModal,
    ntFlash,
    contractData,
    documentsService
) {
    ntFlash.clean();

    $scope.files = {
        replaceId: [],
        replaceFile: [],
        setReplaceId: function(id) {
            $scope.files.replaceId = id;
        }
    };

    $scope.contract = contractData.data.response;
    $scope.actions = contractData.data.action;
    $scope.contract.documents = $filter('versionFilter')($scope.contract.documents);

    if ($scope.contract.changes && $scope.contract.changes.length) {
        $scope.contract.changes.forEach(function(change) {
            if (change.status === 'pending') {
                $scope.changeId = change.id;
            }
        })
    }

    $scope.uploadDocument = function(changeId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Contract/views/modals/contract-documents.upload.modal.html",
            controller: "contractDocumentsUploadModalCtrl",
            resolve: {
                data: function() {
                    return {
                        changeId: $scope.changeId,
                        awardId: $scope.contract.awardID,
                        tenderId: $scope.contract.tender_id,
                        contractId: $scope.contract.id,
                        items: $scope.contract.items
                    }
                }
            },
            backdrop: 'static'
        }).result.then(function() {
            //contractData.data = contractingService.get($stateParams.id).data;
            $state.go($state.current, {}, {reload: true});
        });
    };

    $scope.$watch('files.replaceFile', function() {
        if ($scope.files.replaceFile && $scope.files.replaceFile.length) {
            documentsService.replace($scope.files.replaceFile[0], null, $scope.files.replaceId, {contractId: $scope.contract.id})
                .then(function(response) {
                    if (response) {
                        ntFlash.success(gettext('Документ успешно заменен'));
                        $state.reload();
                    }
                });
        }
    });
});