class ProfileUserAdministratorNotificationsController {
    constructor(
        // $q,
        $scope,
        $state,
        // $filter,
        // $uibModal,
        // $timeout,
        // ntFlash,
        profileService
    ) {
        // this.$q = $q;
        this.ps = profileService
        // this.ntFlash = ntFlash;
        // this.$state = $state;
        // this.$timeout = $timeout;
        // this.$q = $q;
        // this.notificationTypes = ['email', 'viber', 'bell'];
        // this.isNotificationOptionsAvailable = false;
        // this.notificationTypes = {}
        // this.notificationTypes.viber = {}
        // this.notificationTypeLabel = null
        this.notificationKinds = []
        // let self = this;
        this.users = [];
        this.selectedUser = null;
        this.current = {
            name: null,
            email: null
        }
        this.addCurrentUser()

    }
        getNotifications = () => {
            this.ps.getNotificationsSettings(this.selectedUser.email)
                .then((result) => {
                    let settings = result.data.settings;
                    let notifications = {};
                    settings.forEach(
                        s => {
                            if (!notifications[s.kind.key]) {
                                notifications[s.kind.key] = {};
                                notifications[s.kind.key].key = s.kind.key;
                                notifications[s.kind.key].title = s.kind.title;
                                notifications[s.kind.key].email = true;
                                notifications[s.kind.key].bell = true;
                                notifications[s.kind.key].viber = true;
                            }
                            if (s.type.value === 'bell')
                                notifications[s.kind.key].bell = s.is_enabled;

                            if (s.type.value === 'email')
                                notifications[s.kind.key].email = s.is_enabled;

                            if (s.type.value === 'viber')
                                notifications[s.kind.key].viber = s.is_enabled;
                        }
                    )
                    this.notificationKinds = Object.values(notifications);

                }
            );
        }

        loadUsers = () => {
            return this.ps.getAdminCompanyUsers()
                .then((response) => {
                    this.users = [...this.users, ...response.data];
                    // console.log(this.users)
                    this.getNotifications()
                })
                .catch(() => {
                    this.users = [];
                })
        }


        addCurrentUser = ()=>{
            this.ps.reloadCurrentUser()
                .then(()=>{
                    this.current.name = this.ps.current.name
                    this.current.email = this.ps.current.email
                    this.current.allow_admin_notifications = this.ps.current.allow_admin_notifications
                    console.log(this.current);
                    if(this.current.name){
                        this.users.push(this.current)
                        this.selectedUser = this.users[0]
                    }
                })
                .then(()=>{
                    this.loadUsers();
                })

        }





        handleCheckboxChange = (notificationRow, changedType) => {

            // console.log(this.selectedUser)

            let setting = {
                "user": this.selectedUser,
                "settings": [
                    {
                        "is_enabled": notificationRow[changedType],
                        "type": {"value": changedType},
                        "kind": {"key": notificationRow.key}
                    }
                ]
            }
            this.ps.setNotificationsSetting(setting)
        };

        changeAll = (notificationTypeLabel) => {
            const value = !this.notificationKinds[0][notificationTypeLabel];
            let settings = {
                "user": this.selectedUser,
                "settings": []
            }

            for (let el of this.notificationKinds) {
                el[notificationTypeLabel] = value;
                settings.settings.push({
                        "is_enabled": value,
                        "type": {"value": notificationTypeLabel},
                        "kind": {"key": el.key}
                    }
                )
            }
            this.ps.setNotificationsSetting(settings)
        };

        someChecked = (type) => {
            // console.log(self.notificationKinds)
            if(this.notificationKinds){
                let len = this.notificationKinds.filter(t => t[type]).length;
                return len > 0 && len < this.notificationKinds.length;
            } else {
                return 0;
            }

        }
        allChecked = (type) => {

            if(this.notificationKinds){
            let len = this.notificationKinds.filter(t => t[type]).length;
            return len === this.notificationKinds.length;
            } else {
                return 0;
            }
        }


}

profileApp.component('profileUserAdministratorNotifications', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-administrator-notifications.html',
    controller: ProfileUserAdministratorNotificationsController,
    controllerAs: 'vm'
})
