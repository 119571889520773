
class UserContactController {
    constructor() {
        this.confirmBtnDisabled = false;
        this.setDefaultBtnDisabled = false;
        this.setNotifyBtnDisabled = false;
        this.deleteBtnDisabled = false;
    }

    confirmButtonClick() {
        if (!this.onConfirmBtnClick) return;
        this.confirmBtnDisabled = true;
        this.onConfirmBtnClick({item: this.item})
            .finally(() => {this.confirmBtnDisabled = false;});
    }

    setDefaultButtonClick() {
        if (!this.onSetDefaultBtnClick) return;
        this.setDefaultBtnDisabled = true;
        this.onSetDefaultBtnClick({item: this.item})
            .finally(() => {this.setDefaultBtnDisabled = false;});
    }

    setNotifyButtonClick() {
        console.log('notify click');
        if (!this.onSetNotifyBtnClick) return;
        this.setNotifyBtnDisabled = true;
        this.onSetNotifyBtnClick({item: this.item})
            .finally(() => {this.setNotifyBtnDisabled = false;});
    }

    deleteButtonClick() {
        if (!this.onDeleteBtnClick) return;
        this.deleteBtnDisabled = true;
        this.onDeleteBtnClick({item: this.item})
            .finally(() => {this.deleteBtnDisabled = false;});
    }
}

profileApp.component('userContact', {
    templateUrl: '/static/scripts/modules/Profile/views/components/user-contact.html',
    controller: UserContactController,
    controllerAs: 'vm',
    bindings: {
        item: '<',
        onConfirmBtnClick: '&',
        onSetDefaultBtnClick: '&',
        onSetNotifyBtnClick: '&',
        onDeleteBtnClick: '&',
        canSetDefault: '<',
        canConfirm: '<'
    }
});
