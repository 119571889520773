class UserRecoveryPasswordController {
    constructor(loaderService, profileService) {
        this.loader = loaderService;
        this.profileService = profileService;

        this.user = {
            email: ''
        };
        this.isSuccessfullySent = false;
    }
    
    recovery(user) {
        this.loader.startLoading();
        this.profileService
            .recoveryPassword(user)
            .then(() => this.isSuccessfullySent = true)
            .finally(() => this.loader.stopLoading());
    }
}

profileApp.controller('userRecoveryPasswordController', UserRecoveryPasswordController);