class CompanyFormFields {
    constructor(
        loaderService,
        googleMapsPlaces,
        oraCodesService,
        ntFlash,
        companyService,
        OPC_CONFIG,
        $window,
        $timeout
    ) {
        this.$timeout = $timeout;
        this.$window = $window;
        this.loader = loaderService;
        this.googleMapsPlaces = googleMapsPlaces;
        this.oraCodesService = oraCodesService;
        this.ntFlash = ntFlash;
        this.companyService = companyService;

        this.codes = null;
        this.minRegistrationNumberLength = null;
        this.maxRegistrationNumberLength = null;

        this.isEDREnabled = OPC_CONFIG.EDR_ENABLED;

        this.countries = [];
        this.countries_datails = null;
    }

    $onInit() {
        this.$timeout(() => {
            let storageData = JSON.parse(this.$window.localStorage.getItem('CountriesRegions'));
            if (storageData) {
                this.countries = storageData.countries;
                this.countries_datails = storageData.countries_datails;
            }
        }, 1000);
        if(this.countryShortCode) {
            this.setRegistrationNumberLengthLimits(this.countryShortCode);
            this.loadCodesByCategory(this.countryShortCode);
        }
    }

    setRegistrationNumberLengthLimits(country) {
        this.minRegistrationNumberLength = country==='UA' ? 8 : null;
        this.maxRegistrationNumberLength = country==='UA' ? 10 : null;
    }

    loadCodesByCategory(category) {
        this.loader.startLoading();
        return this.oraCodesService
            .getCodesByCategory(category)
            .then(codes => this.codes = codes)
            .finally(() => this.loader.stopLoading());
    }

    checkCountryIsInList() {
        if (this.countries.length === 0) return true;
        if (this.countries.includes(this.company.legal_address.country_name)) return true;
        return false;
    }

    countryChangedList(place) {
        this.company.iati_ora_code = null;
        this.loader.startLoading();
        if (place && this.countries_datails && this.countries_datails.hasOwnProperty(place)) {
            const category = this.countries_datails[place];
            this.setRegistrationNumberLengthLimits(category.alpha2);
            this.loadCodesByCategory(category.alpha2)
                .finally(() => this.loader.stopLoading());
        } else {
            this.ntFlash.error(gettext('Ошибка определения странны'));
            this.loader.stopLoading();
        }
    }

    countryChanged(place) {
        this.company.iati_ora_code = null;
        this.loader.startLoading();
        const category = place && this.googleMapsPlaces.parseCountryShortName(place.address_components);
        if(category) {
            this.setRegistrationNumberLengthLimits(category);
            this.loadCodesByCategory(category)
                .finally(() => this.loader.stopLoading());
        } else {
            this.ntFlash.error(gettext('Ошибка определения странны'));
            this.loader.stopLoading();
        }
        if (!this.checkCountryIsInList()) {
            this.ntFlash.error(gettext('Введена некоректна назва країни або ви використовуєте не українську мову'));
            this.loader.stopLoading();
        }
    }

    updateLegalAddressByPlace(place) {
        if(place) {
            const {region, locality, postalCode} = this.googleMapsPlaces.parsePlaceAddressComponents(place.address_components);
            this.company.legal_address.region = region || '';
            this.company.legal_address.locality = locality || '';
            this.company.legal_address.postal_code = postalCode || '';
        }
    }

    onCheckRegistrationNumber() {
        const company = {
            iati_ora_code: this.company.iati_ora_code,
            registration_number: this.company.registration_number
        };
        this.loader.startLoading();
        this.companyService
            .verifyCompany(company)
            .then(result => this.company.verification_data = result.data)
            .catch(error => this.company.verification_data = '')
            .finally(() => this.loader.stopLoading())
    }

}

profileApp.component('companyFormFields', {
    templateUrl: '/static/scripts/modules/Profile/views/components/company-form-fields.html',
    controller: CompanyFormFields,
    controllerAs: 'vm',
    bindings: {
        company: '=',
        typeForm: '@',
        countryShortCode: '=',
        hasCheckRegistrationNumber: '=',
        form: '='
    }
});