class ProfileUserActivityLog {
    constructor(
        $scope,
        $state,
        profileService
    ) {
        this.$state = $state
        this.ps = profileService
        this.$scope = $scope
        this.hidePagination = true;
        this.currentPage = 1;
        this.paginationLabel = '';
        this.hidePagination = true;
        this.logged = false;

        this.itemsPerPage = 10;
        this.tenderId = '';
        this.users = [];
        this.dateStart = '';
        this.timeStart = '';
        this.dateEnd = '';
        this.timeEnd = '';
        this.subjects = [];
        this.FilterUser = [];
        this.query = ''
        // this.order = 'created_at';
        this.subjectList = [
            'get_tender_info',
            'update_user_contact',
            'create_user_contact',
            'create_tender_draft',
            'patch_tender_draft',
            'update_tender',
            'delete_tender',
            'update_bid_draft',
            'update_bid',
            'delete_bid',
            'bid_publish',
            'tender_publish',
            'update_company_info',
            'tender_sign',
            ];

        this.current = {
            id: '',
            name: '',
            email: '',
            company: ''
        }
        this.availibleCount = [
            10,
            50,
            100,
            200
        ]

    }


    $onInit = () => {
        this.getUser()
    };

        getActivityLogs = () => {
            console.log(this.current)
            return this.ps.getProfileActivityLogs(this.current.company.id, this.currentPage, this.itemsPerPage, this.query)
                .then((result) => {
                        let activityLogs = angular.copy(result.data);
                        this.totalItems = activityLogs.count;
                    let startAt = (this.currentPage > 1) ?
                        ((this.currentPage - 1) * this.itemsPerPage) + 1 : 1;
                    this.hidePagination = Boolean(this.totalItems <= this.itemsPerPage);
                    let endAt = ((startAt + this.itemsPerPage - 1) <= this.totalItems) ?
                        startAt + this.itemsPerPage - 1 : startAt + (this.totalItems - startAt);
                    this.paginationLabel = startAt.toString() + "-" + endAt.toString() + ' ' + gettext('из') + ' ' + this.totalItems;
                    this.activity = Object.values(activityLogs.results);
                    this.logged = true
                    }
                ).catch(e => console.log(e))
        }

        getUser = () => {
          return this.ps.reloadCurrentUser()
                .then((user) => {
                    if(user.companies[0].id) {
                        this.current.company = user.companies[0];
                        this.current.name = user.name
                        this.current.email = user.email
                        this.current.id = user.id
                        if (this.current.name) {
                            this.FilterUser.push(this.current)
                        }
                    }
                })
              .then(() => {
                  this.loadUsers()
              });
        }

        loadUsers = () => {
            this.ps
                .getCompanyUsers(this.current.company)
                .then((result) => {
                    if(result.data.length > 0){
                        const users = result.data.filter(item => item.user.id !== this.current.id);

                        users.map((el)=>{
                            this.FilterUser.push({
                                id: el.user.id,
                                company: el.user.company,
                                name: el.user.name,
                                email: el.user.email,
                            })
                        })
                    }
                    this.users = this.FilterUser[0]
                    this.getActivityLogs()
                });

        }


        pageChanged = ()=>{
            this.activity = [];
                this.getActivityLogs()
        }
        filter(){
            this.query = ''

            if(this.tenderId != ''){
                this.query += `&tender_id=${this.tenderId}`;
            }

            if(this.users.length > 0 ){
                // console.log(this.users)
                this.query += `&email=${this.users.join(',')}`;
            }

            if(this.dateStart != ''){
                this.query += `&dt_start=${this.formatDate(this.dateStart)}`;
            }

            if(this.dateEnd != ''){
                this.query += `&dt_end=${this.formatDate(this.dateEnd)}`;
            }

            if(this.subjects.length > 0){
                this.query += `&subject=${this.subjects.join(',')}`;
            }
            this.activity = []
            this.getActivityLogs( )

        }

        formatDate(date){
            let result;
            let current = new Date(date);
            result = current.getFullYear();
            result += `-${current.getMonth()+1}`
            result += `-${current.getDate()}`
            result += `T${current.getHours()}`
            result += `:${current.getMinutes()}:00`
            return result;
        }

    closeSelectBox() {
        $("md-backdrop").trigger ("click");
    };


}

profileApp.component('profileUserActivityLog', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-activity-log.html',
    controller: ProfileUserActivityLog,
    controllerAs: 'vm'
})
