class ServerValidation {
    constructor() {
        this.restrict = 'A';
        this.scope = {
            form: '=serverValidationForm',
            errors: '=serverValidationErrors',
            listErrorsClassName: '@serverValidationListErrorsClass'
        };
        this.listErrorsClassName = 'server-validation-errors';
    }
    link(scope, element) {
        if(scope.listErrorsClassName) {
            this.listErrorsClassName = scope.listErrorsClassName;
        }

        scope.$watch('errors', (errors = {}) => {
            if(typeof errors === 'string') {
                let errorsList = formListErrors.call(this, errors);
                let item = element[0];
                if(item.firstChild.classList.contains(this.listErrorsClassName)) {
                    item.firstChild.remove();
                }
                item.insertBefore(errorsList, item.firstChild);
                setTimeout(() => errorsList.remove(), 1500);
            } else if(errors) {
                invalidateFields.call(this, errors);
            }
        });

        function invalidateFields(errors) {
            for(let error in errors) {
                const isObject = typeof errors[error] === 'object' && !Array.isArray(errors[error]);
                if(isObject) {
                    invalidateFields.call(this, errors[error]);
                } else if(scope.form[error]) {
                    invalidateField.call(this, scope.form[error], errors[error]);
                }
            }
        }

        function invalidateField(field, errors) {
            const listener = () => {
                field.$setValidity('server', true);
                let index = field.$viewChangeListeners.indexOf(listener);
                if (index > -1) {
                    field.$viewChangeListeners.splice(index, 1);
                    let listErrors = element[0].querySelector(`[name="${field.$name}"]`).nextSibling;
                    if(listErrors.classList.contains(this.listErrorsClassName)) {
                        listErrors.remove();
                    }

                }
            };

            field.$setDirty();
            field.$setValidity('server', false);
            field.$viewChangeListeners.push(listener);

            if(errors) {
                let errorsList = formListErrors.call(this, errors);
                let item = element[0].querySelector(`[name="${field.$name}"]`);
                item.parentNode.insertBefore(errorsList, item.nextSibling);
            }
        }

        function formListErrors(errors = []) {
            let errorsList = document.createElement('ul');
            errorsList.classList.add(this.listErrorsClassName);
            if(typeof errors === 'string') {
                errorsList.innerHTML = `<li>${errors}</li>`;
            } else {
                errors.forEach((error) => {
                    errorsList.innerHTML = `${errorsList.innerHTML}<li>${error}</li>`;
                });
            }

            return errorsList;
        }
    }
}
commons.directive('serverValidation', () => new ServerValidation);