class ConvertToNumber {
    constructor() {
        this.require = 'ngModel';
    }
    link(scope, element, attrs, ngModel) {
        ngModel.$parsers.push((val) => {
            return parseInt(val, 10);
        });
        ngModel.$formatters.push((val) => {
            return '' + val;
        });
    }
}
commons.directive('convertToNumber', () => new ConvertToNumber);