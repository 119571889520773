class calendarRequest {
    constructor(
        http,
        API_BASE_URL
    ) {
        this.http = http;
        this.requestUrl = `${API_BASE_URL}/calendar/`;
    }

    getData(dates) {
        // console.log(dates)
        let query = `?dt_from=${dates.start}&dt_to=${dates.end}`
        // let query = `?dt_from=2022-02-01&dt_to=2022-05-05`
        return this.http.get(this.requestUrl + query, true);
    }
}


calendarModule.service('calendarRequest', calendarRequest);