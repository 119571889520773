tenderView.factory('guaranteeService', function (http, ntFlash, $filter) {
    var getListResource = '/api/v1/guarantee-bank/';
    var saveResource = '/api/v1/guarantee-request/';
    var getResource = '/api/v1/guarantee-request/:tenderId:lotId/';

    var formatData = function (data) {
        data.date_from = $filter('date')(data.date_from, 'yyyy-MM-dd');
        data.date_to = $filter('date')(data.date_to, 'yyyy-MM-dd');
        data.date_got = $filter('date')(data.date_got, 'yyyy-MM-dd');
        data.customer = data.customer ? 1 : 0;
        return data;
    };
    return {
        getBanksList: function (data) {
            return http.get(getListResource, true)
                .then(function (data) {
                    return data;
                })
                .catch((error) => {
                    console.warn('Error get banks guarantee');
                });
        },
        get: function (tenderId, lotId) {
            getUrl = getResource.replace(/:tenderId/g, tenderId).replace(/:lotId/g, lotId ? ":"+lotId : "");
            return http.get(getUrl, true)
                .then(function (data) {
                    return data;
                })
                .catch((error) => {
                    console.warn('Error get banks guarantee');
                });
        },
        request: function (data) {
            // console.log('data: ',data);
            // let testPromise = new Promise((resolve, reject) => {setTimeout(() => { resolve("result"); }, 1000);});
            // return testPromise;

            return http.post(saveResource, formatData(data), true)
                .then(function (data) {
                    return data.data.response;
                });
        }
    };
});