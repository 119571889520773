class UserResetPassword {
    constructor(
        profileService,
        loaderService,
        $state
    ) {
        this.loader = loaderService;
        this.profileService = profileService;
        this.$state = $state;

        this.user = {
            new_password1: '',
            new_password2: ''
        };
        this.errorMessages = null;
        this.isPasswordChanged = false;
        this.isCheckedHash = false;
        this.isHashValid = false;

        this.onInit();
    }

    onInit() {
        this.loader.startLoading();
        this.profileService
            .checkResetPasswordHash(this.$state.params.hash)
            .then(() => this.isHashValid = true)
            .catch(() => this.isHashValid = false)
            .finally(() => {
                this.isCheckedHash = true;
                this.loader.stopLoading();
            });
    }

    savePassword() {
        this.errorMessages = null;
        this.loader.startLoading();
        this.profileService
            .resetUserPassword(this.user, this.$state.params.hash)
            .then(() => this.isPasswordChanged = true)
            .catch((error) => {
                this.errorMessages = error.data.errors
            })
            .finally(() => {
                this.loader.stopLoading();
            });
    }
}


profileApp.controller('userResetPassword', UserResetPassword);