class NotificationsListController {
    constructor(
        loaderService,
        notificationService
    ) {
        this.loader = loaderService;
        this.notificationService = notificationService;
        // this.fullNotification = null;
    }

    setReadedNotification(notification) {
        if(!notification.is_read) {
            return this.notificationService
                .setNotificationRead(notification)
                .then(() => {
                    notification.is_read = true;
                    this.notificationService.countNotReadedNotifications();
                    return notification;
                });
        }
    }

    getUserName(userEmail){
        if(this.users.length > 0){
            const user = this.users.find((el)=>el.email === userEmail)
            if(user){
                return user.name
            }
            return false;
        } else {
            return false;
        }
    }

    getUserRole(userEmail){
        if(this.users.length > 0){

            const user = this.users.find((el)=>el.email === userEmail)
            if(user){
                switch (user.role) {
                    case 0:{
                        return ''
                    }
                    case 1:{
                        return gettext("Администратор")
                    }
                    case 2:{
                        return gettext("Менеджер")
                    }
                    case 3:{
                        return gettext("Аудитор")
                    }
                    case 4:{
                        return ''
                    }
                }
                return user.role
            }
            return 0;
        } else {
            return 0
        }
    }

    getUserRoleNumber(userEmail){
        if(this.users.length < 1){
            const user = this.users.find((el) => el.email === userEmail)
            if(user){
                return user.role
            } else {
                return 0
            }
        } else {
            return 0
        }
    }


    // setFullNotification(notification) {
    //     if(notification) {
    //         if(!notification.is_read) {
    //             this.setReadedNotification(notification);
    //         }
    //         this.fullNotification = this.fullNotification && this.fullNotification.id === notification.id ? null : notification;
    //     } else {
    //         this.fullNotification = null
    //     }
    // }
}

notificationsModule.component('notificationsList', {
    templateUrl: '/static/scripts/modules/Notifications/views/components/notifications-list.html',
    controller: NotificationsListController,
    controllerAs: 'vm',
    bindings: {
        notifications: '=',
        users: '<'
    }
});