tenderView.controller('tenderViewInviteCtrl', function(
    $scope,
    $rootScope,
    invitesData,
    providersSearchService,
    $timeout,
    $stateParams,
    $state,
    invitesService,
    tenderData,
    ntFlash
    ) {

    $scope.actions =  tenderData.action;
    $scope.invites = invitesData;
    $scope.provider = {
        name: '',
        valid: false
    };
    $rootScope.showInviteButton = $scope.invites && $scope.invites.length > 0 ? true : false;
    $scope.providersList = [];
    var selectedProvider = null;

    $scope.addProvider = function() {
        var isNotPlaced = $scope.invites.every(function(el) {
            if (el.invited_email) {
                return el.invited_email != $scope.provider.name;
            } else {
                return $scope.provider.name != el.company_name;
            }
        });

        if (selectedProvider && selectedProvider.value == $scope.provider.name && isNotPlaced) {
            $scope.invites.push({
                invited_company: selectedProvider.id,
                company_name: selectedProvider.value,
                active: true,
                required: false
            })
        } else {
            if (isNotPlaced) {
                $scope.invites.push({
                    company_name: "",
                    active: true,
                    invited_email: $scope.provider.name,
                    phone_or_company_name: "",
                    required: true
                });
            }
        }
        $rootScope.showInviteButton = $scope.invites.length > 0 ? true : false;
        $scope.provider.name = '';
        $scope.providersList = [];
        selectedProvider = null;
        $scope.provider.valid = false;

    };

    $scope.removeField = function(index) {
        $scope.invites.splice(index, 1);
        $rootScope.showInviteButton = $scope.invites.length > 0 ? true : false;
    };

    $scope.$watch('provider.name', function(val) {
        $scope.searchTimer && $timeout.cancel($scope.searchTimer);

        if (validateEmail(val) || (selectedProvider && selectedProvider.value == val)) {
            $scope.provider.valid = true;
            return;
        }

        if (val && val.length > 1) {
            // delaying the request in case the user is typing too fast,
            // so that we avoid extra requests.
            $scope.searchTimer = $timeout(function() {
                providersSearchService.get({query: val})
                    .then(function(data) {
                        $scope.providersList = data;
                        $scope.provider.valid = false;
                    });
            }, 300);
        }
    });

    $scope.selectProvider = function(provider) {
        $scope.provider.name = provider.value;
        selectedProvider = provider;
        $scope.providersList = [];
        $scope.provider.valid = true;
    };

    $rootScope.$on('sendInvite', function() {
        if ($scope.invitesForm.$valid) {
            invitesService.save({tenderId: $stateParams.id, invites: {invites: $scope.invites}})
                .then(function(data) {
                    $scope.invites = data;
                });
        } else {
            ntFlash.error(gettext('Введите номер телефона'));
        }
    });

    $scope.checkBoxAction = function() {
        var counter = 0;
        $scope.invites.forEach(function(val) {
            counter += val.active ? 1 : 0;
        });
        if (counter == 0) {
            $rootScope.showInviteButton = false;
        } else {
            $rootScope.showInviteButton = true;
        }
    };

    $state.get('tenderView.invites').onExit = function() {
        $rootScope.showInviteButton = false;
    };

    function validateEmail(email) {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    }

});