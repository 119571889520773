class ClassifiersList {
    constructor(loaderService, classifierService) {
        this.loader = loaderService;
        this.classifierService = classifierService;
        this.checkedList = this.checkedClassifiers || [];
    }

    toggleTree(item) {
        item.collapse = !item.collapse;
        if (item.collapse && !item.is_leaf) {
            this.classifierService
                .getFullChildList(this.kind, item)
                .then((data) => {
                    item.children = data;
                });
        } else if(!item.collapse) {
            item.children = null;
        }
    }

    isCheckedCode(item) {
        return this.checkedList.includes(item.code);
    }

    toggleCode(item) {
        const index = this.checkedList.indexOf(item.code);
        if(index > -1) {
            this.checkedList.splice(index, 1);
            this.uncheckedClassifier(item);
        } else {
            this.checkedList.push(item.code);
            this.checkedClassifier(item);
        }
    }

    checkedClassifier(classifier) {
        if(this.onCheckClassifier) {
            this.onCheckClassifier({classifier});
        }
    }

    uncheckedClassifier(classifier) {
        if(this.onUncheckClassifier) {
            this.onUncheckClassifier({classifier});
        }
    }
}

classifier.component('classifiersList', {
    templateUrl: '/static/scripts/modules/Classifier/views/components/classifiers-list.html',
    controller: ClassifiersList,
    controllerAs: 'vm',
    bindings: {
        classifiers: '=',
        kind: '=',
        checkedClassifiers: '=',
        onCheckClassifier: '&',
        onUncheckClassifier: '&'
    }
});