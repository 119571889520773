class DocumentScrollVisibility {
    constructor() {
        this.restrict = 'A';
    }
    link(scope, element, attrs) {
        const minVisibleOffset = +attrs.documentScrollVisibility;
        const listener =  () => {
            element[0].style.opacity = minVisibleOffset < document.documentElement.scrollTop ? 1 : 0;
        };
        listener();
        
        document.addEventListener('scroll', listener);
        scope.$on('$destroy', () => {
            document.removeEventListener('scroll', listener);
        });
    }
}

commons.directive('documentScrollVisibility', () => new DocumentScrollVisibility());