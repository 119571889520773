class ProfileContactsController {
    constructor(
        profileService,
        userContactsService,
        CONTACT_TYPES,
        $state,
        $q,
        $stateParams,
        $mdDialog
    ) {
        this.pSrv = profileService;
        this.ucSrv = userContactsService;
        this.$state = $state;
        this.$stateParams = $stateParams;
        this.$q = $q;
        this.$mdDialog = $mdDialog;
        this.CT = CONTACT_TYPES;

        this.contacts = null;
        this.contactTypes = null;
        if (this.contactType) {
            this.contactTypes = [this.contactType];
        }
        this.user = this.pSrv.current
        let self = this;
    }

    getList() {
        this.ucSrv
            .getList(this.user.id)
            .then((result) => {
                this.contacts = result.data;
                this.hasTelegram = this.contacts.map(el=>el.type).includes(40);
            });
    }

    checkConfirmation() {
        let confirmationCode = this.confirmationCode;
        if (!confirmationCode) {
            return this.$q.when();
        }

        return this.ucSrv
            .completeConfirmation(this.user.id, confirmationCode)
            .then((result) => {
                let contactID = result.data.id;
                let contactIsConfirmed = result.data.is_confirmed;
                let contacts = this.contacts;
                for (let i = 0, l = contacts.length; i < l; i++) {
                    let contact = contacts[i];
                    if (contactID === contact['id']) {
                        if (contactIsConfirmed !== contact.is_confirmed) {
                            contact.is_confirmed = contactIsConfirmed;
                        }
                    }
                }
            });
    }

    $onInit() {
        this.user = this.pSrv.current
        this.$q.when()
            .then(() => this.getList())
            .then(() => this.checkConfirmation());
    }

    showConfirmationDialog(title, content, evt, okBtnText, cancelBtnText) {
        okBtnText = okBtnText || gettext('Подтвердить');
        cancelBtnText = cancelBtnText || gettext('Отменить');
        let confirm = this.$mdDialog
            .confirm()
            .title(title)
            .textContent(content)
            .ariaLabel(title)
            .targetEvent(evt)
            .ok(okBtnText)
            .cancel(cancelBtnText);

        return this.$mdDialog.show(confirm);
    }

    onSetDefault(item) {
        if (item.is_default) return this.$q.when();

        let dialogContent = gettext('Вы действительно хотите установить ' +
                'контакт {value} по умолчанию?')
            .replace('{value}', item.value);
        let dialogTitle = gettext('Установка контакта по умолчанию');
        return this.showConfirmationDialog(dialogTitle, dialogContent)
            .then(() => this.setContactAsDefault(item), () => this.$q.when());
    }

    onSetNotify(item) {
        console.log('item.use_notify', !item.use_notify);
        //if (item.use_notify) return this.$q.when();
        return this.ucSrv
            .update(this.user.id, item.id, {
                'use_notify': !item.use_notify,
                "id": item.id
            })
            .then((result) => {
                item.use_notify = !item.use_notify;
                return true;
            })
            .catch((error) => this.errorMessages = error.data.errors);
    }

    setContactAsDefault(item) {
        return this.ucSrv
            .setDefault(this.user.id, item.id)
            .then((result) => {
                if (!result.data.is_default) return;
                this.contacts.forEach((contact) => {
                    if (contact.type == item.type) {
                        contact.is_default = false;
                    }
                });
                item.is_default = true;
                if (item.type === this.CT.EMAIL) {
                    this.pSrv.current.email = item.value;
                }
                return true;
            })
            .catch((error) => this.errorMessages = error.data.errors);
    }

    onSetConfirmed(item) {
        if (item.is_confirmed) return;
        return this.ucSrv
            .setConfirmed(this.user.id, item.id)
            .then((result) => {
                if (result.is_confirmed) {
                    item.is_confirmed = true;
                    return true;
                }
            })
            .catch((error) => this.errorMessages = error.data.errors);
    }

    onDelete(item) {
        const index = this.contacts.indexOf(item);
        if (index === -1) return;

        let dialogContent = gettext('Вы действительно хотите удалить ' +
                'контакт {value}?')
            .replace('{value}', item.value);
        let dialogTitle = gettext('Удаление контакта');
        return this.showConfirmationDialog(dialogTitle, dialogContent)
            .then(() => this.deleteContact(item), () => this.$q.when());
    }

    deleteContact(item) {
        return this.ucSrv
            .remove(this.user.id, item.id)
            .then((result) => {
                const index = this.contacts.indexOf(item);
                this.contacts.splice(index, 1);
                return true;
            })
            .catch((error) => this.errorMessages = error.data.errors);
    }

    onCreate(item) {
        return this.ucSrv
            .create(this.user.id, item)
            .then((result) => {
                this.contacts.push(result.data);
            });
    }
}


profileApp.component('profileContacts', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-contacts.html',
    controller: ProfileContactsController,
    controllerAs: 'vm',
    bindings: {
        user: '<',
        contactType: '<',
        confirmationCode: '<'
    }
});