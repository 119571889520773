profileApp.controller('signDocumentsCtrl', function (
    $scope,
    $state,
    $filter,
    documentsService,
    ntFlash,
    md5,
    uuid4
) {
    var reportQuery = {};
    $scope.loadInProgress = false;
    $scope.selected = {
        files: []
    };
    $scope.replace = {
        files: []
    };
    $scope.addedFiles = [];
    $scope.deletedFiles = [];
    $scope.errorFiles = [];
    $scope.signDocArr = [];
    $scope.signedDoc = [];


    $scope.$watch('selected.files', function(val) {
        console.log('Add file to form:', val)
        if(val){
            val.forEach(function(el) {
                el.createId = md5.createHash(uuid4.generate());
            });
            $scope.signDocArr = [...$scope.signDocArr, ...val];
        }

    });

    $scope.getFormatDocTitle = function(docTitle) {
        const nameArr = docTitle.split('.');
        let title = '';
        let extension = nameArr[nameArr.length - 1]
        nameArr.pop()
        title = nameArr.join('.');
        return `<span class="name">${title}</span><span class="extension">.${extension}</span>`;
    }


    $scope.chooseAllDoc = () =>{

        $scope.signDocArr = [...$scope.addedFiles]
    }

    $scope.addSign = () => {

        if ($scope.signDocArr.length < 1) {
            ntFlash.error(gettext('Нет документов на подпись'));
            return;
        }

        $scope.loadElement = document.createElement('div');
        $scope.loadElement.setAttribute('id', 'loading-bar-spinner')
        let spinner = document.createElement('div')
        spinner.classList.add('spinner-icon')
        $scope.loadElement.appendChild(spinner)
        document.body.appendChild($scope.loadElement)

        $scope.loadInProgress = true;

        $scope.loadElement.remove()
        $scope.SignDocs($scope.signDocArr)
            .finally(()=>{
                $scope.loadInProgress = false;
                ntFlash.success(gettext('Документы подписаны и добавлены.'));
            })

    }

    $scope.SignDocs = (files)=>{
        return documentsService.sign(files, {
            reload: false,
            setSign : true,
            checkCompany: true
        })
            .then(data => {
                console.log('documentsService.sign docs', data)
                console.log('files', files)
                $scope.$apply(()=>{
                    (data || []).map((el, index)=>{
                        el.createId = md5.createHash(uuid4.generate());
                        $scope.signedDoc.push(el)
                        return el;
                    })
                    $scope.$emit('selected.files', data );
                    $scope.signDocArr = []
                })


            })
            .catch(error => console.log('documentsService.sign error', error));
    }
    $scope.deleteFileFromList = function(index) {
        $scope.addedFiles.splice(index, 1);
    };
    $scope.downloadFile = function(file) {
        const blob = new Blob([file])
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = file.name;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    };
    $scope.downloadAll = (file) => {

        $scope.signedDoc.forEach((doc) =>{

            const blob = new Blob([doc])
            const elem = window.document.createElement('a');
            elem.href = window.URL.createObjectURL(blob);
            elem.download = doc.name;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);

        })


    };



});


