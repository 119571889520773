profileApp.controller('companiesOverviewCtrl', function (
    $scope,
    $state,
    companyService,
    ntFlash
) {
    $scope.ntFlash = ntFlash;
    $scope.companyService = companyService;
    $scope.edrpou = $state.params.edrpou;
    $scope.type = $state.params.type;
    $scope.classificationSelected = [];

    $scope.companyService.getPublicCompany($scope.edrpou, $scope.type)
        .then((data) =>  {
            $scope.company = data;
        })
        .catch((error) => {
            console.log('getPublicCompanies error', error);
            $scope.ntFlash.error(gettext('При завантаженні даних компанії виникла помилка'));
        });

    $scope.toggleSelected = function(classification, classificationSelected) {
        let idx = classificationSelected.indexOf(classification);
        if (idx > -1)
            classificationSelected.splice(idx, 1);
        else
            classificationSelected.push(classification);
    }

    $scope.submitSelected = function() {
        let searchData = {
            type: 'buyer',
            edrpou: $scope.edrpou,
            cpv: $scope.classificationSelected.map(el => el.code).toString()
        };
        $state.go('profile.companies.tenders.type', searchData, {
            reload: true,
            inherit: false
        });
    }
});