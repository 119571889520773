commons.filter('agreementContractStatus', () => {
    "use strict";

    const STATUS_MSG = {
        'active': gettext('Рамкову угоду можна укласти з учасником'),
        'unsuccessful': gettext('Рамкову угоду не буде укладено з учасником'),
    };

    return (status) => {
        return STATUS_MSG[status] || status;
    }
});
