tenderView.controller('tenderViewCzoAddPlanCtrl', function(
    $scope,
    $uibModalInstance,
    $state,
    tenderService,
    data,
    ntFlash,
    $timeout
) {
    $scope.planId = '';
    $scope.tenderId = data.tenderId;
    $scope.disableAddPlanBtn = false;

    $scope.addPlanToTenderCZO = function() {
        if ($scope.addPlanToTenderForm.$valid) {
            $scope.disableAddPlanBtn = true;
            tenderService.addPlanToTenderCZO($scope.tenderId, $scope.planId)
                .then(function(resData) {
                    console.log('Response data', resData);
                    ntFlash.success(gettext('Річний План успішно зв\'язаний з цією Закупівлею'));
                    $uibModalInstance.close();
                    $timeout(function() {
                        $state.reload();
                    }, 1000);
                })
                .catch(function(error) {
                    console.log('Response error', error);
                    ntFlash.error(gettext('Під час зв\'язання Плану з Закупівлею виникла помилка'));
                    $scope.disableAddPlanBtn = false;
                });
        }
    };

    $scope.close = function() {
        $uibModalInstance.close();
    };
});