monitoringApp.factory('monitoringService', function(http) {
    return {
        get: function(id, monitoring_id) {
            var url = `tender/${id}/monitorings/`;
            if (monitoring_id) {
                url += `${monitoring_id}/`
            }
            return http.get(url)
                .then(function(result) {
                    return result.data.response;
                });
        },

        newPost: function (tender_id, monitoring_id, data) {
            return http.post(`tender/${tender_id}/monitorings/${monitoring_id}/posts/`, data)
                .then(function(result) {
                    return result.data.response;
                });

        },

        editPost: function (tender_id, monitoring_id, post_id, data) {
            return http.patch(`tender/${tender_id}/monitorings/${monitoring_id}/posts/${post_id}/`, data)
                .then(function(result) {
                    return result.data.response;
                });
        },

        addPostDocument: function (tender_id, monitoring_id, post_id, data) {
            return http.post(`tender/${tender_id}/monitorings/${monitoring_id}/posts/${post_id}/documents/`, data)
                .then(function(result) {
                    return result.data.response;
                });
        },

        newAppeal: function (tender_id, monitoring_id, data) {
            return http.put(`tender/${tender_id}/monitorings/${monitoring_id}/appeal/`, data)
                .then(function(result) {
                    return result.data.response;
                });

        },

        addDocumentToAppeal: function (tender_id, monitoring_id, data) {
            return http.post(`tender/${tender_id}/monitorings/${monitoring_id}/appeal/documents/`, data)
                .then(function(result) {
                    return result.data.response;
                });
        },

        violationEliminationReport: function (tender_id, monitoring_id, data) {
            return http.put(`tender/${tender_id}/monitorings/${monitoring_id}/eliminationReport/`, data)
                .then(function(result) {
                    return result.data.response;
                });

        },

        violationEliminationReportUpdate: function (tender_id, monitoring_id, data) {
            return http.patch(`tender/${tender_id}/monitorings/${monitoring_id}/eliminationReport/`, data)
                .then(function(result) {
                    return result.data.response;
                });

        },

        addViolationEliminationDocument: function (tender_id, monitoring_id, data) {
            return http.post(`tender/${tender_id}/monitorings/${monitoring_id}/eliminationReport/documents/`, data)
                .then(function(result) {
                    return result.data.response;
                });
        },

    }
});
