contractApp.controller('contractChangesCtrl', function(
    $scope,
    $rootScope,
    $uibModal,
    $state,
    $stateParams,
    contractData,
    documentsService,
    contractingService,
    ntFlash
    ) {

    ntFlash.clean();

    var contractId = $state.params.id;

    $scope.files = {
        contract: []
    };

    $scope.changes = [];
    $scope.actions = contractData.data.action;
    $scope.contract = contractData.data.response;

    documentsService.getContractDocsList({contractId: contractId})
        .then(function(response) {
            // console.log(response);
            $scope.documents = [];
        });

    $scope.refreshList = function() {
        contractingService.getChanges(contractId)
            .then(function(result) {
                $scope.canMakeChange = true;
                $scope.changes = result.data.response;

                $scope.changes.forEach(function(change) {
                    if (change.status === 'pending' || $scope.contract.status === 'terminated') {
                        $scope.canMakeChange = false;
                    }
                });
            });
    };

    $scope.refreshList();

    $scope.createChange = function() {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Contract/views/modals/contract-changes.create.modal.html",
            controller: "contractChangesCreateModalCtrl",
            resolve: {
                data: function() {
                    return {
                        contractId: contractId
                    };
                }
            },
            backdrop: 'static'
        }).result.then($scope.refreshList);
    };

    $scope.uploadDocument = function(changeId) {
        return $uibModal.open({
            templateUrl: "/static/scripts/modules/Contract/views/modals/contract-documents.upload.modal.html",
            controller: "contractDocumentsUploadModalCtrl",
            resolve: {
                data: function() {
                    return {
                        changeId: changeId,
                        awardId: $scope.contract.awardID,
                        tenderId: $scope.contract.tender_id,
                        contractId: $scope.contract.contractID,
                        items: $scope.contract.items
                    };
                }
            },
            backdrop: 'static'
        });
    };
});