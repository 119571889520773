class GoogleMapsPlaces {
    constructor($q) {
        this.$q = $q;
        this.places = google.maps.places;
    }

    findCountries(query) {
        return this.findPlace({
            input: query,
            types: '(regions)'
        }).then((list) => {
            const countries = list.filter(item => {
                return item.types && item.types.includes('country')
            });
            return countries;
        });
    }

    findCities(query) {
        return this.findPlace({
            input: query,
            types: '(cities)'
        }).then((list) => {
            const cities = list.filter(item => {
                return item.types && item.types.includes('locality')
            });
            return cities;
        });
    }

    findAddresses(query) {
        let params = {
            input: query
        };
        return this.findPlace(params);
    }

    findPlace(params) {
        const q = this.$q.defer();
        const service = new this.places.AutocompleteService();
        service.getQueryPredictions(params, (predictions) => {
            const list = predictions || [];
            q.resolve(list);
        });
        return q.promise;
    }

    getPlaceDetails(placeId) {
        const q = this.$q.defer();
        const geocoder = new google.maps.Geocoder;
        geocoder.geocode({placeId}, (places) => {
            q.resolve(places[0]);
        });
        return q.promise;
    }

    parsePlaceAddressComponents(components = []) {
        let place = {};
        const componentsWithTypes = components.filter(component => component.types);
        componentsWithTypes.map((component) => {
            if(component.types.includes('administrative_area_level_1')) {
                place.region = component.long_name;
            } else if(component.types.includes('locality')) {
                place.locality = component.long_name;
            } else if(component.types.includes('country')) {
                place.countryName = component.long_name;
            } else if(component.types.includes('postal_code')) {
                place.postalCode = component.long_name;
            }
        });
        return place;
    }

    parseCountryShortName(components = []) {
        let shortName;
        const componentsWithTypes = components.filter(component => component.types);
        componentsWithTypes.map((component) => {
            if(component.types.includes('country')) {
                shortName = component.short_name;
            }
        });
        return shortName;
    }
}

commons.service('googleMapsPlaces', GoogleMapsPlaces);