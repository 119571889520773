class AgreementPage {
    constructor(
        $scope,
        $state,
        $timeout,
        $uibModal,
        // $filter,
        $stateParams,
        $rootScope,
        ntFlash,
        // tenderService,
        agreementInstanceService,
        transferService,
        documentsService
    ) {
        // 'ngInject';
        this.$scope = $scope;
        this.$state = $state;
        this.$rootScope = $rootScope;
        this.$uibModal = $uibModal;
        this.ntFlash = ntFlash;
        this.$timeout = $timeout;
        this.documentsService = documentsService;
        this.agreementId = $stateParams.id;
        this.agreementInstanceService = agreementInstanceService;

        this.transferService = transferService;
        this.transferTokenOfNewAgreement = null;

        this.typeOfEdit = "";
        this.rationaleType = null;

        this.dateSigned = null;

        this.agreementType = '';

        this.initAgreement();
    }

    $onInit() {
    }

    initAgreement() {
        try {
            this.agreementInstanceService.getAgreements(this.agreementId)
                .then((data) => {
                    this.transfer = data.transfer; 
                    this.actions = data.action;
                    this.agreement = data.response;
                    this.agreement.contracts.forEach((c) => {
                        c._amountSet = true;
                        c._toggle = false;
                        for (let i = 0; i < c.unitPrices.length; i++) {
                            let u = c.unitPrices[i];
                            if (u.value.amount === undefined) {
                                c._amountSet = false;
                            }
                        }
                    });
                    if (this.agreement.changes) {
                        this.combineAgreement(this.agreement.changes, this.agreement);
                    } else {
                        this.changeId = '';
                        this.statusOfChange = '';

                    }
                    console.log('agreement', this.agreement);

                    this.isTransferAllowed = true;

                    if (this.isTransferAllowed) {
                        const forbiddenStatuses = ['pending', 'terminated'];
                    
                        for (const forbiddenStatus of forbiddenStatuses) {
                            if (forbiddenStatus === this.agreement.status) this.isTransferAllowed = false;
                        }
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }

    combineAgreement(changesArray, agreement) {
        agreement.contracts.map(
            (elPrice) => {
                elPrice.unitPrices.map(
                    (elRelateItem) => {
                        agreement.items.forEach((elItem) => {
                            if (elItem.id === elRelateItem.relatedItem) {
                                elRelateItem._name = elItem.classification.description;
                            }
                        });
                    }
                );
            }
        );

        changesArray = changesArray.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
        });

        const lastChange = changesArray.length - 1;
        this.lastChangeId = changesArray[lastChange].id;
        this.changeId = changesArray[lastChange].id;
        this.rationaleType = changesArray[lastChange].rationaleType;
        this.statusOfChange = changesArray[lastChange].status;

        if ( this.rationaleType === 'partyWithdrawal' ) {
            this.typeOfEdit = 'user';
        } else {
            this.typeOfEdit = 'price';
        }
    }

    openModal(modalType) {
        if (modalType === 'price') {

            let data = {
                items: this.agreement.items,
                agreementId: this.agreementId,
                changes: this.agreement.changes,
                changeId: this.changeId,
                statusOfChange: this.statusOfChange
            };

            return this.$uibModal.open({
                templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementPriceModal.html",
                controller: "agreementPriceModalCtrl",
                resolve: {
                    data: function() {
                        return data;
                    }
                },
            }).result.then((res) => {
                if (res) {
                    this.agreement.changes.push(res);
                    this.statusOfChange = res.status;
                    this.changeId = res.id;
                    this.changeType = "price";
                    this.typeOfEdit = "price";
                    // this.$state.reload();
                }
            });

        } else {
            let data = {
                users: this.agreement.contracts,
                agreementId: this.agreementId,
                rationaleType: 'partyWithdrawal',
                changes: this.agreement.changes,
                changeId: this.changeId,
                statusOfChange: this.statusOfChange
            };

            return this.$uibModal.open({
                templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementExcludeUserModal.html",
                controller: "agreementExcludeUserModalCtrl",
                resolve: {
                    data: function() {
                        return data;
                    }
                },

            }).result.then((res) => {
                if (res) {
                    this.agreement.changes.push(res);
                    this.statusOfChange = res.status;
                    this.changeId = res.id;
                    this.changeType = "userWithdrawall";
                    this.typeOfEdit = "userWithdrawall";
                    // this.$state.reload();
                }
            });

        }
    }

    openModalPending() {
        this.openModal(this.typeOfEdit);
    }

    cancelChanges() {
        const body = {
            status: "cancelled",
        };

        this.agreementInstanceService.updateChangeAgreements(this.agreementId, this.changeId, body).then((result) => {
            if (result && result.status === 200) {
                this.statusOfChange = result.data.data.status;
                this.initAgreement();
            } else {
                console.log("Can't cancel chenges");
            }
        });
    }

    confirmChanges() {
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementConfirmChangesModal.html",
            controller: "agreementConfirmChangesModalCtrl",
            resolve: {},

        }).result.then((res) => {
            if (res.status) {
                this.dateSigned = res.startDate;
                this.sign(true);
            }
            this.$state.reload();
        });

    }

    agreementActivation() {
        const body = {
            status: "active",
            dateSigned: this.dateSigned
        };
        this.agreementInstanceService.updateChangeAgreements(this.agreementId, this.changeId, body).then((result) => {
            if (result.status === 200) {
                this.statusOfChange = result.data.data.status;
                this.$timeout(()=>this.ntFlash.success(gettext('Підтверджені зміни ви можете переглянути в "Історії змін"')), 2000);
                this.$state.reload();
            }
        });
    }

    toogleUserInfo(item) {
        item._toggle = !item._toggle;
    }

    uploadDocuments(isAgreementDocument) {
        var _lastChangeId = this.lastChangeId;
        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementDocumentModal.html",
            controller: "agreementDocumentModalCtrl",
            resolve: {
                data: function() {
                    return {
                        lastChangeId: _lastChangeId,
                        isAgreementDocument: isAgreementDocument
                    };
                }
            },

        }).result.then((res) => {
            if (res) {
                this.$state.reload();
            }
        });
    }

    checkChanges() {
        this.agreementInstanceService.getChanges(this.agreementId).then((res) => {
            this.agreement = res.data.data;
            this.ntFlash.success(gettext('Оновлені зміни, по кожному учаснику ви можете перевірити натиснувши на "Дивитись"'));
        });
    }

    sign(agreementActivation) {
        const doc = this.agreement.documents;
        const data ={
            agreementInstanceId: this.agreementId,
            documents: doc
        };

        this.documentsService.sign(data)
            .then((res) => {
                console.log('documentsService.sign data', res);
                if (res) {
                    if (agreementActivation) {
                        this.agreementActivation();
                    }
                }
            })
            .catch(error => console.log('documentsService.sign error', error));



        // return this.$uibModal.open({
        //         templateUrl: '/static/scripts/modules/Commons/views/e-sign-modal.html',
        //         controller: 'eSignModalCtrl',
        //         resolve: {
        //             data: () => {
        //                 const doc = this.agreement.documents;
        //                 return {
        //                     agreementInstanceId: this.agreementId,
        //                     documents: doc
        //                 };
        //             }
        //         },
        //         windowClass: 'center-modal'
        //     })
        //     .result
        //     .then((res) => {
        //         if (res) {
        //             if (agreementActivation) {
        //                 this.agreementActivation();
        //             }
        //         }
        //     });





    }

    chooseAction() {
        if (this.agreementType === '') return;

        let data = {
            id: this.agreementId,
            agreement: this.agreement
        };

        if (this.agreementType && this.agreementType === 'stopAgreement') {
            data.modalTitle = 'Припинити дію';
            data.isStop = true;
        } else {
            data.modalTitle = 'Звітувати про виконання угоди';
            data.isStop = false;
        }

        // console.log('body:', data);

        return this.$uibModal.open({
            templateUrl: "/static/scripts/modules/Agreements/views/modals/agreementTerminateModal.html",
            controller: "agreementTerminateModalCtrl",
            resolve: {
                data: function() {
                    return data;
                }
            },
        }).result.then((res) => {
            if (res) {
                this.$state.reload();
            }
        });
    }

    sendTransferToken(token) {
        if (token) {
            this.transferService.send('agreements', token, this.agreement.id)
                .then(data => {
                    console.log('response transferService', data);
                    if (data.data.action.contract_owner) {
                        this.ntFlash.success(gettext('Ваш запит на перенос виконано'));
                        this.$timeout(() => {
                            console.log('reload ...');
                            this.$state.reload();
                        }, 2000);
                    } else {
                        this.ntFlash.error(gettext('При спробі переносу виникла помилка'));
                    }
                })
                .catch(error => {
                    console.log('error', error);
                    this.ntFlash.error(error.errorMessages);
                })
        }
    }
}

agreementModule.component('agreementPage', {
    templateUrl: '/static/scripts/modules/Agreements/components/AgreePage/AgreePage.html',
    controller: AgreementPage,
    controllerAs: 'vm',
});