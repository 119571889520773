class ProfileCompany {
    constructor(COMPANY_USER_STATUSES, COMPANY_USER_ROLES) {
        this.statuses = {
            [COMPANY_USER_STATUSES.ACTIVE]: gettext('Активный'),
            [COMPANY_USER_STATUSES.INACTIVE]: gettext('Отключен')
        };
        this.roles = {
            [COMPANY_USER_ROLES.EMPTY]: gettext('Неназначено'),
            [COMPANY_USER_ROLES.ADMINISTRATOR]: gettext('Администратор'),
            [COMPANY_USER_ROLES.MANAGER]: gettext('Менеджер'),
            [COMPANY_USER_ROLES.AUDITOR]: gettext('Аудитор')
        };
    }
}

profileApp.component('profileCompany', {
    templateUrl: '/static/scripts/modules/Profile/views/components/profile-company.html',
    controller: ProfileCompany,
    controllerAs: 'vm',
    bindings: {
        company: '='
    }
});