class OverviewEDRController {
    constructor(
        $mdDialog,
        data,
        $window,
        http,
        loaderService,
        jsyaml
    ) {
        this.loader = loaderService;
        this.$mdDialog = $mdDialog;
        this.document = data;
        this.edr = null;
        this.$window = $window;
        this.http = http;
        this.jsyaml = jsyaml;

        this.loadDocument();
    }

    loadDocument() {
        this.loader.startLoading();
        this.http
            .get('/opc/proxy_doc_download/?doc_url=' + encodeURIComponent(this.document.url), true)
            .then(res => this.edr = this.jsyaml.load(res.data))
            .catch(() => {
                const error = {errorDetails: gettext('Ошибка получения регистрационного номера')};
                this.edr = {error};
            })
            .finally(() => this.loader.stopLoading());
    }

    print() {
        this.$window.print();
    }

    formatActivity(activity) {
        return activity ? `${activity.id}, ${activity.scheme} - ${activity.description}` : '-';
    }

    getCompanyRegistrationNumber() {
        const identification = this.edr.data.identification;

        if (!identification) return '-';

        let regNumber = identification.id ? `${identification.id}, ${identification.scheme}` : identification.scheme;
        if (identification.legalName) {
            regNumber += `(${identification.legalName})`;
        }

        return regNumber;
    }
    
    formatAddress(address) {
        return address ? `${address.countryName}, ${address.streetAddress}, ${address.postalCode}` : '-';
    }

    close() {
        this.$mdDialog.hide();
    }
}

commons.controller('overviewEDRController', OverviewEDRController);