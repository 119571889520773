var profileApp = angular.module('profile', [
    'commons.app',
    'ngCookies'
]);

profileApp.run(($rootScope, profileService, $window, $cookies, $state, $location, PermRoleStore) => {
    $rootScope.signout = profileService.signout;

    const isAnonymous = !$window.authentication_and_user_info.is_authenticated;
    $window.localStorage.setItem('is-anonymous', '' + isAnonymous);

    $window.addEventListener('storage', (e) => {
        if(e.key==='is-anonymous' && isAnonymous !== JSON.parse(e.newValue)) {
            $window.location.reload();
        }
    });

    $rootScope.$on('$stateChangePermissionDenied', function(event, toState, toParams, options) {
        $cookies.put('returnState', toState.name);
    });

});

profileApp.constant('COMPANY_REQUEST_STATUSES', {
    PENDING: 0,
    ACCEPT: 1,
    REJECT: 2
});

profileApp.constant('COMPANY_USER_STATUSES', {
    PENDING: 0,
    ACTIVE: 1,
    INACTIVE: 2,
    DELETED: 3
});

profileApp.constant('COMPANY_USER_ROLES', {
    EMPTY: 0,
    ADMINISTRATOR: 1,
    MANAGER: 2,
    AUDITOR: 3
});

profileApp.constant('USER_ROLES', {
    PROVIDER: 2,
    CUSTOMER: 3
});
