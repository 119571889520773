tenderView.factory('invitesService', function (http, ntFlash) {
    var getResource = 'invites/:tenderId/';
    var saveResource = 'invites/:tenderId/';
    return {
        get: function (data) {
            var getUrl = getResource.replace(/:tenderId/, data.tenderId);
            return http.get(getUrl)
                .then(function (data) {
                    return data.data.response;
                });
        },
        save: function (data) {
            var saveUrl = saveResource.replace(/:tenderId/, data.tenderId);
            return http.post(saveUrl, data.invites)
                .then(function (data) {
                    ntFlash.success('Приглашения разосланы');
                    return data.data.response;
                });
        }
    };
});