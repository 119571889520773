class ShowDate {
    constructor() {
    }
}

monitoringApp.component('showDate', {
    template: `
        <div ng-show=":: vm.valueData || vm.showEmpty">
            <div class="block-info__title">{{:: vm.label }}</div>
            <div layout="column" layout-gt-sm="row">
                <div class="block-info__text block-info__text--bold">
                    <span class="period-date">{{:: vm.valueData | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                </div>
            </div>
        </div>
    `,
    controller: ShowDate,
    controllerAs: 'vm',
    bindings: {
        showEmpty: '@',
        label: '@',
        valueData: '<',
    }
});