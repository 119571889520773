var commons = angular.module('commons.app', ['ngCookies']);

commons.run(($cacheFactory, $interval) => {
    $interval(() => {
        $cacheFactory.get('$http').removeAll();
    }, 30000);
});

commons.constant('jsyaml', jsyaml);

commons.config(($translateProvider, $provide, $httpProvider) => {
    $provide.constant('baseUrl', DZ_URL);
    $provide.constant('turboMode', !!OPC_CONFIG.OPC_SANDBOX_TURBO_MODE);
    $provide.constant('accelerator', OPC_CONFIG.OPC_SANDBOX_TURBO_MODE || 1440);
    $httpProvider.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    };

    // global locale: calendars etc
    $translateProvider.preferredLanguage('ru');

    // general localization
    $translateProvider.translations('ru', {
        // '': gettext(''),
        'Изменение': gettext('Изменение'),
        'Уведомление о соглашении': gettext('Уведомление о соглашении'),
        'Подписанный договор': gettext('Подписанный договор'),
        'Меры для прекращения договора': gettext('Меры для прекращения договора'),
        'Расписание и этапы': gettext('Расписание и этапы'),
        'Дополнение к договору': gettext('Дополнение к договору'),
        'Обеспечение тендерных предложений': gettext('Обеспечение тендерных предложений'),
        'Субподряд': gettext('Субподряд'),
        // '': gettext(''),

        'Изменение контракта': gettext('Изменение контракта'),
        'Название контракта': gettext('Название контракта'),
        'Признак НДС в договоре': gettext('Признак НДС в договоре'),
        'Значения "Цена договора" и "Цена договора без НДС" должны быть больше 0': gettext('Значения "Цена договора" и "Цена договора без НДС" должны быть больше 0'),
        'Значения "Цена договора" и "Цена договора без НДС" не должны совпадать': gettext('Значения "Цена договора" и "Цена договора без НДС" не должны совпадать'),
        'Значение "Цена договора" должно быть больше чем "Цена договора без НДС"': gettext('Значение "Цена договора" должно быть больше чем "Цена договора без НДС"'),
        'Цена договора': gettext('Цена договора'),
        'Цена договора без НДС': gettext('Цена договора без НДС'),
        'С НДС': gettext('С НДС'),
        'Без НДС': gettext('Без НДС'),
        'Сумма оплаты по договору': gettext('Сумма оплаты по договору'),
        'Сумма оплаты по договору без учета НДС': gettext('Сумма оплаты по договору без учета НДС'),
        // '': gettext(''),
        // '': gettext(''),
        // '': gettext(''),

        'Добавить этап оплаты': gettext('Добавить этап оплаты'),
        'Условия оплаты согласно договора про закупку': gettext('Условия оплаты согласно договора про закупку'),
        'Выберите этап': gettext('Выберите этап'),
        'Выберите тип': gettext('Выберите тип'),
        'Продолжительность': gettext('Продолжительность'),
        'Тип дней': gettext('Тип дней'),
        'Описание этапа': gettext('Описание этапа'),
        'Этап оплаты': gettext('Этап оплаты'),

        'Предложение к лоту': gettext('Предложение к лоту'),
        'Вопросов не задано': gettext('Вопросов не задано'),
        'Нет обжалований': gettext('Нет обжалований'),

        'Событие': gettext('Событие'),
        'Период': gettext('Период'),
        'Тип оплаты': gettext('Тип оплаты'),
        'Размер оплаты': gettext('Размер оплаты'),
        'Завершенные': gettext('Завершенные'),
        'В избранные': gettext('В избранные'),
        'Избранные': gettext('Избранные'),
        'Убрать из избранных': gettext('Убрать из избранных'),

        'На Вашем счете низкий баланс. Внесите средства заранее, чтобы иметь возможность подавать предложения.': gettext('На Вашем счете низкий баланс. Внесите средства заранее, чтобы иметь возможность подавать предложения.'),
        'Заказать гарантию': gettext('Заказать гарантию'),
        'Запрос банковской гарантии': gettext('Запрос банковской гарантии'),
        'Выберите банк': gettext('Выберите банк'),
        'Начало действия': gettext('Начало действия'),
        'Конец действия': gettext('Конец действия'),
        'Желаемая дата получения': gettext('Желаемая дата получения'),
        'Покрытая': gettext('Покрытая'),
        'Не покрытая': gettext('Не покрытая'),
        'Являетесь клиентом банка': gettext('Являетесь клиентом банка'),
        'Запросить': gettext('Запросить'),

        'Состояние ставки': gettext('Состояние ставки'),
        'Активная': gettext('Активная'),
        'Недействительна': gettext('Недействительна'),
        'От вашей компании уже подано предложение': gettext('От вашей компании уже подано предложение'),
        'Видео-инструкция': gettext('Видео-инструкция'),
        'Скачать все документы': gettext('Скачать все документы'),
        'Допороговые закупки': gettext('Допороговые закупки'),
        'Открытые торги': gettext('Открытые торги'),
        'Открытые торги с публикацией на английском языке': gettext('Открытые торги с публикацией на английском языке'),
        'Переговорная процедура': gettext('Переговорная процедура'),
        'Переговорная процедура (сокращенная)': gettext('Переговорная процедура (сокращенная)'),
        'Переговорная процедура для нужд обороны': gettext('Переговорная процедура для нужд обороны'),
        'Упрощенные торги с применением электронной системы закупок': gettext('Упрощенные торги с применением электронной системы закупок'),
        'Конкурентный диалог 1-ый этап': gettext('Конкурентный диалог 1-ый этап'),
        'Конкурентный диалог с публикацией на английском языке 1-ый этап': gettext('Конкурентный диалог с публикацией на английском языке 1-ый этап'),
        'Конкурентный диалог 2-ой этап': gettext('Конкурентный диалог 2-ой этап'),
        'Конкурентный диалог с публикацией на английском языке 2-ой этап': gettext('Конкурентный диалог с публикацией на английском языке 2-ой этап'),
        'Отчет о заключенном договоре': gettext('Отчет о заключенном договоре'),
        'Открытые торги для закупки энергосервиса': gettext('Открытые торги для закупки энергосервиса'),

        'Сумма сокращения затрат': gettext('Сумма сокращения затрат'),
        'За счет участника': gettext('За счет участника'),
        'Учетная ставка НБУ': gettext('Учетная ставка НБУ'),
        'Минимальный шаг повышения показателя эффективности': gettext('Минимальный шаг повышения показателя эффективности'),
        'Источник финансирования': gettext('Источник финансирования'),
        'Фиксированный процент cуммы сокращения затрат заказчика': gettext('Фиксированный процент cуммы сокращения затрат заказчика'),
        'Информация о доноре': gettext('Информация о доноре'),
        'Донор закупки': gettext('Донор закупки'),
        'Информация о подрядчике': gettext('Информация о подрядчике'),
        'Название решения': gettext('Название решения'),
        'Описание решения': gettext('Описание решения'),
        'Активные торги': gettext('Активные торги'),
        'до': gettext('до'),
        'с': gettext('с'),
        'Выписан': gettext('Выписан'),
        'Скачать счет': gettext('Скачать счет'),
        'Оплатить картой': gettext('Оплатить картой'),
        'Устаревший': gettext('Устаревший'),
        'Обобщенное название закупки': gettext('Обобщенное название закупки'),
        'Дата публикации': gettext('Дата публикации'),
        'Год': gettext('Год'),
        'Месяц': gettext('Месяц'),
        'Обобщенное название лота': gettext('Обобщенное название лота'),
        'Срок выполнения работ или оказания услуг': gettext('Срок выполнения работ или оказания услуг'),
        'Начальный срок выполнения работ или оказания услуг': gettext('Начальный срок выполнения работ или оказания услуг'),
        'Место выполнения работ или оказания услуг': gettext('Место выполнения работ или оказания услуг'),
        'Объем выполнения работ или оказания услуг': gettext('Объем выполнения работ или оказания услуг'),
        'Ссылка на процедуру первого этапа': gettext('Ссылка на процедуру первого этапа'),
        'Процедура': gettext('Процедура'),
        'из': gettext('из'),
        'Еще': gettext('Еще'),
        'день': gettext('день'),
        'дня': gettext('дня'),
        'дней': gettext('дней'),
        'Заканчивается сегодня': gettext('Заканчивается сегодня'),
        'ч.': gettext('ч.'),
        'мин.': gettext('мин.'),
        'Стоимость участия:': gettext('Стоимость участия:'),
        'грн': gettext('грн'),
        'Сокращённая рассылка включает в себя 10 самых крупных тендеров в категориях на которые вы подписаны.': gettext('Сокращённая рассылка включает в себя 10 самых крупных тендеров в категориях на которые вы подписаны.'),
        'Сокращенная рассылка': gettext('Сокращенная рассылка'),
        'Отчеты': gettext('Отчеты'),
        'Закупка до 20 тыс грн: 20,4 грн': gettext('Закупка до 20 тыс грн: 20,4 грн'),
        'Закупка до 50 тыс грн: 142,8 грн': gettext('Закупка до 50 тыс грн: 142,8 грн'),
        'Закупка до 200 тыс. грн: 408 грн': gettext('Закупка до 200 тыс. грн: 408 грн'),
        'Закупка до 1 млн. грн: 612 грн': gettext('Закупка до 1 млн. грн: 612 грн'),
        'Закупка до 4 млн. грн: 2040 грн': gettext('Закупка до 4 млн. грн: 2040 грн'),
        'От 4 млн. грн: 4080 грн': gettext('От 4 млн. грн: 4080 грн'),
        'Экспорт тендеров в Excel': gettext('Экспорт тендеров в Excel'),
        'Скачать отчет': gettext('Скачать отчет'),
        'Тарифы': gettext('Тарифы'),
        'Отчет в процессе подготовки': gettext('Отчет в процессе подготовки'),
        'Укажите сумму пополнения': gettext('Укажите сумму пополнения'),
        'Сумма, грн': gettext('Сумма, грн'),
        'Введите сумму пополнения баланса': gettext('Введите сумму пополнения баланса'),
        'Создать счет': gettext('Создать счет'),
        'Примечания (EN)': gettext('Примечания (EN)'),
        'Форма рiчного плану закупiвель': gettext('Форма рiчного плану закупiвель'),
        'Ожидаемая стоимость': gettext('Ожидаемая стоимость'),
        'ФОРМА РІЧНОГО ПЛАНУ ЗАКУПІВЕЛЬ': gettext('ФОРМА РІЧНОГО ПЛАНУ ЗАКУПІВЕЛЬ'),
        'Укажите данные договора': gettext('Укажите данные договора'),
        'общедоступной': gettext('общедоступной'),
        'Закупка': gettext('Закупка'),
        'Договор': gettext('Договор'),
        'Завершить договор': gettext('Завершить договор'),
        'Расторжение или Завершение договора имеет необратимый характер. Убедитесь, что Вы загрузили все необходимые документы и подпись договора КЕП актуальная,только после этого Завершите договор или Расторгните': gettext('Расторжение или Завершение договора имеет необратимый характер. Убедитесь, что Вы загрузили все необходимые документы и подпись договора КЕП актуальная,только после этого Завершите договор или Расторгните'),
        'Договоры': gettext('Договоры'),
        'Вы можете загрузить документы только во время внесения изменений': gettext('Вы можете загрузить документы только во время внесения изменений'),
        'Дата завершения': gettext('Дата завершения'),
        'Дата начала': gettext('Дата начала'),
        'Оплачено': gettext('Оплачено'),
        'Причина': gettext('Причина'),
        'Примечание': gettext('Примечание'),
        'Завершить': gettext('Завершить'),
        'Опубликовать изменения': gettext('Опубликовать изменения'),
        'Инициализировать': gettext('Инициализировать'),
        'Причина расторжения': gettext('Причина расторжения'),
        'Дата подписания': gettext('Дата подписания'),
        'Дата окончания договора': gettext('Дата окончания договора'),
        'Дата начала договора': gettext('Дата начала договора'),
        'Все договоры': gettext('Все договоры'),
        'Мои договоры': gettext('Мои договоры'),
        'Завершение договора': gettext('Завершение договора'),
        'Уведомление о внесении изменений в договор': gettext('Уведомление о внесении изменений в договор'),
        'К сожалению, договоров данной категории не найдено': gettext('К сожалению, договоров данной категории не найдено'),
        'Завершенный договор не может быть изменен': gettext('Завершенный договор не может быть изменен'),
        'Выберите причину отмены': gettext('Выберите причину отмены'),
        'Выберите документы': gettext('Выберите документы'),
        'Загрузить выбранные': gettext('Загрузить выбранные'),
        'Скарга подається до Антимонопольного Комітету України. Для того, щоб ця скарга була прийнята до розгляду, скаржник повинен здійснити оплату за подання скарги відповідно до постанови Кабінету Міністрів України №291 від 23 березня 2016 р. і додати до скарги документ, що підтверджує оплату': gettext('Скарга подається до Антимонопольного Комітету України. Для того, щоб ця скарга була прийнята до розгляду, скаржник повинен здійснити оплату за подання скарги відповідно до постанови Кабінету Міністрів України №291 від 23 березня 2016 р. і додати до скарги документ, що підтверджує оплату'),
        'Выход': gettext('Выход'),
        'Звiт про укладення договору': gettext('Звiт про укладення договору'),
        'Номер договора': gettext('Номер договора'),
        'Подписано КЕП': gettext('Подписано КЕП'),
        'Не подписано КЕП': gettext('Не подписано КЕП'),
        'Субподрядчик': gettext('Субподрядчик'),
        'Подробная информация о поставщике': gettext('Подробная информация о поставщике'),
        'Приглашения': gettext('Приглашения'),
        'Изменения': gettext('Изменения'),
        'Версия для печати': gettext('Версия для печати'),
        'Регламент': gettext('Регламент'),
        'Интерфейс': gettext('Интерфейс'),
        'Обжаловать': gettext('Обжаловать'),
        'Создать неценовые показатели': gettext('Создать неценовые показатели'),
        'Название показателя': gettext('Название показателя'),
        'Удалить показатель': gettext('Удалить показатель'),
        'Добавить показатель': gettext('Добавить показатель'),
        'Подсказка': gettext('Подсказка'),
        'Вес показателя': gettext('Вес показателя'),
        'Показатель для': gettext('Показатель для'),
        'Укажите к кому относится показатель': gettext('Укажите к кому относится показатель'),
        'Поставщик': gettext('Поставщик'),
        'Предмет закупки': gettext('Предмет закупки'),
        'Опция': gettext('Опция'),
        'Вес опции': gettext('Вес опции'),
        'Вес': gettext('Вес'),
        'Ваше предложение': gettext('Ваше предложение'),
        'Удалить опцию': gettext('Удалить опцию'),
        'Добавить опцию': gettext('Добавить опцию'),
        'Ваше предложение, грн': gettext('Ваше предложение, грн'),
        'Ваш показатель': gettext('Ваш показатель'),
        'Приведённая цена': gettext('Приведённая цена'),
        'Согласен, что после завершения Аукциона вся информация о нашей компании как об Участнике торгов, включая информацию о Предложениях, станет': gettext('Согласен, что после завершения Аукциона вся информация о нашей компании как об Участнике торгов, включая информацию о Предложениях, станет'),
        'Заголовок': gettext('Заголовок'),
        'Загрузить договор': gettext('Загрузить договор'),
        'Тендер в статусе': gettext('Тендер в статусе'),
        'вы можете': gettext('вы можете'),
        'Добавить поставщиков:': gettext('Добавить поставщиков:'),
        'Введите E-Mail или название компании': gettext('Введите E-Mail или название компании'),
        'Добавить': gettext('Добавить'),
        'Список приглашенных компаний': gettext('Список приглашенных компаний'),
        'Наши операторы свяжутся с вашими поставщиками и помогут проставить цены': gettext('Наши операторы свяжутся с вашими поставщиками и помогут проставить цены'),
        'Введите номер телефона': gettext('Введите номер телефона'),
        'Пользователь участвовал в аукционе :)': gettext('Пользователь участвовал в аукционе :)'),
        'Пользователь не участвовал в аукционе :(': gettext('Пользователь не участвовал в аукционе :('),
        'c НДС': gettext('c НДС'),
        'НДС': gettext('НДС'),
        'без НДС': gettext('без НДС'),
        'Качественные показатели': gettext('Качественные показатели'),
        'Поиск': gettext('Поиск'),
        'ДКПП': gettext('ДКПП'),
        'Сбросить фильтр': gettext('Сбросить фильтр'),
        'План': gettext('План'),
        'Все договора': gettext('Все договора'),
        'Добавить лот': gettext('Добавить лот'),
        'Добавить предмет закупки': gettext('Добавить предмет закупки'),
        'Отправить приглашения': gettext('Отправить приглашения'),
        'Создать план': gettext('Создать план'),
        'Мои планы': gettext('Мои планы'),
        'Все планы': gettext('Все планы'),
        'Планирование': gettext('Планирование'),
        'Удалить план': gettext('Удалить план'),
        'Подтвердите удаление': gettext('Подтвердите удаление'),
        'Код': gettext('Код'),
        'Новый план': gettext('Новый план'),
        'К сожалению, планов данной категории не найдено': gettext('К сожалению, планов данной категории не найдено'),
        'Запланирован на': gettext('Запланирован на'),
        'Планируемая дата поставки': gettext('Планируемая дата поставки'),
        'Планируемая дата процедуры': gettext('Планируемая дата процедуры'),
        'Классификатор КЕКВ': gettext('Классификатор КЕКВ'),
        'Код плана': gettext('Код плана'),
        'Название проекта': gettext('Название проекта'),
        'Отменить требование': gettext('Отменить требование'),
        'Жалоба': gettext('Жалоба'),
        'Укажите валюту': gettext('Укажите валюту'),
        'Повiдомлення про внесення змiн до договору': gettext('Повiдомлення про внесення змiн до договору'),
        'Лот:': gettext('Лот:'),
        'Просмотр аукциона': gettext('Просмотр аукциона'),
        'Примечания': gettext('Примечания'),
        'Название (EN)': gettext('Название (EN)'),
        'Укажите к чему относится вопрос': gettext('Укажите к чему относится вопрос'),
        'Україна': gettext('Україна'),
        'Сообщение': gettext('Сообщение'),
        'Загрузить': gettext('Загрузить'),
        'Укажите предмет закупки': gettext('Укажите предмет закупки'),
        'Укажите к чему относится документ': gettext('Укажите к чему относится документ'),
        'Тендер': gettext('Тендер'),
        'Черновик': gettext('Черновик'),
        'Документ для': gettext('Документ для'),
        'Название компании (EN)': gettext('Название компании (EN)'),
        'Сохранить черновик': gettext('Сохранить черновик'),
        'Создать лоты': gettext('Создать лоты'),
        'Укажите к чему относится показатель': gettext('Укажите к чему относится показатель'),
        'Решение': gettext('Решение'),
        'Закрыть требование': gettext('Закрыть требование'),
        'Отмена требования': gettext('Отмена требования'),
        'Подтвердить ставку': gettext('Подтвердить ставку'),
        'Загрузить еще': gettext('Загрузить еще'),
        'Удалить лот': gettext('Удалить лот'),
        'Удалить предмет закупки': gettext('Удалить предмет закупки'),
        'Название лота': gettext('Название лота'),
        'Лот': gettext('Лот'),
        'Отклонено': gettext('Отклонено'),
        'Решено': gettext('Решено'),
        'Недействительно': gettext('Недействительно'),
        'Выберите тип ответа': gettext('Выберите тип ответа'),
        'Тип ответа': gettext('Тип ответа'),
        'Укажите лот': gettext('Укажите лот'),
        'Конкретное название предмета закупки': gettext('Конкретное название предмета закупки'),
        'Конкретное название предмета закупки (EN)': gettext('Конкретное название предмета закупки (EN)'),
        'Контактное лицо (EN)': gettext('Контактное лицо (EN)'),
        'Официальное название (EN)': gettext('Официальное название (EN)'),
        'Название тендера (EN)': gettext('Название тендера (EN)'),
        'Мультилотовый тендер': gettext('Мультилотовый тендер'),
        'Безлотовый тендер': gettext('Безлотовый тендер'),
        'Выберите тип процедуры': gettext('Выберите тип процедуры'),
        'Тип процедуры': gettext('Тип процедуры'),
        'Какой тендер Вы желаете создать?': gettext('Какой тендер Вы желаете создать?'),
        'Прием заявок': gettext('Прием заявок'),
        'Не назначено': gettext('Не назначено'),
        'Начало аукциона': gettext('Начало аукциона'),
        'Требование': gettext('Требование'),
        'Опишите причину отклонения': gettext('Опишите причину отклонения'),
        'Не предоставил документы по ст.17': gettext('Не предоставил документы по ст.17'),
        'Отказался от подписания договора': gettext('Отказался от подписания договора'),
        'Не соответствует требованиям закупочной документации': gettext('Не соответствует требованиям закупочной документации'),
        'Имеются основания, указанные в статье 17': gettext('Имеются основания, указанные в статье 17'),
        'Не соответствует квалификационным критериям': gettext('Не соответствует квалификационным критериям'),
        'Выберите основание отклонения': gettext('Выберите основание отклонения'),
        'Основание отклонения': gettext('Основание отклонения'),
        'Подтвердите решение': gettext('Подтвердите решение'),
        'Відповідає кваліфікаційним критеріям, встановленим замовником в тендерній документації': gettext('Відповідає кваліфікаційним критеріям, встановленим замовником в тендерній документації'),
        'Послепороговая европейская процедура': gettext('Послепороговая европейская процедура'),
        'Послепороговая украинская процедура': gettext('Послепороговая украинская процедура'),
        'Вы не можете создавать требования': gettext('Вы не можете создавать требования'),
        'Создать требование': gettext('Создать требование'),
        'Все тендеры': gettext('Все тендеры'),
        'Отправить на рассмотрение': gettext('Отправить на рассмотрение'),
        'Закрыть жалобу': gettext('Закрыть жалобу'),
        'Вы не можете создавать жалобы': gettext('Вы не можете создавать жалобы'),
        'Создать жалобу': gettext('Создать жалобу'),
        'Вопросы': gettext('Вопросы'),
        'Создать обращение': gettext('Создать обращение'),
        'Требования': gettext('Требования'),
        'Лоты': gettext('Лоты'),
        'Расторгнуть': gettext('Расторгнуть'),
        'Завершить квалификацию': gettext('Завершить квалификацию'),
        'Предметы закупки': gettext('Предметы закупки'),
        'Ставки': gettext('Ставки'),
        'Документация': gettext('Документация'),
        'Финансовая документация': gettext('Финансовая документация'),
        'Квалификационная документация': gettext('Квалификационная документация'),
        'Документация критериев приемлемости': gettext('Документация критериев приемлемости'),
        'Отмена': gettext('Отмена'),
        '(минимум 30 символов)': gettext('(минимум 30 символов)'),
        'Город': gettext('Город'),
        'Область': gettext('Область'),
        'Почтовый индекс': gettext('Почтовый индекс'),
        'Веб-сайт': gettext('Веб-сайт'),
        'Подписать': gettext('Подписать'),
        'Підписаний договір': gettext('Підписаний договір'),
        'ЕДРПОУ': gettext('ЕДРПОУ'),
        'Название компании': gettext('Название компании'),
        'Электронная подпись': gettext('Электронная подпись'),
        'Название показателя (EN)': gettext('Название показателя (EN)'),
        'Валюта': gettext('Валюта'),
        'Предложение отменено': gettext('Предложение отменено'),
        'Сумма': gettext('Сумма'),
        'Полное название компании': gettext('Полное название компании'),
        'Электронная гарантия': gettext('Электронная гарантия'),
        'Отсутствует': gettext('Отсутствует'),
        'Вид обеспечения предложений': gettext('Вид обеспечения предложений'),
        'Русский': gettext('Русский'),
        'Доступность': gettext('Доступность'),
        'Английский': gettext('Английский'),
        'Украинский': gettext('Украинский'),
        'Подписано': gettext('Подписано'),
        'Язык контакта': gettext('Язык контакта'),
        'Шаг (%)': gettext('Шаг (%)'),
        'Описание к основанию': gettext('Описание к основанию'),
        'Количество участников рамочного соглашения': gettext('Количество участников рамочного соглашения'),
        'Срок, на который заключается соглашение': gettext('Срок, на который заключается соглашение'),
        'Отменить лот': gettext('Отменить лот'),
        'Причина конфиденциальности': gettext('Причина конфиденциальности'),
        'Приватный документ': gettext('Приватный документ'),
        'Публичный документ': gettext('Публичный документ'),
        'Доступность документа': gettext('Доступность документа'),
        'Смета (расчет стоимости)': gettext('Смета (расчет стоимости)'),
        'Технические спецификации': gettext('Технические спецификации'),
        'Технические спецификации(Лот)': gettext('Технические спецификации(Лот)'),
        'Технические спецификации(Предмет закупки)': gettext('Технические спецификации(Предмет закупки)'),
        'Документы, подтверждающие соответствие': gettext('Документы, подтверждающие соответствие'),
        'Документы, подтверждающие квалификацию': gettext('Документы, подтверждающие квалификацию'),
        'Ценовое предложение': gettext('Ценовое предложение'),
        'Субпідряд': gettext('Субпідряд'),
        'Звiт про змiни в договорi': gettext('Звiт про змiни в договорi'),
        'Додатки до договору': gettext('Додатки до договору'),
        'Розклад та етапи': gettext('Розклад та етапи'),
        'Заходи для припинення договору': gettext('Заходи для припинення договору'),
        'Звiт про виконання договору': gettext('Звiт про виконання договору'),
        'Тип документа': gettext('Тип документа'),
        'Проект договору': gettext('Проект договору'),
        'Відмова у допуску до закупівлі': gettext('Відмова у допуску до закупівлі'),
        'Виявлені конфлікти інтересів': gettext('Виявлені конфлікти інтересів'),
        'Інформація про учасників': gettext('Інформація про учасників'),
        'Кошторис': gettext('Кошторис'),
        'Положення для управління ризиками та зобов’язаннями': gettext('Положення для управління ризиками та зобов’язаннями'),
        'Фірми у короткому списку': gettext('Фірми у короткому списку'),
        'Критерії прийнятності': gettext('Критерії прийнятності'),
        'Пояснення до питань заданих учасниками': gettext('Пояснення до питань заданих учасниками'),
        'Критерії оцінки': gettext('Критерії оцінки'),
        'Технічні специфікації': gettext('Технічні специфікації'),
        'Документи закупівлі': gettext('Документи закупівлі'),
        'Повідомлення про закупівлю': gettext('Повідомлення про закупівлю'),
        'Укажите тип документа': gettext('Укажите тип документа'),
        'Укажите основание': gettext('Укажите основание'),
        'Основание': gettext('Основание'),
        'Описание (EN)': gettext('Описание (EN)'),
        'Крок': gettext('Крок'),
        'Шаг': gettext('Шаг'),
        'шаг': gettext('шаг'),
        'Название лота (EN)': gettext('Название лота (EN)'),
        'Не состоялся': gettext('Не состоялся'),
        'Цифровая подпись': gettext('Цифровая подпись'),
        'Доп. класс': gettext('Доп. класс'),
        'Підтверджує відсутність підстав для відмови від участі відповідно до статті 17 Закону України ”Про державні закупівлі”': gettext('Підтверджує відсутність підстав для відмови від участі відповідно до статті 17 Закону України \"Про державні закупівлі\"'),
        'Обжалования принимаются только в статусе "Ожидание аукциона"': gettext('Обжалования принимаются только в статусе "Ожидание аукциона"'),
        'Обжалования принимаются до': gettext('Обжалования принимаются до'),
        'Валюта ': gettext('Валюта '),
        'Изменить это решение будет возможно только в случае обжалования участником решения закупщика в установленном законом порядке.': gettext('Изменить это решение будет возможно только в случае обжалования участником решения закупщика в установленном законом порядке.'),
        'Внимание! Нажатие кнопки “Принять предложение” или “Отказать” носит необратимый характер.': gettext('Внимание! Нажатие кнопки “Принять предложение” или “Отказать” носит необратимый характер.'),
        'Пожалуйста, проверьте правильность опубликованных документов и убедитесь, что Вы правильно принимаете решение о квалификации.': gettext('Пожалуйста, проверьте правильность опубликованных документов и убедитесь, что Вы правильно принимаете решение о квалификации.'),
        'Відсутні підстави для відмови в участі згідно  ст. 17 Закону України ”Про Публічні закупівлі”': gettext('Відсутні підстави для відмови в участі згідно  ст. 17 Закону України ”Про Публічні закупівлі”'),
        'Перед тем как Принять или Отклонить пользователя, вам необходимо загрузить квалификационные документы и подписать КЕП, после чего выберите действие': gettext('Перед тем как Принять или Отклонить пользователя, вам необходимо загрузить квалификационные документы и подписать КЕП, после чего выберите действие'),
        'Сумма коммерческого предложения': gettext('Сумма коммерческого предложения'),
        'Информация о субподрядчике': gettext('Информация о субподрядчике'),
        'Дата аукциона может быть изменена после завершения Закупщиком рассмотрения полученных предложений': gettext('Дата аукциона может быть изменена после завершения Закупщиком рассмотрения полученных предложений'),
        'Дополнительный классификатор': gettext('Дополнительный классификатор'),

        'Добавьте информацию о поставщике': gettext('Добавьте информацию о поставщике'),
        'Добавить поставщика': gettext('Добавить поставщика'),
        'ДК 021:2015': gettext('ДК 021:2015'),

        //Notifications
        'Ваши уведомления': gettext('Ваши уведомления'),
        'Перейти к тендеру': gettext('Перейти к тендеру'),
        'К сожалению, уведомлений не найдено': gettext('К сожалению, уведомлений не найдено'),
        'Пометить все прочитанными': gettext('Пометить все прочитанными'),

        'Вид': gettext('Вид'),
        'Email': gettext('Email'),
        'Viber': gettext('Viber'),
        'Bell': gettext('Bell'),
        'Пользователь': gettext('Пользователь'),
        'Настройка уведомлений администратора': gettext('Настройка уведомлений администратора'),
        'Настройка уведомлений': gettext('Настройка уведомлений'),

        //Main
        'Создать': gettext('Создать'),
        'Создать закупку': gettext('Создать закупку'),
        'Созданные мной закупки': gettext('Созданные мной закупки'),
        'Созданные закупки компании': gettext('Созданные закупки компании'),
        'Закупки с моим участием': gettext('Закупки с моим участием'),
        'Закупки с участием компании': gettext('Закупки с участием компании'),
        'Договоры компании': gettext('Договоры компании'),
        'Планы компании': gettext('Планы компании'),
        'Профиль пользователя': gettext('Профиль пользователя'),
        'Изменить пароль': gettext('Изменить пароль'),
        'Ваша подписка': gettext('Ваша подписка'),
        'Профиль компании': gettext('Профиль компании'),
        'Пользователи компании': gettext('Пользователи компании'),
        'Пополнить баланс': gettext('Пополнить баланс'),
        'Пополнить': gettext('Пополнить'),
        'Выписанные счета': gettext('Выписанные счета'),
        'Список транзакций': gettext('Список транзакций'),
        'Аналитика': gettext('Аналитика'),
        'Квитанції': gettext('Квитанції'),
        'Служба поддержки': gettext('Служба поддержки'),
        'Баланс': gettext('Баланс'),
        'Планы': gettext('Планы'),
        'Закупки': gettext('Закупки'),
        'Вы работаете в тестовом режиме': gettext('Вы работаете в тестовом режиме'),
        'В тестовом режиме Вы можете ознакомится с функционалом площадки: научится создавать тендера, делать ставки.': gettext('В тестовом режиме Вы можете ознакомится с функционалом площадки: научится создавать тендера, делать ставки.'),
        'После того, как Вы будете уверенно работать c площадкой, обратитесь в службу поддержки для отключения тестового режима.': gettext('После того, как Вы будете уверенно работать c площадкой, обратитесь в службу поддержки для отключения тестового режима.'),
        'Согласно документации': gettext('Согласно документации'),
        'Указать самостоятельно': gettext('Указать самостоятельно'),
        'Максимальный размер файла для загрузки не должен превышать 50 Мб': gettext('Максимальный размер файла для загрузки не должен превышать 50 Мб'),
        'Ошибка получения регистрационного номера': gettext('Ошибка получения регистрационного номера'),
        'Деятельность': gettext('Деятельность'),
        'Распечатать': gettext('Распечатать'),
        'Учредители': gettext('Учредители'),
        'Управление': gettext('Управление'),
        'Расширенная деятельность': gettext('Расширенная деятельность'),
        'Капитал': gettext('Капитал'),
        'Статус регистрации': gettext('Статус регистрации'),
        'Статус наличия банкротства': gettext('Статус наличия банкротства'),
        'Справку можно использовать при проведении квалификации': gettext('Справку можно использовать при проведении квалификации'),
        'Справка носит информативный характер': gettext('Справка носит информативный характер'),
        'Уведомления администратора': gettext('Уведомления администратора'),
        'Уведомления': gettext('Уведомления'),

        //Profile
        'Имя и фамилия': gettext('Имя и фамилия'),
        'Фамилия': gettext('Фамилия'),
        "Имя и фамилия по английски": gettext("Имя и фамилия по английски"),
        "Назва компанії англійською": gettext("Назва компанії англійською"),
        'Мова спілкування': gettext('Мова спілкування'),
        'Должность': gettext('Должность'),
        'Роль': gettext('Роль'),
        'Выберите роль пользователя': gettext('Выберите роль пользователя'),
        'Закупщик': gettext('Закупщик'),
        'Телефон': gettext('Телефон'),
        'Описание компании': gettext('Описание компании'),
        'Сайт компании': gettext('Сайт компании'),
        'Тип гос. заказчика': gettext('Тип гос. заказчика'),
        'Юридична особа, яка забезпечує потреби держави або територіальної громади': gettext('Юридична особа, яка забезпечує потреби держави або територіальної громади'),
        'Замовник, що здійснює закупівлі для потреб оборони': gettext('Замовник, що здійснює закупівлі для потреб оборони'),
        'Юридична особа, яка здійснює діяльність в одній або декількох окремих сферах господарювання': gettext('Юридична особа, яка здійснює діяльність в одній або декількох окремих сферах господарювання'),
        'Державне або комунальне підприємство, яке не належить до замовників': gettext('Державне або комунальне підприємство, яке не належить до замовників'),
        'Юридична особа, що здійснює закупівлі в інтересах замовників (ЦЗО)': gettext('Юридична особа, що здійснює закупівлі в інтересах замовників (ЦЗО)'),
        'Орган державної влади, місцевого самоврядування або правоохоронний орган': gettext('Орган державної влади, місцевого самоврядування або правоохоронний орган'),
        'Орган соціального страхування': gettext('Орган соціального страхування'),
        'По запросу': gettext('По запросу'),
        'Ваш город': gettext('Ваш город'),
        'ничего не найдено': gettext('ничего не найдено'),
        'Почтовый адрес': gettext('Почтовый адрес'),
        'Старый пароль': gettext('Старый пароль'),
        'Новый пароль': gettext('Новый пароль'),
        'Пароль повторно': gettext('Пароль повторно'),
        'Подтвердите пароль': gettext('Подтвердите пароль'),
        'Пароль': gettext('Пароль'),
        'Поиск по названию': gettext('Поиск по названию'),
        'Принять запрос': gettext('Принять запрос'),
        'Отклонить запрос': gettext('Отклонить запрос'),
        'Роль в компании': gettext('Роль в компании'),
        'Администратор': gettext('Администратор'),
        'Менеджер': gettext('Менеджер'),
        'Аудитор': gettext('Аудитор'),
        'Регистрация пользователя': gettext('Регистрация пользователя'),
        'Создание новой компании': gettext('Создание новой компании'),
        'Страна': gettext('Страна'),
        'Выберите код компании': gettext('Выберите код компании'),
        'Регистрационный номер компании': gettext('Регистрационный номер компании'),
        'Компания с данным номером уже есть в системе. Вы можете подать заявку владельцу компании на получение прав для работы с этой компанией': gettext('Компания с данным номером уже есть в системе. Вы можете подать заявку владельцу компании на получение прав для работы с этой компанией'),
        'Отправить заявку': gettext('Отправить заявку'),
        'Страна компании': gettext('Страна компании'),
        'Подача заявки в компанию': gettext('Подача заявки в компанию'),
        'Ваш запрос в компанию': gettext('Ваш запрос в компанию'),
        'Вы отправили запрос на получение прав работы с компанией': gettext('Вы отправили запрос на получение прав работы с компанией'),
        'Удалить запрос': gettext('Удалить запрос'),
        'Дата отправки': gettext('Дата отправки'),
        'Код компании': gettext('Код компании'),
        'Статус': gettext('Статус'),
        'Ваша компания': gettext('Ваша компания'),
        'Статус пользователя в компании': gettext('Статус пользователя в компании'),
        'Роль пользователя в компании': gettext('Роль пользователя в компании'),
        'Удалиться из компании': gettext('Удалиться из компании'),
        'Запросы в компанию': gettext('Запросы в компанию'),
        'Нет результатов': gettext('Нет результатов'),
        'Активировать пользователя': gettext('Активировать пользователя'),
        'Деактивировать пользователя': gettext('Деактивировать пользователя'),
        'Удалить пользователя': gettext('Удалить пользователя'),
        'Необходимо указать роль Вашего пользователя:': gettext('Необходимо указать роль Вашего пользователя:'),
        'Указать роль': gettext('Указать роль'),
        'Принять оферту': gettext('Принять оферту'),
        'Принять регламент': gettext('Принять регламент'),
        'Ваша компания проходит модерацию. После проверки Вы сможете работать с процедурами': gettext('Ваша компания проходит модерацию. После проверки Вы сможете работать с процедурами'),
        'Смена пароля': gettext('Смена пароля'),
        'Тип компании': gettext('Тип компании'),
        'Государственная компания': gettext('Государственная компания'),
        'Коммерческая компания': gettext('Коммерческая компания'),
        'К сожалению, кодов для данной странны нет. Введите тип кода в ручную.': gettext('К сожалению, кодов для данной странны нет. Введите тип кода в ручную.'),
        'Ваша компания успешно создана!': gettext('Ваша компания успешно создана!'),
        'Подписка пользователя': gettext('Подписка пользователя'),
        'Ваши классификаторы': gettext('Ваши классификаторы'),
        'Добавить классификаторы': gettext('Добавить классификаторы'),
        'У Вас отсутствуют классификаторы в подписке': gettext('У Вас отсутствуют классификаторы в подписке'),
        'Письмо для восстановления пароля успешно отправлено Вам на почту': gettext('Письмо для восстановления пароля успешно отправлено Вам на почту'),
        'Пароль успешно обновлен. Теперь Вы можете зайти на сайт с новым паролем': gettext('Пароль успешно обновлен. Теперь Вы можете зайти на сайт с новым паролем'),
        'Восстановить пароль': gettext('Восстановить пароль'),
        'Восстановление пароля': gettext('Восстановление пароля'),
        'Восстановить': gettext('Восстановить'),
        'Ключ для восстановления пароля не валидный. Попробуйте восстановить пароль повторно': gettext('Ключ для восстановления пароля не валидный. Попробуйте восстановить пароль повторно'),
        'Компания с этим регистрационным номером уже существует': gettext('Компания с этим регистрационным номером уже существует'),
        'На нашей площадке уже зарегистрирована компания с таким регистрационным номером. Вы можете отправить заявку в эту компании или зарегистрироваться с другим регистрационным номером компании.': gettext('На нашей площадке уже зарегистрирована компания с таким регистрационным номером. Вы можете отправить заявку в эту компании или зарегистрироваться с другим регистрационным номером компании.'),
        'Отправить': gettext('Отправить'),
        'Пользователь успешно зарегистрирован': gettext('Пользователь успешно зарегистрирован'),
        'Отсутствует информация по этому регистрационному номеру': gettext('Отсутствует информация по этому регистрационному номеру'),
        'Проверить': gettext('Проверить'),
        'История действий': gettext('История действий'),

        //info-popover
        'У вас отсутствует компания. Вам необходимо:': gettext('У вас отсутствует компания. Вам необходимо:'),
        'Создать компанию': gettext('Создать компанию'),
        'или': gettext('или'),
        'Подать заявку в компанию': gettext('Подать заявку в компанию'),
        'Необходимо заполнить информацию о Вашей компании:': gettext('Необходимо заполнить информацию о Вашей компании:'),
        'Заполнить информацию': gettext('Заполнить информацию'),
        'Овердрафт': gettext('Овердрафт'),
        'Необходимо заполнить адрес Вашей компании': gettext('Необходимо заполнить адрес Вашей компании'),
        'Заполнить адрес': gettext('Заполнить адрес'),

        'Торг отменен': gettext('Торг отменен'),
        'Дата и время отмены торга': gettext('Дата и время отмены торга'),
        'Причина отмены': gettext('Причина отмены'),
        'Время': gettext('Время'),
        'Документы:': gettext('Документы:'),
        'Дата и время аукциона': gettext('Дата и время аукциона'),
        'Аукцион не проводился': gettext('Аукцион не проводился'),
        'Вы не можете загружать документы, пока не сделали предложение': gettext('Вы не можете загружать документы, пока не сделали предложение'),
        'Заказчик': gettext('Заказчик'),
        'Укажите тип процедуры': gettext('Укажите тип процедуры'),
        'Підтверджує відповідність критеріям прийнятності, встановлених замовником в тендерній документації': gettext('Підтверджує відповідність критеріям прийнятності, встановлених замовником в тендерній документації'),
        'Классификатор ДКПП': gettext('Классификатор ДКПП'),
        'Классификатор ДК021': gettext('Классификатор ДК021'),
        'Адрес поставки': gettext('Адрес поставки'),
        'Редактировать': gettext('Редактировать'),
        'грн.': gettext('грн.'),
        'Адрес доставки': gettext('Адрес доставки'),
        'К сожалению, тендеров данной категории не найдено': gettext('К сожалению, тендеров данной категории не найдено'),
        'Почтовый код': gettext('Почтовый код'),
        'Регион': gettext('Регион'),
        'Населенный пункт': gettext('Населенный пункт'),
        'Сохранить': gettext('Сохранить'),
        'Номер телефона': gettext('Номер телефона'),
        'Опишите причину отказа': gettext('Опишите причину отказа'),
        'Укажите причину отказа': gettext('Укажите причину отказа'),
        'Все': gettext('Все'),
        'Загрузить документы': gettext('Загрузить документы'),
        'Введите название классификатора': gettext('Введите название классификатора'),
        'Закрыть': gettext('Закрыть'),
        'Выбрать': gettext('Выбрать'),
        'Отменить': gettext('Отменить'),
        'Отменить решение': gettext('Отменить решение'),
        'Мое предложение': gettext('Мое предложение'),
        'Вы действительно хотите отменить предложение?': gettext('Вы действительно хотите отменить предложение?'),
        'Не отменять': gettext('Не отменять'),
        'Сделать предложение': gettext('Сделать предложение'),
        'Изменить предложение': gettext('Изменить предложение'),
        'Отменить предложение': gettext('Отменить предложение'),
        'служба поддержки': gettext('служба поддержки'),
        'Вернуться': gettext('Вернуться'),
        'Результаты поиска по запросу': gettext('Результаты поиска по запросу'),
        'Отменить ставку': gettext('Отменить ставку'),
        'Последние предложение': gettext('Последние предложение'),
        'Изменить ставку': gettext('Изменить ставку'),
        'Сделать ставку': gettext('Сделать ставку'),
        'Ссылка на аукцион': gettext('Ссылка на аукцион'),
        'Вернуться в основной NEWTEND': gettext('Вернуться в основной NEWTEND'),
        'Все торги': gettext('Все торги'),
        'Мои торги': gettext('Мои торги'),
        'Все закупки': gettext('Все закупки'),
        'Мои закупки': gettext('Мои закупки'),
        'Регистрация': gettext('Регистрация'),
        'Вход': gettext('Вход'),

        /*missed*/

        'Класс ДК021': gettext('Класс ДК021'),
        'Класс ДКПП': gettext('Класс ДКПП'),
        'Уточнения до': gettext('Уточнения до'),
        'Прием заявок до': gettext('Прием заявок до'),

        // create tender
        'Новый тендер': gettext('Новый тендер'),
        'Название тендера': gettext('Название тендера'),
        'Описание': gettext('Описание'),
        'План (грн.)': gettext('План (грн.)'),
        'с НДС': gettext('с НДС'),
        'Шаг (грн.)': gettext('Шаг (грн.)'),
        'Количество': gettext('Количество'),
        'Классификатор': gettext('Классификатор'),
        'Дата с какой ожидается поставка': gettext('Дата с какой ожидается поставка'),
        'Требуемый крайний срок поставки': gettext('Требуемый крайний срок поставки'),
        'Начало периода уточнений': gettext('Начало периода уточнений'),
        'Завершение периода уточнений': gettext('Завершение периода уточнений'),
        'Начало регистрации предложений': gettext('Начало регистрации предложений'),
        'Конец регистрации предложений': gettext('Конец регистрации предложений'),
        'Удалить': gettext('Удалить'),
        'Опубликовать': gettext('Опубликовать'),
        'Поиск по классификатору': gettext('Поиск по классификатору'),
        'единицы измерения': gettext('единицы измерения'),
        'Выбрать из ранее добавленных адресов': gettext('Выбрать из ранее добавленных адресов'),
        'Поиск адреса по названию': gettext('Поиск адреса по названию'),
        'Страна доставки': gettext('Страна доставки'),
        'Город доставки': gettext('Город доставки'),
        'Регион доставки': gettext('Регион доставки'),


        //documents
        'Загрузить документ': gettext('Загрузить документ'),
        'Обновлен': gettext('Обновлен'),
        'в': gettext('в'),
        'Заменить': gettext('Заменить'),

        // pagination
        'Вперед': gettext('Вперед'),
        'Назад': gettext('Назад'),
        'Страница': gettext('Страница'),

        // tender statuses
        'Уточнение': gettext('Уточнение'),
        'Предложения': gettext('Предложения'),
        'Аукцион': gettext('Аукцион'),
        'Квалификация': gettext('Квалификация'),
        'Рассмотрен': gettext('Рассмотрен'),
        'Несостоявшийся': gettext('Несостоявшийся'),
        'Завершен': gettext('Завершен'),
        'Отменен': gettext('Отменен'),
        'Заблокирована': gettext('Заблокирована'),
        'Есть неотвеченные требования': gettext('Есть неотвеченные требования'),
        'Присутствуют жалобы, которые блокируют процедуру': gettext('Присутствуют жалобы, которые блокируют процедуру'),


        /*Customer app*/


        // sidebar-default
        'Создать тендер': gettext('Создать тендер'),
        'Мои тендеры': gettext('Мои тендеры'),
        'Архив': gettext('Архив'),
        // sidebar-my-tenders
        'Рассмотрено': gettext('Рассмотрено'),

        // sidebar-tender
        'Уточнения': gettext('Уточнения'),
        'Торги': gettext('Торги'),
        'Обжалования': gettext('Обжалования'),
        'Изменить': gettext('Изменить'),
        'Предложение в черновике, необходимо его опубликовать': gettext('Предложение в черновике, необходимо его опубликовать'),
        'Ставка в черновике, необходимо ее опубликовать': gettext('Ставка в черновике, необходимо ее опубликовать'),
        'Опубликовать предложение': gettext('Опубликовать предложение'),
        'Опубликовать ставку': gettext('Опубликовать ставку'),

        // tenders
        'Первая': gettext('Первая'),
        'Последняя': gettext('Последняя'),
        'Размер ставки': gettext('Размер ставки'),
        'Все области': gettext('Все области'),
        'Минимальный бюджет': gettext('Минимальный бюджет'),
        'Максимальный бюджет': gettext('Максимальный бюджет'),
        'Цена за единицу товара': gettext('Цена за единицу товара'),
        'Максимальная цена': gettext('Максимальная цена'),
        'По области': gettext('По области'),
        'Введите дату': gettext('Введите дату'),
        'Оглашение тендера': gettext('Оглашение тендера'),
        'Прием предложений': gettext('Прием предложений'),
        'Искать в донорах': gettext('Искать в донорах'),
        'название или код': gettext('название или код'),
        'Искать в аукционах с жалобой': gettext('Искать в аукционах с жалобой'),

        'По статусу': gettext('По статусу'),
        'По типу процедуры': gettext('По типу процедуры'),
        'Больше опций': gettext('Больше опций'),
        'Сохраните настройки поиска': gettext('Сохраните настройки поиска'),
        'Выберите сохраненный поиск': gettext('Выберите сохраненный поиск'),

        // tender-view.auction
        'Дата и время аукциона:': gettext('Дата и время аукциона:'),
        'Тут будет ссылка на аукцион': gettext('Тут будет ссылка на аукцион'),
        'Участники аукциона': gettext('Участники аукциона'),
        'Подписание договора': gettext('Подписание договора'),
        'Загрузите отсканированый и подписаный договор': gettext('Загрузите отсканированый и подписаный договор'),
        'Закончить торги': gettext('Закончить торги'),

        // tender-view.bid
        'Код ЕДРПОУ': gettext('Код ЕДРПОУ'),
        'Контактное лицо': gettext('Контактное лицо'),
        'Документы': gettext('Документы'),
        'Отказать': gettext('Отказать'),
        'Принять предложение': gettext('Принять предложение'),

        // tender-view.chat
        'Задать вопрос': gettext('Задать вопрос'),
        'Вы не можете задавать вопросы': gettext('Вы не можете задавать вопросы'),

        // tender-view.chat.modal
        'Вопрос': gettext('Вопрос'),
        'Ответ': gettext('Ответ'),
        'Ответить': gettext('Ответить'),

        // tender-view.documents
        'К тендеру не прикреплен ни один документ.': gettext('К тендеру не прикреплен ни один документ.'),

        // customer-modal
        'Информация о компании': gettext('Информация о компании'),
        'Название': gettext('Название'),
        'Адрес': gettext('Адрес'),
        'Контактное лицо:': gettext('Контактное лицо:'),
        'Номер телефона:': gettext('Номер телефона:'),

        // tender-delete-modal
        'Тендер будет отменен. Продолжить?': gettext('Тендер будет отменен. Продолжить?'),
        'Отменить тендер': gettext('Отменить тендер'),

        // tender-view.bids.modal
        'Загрузите документы': gettext('Загрузите документы'),

        // tenderCreateAttachDocsModal
        'В вашем тендере будут документы?': gettext('В вашем тендере будут документы?'),
        'Не будет документов': gettext('Не будет документов'),

        //tender-view.overview
        'Информация про процедуру': gettext('Информация про процедуру'),
        'Контактная информация': gettext('Контактная информация'),
        'Критерий': gettext('Критерий'),
        'Коэффициент коррекции': gettext('Коэффициент коррекции'),
        'Список лотов': gettext('Список лотов'),
        'Перейти к лоту': gettext('Перейти к лоту'),
        'Перейти к предмету закупки': gettext('Перейти к предмету закупки'),
        'c': gettext('c'),
        'Объявлен': gettext('Объявлен'),
        'Документы закупки': gettext('Документы закупки'),
        'Ваши документы': gettext('Ваши документы'),
        'Принять участие в тендере': gettext('Принять участие в тендере'),
        'Принять участие': gettext('Принять участие'),
        'Качественные показатели для поставщика': gettext('Качественные показатели для поставщика'),
        'Перейти к закупке': gettext('Перейти к закупке'),
        'Перейти к аукциону': gettext('Перейти к аукциону'),
        'Подтвердить': gettext('Подтвердить'),
        'Предложение не валидно, необходимо его опубликовать': gettext('Предложение не валидно, необходимо его опубликовать'),
        'Ставка не валидна, необходимо ее опубликовать': gettext('Ставка не валидна, необходимо ее опубликовать'),
        'Ваша ставка в черновике': gettext('Ваша ставка в черновике'),
        'Для участия в торгах, Вам необходимо опубликовать ставку': gettext('Для участия в торгах, Вам необходимо опубликовать ставку'),

        'Объявить второй этап': gettext('Объявить второй этап'),
        'Торги отменены': gettext('Торги отменены'),
        'Торги не состоялись': gettext('Торги не состоялись'),
        'Прикрепить документы': gettext('Прикрепить документы'),
        'Прикрепить документ': gettext('Прикрепить документ'),
        'Конкурентный диалог': gettext('Конкурентный диалог'),
        'Конкурентный диалог с публикацией на английском языке': gettext('Конкурентный диалог с публикацией на английском языке'),
        'Технічний опис предмету закупівлі': gettext('Технічний опис предмету закупівлі'),
        'Тендерна документація': gettext('Тендерна документація'),
        'Объявление второго этапа': gettext('Объявление второго этапа'),
        'Ожидание второго этапа': gettext('Ожидание второго этапа'),
        'Подтвердите оглашение второго этапа': gettext('Подтвердите оглашение второго этапа'),
        'Перейти на второй этап': gettext('Перейти на второй этап'),
        'Первый этап': gettext('Первый этап'),
        'Черновик. Второй этап': gettext('Черновик. Второй этап'),
        'Активировать процедуру': gettext('Активировать процедуру'),
        'Обжалования принимаются': gettext('Обжалования принимаются'),
        'Преквалификация': gettext('Преквалификация'),
        'Подтвердить предложение': gettext('Подтвердить предложение'),
        'Ожидание аукциона': gettext('Ожидание аукциона'),
        'Активная закупка': gettext('Активная закупка'),
        'Расширенный поиск': gettext('Расширенный поиск'),
        'Статусы': gettext('Статусы'),
        'Процедуры': gettext('Процедуры'),
        'Классификаторы': gettext('Классификаторы'),
        'Искать': gettext('Искать'),
        'Назад в поиск': gettext('Назад в поиск'),

        'Искать в': gettext('Искать в'),
        'По принадлежности': gettext('По принадлежности'),

        'Искать только в моих закупках': gettext('Искать только в моих закупках'),
        'Все статусы': gettext('Все статусы'),
        'Принять': gettext('Принять'),
        'Отклонить': gettext('Отклонить'),
        'Внести изменения': gettext('Внести изменения'),
        'Підтвердження відповідності кваліфікаційним критеріям': gettext('Підтвердження відповідності кваліфікаційним критеріям'),
        'Опис рішення про закупівлю': gettext('Опис рішення про закупівлю'),
        'Зміни до договору': gettext('Зміни до договору'),
        'Звіт про виконання': gettext('Звіт про виконання'),
        'Звіт про розірвання': gettext('Звіт про розірвання'),
        'Завершить изменения': gettext('Завершить изменения'),
        'Без применения электронной системы': gettext('Без применения электронной системы'),
        'Для публикации процедуры на официальном портале добавьте участника процедуры и квалифицируйте его': gettext('Для публикации процедуры на официальном портале добавьте участника процедуры и квалифицируйте его'),
        'Вы можете внести несколько участников Переговорной процедуры. Внесите данные по участникам Переговоров, а затем внесите данные по Победителю': gettext('Вы можете внести несколько участников Переговорной процедуры. Внесите данные по участникам Переговоров, а затем внесите данные по Победителю'),
        'Создать копию': gettext('Создать копию'),


        'Качественные показатели для предмета закупки': gettext('Качественные показатели для предмета закупки'),
        'Качественные показатели для лота': gettext('Качественные показатели для лота'),
        'Участвовать в аукционе': gettext('Участвовать в аукционе'),

        'Пароли не совпадают': gettext('Пароли не совпадают'),
        'Минимальная длина:': gettext('Минимальная длина:'),
        'Максимальная длина:': gettext('Максимальная длина:'),

        'По умолчанию': gettext('По умолчанию'),
        'Отправлять уведомления на этот адрес': gettext('Отправлять уведомления на этот адрес'),
        'Получает по умолчанию': gettext('Получает по умолчанию'),
        'Вы не подтвердили эту электронную почту': gettext('Вы не подтвердили эту электронную почту'),
        'Изменить контактную информацию': gettext('Изменить контактную информацию'),
        'Классификатор INN': gettext('Классификатор INN'),
        'Классификатор ATC': gettext('Классификатор ATC'),
        'INN': gettext('INN'),
        'ATC': gettext('ATC'),


        'Рішення про початок моніторингу': gettext('Рішення про початок моніторингу'),
        'Діалог': gettext('Діалог'),
        'Учасники': gettext('Учасники'),
        'Усунення порушення': gettext('Усунення порушення'),
        'Підстави для прийняття рішення про початок моніторингу': gettext('Підстави для прийняття рішення про початок моніторингу'),
        'Етапи закупівельного процесу': gettext('Етапи закупівельного процесу'),
        'Період мониторинга': gettext('Період мониторинга'),
        'Дата створення проекту': gettext('Дата створення проекту'),
        'Дата публікації проектуh': gettext('Дата публікації проекту'),
        'Дата публікації висновку про результати': gettext('Дата публікації висновку про результати'),
        'Звернення': gettext('Звернення'),
        'Опис': gettext('Опис'),
        'Документи': gettext('Документи'),
        'Дата створення': gettext('Дата створення'),
        'Дата публіукації': gettext('Дата публіукації'),
        'Опис підстав для здійснення моніторингу': gettext('Опис підстав для здійснення моніторингу'),
        'Дата прийняття рішення про проведення моніторингу': gettext('Дата прийняття рішення про проведення моніторингу'),
        'Запитання': gettext('Запитання'),
        'Відповідь': gettext('Відповідь'),
        'Надати відповідь': gettext('Надати відповідь'),
        'Подати звернення з власної ініцівтиви': gettext('Подати звернення з власної ініцівтиви'),
        'Подати звернення за розясненням щодо висновку': gettext('Подати звернення за розясненням щодо висновку'),
        'Висновок про результати моніторингу': gettext('Висновок про результати моніторингу'),
        'Виявлено порушення/не виявлено порушення': gettext('Виявлено порушення/не виявлено порушення'),
        'Виявлено порушення': gettext('Виявлено порушення'),
        'Не виявлено порушення': gettext('Не виявлено порушення'),
        'Типи порушень': gettext('Типи порушень'),
        'Інформація про результати моніторингу закупівлі у розрізі стадій проведення процедури закупівлі': gettext('Інформація про результати моніторингу закупівлі у розрізі стадій проведення процедури закупівлі'),
        'Зобов’язанння щодо усунення порушення (порушень) законодавства у сфері публічних закупівель': gettext('Зобов’язанння щодо усунення порушення (порушень) законодавства у сфері публічних закупівель'),
        'Висновок про наявність або відсутність порушень законодавства': gettext('Висновок про наявність або відсутність порушень законодавства'),
        'Відміна моніторінга': gettext('Відміна моніторінга'),
        'Підстава': gettext('Підстава'),
        'Дата публікації': gettext('Дата публікації'),
        'Інформація про усунення порушення': gettext('Інформація про усунення порушення'),
        'Звіт': gettext('Звіт'),
        'Документи звіту': gettext('Документи звіту'),
        'Дата редагування': gettext('Дата редагування'),
        'Звіт рішенния': gettext('Звіт рішенния'),
        'Дата створення звіта рішення': gettext('Дата створення звіта рішення'),
        'Дата редагування рішення': gettext('Дата редагування рішення'),
        'Результат': gettext('Результат'),
        'Виявлені порушення': gettext('Виявлені порушення'),
        'Документи рішення': gettext('Документи рішення'),
        'Підтвердити': gettext('Підтвердити'),
        'Зміст рішення': gettext('Зміст рішення'),
        'Завантажити документ': gettext('Завантажити документ'),
        'Додати документ': gettext('Додати документ'),
        'Завантажуються файли. Залишилось:': gettext('Завантажуються файли. Залишилось:'),
        'Заголовок питання': gettext('Заголовок питання'),
        'Зміст питання': gettext('Зміст питання'),
        'Файли завантажені ранійше': gettext('Файли завантажені ранійше'),
        'Нові файли': gettext('Нові файли'),
        'Подати звернення': gettext('Подати звернення'),
        'Зміст звернення': gettext('Зміст звернення'),
        'Додати файли до звернення': gettext('Додати файли до звернення'),
        'Рішення про початок моніторингу відсутні': gettext('Рішення про початок моніторингу відсутні'),
        'на рішення': gettext('на рішення'),
        'на висновок': gettext('на висновок'),
        'Надати відповідь до': gettext('Надати відповідь до'),
        'від': gettext('від'),
        'Дата публікації висновку': gettext('Дата публікації висновку'),
        'Оскарження в суді': gettext('Оскарження в суді'),
        'Інспекція': gettext('Інспекція'),

        'Замовити юридичну консультацію': gettext('Замовити юридичну консультацію'),
        'Примітка': gettext('Примітка'),
        'Ваш запит вже в роботі': gettext('Ваш запит вже в роботі'),
        'Заявку відправлено. З Вами зв\'яжеться представник майданчика.': gettext('Заявку відправлено. З Вами зв\'яжеться представник майданчика.'),
        'Редагування': gettext('Редагування'),
        'Терміни закупівлі': gettext('Терміни закупівлі'),
        'Замовник та контактна особа': gettext('Замовник та контактна особа'),

        'Добавить предмет закупки Вы можете внутри формы добавления лота': gettext('Добавить предмет закупки Вы можете внутри формы добавления лота'),
        'Стоимость': gettext('Стоимость'),

        'Років': gettext('Років'),
        'Місяців': gettext('Місяців'),
        'Днів': gettext('Днів'),

        'Сформувати протокол розгляду': gettext('Сформувати протокол розгляду'),
        'Протокол розгляду сформовано': gettext('Протокол розгляду сформовано'),
        'Неактивоване запрошення': gettext('Неактивоване запрошення'),
        'Неуспішна закупівля': gettext('Неуспішна закупівля'),
        'Період запрошення': gettext('Період запрошення'),
        'Виконана рамкова угода': gettext('Виконана рамкова угода'),
        'Розірвана рамкова угода': gettext('Розірвана рамкова угода'),
        'Причини припинення': gettext('Причини припинення'),
        'Добавить Условия оплаты': gettext('Добавить Условия оплаты'),

        'Оберіть тип процедури:': gettext('Оберіть тип процедури:'),
        'Тип процедури:': gettext('Тип процедури:'),
        'Спрощена': gettext('Спрощена'),
        'Допорогова': gettext('Допорогова'),

        'Заказчики': gettext('Заказчики'),
        'Поставщики': gettext('Поставщики'),
        'О Компании': gettext('О Компании'),
        'Закупки компании': gettext('Закупки компании'),
        'Виды деятельности': gettext('Виды деятельности'),
        'Введите название компании или код ЕДРПОУ': gettext('Введите название компании или код ЕДРПОУ'),
        'Выберите область': gettext('Выберите область'),
        'По классификатору ДК 021:2015': gettext('По классификатору ДК 021:2015'),
        'Номер додаткової угоди': gettext('Номер додаткової угоди'),
        'Мои черновики': gettext('Мои черновики'),
        'Перейти на страницу компании': gettext('Перейти на страницу компании'),
        'Угоди': gettext('Угоди'),
        'Компании': gettext('Компании'),
        'Мои': gettext('Мои'),
        'Перейти до обраного': gettext('Перейти до обраного'),
        'Конфіденційність документів визначається Законом': gettext('Конфіденційність документів визначається Законом'),
        'стаття 27, п. 2': gettext('стаття 27, п. 2'),
        'позиц.': gettext('позиц.'),
        'Інформація про умови та етапи оплати': gettext('Інформація про умови та етапи оплати'),
        'Вид предмету закупівлі': gettext('Вид предмету закупівлі'),
        'Зв’язані плани Закупівлі': gettext('Зв’язані плани Закупівлі'),
        'За обраними параметрами пошуку Закупівлі не знайдені': gettext('За обраними параметрами пошуку Закупівлі не знайдені'),
        'Закупівельник та контактна особа': gettext('Закупівельник та контактна особа'),
        'Ви учасник': gettext('Ви учасник'),
        'Перейти ...': gettext('Перейти ...'),
        'Кількість обраних файлів не обмежена': gettext('Кількість обраних файлів не обмежена'),
        'Мониторинг': gettext('Мониторинг'),
        'Украинский язык': gettext('Украинский язык'),
        'Английский язык': gettext('Английский язык'),
        'Русский язык': gettext('Русский язык'),
        'Увага!': gettext('Увага!'),
        'Річний План': gettext('Річний План'),
        'Ідентифікатор плану': gettext('Ідентифікатор плану'),
        'По найменуванню': gettext('По найменуванню'),
        'По даті публікації': gettext('По даті публікації'),
        'За типами документів': gettext('За типами документів'),
        'По лотах і позиціям': gettext('По лотах і позиціям'),
        'Скинути параметри пошуку': gettext('Скинути параметри пошуку'),
        'Регион компании': gettext('Регион компании'),
        'Классификация субъектов предпринимательской деятельности': gettext('Классификация субъектов предпринимательской деятельности'),
        'Не является субъектом предпринимательской деятельности': gettext('Не является субъектом предпринимательской деятельности'),
        'Субъект большого предпринимательства': gettext('Субъект большого предпринимательства'),
        'Субъект среднего предпринимательства': gettext('Субъект среднего предпринимательства'),
        'Субъект малого предпринимательства': gettext('Субъект малого предпринимательства'),
        'Субъект микропредпринимательства': gettext('Субъект микропредпринимательства'),
        'Посилання': gettext('Посилання'),
        'Новини майданчика': gettext('Новини майданчика'),
        'Додати документи': gettext('Додати документи'),
        'Закупівля (до тендеру в цiлому)': gettext('Закупівля (до тендеру в цiлому)'),
        'Переможець тендеру': gettext('Переможець тендеру'),
        'прийнято рішення': gettext('прийнято рішення'),
        'місце розгляду': gettext('місце розгляду'),
        'розглянуто скаргу': gettext('розглянуто скаргу'),
        'Цена за единицу': gettext('Цена за единицу'),
        'Випадки для внесення змін до істотних умов договору згідно з частиною п\"ятою статті 41 Закону України \"Про публічні закупівлі\"': gettext('Випадки для внесення змін до істотних умов договору згідно з частиною п\'ятою статті 41 Закону України "Про публічні закупівлі"'),
        'Добавьте електронную подпись': gettext('Добавьте електронную подпись'),
        'Теперь можно подписать': gettext('Теперь можно подписать'),
        'Обновить': gettext('Обновить'),
        'Подпись документов': gettext('Подпись документов'),
        'Добавить подпись': gettext('Добавить подпись'),
        'Закончить': gettext('Закончить'),
        'Мои предложения': gettext('Мои предложения'),
        'Добавить критерии': gettext('Добавить критерии'),
        'Права Уповноваженої особи': gettext('Права Уповноваженої особи'),
        'Сохранить': gettext('Сохранить'),
        'Страна производитель для': gettext('Страна производитель для'),
        'Проверка на Уповноважену Особу отклонена': gettext('Проверка на Уповноважену Особу отклонена'),
        'Дані про Пропозицію відсутні': gettext('Дані про Пропозицію відсутні'),
        'КЕП закупівлі': gettext('КЕП закупівлі'),
        'get_tender_info': gettext('get_tender_info'),
        'update_user_contact': gettext('update_user_contact'),
        'create_user_contact': gettext('create_user_contact'),
        'create_tender_draft': gettext('create_tender_draft'),
        'patch_tender_draft': gettext('patch_tender_draft'),
        'update_tender': gettext('update_tender'),
        'delete_tender': gettext('delete_tender'),
        'update_bid_draft': gettext('update_bid_draft'),
        'update_bid': gettext('update_bid'),
        'delete_bid': gettext('delete_bid'),
        'bid_publish': gettext('bid_publish'),
        'tender_publish': gettext('tender_publish'),
        'update_company_info': gettext('update_company_info'),
        'tender_sign': gettext('tender_sign'),
        'Дата': gettext('Дата'),
        'Фильтровать': gettext('Фильтровать'),
        'По событиям': gettext('По событиям'),
        'ID тендера': gettext('ID тендера'),
        'Время по:': gettext('Время по:'),
        'Дата по:': gettext('Дата по:'),
        'Время с:': gettext('Время с:'),
        'Дата с:': gettext('Дата с:'),
        'Пользователь': gettext('Пользователь'),
        'Наименование заказчика': gettext('Наименование заказчика'),
        'Опис Закупівлі': gettext('Опис Закупівлі'),
        'Найменування': gettext('Найменування'),
        'Переглянути Закупівлю': gettext('Переглянути Закупівлю'),
        'Опис Пропозиції': gettext('Опис Пропозиції'),
        'Дата підписання': gettext('Дата підписання'),
        'Контактна особа': gettext('Контактна особа'),
        'Документи Пропозиції': gettext('Документи Пропозиції'),
        'Сортувати': gettext('Сортувати'),
        'Перейти до Пропозиції': gettext('Перейти до Пропозиції'),
        'Всі документи': gettext('Всі документи'),
        'До:': gettext('До:'),
        'Приватність': gettext('Приватність'),
        'Перевірка': gettext('Перевірка'),
        'Позиція': gettext('Позиція'),
        'tender' : gettext('tender'),
        'auction' : gettext('auction'),
        'auction_lot' : gettext('auction_lot'),
        'award' : gettext('award'),
        'complaint' : gettext('complaint'),
        'enquiry' : gettext('enquiry'),
        'День': gettext('День'),
        'Неделя': gettext('Неделя'),
        'весь день': gettext('весь день'),
        'С 23 Августа': gettext('С 23 Августа создать запороговые торги можно только с созданного Плана Закупок. Перейдите в '),
        'Планируемая дата начала процедуры': gettext('Планируемая дата начала процедуры'),
        'Реєстраційні дані компанії не відповідають реєстраційним даним ключа': gettext('Реєстраційні дані компанії не відповідають реєстраційним даним ключа'),
        'Ваша розсилка': gettext('Ваша розсилка'),
        'Title': gettext('Title'),
        'Enabled': gettext('Enabled'),
        'Sort': gettext('Sort'),
        'Count of result': gettext('Count of result'),
        'EDRPOU codes': gettext('EDRPOU codes'),
        'Классификатор Дкпп': gettext('Классификатор Дкпп'),
        'Create': gettext('Create'),
        'Delete': gettext('Delete'),
        'Created': gettext('Created'),
        'Max Budget': gettext('Max Budget'),
        'Min budget': gettext('Min budget'),
        'CPV Code': gettext('CPV Code'),
        'Dkpp code': gettext('Dkpp code'),
        'Add Edrpou': gettext('Add Edrpou'),
        'Шось зламалось': gettext('Шось зламалось'),
        'Форма не валідна': gettext('Форма не валідна'),
        'Видалено': gettext('Видалено'),
        'For localization criteria item should have category': gettext('For localization criteria item should have category'),

    });
});
