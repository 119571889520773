
tenderView.controller('tenderViewContractInfo', function (
    $scope,
    $rootScope,
    $uibModalInstance,
    data,
    ntFlash,
    uibDatepickerConfig,
    contractService,
) {
    $scope.contract = data.contract
    $scope.isWinner = data.isWinner

    $scope.signerInfo = {
        name: '',
        email: '',
        position: '',
        telephone: '',
        authorizedBy: '',
        iban: '',
    }
    if($scope.contract.buyer.signerInfo  &&  !$scope.isWinner){
        $scope.signerInfo = $scope.contract.buyer.signerInfo
    }
    if($scope.contract.suppliers[0].signerInfo &&  $scope.isWinner){
        $scope.signerInfo = $scope.contract.suppliers[0].signerInfo
    }

    $scope.addInfo = function() {
        return contractService
            .addData($scope.signerInfo, $scope.contract.id, $scope.isWinner)
            .then(() => {
                $uibModalInstance.close();
                ntFlash.success(gettext('Збережено'));
            });
    }

    $scope.close = function () {
        $uibModalInstance.close();
    };




});
